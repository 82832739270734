import React, { CSSProperties } from 'react';
import styles from './LotDemandWidget.module.css';
import { normalizeOpacity } from './utils';
import StarIcon from 'components/Icons/StarIcon';

export type HeatMapGraphProps = {
    maxValue: { copy: string; value: number; coordinates: string };
    data: Record<string, number[]>;
    xLabels: string[];
    lastUpdatedAt: string;
    yLabelsStyle?: CSSProperties;
};

const HeatMapGraph = ({ data, xLabels, maxValue, yLabelsStyle = {} }: HeatMapGraphProps) => {
    return (
        <div className={styles.heatmap}>
            <table>
                <tbody>
                    {Object.keys(data).map((key: string) => {
                        const dataBlock = data[key];
                        return (
                            <tr key={key}>
                                <td
                                    className={styles.label}
                                    style={{ textAlign: 'right', paddingRight: '8px', ...yLabelsStyle }}
                                >
                                    {key}
                                </td>
                                {dataBlock.map((value, i) => {
                                    const opacity = normalizeOpacity(value, maxValue.value);
                                    const isMaxValue = `${key}-${i}` === maxValue.coordinates;
                                    const op = opacity > 0.04 ? opacity : 0.04;
                                    const textColor = opacity > 0.3 ? '#FFF' : '#717680';

                                    return (
                                        <td
                                            style={{ backgroundColor: `rgba(90, 106, 255, ${op})` }}
                                            key={`${key}_${i}`}
                                            className={isMaxValue ? styles.maxValue : styles.values}
                                        >
                                            <span style={{ color: textColor }}>
                                                {isMaxValue && <StarIcon color="#ffffff" height="8px" width="8px" />}
                                                {value || '-'}
                                            </span>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    <tr>
                        {/* Next line with empty td is needed for proper alignment */}
                        <td></td>
                        {xLabels.map((label) => (
                            <td className={styles.label} key={label}>
                                {label}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default HeatMapGraph;
