import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { PricingPayload } from 'types';
import WidgetSubheader from '../widgets/WidgetSubheader';
import styles from './PricingWidget.module.css';
import PriceGraph from './PriceGraph';
import LightingIcon from 'components/Icons/LightingIcon';

const TOOLTIP = {
    title: 'Hourly rate trends',
    message:
        'The hourly rate for your lot is automatically adjusted based on lot demand, to increase your revenue during high-demand periods. Below are historical rates for the past 7 days.',
};

const LEARN_MORE_URL = 'https://airgarage.notion.site/airgarage/Dynamic-Pricing-7c5880c4042c4870a5f8d3e16b3149f7';

type PriceGraphProps = {
    rates: PricingPayload[] | null;
    errorMessage: string;
    timezone: string;
};
function PriceGraphWrapper({ rates, errorMessage, timezone }: PriceGraphProps): JSX.Element {
    return (
        <div className={styles.graphWrapper}>
            <WidgetSubheader icon={<LightingIcon />} label="Hourly Rate Trends" tooltip={TOOLTIP} />
            <p>
                We optimize your hourly rate based on lot demand.{' '}
                <a href={LEARN_MORE_URL} target="_blank" rel="noopener noreferrer">
                    Learn More <Icon name="external alternate" />
                </a>
            </p>
            {errorMessage ? (
                <Message color="red">{errorMessage}</Message>
            ) : (
                rates && <PriceGraph rates={rates} timezone={timezone} />
            )}
        </div>
    );
}

export default PriceGraphWrapper;
