import React, { useMemo, useContext, createContext, useCallback } from 'react';
import reviewReportReducer, { ReviewReportState, initialReviewReportState } from './reducer';
import { REVIEW_REPORTS_FAILED, REVIEW_REPORTS_REQUESTED, REVIEW_REPORTS_SUCCESS } from './constants';
import { reportClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';

interface ReviewReportActions {
    getReviewReports: (spotUuid: string, days: number) => void;
}

interface ReviewReportContextType extends ReviewReportState, ReviewReportActions {}

export const ReviewReportContext = createContext<ReviewReportContextType | null>(null);

export const ReviewReportProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        reviewReportReducer,
        {
            ...initialReviewReportState,
        },
        [],
        [unauthorized]
    );

    const getReviewReports = useCallback(async (spotUuid: string, days: number) => {
        dispatch({ type: REVIEW_REPORTS_REQUESTED });
        try {
            const response = await reportClient.getReviewReport(spotUuid, days);
            dispatch({ type: REVIEW_REPORTS_SUCCESS, payload: { report: response } });
        } catch (error) {
            dispatch({
                type: REVIEW_REPORTS_FAILED,
                payload: {
                    error,
                    message: error?.toString() || 'Could not fetch reports',
                },
            });
        }
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo<ReviewReportContextType>(() => {
        return {
            ...state,
            getReviewReports,
        };
    }, [state, getReviewReports]);

    return <ReviewReportContext.Provider value={value}>{children}</ReviewReportContext.Provider>;
};

export const useReviewReport = () => {
    const context = useContext(ReviewReportContext);

    if (!context) {
        throw new Error('Error: useReviewReport should be wrapped by ReviewReportProvider.');
    }

    return context;
};
