import React from 'react';
import { useDailyRentals } from 'contexts/DailyRentalsContext';
import { SlotInterface, SpotInterface } from 'types';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import { EFEKTA_ORG_PK, RentalType, SlotState } from 'utils/constants';
import Loader from 'components/Loader/Loader';
import SearchInput from 'components/AGForm/Inputs/SearchInput/SearchInput';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import AGMultipleSelect from 'components/AGSelect/AGMultipleSelect/AGMultipleSelect';
import AGMessage from 'components/AGMessage/AGMessage';
import Modal, { ModalRef } from 'components/Modal/Modal';
import EditRenterForm, { Changes } from 'components/RenterList/EditRenterForm';
import DailyRentalsTable from './DailyRentalsTable';
import styles from './DailyRentals.module.css';

type DailyRentalsProps = {
    selectedSpot: SpotInterface;
    filters: {
        page: string;
        searchTerm: string;
        slotState: SlotState;
        currentSearchTerm: string;
        rentalType: RentalType;
    };
    urlUpdater: {
        page: (page: string | null) => void;
        searchTerm: (search: string) => void;
        slotState: (state: SlotState) => void;
        rentalType: (rentalType: RentalType) => void;
    };
    organizationId: number;
};

function DailyRentals({ urlUpdater, filters, selectedSpot, organizationId }: DailyRentalsProps) {
    const [rentalBeingEdited, setRentalBeingEdited] = React.useState<SlotInterface | null>(null);
    const editRentalModal = React.useRef<ModalRef>();

    const rentalTypeOptions = [
        { value: RentalType.Daily, label: 'Daily' },
        { value: RentalType.Flat, label: 'Flat Rate' },
        { value: RentalType.Hourly, label: 'Hourly' },
    ];

    const {
        dailyRentals,
        loading,
        errorMessage,
        rentalOperationLoading,
        rentalOperationErrorMessage,
        getDailyRentals,
        editDailyRental,
    } = useDailyRentals();

    React.useEffect(() => {
        getDailyRentals(selectedSpot.pk, {
            page: Number(filters.page),
            search: filters.searchTerm,
            slotState: filters.slotState,
            rentalType: filters.rentalType,
        });
    }, [filters.slotState, filters.page, filters.searchTerm, filters.rentalType, getDailyRentals, selectedSpot]);

    function handleSlotStateChange(slot: SlotState): void {
        urlUpdater.slotState(slot);
        urlUpdater.page('1');
    }

    function handleSlotRentalTypeChange(rentalType: RentalType): void {
        urlUpdater.rentalType(rentalType);
        urlUpdater.page('1');
    }

    function searchRentals(event: React.ChangeEvent<HTMLInputElement>) {
        urlUpdater.searchTerm(event.target.value);
    }

    async function onEditRental(changes: Changes) {
        if (rentalBeingEdited) {
            await editDailyRental(
                selectedSpot.pk,
                rentalBeingEdited,
                {
                    page: Number(filters.page),
                    search: filters.searchTerm,
                    slotState: filters.slotState,
                    rentalType: filters.rentalType,
                },
                changes
            );
            editRentalModal.current?.closeModal();
            setRentalBeingEdited(null);
        }
    }

    function editRental(rental: SlotInterface) {
        setRentalBeingEdited(rental);
        editRentalModal.current?.openModal();
    }

    return (
        <div style={{ width: '100%' }}>
            {errorMessage && <AGMessage color="error" title={errorMessage} />}
            {rentalOperationErrorMessage && <AGMessage color="error" title={rentalOperationErrorMessage} />}

            <div className={styles.filters}>
                <SearchInput
                    onChange={searchRentals}
                    value={filters.currentSearchTerm}
                    placeholder="Search by plate, name, or phone"
                    autoFocus
                    type="text"
                />
                <AGSimpleSelect
                    selected={filters.slotState}
                    onSelect={(selection) => handleSlotStateChange(selection as SlotState)}
                    options={[
                        { value: SlotState.Active, label: 'Active' },
                        { value: SlotState.Upcoming, label: 'Upcoming' },
                    ]}
                    icon={<Lines />}
                />
                <AGMultipleSelect
                    selectedValues={filters.rentalType.split(',')}
                    onSelect={(selection) => handleSlotRentalTypeChange(selection.join(',') as RentalType)}
                    options={rentalTypeOptions}
                    icon={<Lines />}
                    placeholder="Rental type"
                />
            </div>
            {loading || !dailyRentals ? (
                <Loader />
            ) : (
                <DailyRentalsTable
                    paginatedDailyRentals={dailyRentals}
                    page={filters.page}
                    setPage={urlUpdater.page}
                    editRental={editRental}
                    organizationId={organizationId}
                    showContact={
                        filters.rentalType.includes(RentalType.Daily) ||
                        (filters.rentalType.includes(RentalType.Flat) && organizationId === EFEKTA_ORG_PK)
                    }
                />
            )}

            {/* Edit rental modal */}
            <Modal ref={editRentalModal} title="Update renter">
                {rentalBeingEdited && (
                    <EditRenterForm
                        rental={rentalBeingEdited}
                        updateRental={onEditRental}
                        loading={rentalOperationLoading}
                    />
                )}
            </Modal>
        </div>
    );
}

export default DailyRentals;
