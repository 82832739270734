import React, { useState, useEffect, useRef } from 'react';
import { View } from 'react-big-calendar';
import { RateCalendarRecord } from 'types/Rate';
import { ExperimentCalendarRecord } from 'types/Experiment';
import { getStartAndEndFromViewAndDate } from 'utils/helpers';
import { useRateCalendar } from 'contexts/RatesCalendarContext';
import { useExperimentCalendar } from 'contexts/ExperimentsCalendarContext';
import AGMessage from 'components/AGMessage/AGMessage';
import Modal, { ModalRef } from 'components/Modal/Modal';
import CalendarWrapper from 'components/CalendarWrapper/CalendarWrapper';
import { transformEvents } from './utils';
import RateInfo from './RateInfo';
import ExperimentInfo from './ExperimentInfo';

function RatesCalendar({ selectedSpot }: { selectedSpot: number }) {
    // context props
    const { rates, getRates, errorMessage: rateErrorMessage } = useRateCalendar();
    const { experiments, getExperiment, errorMessage: expErrorMessage } = useExperimentCalendar();
    // local state
    const [rate, setRate] = useState<RateCalendarRecord | null>(null);
    const [experiment, setExperiment] = useState<ExperimentCalendarRecord | null>(null);
    const [defaultDate, setDefaultDate] = useState<Date>(new Date());
    const [defaultView, setDefaultView] = useState<View>('week');
    // modals
    const rateModalRef = useRef<ModalRef>();
    const experimentModalRef = useRef<ModalRef>();

    const errorMessage = rateErrorMessage || expErrorMessage;

    useEffect(() => {
        const { start, end } = getStartAndEndFromViewAndDate(defaultDate, defaultView);
        getRates(selectedSpot, start, end);
        getExperiment(selectedSpot, start, end);
    }, [getExperiment, getRates, selectedSpot, defaultDate, defaultView]);

    function computeRangeAndGetRates(date: Date, view: View) {
        const { start, end } = getStartAndEndFromViewAndDate(date, view);
        setDefaultDate(date);
        setDefaultView(view);
        getRates(selectedSpot, start, end);
        getExperiment(selectedSpot, start, end);
    }

    function handleOnSelect(calendarRecord: any) {
        if ((calendarRecord as ExperimentCalendarRecord).segments) {
            setExperiment(calendarRecord as ExperimentCalendarRecord);
            experimentModalRef.current?.openModal();
        } else {
            setRate(calendarRecord as RateCalendarRecord);
            rateModalRef.current?.openModal();
        }
    }

    return (
        <div>
            <div>
                {errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}
                <Modal ref={rateModalRef} title={rate?.name || ''}>
                    {rate && <RateInfo rate={rate} />}
                </Modal>
                <Modal ref={experimentModalRef} title={experiment?.name || ''}>
                    {experiment && <ExperimentInfo experiment={experiment} />}
                </Modal>
            </div>
            <div>
                <CalendarWrapper
                    defaultView={defaultView as View}
                    min={new Date(2023, 0, 2, 5, 0)}
                    events={transformEvents([...rates, ...experiments])}
                    onNavigate={(date, view) => {
                        computeRangeAndGetRates(date, view);
                    }}
                    onView={(view) => {
                        computeRangeAndGetRates(defaultDate, view);
                    }}
                    onSelectEvent={handleOnSelect}
                    // backgroundEvents={transformEvents(experiments)} // TODO: Ticket AG-4846 - upgrade calendar to get this property
                />
            </div>
            <div>
                <span>
                    *Please note that our system prioritizes overlapping rates in the following order: one time,
                    monthly, weekly, daily.
                </span>
            </div>
        </div>
    );
}

export default RatesCalendar;
