import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import VisitorCodes from 'components/VisitorCodes/VisitorCodes';
import { AppState } from 'reducers/reducers';
import { selectedSpotId } from 'selectors';

import { VisitorCodesProvider } from 'contexts/VisitorCodesContext';
import { EventsProvider } from 'contexts/EventsContext';

type VisitorCodesModuleContainerProps = ReturnType<typeof mapStateToProps>;

function VisitorCodesModuleContainer(props: VisitorCodesModuleContainerProps) {
    const { selectedSpot } = props;

    return (
        <div>
            {selectedSpot ? (
                <VisitorCodesProvider>
                    <EventsProvider>
                        <VisitorCodes selectedSpot={selectedSpot} />
                    </EventsProvider>
                </VisitorCodesProvider>
            ) : (
                <Loader active inline />
            )}
        </div>
    );
}

function mapStateToProps(state: AppState) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spotId = selectedSpotId(state)!;
    return {
        selectedSpot: spotId,
    };
}

export default connect(mapStateToProps, {})(VisitorCodesModuleContainer);
