import React from 'react';
import { Event, EventInterval } from 'types/Event';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import Label from 'components/AGForm/Inputs/Label/Label';
import AGMultipleSelect from 'components/AGSelect/AGMultipleSelect/AGMultipleSelect';
import SingleDateSelector from 'components/SingleDateSelector/SingleDateSelector';
import { ValidityType } from './helpers';
import { VisitorCodeForm } from 'contexts/VisitorCodesContext';
import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { newUTCDate } from 'utils/helpers';

type ValidityInputsProps = {
    validityType: ValidityType;
    events: Event[];
    inputs: VisitorCodeForm;
    handleInput: (event: React.SyntheticEvent<HTMLInputElement, globalThis.Event>) => void;
    handleEventChange: (eventsPk: string[]) => void;
    handleDateChange: (date: Date | undefined) => void;
};

function ValidityInputs({ validityType, inputs, handleInput, ...props }: ValidityInputsProps) {
    switch (validityType) {
        case ValidityType.EVENTS: {
            const eventOptions: SelectOption[] = [];

            for (const event of props.events) {
                const value = String(event.pk);

                if (!eventOptions.find((e) => e.value === value)) {
                    const details = event.interval === EventInterval.OneTime ? event.date : event.interval;
                    eventOptions.push({
                        value,
                        label: `${event.name} (${details})`,
                    });
                }
            }

            return (
                <div>
                    <Label label="Events" />
                    <AGMultipleSelect
                        onSelect={props.handleEventChange}
                        selectedValues={inputs.events}
                        placeholder="Select event..."
                        options={eventOptions}
                        width="100%"
                    />
                </div>
            );
        }
        case ValidityType.TEMPORARY:
            return (
                <>
                    <TextInput
                        label="Days"
                        onChange={handleInput}
                        name="days_valid"
                        value={inputs.days_valid || ''}
                        type="number"
                        errors={null}
                        placeholder="0"
                    />
                    <TextInput
                        label="Hours"
                        onChange={handleInput}
                        name="hours_valid"
                        value={inputs.hours_valid || ''}
                        type="number"
                        errors={null}
                        placeholder="0"
                    />
                </>
            );
        case ValidityType.EXPIRATION: {
            const date = inputs.expiration_date ? newUTCDate(inputs.expiration_date) : undefined;
            return (
                <div>
                    <Label label="Expiration Date" />
                    <SingleDateSelector
                        date={date}
                        onSave={props.handleDateChange}
                        placeHolder="Select Expiration Date"
                        noIcon
                        dropdownWidth="100%"
                    />
                </div>
            );
        }
        case ValidityType.PERMANENT:
            return <span>Drivers who use this code will have free parking until the code is deleted.</span>;
    }
}

export default ValidityInputs;
