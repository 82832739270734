import React from 'react';
import styles from './WidgetBox.module.css';

export type WidgetTitleProps = {
    title: string;
    icon?: JSX.Element;
    updatesLabel?: JSX.Element;
    dropdown?: JSX.Element;
    lastUpdatedLabel?: string;
};

function WidgetTitle({ icon, title, updatesLabel, dropdown, lastUpdatedLabel }: WidgetTitleProps): JSX.Element {
    return (
        <div className={styles.header}>
            <div className={styles.headerTitle}>
                {icon}
                <label className={styles.title}>{title}</label>
            </div>
            {lastUpdatedLabel && <span className={styles.lastUpdatedLabel}>{lastUpdatedLabel} </span>}
            {updatesLabel && <div className={styles.updateLabelBox}>{updatesLabel}</div>}
            {dropdown && <div className={styles.typeSelect}>{dropdown}</div>}
        </div>
    );
}

export default WidgetTitle;
