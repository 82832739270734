const HELP_VIDEOS = [
    {
        title: 'Events',
        url: 'https://storage.googleapis.com/airvideos/Howtoevents.mp4',
    },
    {
        title: 'Visitors',
        url: 'https://storage.googleapis.com/airvideos/Howtovisitors.mp4',
    },
    {
        title: 'Discount codes',
        url: 'https://storage.googleapis.com/airvideos/Howtopromocode.mp4',
    },
    {
        title: 'Reports',
        url: 'https://storage.googleapis.com/airvideos/Howtoreports.mp4',
    },
    {
        title: 'Validation code',
        url: 'https://storage.googleapis.com/airvideos/Howtovalidationcode.mp4',
    },
    {
        title: 'Team',
        url: 'https://storage.googleapis.com/airvideos/Howtoteam.mp4',
    },
];

export default HELP_VIDEOS;
