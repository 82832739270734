import {
    UPLOAD_SPOT_PHOTO_REQUESTED,
    UPLOAD_SPOT_PHOTO_SUCCESS,
    UPLOAD_SPOT_PHOTO_FAILED,
    UPLOAD_LOGO_REQUESTED,
    UPLOAD_LOGO_SUCCESS,
    UPLOAD_LOGO_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

export const initialUploadState = {
    spotPhotoLoading: null,
    spotPhotoURL: '',
    logoLoading: '',
    logoURL: false,
};

export default function uploadReducer(state = initialUploadState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case UPLOAD_SPOT_PHOTO_REQUESTED:
            newState.spotPhotoLoading = true;
            return newState;
        case UPLOAD_SPOT_PHOTO_SUCCESS:
            newState.spotPhotoLoading = false;
            newState.spotPhotoURL = action.payload.url;
            return newState;
        case UPLOAD_SPOT_PHOTO_FAILED:
            newState.spotPhotoLoading = false;
            newState.errorMessage = action.message;
            return newState;
        case UPLOAD_LOGO_REQUESTED:
            newState.logoLoading = true;
            return newState;
        case UPLOAD_LOGO_SUCCESS:
            newState.logoLoading = false;
            newState.logoURL = action.payload.url;
            return newState;
        case UPLOAD_LOGO_FAILED:
            newState.logoLoading = false;
            newState.errorMessage = action.message;
            return newState;

        default:
            return state || initialUploadState;
    }
}
