import React from 'react';
import { Modal, Header, Image, Grid, Button } from 'semantic-ui-react';

const PlateImage = (props: any) => {
    const { isShowing, toggle, enforcement, updateEnforcement } = props;

    return (
        <Modal open={isShowing} onClose={toggle} closeIcon>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={10}>
                        <Image
                            size="huge"
                            src={enforcement.action === 'note' ? enforcement.image_url : enforcement.plate_url}
                            rounded
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Header as="h2" content={enforcement.plate} />
                        <Header as="h4" content={'Time in: ' + enforcement.created_at_formatted} />
                        <Header as="h4" content={'Status: ' + enforcement.status} />
                        {enforcement.action === 'Ticket issued' && (
                            <Header as="h4" content={'Ticket issued by: ' + enforcement.enforcer_username} />
                        )}
                    </Grid.Column>
                </Grid>
            </Modal.Content>

            {enforcement.status === 'Not found' && enforcement.action !== 'Ticket issued' && (
                <Modal.Actions>
                    <Button
                        fluid
                        color="blue"
                        content="Issue ticket"
                        onClick={() => updateEnforcement(enforcement.pk, { action: 'Ticket issued' })}
                    />
                </Modal.Actions>
            )}
        </Modal>
    );
};

export default PlateImage;
