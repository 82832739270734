import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Loader } from 'semantic-ui-react';
import { getDailyReportsAction, getReportsAction } from 'actions/actions';
import Graphs from 'components/Dashboard/Modules/Graphs';
import useFilters from 'hooks/useFilters';
import { AppState } from 'reducers/reducers';
import { reportForSelectedSpot, selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';
import { isValidDateForReports } from 'utils/helpers';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';

type GraphsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const GraphsModuleContainer = (props: GraphsModuleContainerProps) => {
    const {
        spotReports,
        selectedSpot,
        getReports,
        selectedDailyReports,
        getDailyReports,
        push: connectedPush,
        organizationId,
        profile,
    } = props;
    const monthYearUtil = new MonthYearUtil();
    const { filters, urlUpdater } = useFilters<{ rentalType: 'daily' | 'monthly'; monthYear: MonthYear['value'] }>({
        filterValidators: {
            rentalType: function rentalTypeValidator(currentValue: string | null): 'daily' | 'monthly' {
                return currentValue === 'daily' || currentValue === 'monthly' ? currentValue : 'daily';
            },
            monthYear: function dateValidator(currentValue: string | null): string {
                return isValidDateForReports(currentValue) ? currentValue : monthYearUtil.currentMonthYear.value;
            },
        },
        push: connectedPush,
    });

    useEffect(() => {
        const [month, year] = filters.monthYear.split(':');
        getDailyReports({
            month,
            year,
        });
    }, [selectedSpot, getDailyReports, filters.monthYear]);

    useEffect(() => {
        getReports();
        trackPageViewed({
            pageName: 'Graphs',
            organizationId,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, selectedSpot, profile, getReports]);

    const dailyReports = selectedDailyReports && selectedDailyReports.daily_reports;

    function handleDateFilterSelected(value?: string): void {
        urlUpdater.monthYear(value ? value : monthYearUtil.currentMonthYear.value);
    }

    function handleRentalTypeFilterSelected(value: 'daily' | 'monthly'): void {
        urlUpdater.rentalType(value);
    }

    return spotReports ? (
        <Graphs
            reports={spotReports}
            selectedDailyReports={dailyReports}
            handleDateFilterSelected={handleDateFilterSelected}
            handleRentalTypeFilterSelected={handleRentalTypeFilterSelected}
            fields={filters}
        />
    ) : (
        <Loader active inline />
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.reports.errorMessage,
        loading: state.reports.loading,
        spotReports: reportForSelectedSpot(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        selectedDailyReports: state.reports.selectedDailyReports,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getReports: getReportsAction,
    getDailyReports: getDailyReportsAction,
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphsModuleContainer);
