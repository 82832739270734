import React from 'react';

type useInfiniteScrollProps = {
    loadMore: () => void;
};

export default function useInfiniteScroll({ loadMore }: useInfiniteScrollProps) {
    const observerTarget = React.useRef<HTMLElement | null>(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMore();
                }
            },
            { threshold: 1 }
        );

        const target = observerTarget.current;
        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [observerTarget, loadMore]);

    return observerTarget;
}
