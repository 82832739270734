import React from 'react';

const LightingIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="9" height="16" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M4.53749 18.125H3.57916L4.53749 11.4167H1.18332C0.339989 11.4167 0.867072 10.6979 0.886239 10.6692C2.12249 8.48417 3.98166 5.22583 6.46374 0.875H7.42207L6.46374 7.58333H9.82749C10.2108 7.58333 10.4217 7.76542 10.2108 8.21583C6.42541 14.8187 4.53749 18.125 4.53749 18.125Z"
                fill={color || '#1F2227'}
            />
        </svg>
    );
};

export default LightingIcon;
