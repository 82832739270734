import React from 'react';
import styles from './WidgetData.module.css';

type WidgetDataProps = React.ComponentProps<'h1'>;
function WidgetData({ children, ...props }: WidgetDataProps): JSX.Element {
    return (
        <span className={styles.data} {...props}>
            {children}
        </span>
    );
}

export default WidgetData;
