import APIClient from './APIClient';
import Cookies from 'js-cookie';
import { downloadFile } from 'utils/helpers';
import env from 'env';

export interface PayoutInterface {
    cents: number;
    formatted_amount: string;
    local_date: string;
    organization: number;
    payee: number;
    payer: any;
    transaction_type: string;
    payoutAccountInfo: any;
    pk: string;
    stripe_id: string;
    statement_descriptor: string;
}

class PayoutsService extends Object {
    async getPayouts(organization: number, token: string): Promise<PayoutInterface[]> {
        const result = await APIClient.get(`transactions/payouts/${organization}`, token);
        return result['results'];
    }
    async getPayoutAccountInfo(organization: number, token: string): Promise<PayoutInterface> {
        const result = await APIClient.get(`payouts?organization=${organization}`, token);
        return result;
    }
    async updatePayoutAccountInfo(payoutInfo: any, token: string): Promise<PayoutInterface> {
        const result = await APIClient.post(`payouts`, payoutInfo, token);
        return result;
    }
    async getSelectedPayoutDetails(stripeId: string, token: string) {
        const result = await APIClient.get(`reports/daily_reports?payout_transaction=${stripeId}`, token);
        return result;
    }

    downloadMonthlyPayouts(organizationUuid: string): Promise<void> {
        const link = `${env.DOWNLOAD_DOMAIN}monthlypayout/${organizationUuid}/`;
        const headers = new Headers();
        const token = Cookies.get('airgarage_auth');
        if (token) {
            headers.append('Authorization', `Token ${token}`);
        }
        fetch(link, {
            headers,
        })
            .then((response) => response.blob())
            .then((blob) => {
                downloadFile({
                    data: blob,
                    fileName: 'monthly_payouts.csv',
                    fileType: 'csv',
                });
            });
        return Promise.resolve();
    }
}

export default new PayoutsService();
