import React from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthView = (props: any) => {
    const token = props.match.params.token;
    Cookies.set('airgarage_auth', token, { domain: 'airgara.ge', path: '/' });
    Cookies.set('airgarage_auth', token, { domain: 'airgarage.com', path: '/', expires: 2000 });
    window.location.href = '/';
    return (
        <Container>
            <Divider hidden />
            <p>Authorizing...</p>
        </Container>
    );
};

export default withRouter(AuthView);
