import APIClient from './APIClient';

class RefundService extends Object {
    async getRefunds(token: string): Promise<object> {
        const result = await APIClient.get(`refund_requests/?issued=False&ignored=False`, token);
        return result;
    }
    async issueRefund(refundPk: number, token: string) {
        const result = await APIClient.patch(`refund_requests/${refundPk}/`, { issued: true }, token);
        return result;
    }

    async ignoreRefund(refundPk: number, token: string) {
        const result = await APIClient.patch(`refund_requests/${refundPk}/`, { ignored: true }, token);
        return result;
    }
}

export default new RefundService();
