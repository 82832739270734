import React, { SyntheticEvent, useEffect, useState } from 'react';

import useForm from 'hooks/useForm';
import Button from 'components/Button/Button';
import CarIcon from 'components/Icons/CarIcon';
import { states, stringRepresentationToValues } from 'utils/statesSelect';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';
import EmailIcon from 'components/Icons/EmailIcon';
import PersonIcon from 'components/Icons/PersonIcon';
import { validateEmail } from 'utils/helpers';
import { AddSlotParams } from 'clients/SlotClient';

type AddRenterFormProps = {
    addRenter(rental: AddSlotParams): void;
    loading: boolean;
};

function AddRenterForm({ addRenter, loading }: AddRenterFormProps) {
    const [emailError, setEmailError] = useState('');
    const [plateError, setPlateError] = useState('');
    const [stateError, setStateError] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const form = useForm({
        email: '',
        firstName: '',
        lastName: '',
        plate: '',
        state: '',
    });

    function onAddNewRenter(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        const { email, firstName: first_name, lastName: last_name, plate, state } = form.inputs;
        const stateOption = state ? stringRepresentationToValues(state) : null;
        const cars = plate && stateOption ? [{ plate, state: stateOption?.state, country: stateOption?.country }] : [];

        const newRentalPayload = {
            email,
            first_name,
            last_name,
            cars,
        };

        addRenter(newRentalPayload);
    }

    function checkEmailValidation() {
        if (!validateEmail(form.inputs.email)) setEmailError('Invalid email');
        else setEmailError('');
    }

    function onEmailChange(e: SyntheticEvent<HTMLInputElement>) {
        form.handleInput(e);
        if (emailError) checkEmailValidation();
    }

    useEffect(() => {
        if (plateError || stateError || emailError || !form.inputs.email || !validateEmail(form.inputs.email))
            setButtonDisabled(true);
        else setButtonDisabled(false);
    }, [plateError, stateError, emailError, form.inputs.email]);

    useEffect(() => {
        const plate = form.inputs.plate;
        const state = form.inputs.state;

        if ((plate && state) || (!plate && !state)) {
            setPlateError('');
            setStateError('');
        } else if (plate && !state) {
            setPlateError('');
            setStateError('License plate state is required');
        } else if (!plate && state) {
            setPlateError('License plate number is required');
            setStateError('');
        }
    }, [form.inputs.plate, form.inputs.state]);

    return (
        <AGForm>
            <TextInput
                label="Email *"
                onChange={onEmailChange}
                placeholder="Required"
                errors={emailError}
                icon={<EmailIcon height={20} width={20} />}
                name="email"
                value={form.inputs.email}
                onBlur={checkEmailValidation}
            />
            <TextInput
                label="First Name"
                onChange={form.handleInput}
                placeholder="Optional"
                errors={null}
                icon={<PersonIcon height={20} width={20} />}
                name="firstName"
                value={form.inputs.firstName}
            />
            <TextInput
                label="Last Name"
                onChange={form.handleInput}
                placeholder="Optional"
                errors={null}
                icon={<PersonIcon height={20} width={20} />}
                name="lastName"
                value={form.inputs.lastName}
            />
            <TextInput
                label="License Plate"
                onChange={form.handleInput}
                placeholder="Optional"
                errors={plateError}
                icon={<CarIcon height={20} width={20} />}
                isUppercase={true}
                name="plate"
                value={form.inputs.plate}
            />
            <SearchSelectInput
                placeholder="Enter license state"
                label="License State"
                options={states}
                errors={stateError}
                icon={<CarIcon height={20} width={20} />}
                onSelected={(value) => {
                    form.handleValueChange('state', value);
                }}
                name="state"
            />

            <span>An invite email will be sent and their spot will be activated immediately.</span>

            <Button type="button" onClick={onAddNewRenter} loading={loading} disabled={buttonDisabled || loading}>
                Invite Renter
            </Button>
        </AGForm>
    );
}

export default AddRenterForm;
