import React from 'react';
import ImageWithFallback from 'components/ImageWithFallback/ImageWithFallback';
import styles from './HandFanGallery.module.css';
import { BucketNames, getTimelineThumbnailFromBuckets } from 'components/Dashboard/TimelineWidget/entries/utils';

interface HandFanGalleryProps {
    images: string[];
}

const MAX_IMAGES_LENGTH = 5;
const CENTER_POSITION = Math.ceil(MAX_IMAGES_LENGTH / 2);
const ANGLE = 10;
const X_OFFSET = 20;
const Y_OFFSET = 10;

export default function HandFanGallery({ images }: HandFanGalleryProps) {
    return (
        <div className={styles.galleryContainer}>
            {images.slice(0, MAX_IMAGES_LENGTH).map((image, index) => {
                const count = index + 1;
                const mult = count - CENTER_POSITION;
                const isLastImage = index === images.slice(0, MAX_IMAGES_LENGTH).length - 1;
                const style = {
                    transform: `${isLastImage ? `rotate(${-ANGLE}deg) scale(1.2)` : ''} translateX(${
                        -X_OFFSET * mult
                    }px) translateY(${-Y_OFFSET * mult}px)`,
                    transformOrigin: 'right bottom',
                };
                return (
                    <ImageWithFallback
                        key={index}
                        src={getTimelineThumbnailFromBuckets(image, [
                            BucketNames.EnforcementScan,
                            BucketNames.EnforcementTicket,
                        ])}
                        fallbackSrc={image}
                        alt={`Gallery item ${count}`}
                        style={style}
                        height={150}
                        width={110}
                        className={styles.galleryImage}
                        loading="lazy"
                    />
                );
            })}
        </div>
    );
}
