import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from '../reducers/reducers';
import createSagaMiddleware from 'redux-saga';
import saga from '../sagas/index';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(preloadedState: any) {
    const store = createStore(
        rootReducer,
        preloadedState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    );
    return store;
}

const store = configureStore({});

sagaMiddleware.run(saga);

export default store;
