import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './WidgetBox.module.css';
import WidgetTitle, { WidgetTitleProps } from './WidgetTitle';

type WidgetBoxProps = WidgetTitleProps & {
    children: JSX.Element;
};

function WidgetBox({ children, icon, title, updatesLabel, lastUpdatedLabel, dropdown }: WidgetBoxProps): JSX.Element {
    return (
        <Container fluid>
            <div className={styles.wrapper}>
                {title && (
                    <WidgetTitle
                        title={title}
                        icon={icon}
                        lastUpdatedLabel={lastUpdatedLabel}
                        updatesLabel={updatesLabel}
                        dropdown={dropdown}
                    />
                )}
                {children}
            </div>
        </Container>
    );
}

export default WidgetBox;
