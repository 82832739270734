import React from 'react';
import moment from 'moment';

type LastUpdatedDateProps = {
    lastUpdatedAt: string;
};

function LastUpdatedDate({ lastUpdatedAt }: LastUpdatedDateProps): JSX.Element {
    return (
        <div style={{ fontSize: '12px', marginTop: '20px' }}>
            <span>Last Updated: {moment(lastUpdatedAt).format('MM/DD/YYYY')}</span>
        </div>
    );
}

export default LastUpdatedDate;
