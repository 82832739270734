import {
    ENFORCEMENTS_REQUESTED,
    ENFORCEMENTS_SUCCESS,
    ENFORCEMENTS_FAILED,
    UPDATE_ENFORCEMENT_REQUESTED,
    UPDATE_ENFORCEMENT_SUCCESS,
    UPDATE_ENFORCEMENT_FAILED,
    CURRENTLY_PARKED_REQUESTED,
    CURRENTLY_PARKED_SUCCESS,
    CURRENTLY_PARKED_FAILED,
    ADD_ENFORCEMENT_PENALTY_REQUESTED,
    ADD_ENFORCEMENT_PENALTY_SUCCESS,
    ADD_ENFORCEMENT_PENALTY_FAILED,
    CHECK_PLATE_REQUESTED,
    CHECK_PLATE_SUCCESS,
    CHECK_PLATE_FAILED,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};
export const initialEnforcementsState = {
    loading: false,
    errorMessage: '',
    enforcements: {},
    currentlyParked: {},
    checkPlateResults: [],
};

export default function enforcementsReducer(state = initialEnforcementsState, action: any) {
    const newState = cloneObject(state);
    const selectedSpotID = action.selectedSpotID;
    switch (action.type) {
        case ENFORCEMENTS_REQUESTED:
            newState.loading = true;
            return newState;
        case ENFORCEMENTS_SUCCESS:
            newState.loading = false;
            newState.enforcements[selectedSpotID] = action.payload.enforcements;
            return newState;
        case ENFORCEMENTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case UPDATE_ENFORCEMENT_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case UPDATE_ENFORCEMENT_SUCCESS:
            newState.loading = false;
            newState.enforcements[selectedSpotID] = action.payload.enforcements;
            return newState;
        case UPDATE_ENFORCEMENT_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case CURRENTLY_PARKED_REQUESTED:
            newState.loading = true;
            return newState;
        case CURRENTLY_PARKED_SUCCESS:
            newState.currentlyParked[selectedSpotID] = action.payload.records;
            newState.loading = false;
            return newState;
        case CURRENTLY_PARKED_FAILED:
            newState.loading = false;
            return newState;
        case CHECK_PLATE_REQUESTED:
            newState.loading = true;
            return newState;
        case CHECK_PLATE_SUCCESS:
            newState.checkPlateResults = [action.payload.result, ...newState.checkPlateResults];
            newState.loading = false;
            return newState;
        case CHECK_PLATE_FAILED:
            newState.loading = false;
            return newState;
        case ADD_ENFORCEMENT_PENALTY_REQUESTED:
            newState.loading = true;
            return newState;
        case ADD_ENFORCEMENT_PENALTY_SUCCESS: {
            const enforcementToUpdate = action.payload.result.parent_enforcement;
            const indexToModify = newState.checkPlateResults.findIndex((obj: any) => obj.pk === enforcementToUpdate);
            newState.checkPlateResults[indexToModify].penalty = action.payload.result;
            newState.loading = false;
            return newState;
        }
        case ADD_ENFORCEMENT_PENALTY_FAILED:
            newState.loading = false;
            return newState;
        default:
            return state || initialEnforcementsState;
    }
}
