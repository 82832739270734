import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTicketAction, waiveTicketAction } from 'actions/actions';
import TicketLookup from 'components/Dashboard/Modules/TicketLookup/TicketLookup';
import { AppState } from 'reducers/reducers';
import { trackPageViewed } from 'utils/analytics';

type TicketModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const TicketModuleContainer = ({
    profile,
    organization,
    getTicket,
    ticket,
    loading,
    waiveTicket,
    errorMessage,
    successMessage,
}: TicketModuleContainerProps) => {
    useEffect(() => {
        trackPageViewed({
            pageName: 'Waive a Violation',
            organizationId: organization.pk,
            spotId: null,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [profile, organization]);

    return (
        <div>
            <TicketLookup
                loading={loading}
                getTicket={getTicket}
                waiveTicket={waiveTicket}
                ticket={ticket}
                successMessage={successMessage}
                errorMessage={errorMessage}
            />
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        loading: state.tickets.loading,
        successMessage: state.tickets.successMessage,
        errorMessage: state.tickets.errorMessage,
        ticket: state.tickets.ticket,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
    };
}
const mapDispatchToProps = {
    getTicket: getTicketAction,
    waiveTicket: waiveTicketAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketModuleContainer);
