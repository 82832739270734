import React from 'react';

const DollarIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M11.5 0.25C5.29 0.25 0.25 5.29 0.25 11.5C0.25 17.71 5.29 22.75 11.5 22.75C17.71 22.75 22.75 17.71 22.75 11.5C22.75 5.29 17.71 0.25 11.5 0.25ZM11.5 20.5C6.53875 20.5 2.5 16.4613 2.5 11.5C2.5 6.53875 6.53875 2.5 11.5 2.5C16.4613 2.5 20.5 6.53875 20.5 11.5C20.5 16.4613 16.4613 20.5 11.5 20.5ZM11.8488 10.5325C9.8575 10.0263 9.21625 9.475 9.21625 8.65375C9.21625 7.70875 10.105 7.045 11.5787 7.045C13.1312 7.045 13.7163 7.7875 13.7613 8.89H15.685C15.6287 7.3825 14.7062 5.99875 12.8837 5.54875V3.625H10.2625V5.52625C8.56375 5.88625 7.2025 6.98875 7.2025 8.6875C7.2025 10.7013 8.87875 11.7137 11.32 12.2987C13.5138 12.8162 13.9525 13.5925 13.9525 14.4025C13.9525 14.9987 13.5138 15.9663 11.59 15.9663C9.79 15.9663 9.08125 15.1562 8.98 14.1213H7.045C7.1575 16.0337 8.575 17.1137 10.2625 17.4625V19.375H12.895V17.4963C14.605 17.17 15.955 16.1913 15.9663 14.38C15.955 11.905 13.8288 11.05 11.8488 10.5325Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default DollarIcon;
