export const VISITOR_CODES_REQUESTED = 'VISITOR_CODES_REQUESTED';
export const VISITOR_CODES_SUCCESS = 'VISITOR_CODES_SUCCESS';
export const VISITOR_CODES_FAILED = 'VISITOR_CODES_FAILED';

export const ADD_VISITOR_CODE_REQUESTED = 'ADD_VISITOR_CODE_REQUESTED';
export const ADD_VISITOR_CODE_SUCCESS = 'ADD_VISITOR_CODE_SUCCESS';
export const ADD_VISITOR_CODE_FAILED = 'ADD_VISITOR_CODE_FAILED';

export const EDIT_VISITOR_CODE_REQUESTED = 'EDIT_VISITOR_CODE_REQUESTED';
export const EDIT_VISITOR_CODE_SUCCESS = 'EDIT_VISITOR_CODE_SUCCESS';
export const EDIT_VISITOR_CODE_FAILED = 'EDIT_VISITOR_CODE_FAILED';

export const DELETE_VISITOR_CODE_REQUESTED = 'DELETE_VISITOR_CODE_REQUESTED';
export const DELETE_VISITOR_CODE_SUCCESS = 'DELETE_VISITOR_CODE_SUCCESS';
export const DELETE_VISITOR_CODE_FAILED = 'DELETE_VISITOR_CODE_FAILED';

export const REFRESH_VISITOR_CODES_REQUESTED = 'REFRESH_VISITOR_CODES_REQUESTED';
export const REFRESH_VISITOR_CODES_SUCCESS = 'REFRESH_VISITOR_CODES_SUCCESS';
export const REFRESH_VISITOR_CODES_FAILED = 'REFRESH_VISITOR_CODES_FAILED';
