import { IncomeStatementReport } from 'types';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { downloadFile } from 'utils/helpers';

type DownloadClientServiceParams = HTTPClientParameters;

class DownloadClient extends HTTPClient {
    constructor(params: DownloadClientServiceParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    async downloadIncomeStatementReport({
        orgUuid,
        startDate,
        endDate,
        spotUuid,
    }: {
        orgUuid: string;
        startDate: string;
        endDate: string;
        spotUuid?: string;
    }): Promise<void | IncomeStatementReport> {
        let request = `downloads/income_statement?start_date=${startDate}&end_date=${endDate}`;
        if (spotUuid) request += `&spot=${spotUuid}`;
        else request += `&organization=${orgUuid}`;
        return this.getRaw(request)
            .then((response) => response.blob())
            .then((blob) => {
                downloadFile({
                    data: blob,
                    fileName: `income_statement-${startDate}_${endDate}.csv`,
                    fileType: 'csv',
                });
            });
    }
}

export default DownloadClient;
