import React, { SyntheticEvent, useState } from 'react';
import { Grid, Loader, Dropdown, DropdownProps, Checkbox, ModalContent, Modal } from 'semantic-ui-react';
import { ValidatedDiscountCode, PromoCodeReportInterface, SpotInterface, PaginatedPromoCodes } from 'types';
import MonthYearUtil from 'utils/monthYear';
import PieGraph from '../SpotGraphs/PieGraph';
import styles from './PromoCode.module.css';
import Button from 'components/Button/Button';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import AGDropdown from 'components/AGDropdown/AGDropdown';
import AGModal, { ModalRef } from 'components/Modal/Modal';
import DiscountCodeFlyer from '../DiscountCodeFlyer/DiscountCodeFlyer';
import PromoCodeTable from '../PromoCodeTable/PromoCodeTable';
import AGMessage from 'components/AGMessage/AGMessage';

type PromoCodesProps = {
    selectedSpotInfo: SpotInterface;
    errorMessage?: string;
    handleDropdown: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
    promoCodes: PaginatedPromoCodes;
    promoCodeReport: PromoCodeReportInterface;
    fields: { monthYears: string };
    openAddDiscountModal: () => void;
    deletePromoCode: (codePk: number) => void;
    getPromoCodes: (page: number, usableCodes: boolean) => void;
    showingUsableCodes: boolean;
    toggleShowingUsableCodes: () => void;
    generateQrCode: (discountCodePk: number) => Promise<ValidatedDiscountCode>;
    handlePageChange: (pageNumber: number) => void;
};

function PromoCodes(props: PromoCodesProps) {
    const monthYearUtil = new MonthYearUtil();
    const {
        errorMessage,
        promoCodeReport,
        fields,
        handleDropdown,
        selectedSpotInfo,
        showingUsableCodes,
        toggleShowingUsableCodes,
        deletePromoCode,
        handlePageChange,
    } = props;
    const [openDropdown, toggleOpenDropdown] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedPromoCode, setSelectedPromoCode] = useState<ValidatedDiscountCode | null>(null);
    const generateQrCodeModal = React.useRef<ModalRef>();
    const [discountCode, setDiscountCode] = useState<ValidatedDiscountCode | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [qrCodeError, setQrCodeError] = useState<string | null>(null);

    function handleDeleteConfirmation(promoCode: ValidatedDiscountCode) {
        setSelectedPromoCode(promoCode);
        setOpenDeleteModal(true);
    }

    function handleDeletePromoCode() {
        if (selectedPromoCode !== null) {
            deletePromoCode(selectedPromoCode.pk);
            setSelectedPromoCode(null);
        }
        setOpenDeleteModal(false);
    }

    async function openQrCodeModal(discountCode: ValidatedDiscountCode) {
        setLoading(true);
        generateQrCodeModal.current?.openModal();
        try {
            const discountCodeWithQR = await props.generateQrCode(discountCode.pk);
            setDiscountCode(discountCodeWithQR);
        } catch (err) {
            const errorMessage = 'Something went wrong! Please try again later';
            setQrCodeError(errorMessage);
            generateQrCodeModal.current?.closeModal();
        } finally {
            setLoading(false);
        }
    }

    function getTotalPromoUses() {
        let total = 0;
        for (const key in promoCodeReport.promo_uses) {
            total += promoCodeReport.promo_uses[key];
        }
        return total;
    }

    async function handlePaginationChange(page: string) {
        handlePageChange(Number(page));
        await props.getPromoCodes(Number(page), showingUsableCodes);
    }

    const spotName = selectedSpotInfo.name;
    const { formatted_value: formattedValue, minutes_to_discount: minutesToDiscount, code } = discountCode || {};
    const formattedValueDisplay = !loading ? formattedValue : '';
    const codeDisplay = !loading ? code : '';
    const discountType = minutesToDiscount ? 'free' : 'off';
    const discountMessage = `${formattedValueDisplay} ${discountType} parking code`;
    const description = `Scan below or use code "${codeDisplay}"`;
    return (
        <>
            <Grid.Row style={{ padding: '1em' }}>
                <Grid.Column>
                    <Grid.Row>{errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}</Grid.Row>
                    <Grid.Row>{qrCodeError && <AGMessage color="error">{qrCodeError}</AGMessage>}</Grid.Row>
                    {props.promoCodes && (
                        <Grid stackable>
                            <Grid.Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <AGDropdown
                                    text={'Filters'}
                                    open={openDropdown}
                                    toggleOpen={toggleOpenDropdown}
                                    icon={<Lines />}
                                    width={'250px'}
                                >
                                    <div className={styles.promoFilterContent}>
                                        <Checkbox
                                            checked={!showingUsableCodes}
                                            label="Show discount codes with 0 uses left"
                                            onClick={toggleShowingUsableCodes}
                                        />
                                    </div>
                                </AGDropdown>
                                <Button onClick={props.openAddDiscountModal} size="sm" className={styles.promoButton}>
                                    + Add Discount Code
                                </Button>
                            </Grid.Row>
                            <Grid.Row>
                                <PromoCodeTable
                                    promoCodes={props.promoCodes}
                                    handlePaginationChange={handlePaginationChange}
                                    deletePromoCode={handleDeleteConfirmation}
                                    openQrCodeModal={openQrCodeModal}
                                    spotTimezone={selectedSpotInfo?.timezone}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                {promoCodeReport && getTotalPromoUses() > 0 ? (
                                    <div className={styles.promoCodeReportContainer}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginBottom: '1em',
                                            }}
                                        >
                                            <div>
                                                <h3 style={{ fontSize: '2em', marginRight: '1em' }}>
                                                    Discount Code Utilization
                                                </h3>
                                            </div>
                                            <Dropdown
                                                name="monthYears"
                                                placeholder="Select a month"
                                                selection
                                                onChange={handleDropdown}
                                                options={monthYearUtil.monthYears}
                                                value={fields.monthYears}
                                                style={{ textAlign: 'center', height: '2em' }}
                                            />
                                        </div>
                                        <PieGraph
                                            title="Usage by discount code"
                                            dataLabel="Count of rentals"
                                            description={'This is the count of rentals made using this discount code.'}
                                            xData={Object.keys(promoCodeReport.promo_uses)}
                                            yData={Object.values(promoCodeReport.promo_uses)}
                                        />
                                    </div>
                                ) : promoCodeReport && getTotalPromoUses() === 0 ? (
                                    <></>
                                ) : (
                                    <Loader active inline />
                                )}
                                <Modal
                                    size="mini"
                                    open={openDeleteModal}
                                    onClose={() => setOpenDeleteModal(false)}
                                    onOpen={() => setOpenDeleteModal(true)}
                                >
                                    <ModalContent>
                                        <h3 style={{ fontSize: 22, fontWeight: 'bold' }}>
                                            Delete Discount Code {selectedPromoCode && selectedPromoCode.code}?
                                        </h3>
                                        <p>
                                            This code will be deleted immediately and drivers will no longer be able to
                                            use it.
                                        </p>
                                        <Button color="error-outline" onClick={handleDeletePromoCode}>
                                            Delete
                                        </Button>
                                    </ModalContent>
                                </Modal>

                                <AGModal ref={generateQrCodeModal} title={'Download QR Code Flyer'}>
                                    <DiscountCodeFlyer
                                        spotName={spotName}
                                        discountMessage={discountMessage}
                                        description={description}
                                        qrCode={discountCode ? discountCode.qr_code : ''}
                                        loading={loading}
                                    />
                                </AGModal>
                            </Grid.Row>
                        </Grid>
                    )}
                </Grid.Column>
            </Grid.Row>
        </>
    );
}

export default PromoCodes;
