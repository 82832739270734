import React from 'react';
import styles from './TabPanel.module.css';

export type TabPanelItems<K> = { title: string; component: JSX.Element; key: K }[];

type TabPanelProps<K> = {
    items: TabPanelItems<K>;
    activeTabKey: K;
    setActiveTab: (tab: K) => void;
};

function TabPanel<K>({ items, activeTabKey, setActiveTab }: TabPanelProps<K>) {
    const activeTab = items.find((i) => i.key === activeTabKey) || items[0];

    return (
        <div className={styles.tabWrapper}>
            <div className={styles.tabMenu}>
                {items.map((item, i) => {
                    return (
                        <button
                            className={item.key === activeTabKey ? styles.active : ''}
                            onClick={() => setActiveTab(item.key)}
                            key={i}
                        >
                            {item.title}
                        </button>
                    );
                })}
            </div>
            <div className={styles.tabDivider} />
            {activeTab.component}
        </div>
    );
}

export default TabPanel;
