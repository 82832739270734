import React from 'react';
import { Form } from 'semantic-ui-react';
import styles from './../ListForm/ListForm.module.css';

const SpotServicesFields = (props: any) => {
    const { handleValueChange, fields } = props;

    return (
        <>
            <p className={styles.tabHeading}>Services</p>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Checkbox
                        name="active"
                        label="LPR Service"
                        onChange={() => {
                            handleValueChange('lpr_service', !fields.lpr_service);
                        }}
                        checked={fields.lpr_service}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Checkbox
                        name="hidden"
                        label="Cleaning Service"
                        onChange={() => {
                            handleValueChange('cleaning_service', !fields.cleaning_service);
                        }}
                        checked={fields.cleaning_service}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Checkbox
                        name="active"
                        label="Security Service"
                        onChange={() => {
                            handleValueChange('security_service', !fields.security_service);
                        }}
                        checked={fields.security_service}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Checkbox
                        name="hidden"
                        label="Snow Service"
                        onChange={() => {
                            handleValueChange('snow_service', !fields.snow_service);
                        }}
                        checked={fields.snow_service}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <Form.Checkbox
                    name="hidden"
                    label="Utilities Service"
                    onChange={() => {
                        handleValueChange('utilities_service', !fields.utilities_service);
                    }}
                    checked={fields.utilities_service}
                />
            </Form.Field>
        </>
    );
};

export default SpotServicesFields;
