import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Popup, Icon } from 'semantic-ui-react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { pieChartOptions, pieGraphColors } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

type PieGraphProps = {
    title?: string;
    description?: string;
    xData: string[];
    yData: number[];
    dataLabel: string;
};

const PieGraph = (props: PieGraphProps) => {
    const { title, description, xData, yData, dataLabel } = props;

    const data: ChartData<'doughnut'> = {
        labels: xData,
        datasets: [
            {
                label: dataLabel,
                data: yData,
                backgroundColor: pieGraphColors,
                borderWidth: 2,
                borderColor: '#fff',
            },
        ],
    };

    return (
        <>
            <div style={{ display: 'flex', gap: 4 }}>
                {description && (
                    <Popup trigger={<Icon name="info circle" />} content={description} on="click" pinned inverted />
                )}

                {title && <h4 style={{ margin: 0 }}>{title}</h4>}
            </div>

            <Doughnut data={data} options={pieChartOptions} />
        </>
    );
};

export default PieGraph;
