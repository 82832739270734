import React from 'react';
import styles from './IncomeStatement.module.css';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import GroupedCollapsibleRows from '../AGTable/CollapsibleTable/GroupedCollapsibleRows';
import AGCollapsibleTable from 'components/AGTable/CollapsibleTable/AGCollapsibleTable';
import IndentedTR from 'components/AGTable/CollapsibleTable/IndentedTR';
import { convertCentsToDisplayPrice } from 'utils/currency';
import { IncomeStatementReport } from 'types';

type TableProps = {
    report: IncomeStatementReport;
};

const Table = ({ report }: TableProps) => {
    const {
        income_statement: {
            revenue,
            revenue_monthly: revenueMonthly,
            revenue_transient: revenueTransient,
            revenue_enterprise: revenueEnterprise,
            refunds,
            tax_amount: taxAmount,
            contribution_amount_exclude_tax: contributionAmountExcludeTax,
            net_income: netIncome,
            net_income_monthly: netIncomeMonthly,
            net_income_transient: netIncomeTransient,
            net_income_enterprise: netIncomeEnterprise,
        },
    } = report;

    const revenueContent = <td>{convertCentsToDisplayPrice(revenue)}</td>;
    const netIncomeContent = <td>{convertCentsToDisplayPrice(netIncome)}</td>;

    return (
        <div className={styles.tableContainer}>
            <AGCollapsibleTable>
                <tbody>
                    {/* Revenue */}
                    <GroupedCollapsibleRows parentRowText="Revenue" parentRowContent={revenueContent}>
                        {!!revenueMonthly && (
                            <IndentedTR>
                                <td>Monthlies</td>
                                <td>{convertCentsToDisplayPrice(revenueMonthly)}</td>
                            </IndentedTR>
                        )}
                        <IndentedTR>
                            <td>Transients</td>
                            <td>{convertCentsToDisplayPrice(revenueTransient)}</td>
                        </IndentedTR>
                        {!!revenueEnterprise && (
                            <IndentedTR>
                                <td>Enterprise</td>
                                <td>{convertCentsToDisplayPrice(revenueEnterprise)}</td>
                            </IndentedTR>
                        )}
                        <IndentedTR>
                            <td>Refunds</td>
                            <td>({convertCentsToDisplayPrice(refunds)})</td>
                        </IndentedTR>
                    </GroupedCollapsibleRows>

                    {/* Taxes */}
                    {!!taxAmount && (
                        <tr>
                            <td>Taxes</td>
                            <td>({convertCentsToDisplayPrice(taxAmount)})</td>
                        </tr>
                    )}

                    {/* AirGarage Commission */}
                    <tr>
                        <td>
                            AirGarage Commission
                            <InfoTooltip
                                text="AirGarage Commission is taken after taxes and refunds,
 and includes all operating expenses."
                            />
                        </td>
                        <td>({convertCentsToDisplayPrice(contributionAmountExcludeTax)})</td>
                    </tr>

                    {/* Net income */}
                    {netIncomeMonthly || netIncomeEnterprise ? (
                        <GroupedCollapsibleRows parentRowText="Net Income" parentRowContent={netIncomeContent}>
                            {!!netIncomeMonthly && (
                                <IndentedTR>
                                    <td>Monthlies</td>
                                    <td>{convertCentsToDisplayPrice(netIncomeMonthly)}</td>
                                </IndentedTR>
                            )}
                            <IndentedTR>
                                <td>Transients</td>
                                <td>{convertCentsToDisplayPrice(netIncomeTransient)}</td>
                            </IndentedTR>
                            {!!netIncomeEnterprise && (
                                <IndentedTR>
                                    <td>Enterprise</td>
                                    <td>{convertCentsToDisplayPrice(netIncomeEnterprise)}</td>
                                </IndentedTR>
                            )}
                        </GroupedCollapsibleRows>
                    ) : (
                        <tr>
                            <td>Net Income</td>
                            {netIncomeContent}
                        </tr>
                    )}
                </tbody>
            </AGCollapsibleTable>
        </div>
    );
};

export default Table;
