import React, { useEffect, useState } from 'react';
import AGDropdown, { AGDropdownWrapperProps } from 'components/AGDropdown/AGDropdown';
import styles from '../AGSelect.module.css';

export type MultipleSelectOption<O = object> = {
    value: string;
    label: string;
} & O;

type AGMultipleSelectProps = AGDropdownWrapperProps & {
    selectedValues: string[];
    onSelect: (values: string[]) => void;
    options: MultipleSelectOption[];
    allowNoOptionSelected?: boolean;
    placeholder?: string;
};

function AGMultipleSelect({
    selectedValues,
    onSelect,
    options,
    placeholder = '',
    allowNoOptionSelected = false,
    ...restProps
}: AGMultipleSelectProps) {
    const [openDropdown, toggleOpenDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [label, setLabel] = useState(placeholder);

    function handleSelect(value: string) {
        const wasSelected = selectedValues.includes(value);

        if (wasSelected && selectedValues.length === 1 && !allowNoOptionSelected)
            setErrorMessage('At least one option must be selected');
        else {
            setErrorMessage('');
            const newSetOfValues = wasSelected ? selectedValues.filter((v) => v !== value) : [...selectedValues, value];
            onSelect(newSetOfValues);
        }
    }

    useEffect(() => {
        if (selectedValues.length > 0) {
            const selectedValuesLabels = selectedValues.map((v) => options.find((o) => o.value === v)?.label);
            setLabel(selectedValuesLabels.join(', '));
        } else setLabel(placeholder);
    }, [selectedValues, options, placeholder]);

    return (
        <AGDropdown text={label} open={openDropdown} toggleOpen={toggleOpenDropdown} {...restProps}>
            <ul className={`${styles.selectorOptions} ${styles.multiSelect}`}>
                {errorMessage && <span>{errorMessage}</span>}
                {options.map((option) => {
                    const isSelected = selectedValues.includes(option.value);
                    return (
                        <li key={option.value}>
                            <button onClick={() => handleSelect(option.value)} type="button">
                                {option.label}
                                <input type="checkbox" checked={isSelected} readOnly />
                            </button>
                        </li>
                    );
                })}
            </ul>
        </AGDropdown>
    );
}

export default AGMultipleSelect;
