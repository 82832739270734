import React from 'react';
import ReactRouter from './Router';
import { Provider } from 'react-redux';
import store from './store/index';
import 'semantic-ui-less/semantic.less';
import amplitude from 'amplitude-js';
import { defaults } from 'chart.js';
import { ReviewReportProvider } from 'contexts/ReviewReportContext';
import { DriversFrequencyReportProvider } from 'contexts/DriversFrequencyReportContext';
import { OccupancyReportProvider } from 'contexts/OccupancyReportContext';

function App() {
    amplitude.getInstance().init('3f35d24e7bad739eee486cbe3da4a5a6');
    defaults.font.family = "'Outfit', 'Helvetica Neue', Arial, Helvetica, sans-serif";
    return (
        <Provider store={store}>
            <ReviewReportProvider>
                <DriversFrequencyReportProvider>
                    <OccupancyReportProvider>
                        <ReactRouter />
                    </OccupancyReportProvider>
                </DriversFrequencyReportProvider>
            </ReviewReportProvider>
        </Provider>
    );
}

export default App;
