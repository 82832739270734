import React, { useMemo, useContext, createContext, useCallback } from 'react';

import { reportClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';
import {
    DAILY_OCCUPANCY_REPORTS_FAILED,
    DAILY_OCCUPANCY_REPORTS_REQUESTED,
    DAILY_OCCUPANCY_REPORTS_SUCCESS,
    MONTHLY_OCCUPANCY_REPORTS_FAILED,
    MONTHLY_OCCUPANCY_REPORTS_REQUESTED,
    MONTHLY_OCCUPANCY_REPORTS_SUCCESS,
} from './constants';
import occupancyReportReducer, { OccupancyReportState, initialOccupancyReportState } from './reducer';

interface OccupancyReportActions {
    getMonthlyOccupancyReports: (spotId: number) => void;
    getDailyOccupancyReports: (spotId: number) => void;
}

interface OccupancyContextType extends OccupancyReportState, OccupancyReportActions {}

export const OccupancyReportContext = createContext<OccupancyContextType | null>(null);

export const OccupancyReportProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        occupancyReportReducer,
        {
            ...initialOccupancyReportState,
        },
        [],
        [unauthorized]
    );

    const getMonthlyOccupancyReports = useCallback(async (spotId: number) => {
        dispatch({ type: MONTHLY_OCCUPANCY_REPORTS_REQUESTED });
        try {
            const response = await reportClient.getMonthlyHeatmapReport(spotId);

            dispatch({
                type: MONTHLY_OCCUPANCY_REPORTS_SUCCESS,
                payload: { monthly: response },
            });
        } catch (error) {
            dispatch({
                type: MONTHLY_OCCUPANCY_REPORTS_FAILED,
                payload: {
                    error,
                    message: error?.toString() || 'Could not fetch monthly occupancy reports',
                },
            });
        }
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDailyOccupancyReports = useCallback(async (spotId: number) => {
        dispatch({ type: DAILY_OCCUPANCY_REPORTS_REQUESTED });
        try {
            const response = await reportClient.getDailyHeatmapReport(spotId);

            dispatch({
                type: DAILY_OCCUPANCY_REPORTS_SUCCESS,
                payload: { daily: response },
            });
        } catch (error) {
            dispatch({
                type: DAILY_OCCUPANCY_REPORTS_FAILED,
                payload: {
                    error,
                    message: error?.toString() || 'Could not fetch daily occupancy reports',
                },
            });
        }
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo<OccupancyContextType>(() => {
        return {
            ...state,
            getMonthlyOccupancyReports,
            getDailyOccupancyReports,
        };
    }, [state, getMonthlyOccupancyReports, getDailyOccupancyReports]);

    return <OccupancyReportContext.Provider value={value}>{children}</OccupancyReportContext.Provider>;
};

export const useOccupancyReport = () => {
    const context = useContext(OccupancyReportContext);

    if (!context) {
        throw new Error('Error: useOccupancy should be wrapped by OccupancyProvider.');
    }

    return context;
};
