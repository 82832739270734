import { Chart as ChartJS } from 'chart.js';
import { DAYS_FOR_REVIEW_REPORT } from 'utils/constants';

export function getFormattedRatingData(ratingReport: Record<string, number>) {
    const labels: string[] = [];
    const ratingData: number[] = [];
    let total: number = 0;

    Object.entries(ratingReport)
        .reverse()
        .forEach(([rating, amount]) => {
            labels.push(`${rating} stars`);
            ratingData.push(amount);
            total += amount;
        });

    return { labels, ratingData, total };
}

export function labelsOnChart(chartId: string, total: number) {
    return {
        id: `labels-on-chart-${chartId}`,
        afterDraw(chart: ChartJS) {
            const { ctx } = chart;
            if (ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }
            const { data } = chart.getDatasetMeta(0);
            if (data.length === 0) {
                return;
            }
            ctx.save();

            const values = chart.data.datasets[0].data;

            data.forEach((dataPoint, i) => {
                const { x: centerX, y: centerY } = dataPoint.tooltipPosition();
                const value = values[i];

                if (value && typeof value === 'number') {
                    const percentage = Number((value / total) * 100);

                    if (percentage > 5) {
                        const text = `${Math.round(percentage)}%`;
                        const textWidth = ctx.measureText(text).width;

                        const textX = centerX - textWidth / 2;
                        ctx.fillStyle = i < 2 ? '#FFF' : '#717680';
                        ctx.fillText(text, textX, centerY);
                        ctx.restore();
                    }
                }
            });
        },
    };
}

export function totalValueOnChart(chartId: string, total: number) {
    return {
        id: `total-value-on-chart-${chartId}`,
        afterDraw(chart: ChartJS) {
            const { ctx } = chart;

            if (ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }

            const { data } = chart.getDatasetMeta(0);
            if (data.length === 0) {
                return;
            }
            ctx.save();

            const xCoord = chart.getDatasetMeta(0).data[0].x;
            const yCoord = chart.getDatasetMeta(0).data[0].y;

            const firstLine = String(total);
            const secondLine = 'reviews in';
            const thirdLine = `the last ${DAYS_FOR_REVIEW_REPORT} days`;

            const fontSize = Number(ctx.font.slice(0, 2)) || 16;

            const padding = 4;
            const marginBottom = fontSize >= 18 ? 32 : fontSize === 16 ? 24 : 16;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000';

            ctx.fillText(secondLine, xCoord, yCoord - fontSize - padding - marginBottom);
            ctx.fillText(thirdLine, xCoord, yCoord - marginBottom);

            ctx.font = 'bolder ' + ctx.font;
            ctx.fillText(firstLine, xCoord, yCoord - fontSize * 2 - padding * 2 - marginBottom);
            ctx.restore();
        },
    };
}
