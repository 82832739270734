import { EXPERIMENT_CAL_REQUESTED, EXPERIMENT_CAL_SUCCESS, EXPERIMENT_CAL_FAILED } from './constants';
import { Action } from '../useReducerWithMiddleware';
import { ExperimentCalendarRecord } from 'types/Experiment';

export type ExperimentsCalendarState = {
    experiments: Array<ExperimentCalendarRecord>;
    errorMessage: string;
    loading: boolean;
};

export const initialExperimentsCalendarState: ExperimentsCalendarState = {
    experiments: [],
    errorMessage: '',
    loading: false,
};

export default function experimentCalendarReducer(
    state: ExperimentsCalendarState = initialExperimentsCalendarState,
    action: Action
): ExperimentsCalendarState {
    switch (action.type) {
        case EXPERIMENT_CAL_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case EXPERIMENT_CAL_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                experiments: (action.payload as { experiments: Array<ExperimentCalendarRecord> }).experiments,
            };
        case EXPERIMENT_CAL_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { error: { message: string } }).error.message,
            };
        default:
            return state;
    }
}
