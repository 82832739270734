import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions, Plugin } from 'chart.js';
import { getFormattedRatingData, labelsOnChart, totalValueOnChart } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

type RatingGraphProps = {
    ratingReport: Record<string, number>;
    graphKey?: string;
};

// If a change at COLOR is made, please update LIGHT_COLORS
const COLORS = ['#33B28F', '#77DC99', '#B3E48D', '#F9D998', '#FAE3E3'];
const LIGHT_COLORS = ['#33B28F4d', '#77DC994d', '#B3E48D4d', '#F9D9984d', '#FAE3E34d'];

const XS_BREAKPOINT = 205;
const SM_BREAKPOINT = 300;
const MD_BREAKPOINT = 450;
const LG_BREAKPOINT = 520;
const XL_BREAKPOINT = 600;
const XXL_BREAKPOINT = 720;

const RatingGraph = ({ ratingReport, graphKey = '' }: RatingGraphProps) => {
    const { labels, ratingData, total } = getFormattedRatingData(ratingReport);
    const [containerWidth, setContainerWidth] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);

    const graphColors = [...COLORS];
    const ratingChartId = 'rating-chart';
    const emptyRatingChartId = 'empty-rating-chart';

    function resizeHandler() {
        if (containerRef.current?.clientWidth) setContainerWidth(containerRef.current.clientWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        setContainerWidth(containerRef?.current?.clientWidth || 0);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    function getFontSize() {
        return containerWidth > LG_BREAKPOINT
            ? 20
            : containerWidth > MD_BREAKPOINT
            ? 18
            : containerWidth > SM_BREAKPOINT
            ? 16
            : 12;
    }

    const data: ChartData<'doughnut'> = {
        labels,
        datasets: [
            {
                data: ratingData,
                backgroundColor: graphColors,
                borderWidth: 2,
                borderColor: '#fff',
            },
        ],
    };

    const options: ChartOptions<'doughnut'> = {
        rotation: -90,
        circumference: 180,
        layout: {
            padding: containerWidth > XL_BREAKPOINT ? 20 : 0,
        },
        cutout: '60%',
        responsive: true,
        aspectRatio:
            containerWidth > XXL_BREAKPOINT
                ? 2
                : containerWidth > LG_BREAKPOINT
                ? 1.7
                : containerWidth > MD_BREAKPOINT
                ? 1.4
                : containerWidth > XS_BREAKPOINT
                ? 1.3
                : 1,
        font: {
            size: getFontSize(),
        },
        plugins: {
            legend: {
                position: 'bottom',
                onClick: () => {},
                onHover: (_evt, item, legend) => {
                    for (let i = 0; i < graphColors.length; i++) {
                        if (i !== item.index) graphColors[i] = LIGHT_COLORS[i];
                        else graphColors[i] = COLORS[i];
                    }
                    legend.chart.update();
                },
                onLeave: (_evt, _item, legend) => {
                    for (let i = 0; i < graphColors.length; i++) {
                        graphColors[i] = COLORS[i];
                    }
                    legend.chart.update();
                },
                labels: {
                    usePointStyle: true,
                    padding: containerWidth > MD_BREAKPOINT ? 18 : containerWidth > SM_BREAKPOINT ? 16 : 12,
                    font: {
                        size: getFontSize(),
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    const plugins: Plugin<'doughnut'>[] = [
        labelsOnChart(ratingChartId, total),
        totalValueOnChart(ratingChartId, total),
    ];

    const emptyGraphData: ChartData<'doughnut'> = {
        labels: [''],
        datasets: [{ data: [1], backgroundColor: '#F3F4F6' }],
    };

    const emptyGraphOptions: ChartOptions<'doughnut'> = {
        ...options,
        plugins: {
            legend: {
                labels: {
                    font: {
                        size: getFontSize(),
                    },
                    filter: () => false,
                },
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    return (
        <div style={{ maxWidth: '550px', margin: 'auto', paddingBlock: '8px' }} ref={containerRef}>
            {total ? (
                <Doughnut
                    key={`${graphKey}-${total}`}
                    role={ratingChartId}
                    data={data}
                    options={options}
                    plugins={plugins}
                />
            ) : (
                <Doughnut
                    key={`${graphKey}-${total}`}
                    role={emptyRatingChartId}
                    data={emptyGraphData}
                    options={emptyGraphOptions}
                    plugins={[totalValueOnChart(emptyRatingChartId, total)]}
                />
            )}
        </div>
    );
};

export default RatingGraph;
