import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ListForm from 'components/ListForm/ListForm';
import { getSalesRepsAction, addSpotAction, uploadSpotPhotoAction } from 'actions/actions';
import { selectSalesRepDropdown } from 'selectors';

const CreateSpotModuleContainer = (props: any) => {
    const {
        getSalesReps,
        salesReps,
        errorMessage,
        successMessage,
        loading,
        uploadSpotPhoto,
        spotPhotoLoading,
        organization,
        addSpot,
    } = props;

    useEffect(() => {
        getSalesReps();
    }, [getSalesReps]);

    return (
        <ListForm
            salesReps={salesReps}
            errorMessage={errorMessage}
            successMessage={successMessage}
            loading={loading}
            photo_url={props['photo_url']}
            spotPhotoLoading={spotPhotoLoading}
            organization={organization}
            uploadSpotPhoto={uploadSpotPhoto}
            addSpot={addSpot}
        />
    );
};

function mapStateToProps(state: any) {
    return {
        salesReps: selectSalesRepDropdown(state),
        errorMessage: state.spots.addSpotErrorMessage,
        successMessage: state.spots.addSpotSuccessMessage,
        loading: state.spots.loading,
        photo_url: state.upload.spotPhotoURL,
        spotPhotoLoading: state.upload.spotPhotoLoading,
        organization: state.organization.organization,
    };
}

const mapDispatchToProps = {
    getSalesReps: getSalesRepsAction,
    addSpot: addSpotAction,
    uploadSpotPhoto: uploadSpotPhotoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSpotModuleContainer);
