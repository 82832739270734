import React, { useEffect } from 'react';
import Table from './Table';
import { Grid, Loader } from 'semantic-ui-react';
import UpliftBox from './UpliftBox';
import useFilters from 'hooks/useFilters';
import { Organization, SpotInterface } from 'types';
import { Push } from 'connected-react-router';
import { getFormattedDateYYYY_MM_DD, isValidDateForIncomeStatement, newUTCDate } from 'utils/helpers';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import DateSelector from './DateSelector';
import { getDateRangeKey, getSpotSelectOptions } from './utils';
import DownloadStatement from './DownloadStatement';
import styles from './IncomeStatement.module.css';
import { useIncomeStatement } from 'contexts/IncomeStatementContext';
import AGMessage from 'components/AGMessage/AGMessage';

type IncomeStatementProps = {
    connectedPush: Push;
    orgSpots: Record<string, SpotInterface>;
    organization: Organization;
};

const IncomeStatement = ({ connectedPush, orgSpots, organization }: IncomeStatementProps) => {
    const { getIncomeStatement, loading, errorMessage, incomeStatements } = useIncomeStatement();
    const selectOptions = getSpotSelectOptions(orgSpots);
    const today = new Date();

    const { filters, urlUpdater } = useFilters<{ spotPk: string; startDate: string; endDate: string }>({
        filterValidators: {
            spotPk: function spotPkValidator(currentValue: string | null): string {
                return currentValue && orgSpots && orgSpots[currentValue] ? currentValue : '';
            },
            startDate: function dateValidator(currentValue: string | null): string {
                return isValidDateForIncomeStatement(currentValue)
                    ? currentValue
                    : getFormattedDateYYYY_MM_DD(new Date(today.getFullYear(), today.getMonth(), 1));
            },
            endDate: function dateValidator(currentValue: string | null): string {
                return isValidDateForIncomeStatement(currentValue) ? currentValue : getFormattedDateYYYY_MM_DD(today);
            },
        },
        push: connectedPush,
    });

    function updateStartAndEndDate(startDate: Date, endDate: Date) {
        urlUpdater.startDate(getFormattedDateYYYY_MM_DD(startDate));
        urlUpdater.endDate(getFormattedDateYYYY_MM_DD(endDate));
    }

    let report;
    const dateRange = getDateRangeKey(filters.startDate, filters.endDate);
    const completeReport = incomeStatements[dateRange];
    if (completeReport) {
        if (!filters.spotPk) report = completeReport.organization;
        else report = completeReport.spots.find((report) => report.uuid === orgSpots[filters.spotPk].uuid);
    }

    useEffect(() => {
        const dateRange = getDateRangeKey(filters.startDate, filters.endDate);

        if (!incomeStatements[dateRange] && filters.startDate && filters.endDate)
            getIncomeStatement(organization.uuid, filters.startDate, filters.endDate, dateRange);
    }, [filters.startDate, filters.endDate, orgSpots, getIncomeStatement, incomeStatements, organization]);

    return (
        <div style={{ paddingBottom: '8%' }}>
            <Grid stackable>
                {!!errorMessage && (
                    <AGMessage color="error" style={{ width: '100%', marginInline: '1rem' }}>
                        {errorMessage}
                    </AGMessage>
                )}

                <div className={styles.headerOptions}>
                    {!!selectOptions && (
                        <AGSimpleSelect
                            icon={<Lines />}
                            selected={filters.spotPk}
                            options={selectOptions}
                            onSelect={urlUpdater.spotPk}
                            width="250px"
                        />
                    )}
                    <DateSelector
                        startDate={newUTCDate(filters.startDate)}
                        endDate={newUTCDate(filters.endDate)}
                        onSave={updateStartAndEndDate}
                        dropdownWidth="290px"
                    />
                    <DownloadStatement
                        propertyOptions={selectOptions}
                        {...filters}
                        orgSpots={orgSpots}
                        orgUuid={organization.uuid}
                    />
                </div>
                <Grid.Row>
                    {!report || loading ? (
                        <Loader active inline="centered" />
                    ) : (
                        <>
                            <Grid.Column computer={10} tablet={16}>
                                <Table report={report} />
                            </Grid.Column>
                            <Grid.Column computer={6} tablet={16}>
                                <UpliftBox report={report} />
                            </Grid.Column>
                        </>
                    )}
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default IncomeStatement;
