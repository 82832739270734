import { PaginatedVisitors } from 'clients/VisitorClient';
import {
    VISITORS_REQUESTED,
    VISITORS_SUCCESS,
    VISITORS_FAILED,
    ADD_VISITOR_REQUESTED,
    ADD_VISITOR_SUCCESS,
    ADD_VISITOR_FAILED,
    EDIT_VISITOR_REQUESTED,
    EDIT_VISITOR_SUCCESS,
    EDIT_VISITOR_FAILED,
    DELETE_VISITOR_REQUESTED,
    DELETE_VISITOR_SUCCESS,
    DELETE_VISITOR_FAILED,
} from './constants';
import { Action } from '../useReducerWithMiddleware';

export type VisitorsState = {
    loading: boolean;
    errorMessage: string;
    visitors: PaginatedVisitors | null;
    successMessage: string;
};

export const initialVisitorsState: VisitorsState = {
    loading: false,
    errorMessage: '',
    successMessage: '',
    visitors: null,
};

export default function visitorsReducer(state: VisitorsState = initialVisitorsState, action: Action): VisitorsState {
    switch (action.type) {
        case VISITORS_REQUESTED:
        case ADD_VISITOR_REQUESTED:
        case EDIT_VISITOR_REQUESTED:
        case DELETE_VISITOR_REQUESTED:
            return {
                ...state,
                errorMessage: '',
                successMessage: '',
                loading: true,
            };
        case VISITORS_SUCCESS:
            return {
                ...state,
                loading: false,
                visitors: (action.payload as { visitors: PaginatedVisitors }).visitors,
            };
        case VISITORS_FAILED:
        case ADD_VISITOR_FAILED:
        case EDIT_VISITOR_FAILED:
        case DELETE_VISITOR_FAILED:
            return {
                ...state,
                errorMessage: (action.payload as { message: string }).message,
                loading: false,
            };
        case ADD_VISITOR_SUCCESS:
        case EDIT_VISITOR_SUCCESS:
        case DELETE_VISITOR_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: (action.payload as { message: string }).message,
                visitors: (action.payload as { visitors: PaginatedVisitors }).visitors,
            };
        default:
            return state || initialVisitorsState;
    }
}
