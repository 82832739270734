import React, { SyntheticEvent } from 'react';
import { Card, Dropdown, DropdownItemProps, DropdownProps, Grid } from 'semantic-ui-react';
import { Organization, SpotInterface } from 'types';
import styles from './SpotHeader.module.css';
import LocationIcon from 'components/Icons/LocationIcon';
import BriefcaseIcon from 'components/Icons/BriefcaseIcon';

type SpotHeaderProps = {
    organization: Organization;
    currentSpot: SpotInterface | null;
    orgSpots: SpotInterface[] | null;
    onSelectSpot: (pk: number) => void;
};

function SpotHeader({ orgSpots, currentSpot, organization, onSelectSpot }: SpotHeaderProps): JSX.Element {
    if (orgSpots === null || orgSpots.length === 0 || currentSpot === null) return <></>;
    const { address_line1, address_line2, city, state, zipcode } = currentSpot.address;
    const options: DropdownItemProps[] = orgSpots.map((s: SpotInterface) => ({
        text: s.address.address_line1,
        value: s.pk,
        icon: s.pk === currentSpot.pk ? 'check' : '',
    }));

    const handleSpotChange = (e: SyntheticEvent, { value }: DropdownProps) => {
        if (value) {
            onSelectSpot(value as number);
        }
    };

    const subHeader = (
        <div className={styles.subHeaderWrapper}>
            <div className={styles.subHeader}>
                <LocationIcon />
                <span className={styles.subHeaderLabel}>{currentSpot.name}</span>
            </div>
            <div className={styles.subHeader}>
                <BriefcaseIcon />
                <span className={styles.subHeaderLabel}>{organization.name}</span>
            </div>
        </div>
    );

    return (
        <Grid columns="equal">
            <Grid.Column>
                <Card.Header>
                    {orgSpots.length > 1 ? (
                        <Dropdown
                            as="h1"
                            options={options}
                            onChange={handleSpotChange}
                            className={styles.dropdown}
                            defaultValue={currentSpot.pk}
                            value={currentSpot.pk}
                        />
                    ) : (
                        <h1 className={styles.spotTitle}>{address_line1}</h1>
                    )}
                    <p className={styles.spotSubtitle}>
                        {address_line2} {city}, {state} {zipcode}
                    </p>
                    <div className={styles.smallerScreenSubheader}>{subHeader}</div>
                </Card.Header>
            </Grid.Column>

            <Grid.Column floated="right" className={styles.biggerScreenSubheader}>
                {subHeader}
            </Grid.Column>
        </Grid>
    );
}

export default SpotHeader;
