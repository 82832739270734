import React from 'react';
import WidgetDataLabel from '../widgets/WidgetDataLabel';
import WidgetData from '../widgets/WidgetData';
import styles from './OverviewWidget.module.css';

type RentalAndRevenueProps = {
    rentals: { title: string; count: number }[];
    rentalTooltipMessage: string;
    revenue: string;
    revenueTooltipMessage: string;
};

const RENTALS = 'Rentals';
const REVENUE = 'Total Gross Revenue';

function RentalAndRevenue(props: RentalAndRevenueProps) {
    return (
        <div>
            <div>
                <WidgetDataLabel label={RENTALS} tooltip={{ title: RENTALS, message: props.rentalTooltipMessage }} />
                <div className={styles.rentalCount}>
                    {props.rentals.map((r) => {
                        return (
                            <div>
                                <p>{r.title}</p>
                                <WidgetData>{r.count}</WidgetData>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div>
                <WidgetDataLabel label={REVENUE} tooltip={{ title: REVENUE, message: props.revenueTooltipMessage }} />
                <WidgetData>{props.revenue}</WidgetData>
            </div>
        </div>
    );
}

export default RentalAndRevenue;
