import amplitude from 'amplitude-js';
import { AffiliationRole } from './constants';

type PageViewedParams = {
    pageName: string;
    organizationId: number | null;
    spotId: number | null;
    affiliationRole: AffiliationRole | null;
    superuser: boolean | null;
    [index: string]: unknown;
};

function asyncTrack(event: string, data: { [index: string]: unknown }) {
    setTimeout(() => {
        amplitude.getInstance().logEvent(event, data);
    }, 0);
}
export function trackPageViewed({ pageName, organizationId, spotId, ...rest }: PageViewedParams): void {
    const event = `Viewed ${pageName} Page`;
    asyncTrack(event, { organizationId, spotId, ...rest });
}
