import React, { useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import airGarageWhiteLogo from '../../../assets/whiteLogo.png';
import styles from './DiscountCodeFlyer.module.css';
import Loader from 'components/Loader/Loader';
import { Icon } from 'semantic-ui-react';

type DiscountCodeFlyerProps = {
    qrCode: string;
    loading: boolean;
    spotName: string;
    discountMessage: string;
    description: string;
};

export default function DiscountCodeFlyer({
    qrCode,
    loading,
    spotName,
    discountMessage,
    description,
}: DiscountCodeFlyerProps) {
    const [downloading, setDownloading] = useState(false);

    function downloadPDF() {
        setDownloading(true);
        const qrCodeDownloadButton = document.querySelector('#qrCodeDownloadButton') as Element;
        const capture = document.querySelector('#qrCodeFlyer') as HTMLElement;
        html2canvas(capture, {
            scale: 4,
            useCORS: true,
            backgroundColor: '#fff',
            ignoreElements: (element) => {
                return [qrCodeDownloadButton].includes(element);
            },
        }).then((canvas) => {
            const imgData = canvas.toDataURL('img/png');
            const doc = new jsPDF('p', 'mm', 'a4');
            const componentWidth = doc.internal.pageSize.getWidth();
            const componentHeight = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight, undefined, 'FAST');
            doc.save(`${`${spotName}_${discountMessage}`.replaceAll(' ', '_')}.pdf`);
            setDownloading(false);
        });
    }

    return (
        <div className={styles.flyerWrapper} id="qrCodeFlyer">
            <div className={styles.flyerHeader}>
                <img className={styles.flyerAGLogo} src={airGarageWhiteLogo} alt="AirGarage" />
                <h1 className={styles.flyerTitle}>
                    {spotName}
                    <br />
                    {discountMessage}
                </h1>
                {!loading && (
                    <button
                        id="qrCodeDownloadButton"
                        onClick={downloadPDF}
                        className={styles.flyerDownloadButton}
                        disabled={downloading}
                    >
                        <Icon name="download" size="small" className={styles.flyerDownloadIcon} />
                    </button>
                )}
            </div>
            <div className={styles.flyerMain}>
                <h2 contentEditable={true} className={styles.flyerSubtitle}>
                    {description}
                </h2>
                <div className={styles.flyerQrCode}>
                    {loading ? (
                        <Loader size="sm" />
                    ) : (
                        <img
                            src={qrCode}
                            alt={discountMessage}
                            className={styles.flyerQrCodeImg}
                            crossOrigin="anonymous"
                        />
                    )}
                </div>
                <h6 className={styles.flyerLabel}>For assistance or more information:</h6>
                <span className={styles.flyerValue}>help.airgarage.com</span>
            </div>
        </div>
    );
}
