import React, { useEffect, useState } from 'react';
import { Header, Loader, Tab } from 'semantic-ui-react';
import { ReportInterface } from 'types';
import MonthYearUtil from 'utils/monthYear';
import { usdFormatterWithoutCents } from 'utils/helpers';
import WidgetBox from '../widgets/WidgetBox';
import RentalAndRevenue from './RentalAndRevenue';
import FlagIcon from 'components/Icons/FlagIcon';
import LiveUpdateLabel from 'components/widgets/LiveUpdateLabel';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import { RentalsOverview, getAllRentalsOverview } from './task';

type OverviewWidgetProps = {
    reportError: string;
    report: ReportInterface | null;
    isReportLoading: boolean;
    updateReportsLoading: boolean;
    spotId: string;
    spotTimeZone: string;
};

const ACTIVE_RENTALS_MESSAGE = 'Active: bookings that are currently active.';
const UPCOMING_RENTALS_MESSAGE = '\n\n Upcoming: future bookings that have not yet started.';
const ENDED_RENTALS_MESSAGE = '\n\n Ended: total bookings that have been completed in the selected time frame.';

const TODAY_RENTALS_TOOLTIP_MESSAGE = ACTIVE_RENTALS_MESSAGE + ENDED_RENTALS_MESSAGE;
const THIS_MONTH_AND_YEAR_RENTALS_TOOLTIP_MESSAGE =
    ACTIVE_RENTALS_MESSAGE + UPCOMING_RENTALS_MESSAGE + ENDED_RENTALS_MESSAGE;

const TODAY_REVENUE_TOOLTIP_MESSAGE =
    "Today's cumulative gross revenue before refunds and AirGarage commission - earned when rentals have ended and been charged";
const MONTH_REVENUE_TOOLTIP_MESSAGE =
    'Cumulative gross revenue this month before refunds and AirGarage commission - earned when rentals have ended and been charged';
const YEAR_REVENUE_TOOLTIP_MESSAGE =
    'Cumulative gross revenue this year before refunds and AirGarage commission - earned when rentals have ended and been charged';

const ACTIVE = 'Active';
const UPCOMING = 'Upcoming';
const ENDED = 'Ended';

function OverviewWidget({
    report,
    reportError,
    isReportLoading,
    updateReportsLoading,
    spotId,
    spotTimeZone,
}: OverviewWidgetProps): JSX.Element {
    const monthYearUtil = new MonthYearUtil();
    const [rentalsOverview, setRentalsOverview] = useState<RentalsOverview | null>(null);

    useEffect(() => {
        async function getRentalsOverview() {
            const overview = await getAllRentalsOverview(spotId, spotTimeZone);
            setRentalsOverview(overview);
        }
        getRentalsOverview();
    }, [spotId, spotTimeZone]);

    if (reportError) {
        return (
            <WidgetBox title="Overview" icon={<FlagIcon />}>
                <>
                    <Header as="h1">Something went wrong</Header>
                    <Header as="h3">Please refresh the page</Header>
                </>
            </WidgetBox>
        );
    }

    if (isReportLoading || report === null || !rentalsOverview) {
        return (
            <WidgetBox title="Overview" icon={<FlagIcon />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    const { text: currentMonthYearText } = monthYearUtil.currentMonthYear; // This is something like `{ text: "April 2023" }`
    const [currentMonth, currentYear] = currentMonthYearText.split(' ');
    const { hourly_live, monthly, yearly } = report;
    const { todayOverview, thisMonthOverview, thisYearOverview } = rentalsOverview;

    const panes = [
        {
            menuItem: 'Today',
            render(): JSX.Element {
                let totalRevenue = 0;
                for (const { gmv } of Object.values(hourly_live)) {
                    totalRevenue += gmv;
                }
                return (
                    <Tab.Pane attached={false}>
                        <RentalAndRevenue
                            rentals={[
                                { title: ACTIVE, count: todayOverview.ACTIVE },
                                { title: ENDED, count: todayOverview.ENDED },
                            ]}
                            rentalTooltipMessage={TODAY_RENTALS_TOOLTIP_MESSAGE}
                            revenue={usdFormatterWithoutCents(totalRevenue)}
                            revenueTooltipMessage={TODAY_REVENUE_TOOLTIP_MESSAGE}
                        />
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: currentMonth,
            render(): JSX.Element {
                // Theoretically, the last element of the monthly array should be what we need, but we can match by month - year label ot be safe
                // monthly object has a 'date' field which reads something like "Apr 2023"
                let thisMoYe = monthly.find(({ date }) => {
                    return date === `${currentMonth.slice(0, 3)} ${currentYear}`;
                });
                if (thisMoYe === undefined) {
                    thisMoYe = monthly[monthly.length - 1];
                }
                return (
                    <Tab.Pane attached={false}>
                        <RentalAndRevenue
                            rentals={[
                                { title: ACTIVE, count: thisMonthOverview.ACTIVE },
                                { title: UPCOMING, count: thisMonthOverview.UPCOMING },
                                { title: ENDED, count: thisMonthOverview.ENDED },
                            ]}
                            rentalTooltipMessage={THIS_MONTH_AND_YEAR_RENTALS_TOOLTIP_MESSAGE}
                            revenue={usdFormatterWithoutCents(thisMoYe === undefined ? 0 : thisMoYe.gross_revenue)}
                            revenueTooltipMessage={MONTH_REVENUE_TOOLTIP_MESSAGE}
                        />
                    </Tab.Pane>
                );
            },
        },
        {
            menuItem: currentYear,
            render(): JSX.Element {
                return (
                    <Tab.Pane attached={false}>
                        <RentalAndRevenue
                            rentals={[
                                { title: ACTIVE, count: thisYearOverview.ACTIVE },
                                { title: UPCOMING, count: thisYearOverview.UPCOMING },
                                { title: ENDED, count: thisYearOverview.ENDED },
                            ]}
                            rentalTooltipMessage={THIS_MONTH_AND_YEAR_RENTALS_TOOLTIP_MESSAGE}
                            revenue={usdFormatterWithoutCents(yearly.gross_revenue)}
                            revenueTooltipMessage={YEAR_REVENUE_TOOLTIP_MESSAGE}
                        />
                    </Tab.Pane>
                );
            },
        },
    ];

    return (
        <WidgetBox
            title="Overview"
            icon={<FlagIcon />}
            updatesLabel={<LiveUpdateLabel loading={updateReportsLoading} />}
        >
            <Tab menu={{ secondary: true }} panes={panes} />
        </WidgetBox>
    );
}

function OverviewWidgetWrapper(props: OverviewWidgetProps): JSX.Element {
    return (
        <AuthorizedView allowed={Roles.Admin}>
            <OverviewWidget {...props} />
        </AuthorizedView>
    );
}

export default OverviewWidgetWrapper;
