import React, { useState, useEffect } from 'react';
import { View } from 'react-big-calendar';
import EventForm from '../EventForm/EventForm';
import { useVisitorsCode } from 'contexts/VisitorCodesContext';
import { FormattedEvent, formatEvents } from 'contexts/EventsContext/utils';
import { useEvents } from 'contexts/EventsContext';
import { getStartAndEndFromViewAndDate } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import AGMessage from 'components/AGMessage/AGMessage';
import styles from './EventCalendar.module.css';
import CalendarWrapper from 'components/CalendarWrapper/CalendarWrapper';
import AGButton from 'components/Button/Button';
import Modal, { ModalRef } from 'components/Modal/Modal';

function EventBox(props: any) {
    const event = props.event as FormattedEvent;

    return (
        <div>
            <p>{event.name}</p>
            <p className={styles.calendarLabel}>{event.quantity} spaces for public</p>
            {event.display_price && <p className={styles.calendarLabel}>Special Event Price: {event.display_price}</p>}
        </div>
    );
}

function EventCalendar({ selectedSpot }: { selectedSpot: number }) {
    const { getVisitorCodes, formattedVisitorCodes } = useVisitorsCode();
    const { events, loading, errorMessage, getEvents, addEvent, editEvent, deleteEvent } = useEvents();

    const [selectedEvent, setSelectedEvent] = useState<FormattedEvent | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedView, setSelectedView] = useState<View>('week');

    const eventFormModal = React.useRef<ModalRef>();

    function onAddEvent() {
        setSelectedEvent(null);
        eventFormModal.current?.openModal();
    }

    function closeEventFormModal() {
        eventFormModal.current?.closeModal();
    }

    useEffect(() => {
        const { start, end } = getStartAndEndFromViewAndDate(selectedDate, selectedView);
        getEvents(selectedSpot, { start, end });
    }, [selectedSpot, getEvents, selectedDate, selectedView]);

    useEffect(() => {
        getVisitorCodes(selectedSpot);
    }, [selectedSpot, getVisitorCodes]);

    if (loading) return <Loader />;
    return (
        <div style={{ width: '100%' }}>
            {errorMessage && <AGMessage color="error" title={errorMessage} />}

            <div style={{ marginBlock: '32px' }}>
                <CalendarWrapper
                    defaultView={selectedView}
                    defaultDate={selectedDate}
                    min={new Date(2008, 0, 2, 5, 0)}
                    events={formatEvents(events)}
                    components={{ day: { event: EventBox } }}
                    onSelectEvent={(event) => {
                        setSelectedEvent(event as FormattedEvent);
                        eventFormModal.current?.openModal();
                    }}
                    onNavigate={setSelectedDate}
                    onView={setSelectedView}
                />
            </div>
            <div>
                <span>
                    *Please note that our system prioritizes overlapping events in the following order: one time,
                    monthly, weekly, daily.
                </span>
            </div>

            <div className={styles.addEventBtn}>
                <AGButton onClick={onAddEvent} size="sm">
                    + Add Event
                </AGButton>
            </div>
            <Modal ref={eventFormModal} title={selectedEvent && selectedEvent.pk ? 'Edit Event' : 'Add Event'}>
                <EventForm
                    addEvent={addEvent}
                    deleteEvent={deleteEvent}
                    editEvent={editEvent}
                    selectedEvent={selectedEvent}
                    visitorCodes={formattedVisitorCodes}
                    selectedSpot={selectedSpot}
                    selectedDate={selectedDate}
                    selectedView={selectedView}
                    closeEventFormModal={closeEventFormModal}
                />
            </Modal>
        </div>
    );
}

export default EventCalendar;
