import React, { useState } from 'react';

import { ReactComponent as ColorIcon } from 'assets/icons/color.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { CheckInPayload } from 'clients/ValetSessionClient';
import Button from 'components/Button/Button';
import LinkLikeButton from 'components/Button/LinkLikeButton';
import CarIcon from 'components/Icons/CarIcon';
import AGForm from 'components/AGForm/AGForm';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import { StepComponentProps } from 'components/ValetDashboard/Steps/Steps';
import useForm from 'hooks/useForm';
import { RentalType } from 'utils/constants';
import { states, stringRepresentationToValues } from 'utils/statesSelect';

interface CarDetailFormProps extends StepComponentProps {
    checkInCar: (payload: CheckInPayload) => Promise<Response>;
}

function CarDetailForm(props: CarDetailFormProps) {
    const form = useForm({ plate: '', state: '', make: '', model: '', color: '', notes: '' });
    const [showMoreInputs, setShowMoreInputs] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleCheckIn() {
        setLoading(true);
        const values = stringRepresentationToValues(form.inputs.state);
        try {
            await props.checkInCar({
                ...form.inputs,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                state: values!.state,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                country: values!.country,
                rental_type: RentalType.Hourly,
            });

            props.goNext();
        } catch (err) {
            // TODO: Add Error Handling
        } finally {
            setLoading(false);
        }
    }

    return (
        <AGForm>
            <TextInput
                label="License Plate"
                onChange={form.handleInput}
                errors={null}
                placeholder="Enter license plate number"
                icon={<CarIcon width={20} />}
                isUppercase={true}
                name="plate"
            />

            <SearchSelectInput
                placeholder="Enter license state"
                label="License State"
                options={states}
                errors={null}
                icon={<CarIcon width={20} />}
                onSelected={(value) => {
                    form.handleValueChange('state', value);
                }}
                name="state"
            />

            <TextInput
                label="Notes"
                onChange={form.handleInput}
                errors={null}
                placeholder="Optional"
                icon={<EditIcon />}
                name="notes"
            />

            {showMoreInputs ? (
                <>
                    <TextInput
                        label="Car Make"
                        onChange={form.handleInput}
                        errors={null}
                        placeholder="Optional"
                        icon={<CarIcon width={20} />}
                        name="make"
                    />

                    <TextInput
                        label="Car Model"
                        onChange={form.handleInput}
                        errors={null}
                        placeholder="Optional"
                        icon={<CarIcon width={20} />}
                        name="model"
                    />

                    <TextInput
                        label="Car Color"
                        onChange={form.handleInput}
                        errors={null}
                        placeholder="Optional"
                        icon={<ColorIcon />}
                        name="color"
                    />
                </>
            ) : null}

            {showMoreInputs ? (
                <LinkLikeButton onClick={() => setShowMoreInputs(false)}>Hide advanced options</LinkLikeButton>
            ) : (
                <LinkLikeButton onClick={() => setShowMoreInputs(true)}>Show advanced options</LinkLikeButton>
            )}

            <Button
                type="button"
                onClick={handleCheckIn}
                loading={loading}
                disabled={loading || form.inputs.plate.length === 0 || form.inputs.state.length === 0}
            >
                Check In Vehicle
                <CheckIcon />
            </Button>
        </AGForm>
    );
}

export default CarDetailForm;
