import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTeamAction, deleteTeamMemberAction } from 'actions/actions';
import Team from 'components/Dashboard/Modules/Team';
import { AppState } from 'reducers/reducers';
import Loader from 'components/Loader/Loader';

type TeamModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function TeamModuleContainer({
    invitations,
    organization,
    getTeam,
    loading,
    deleteTeamMember,
    errorMessage,
    successMessage,
}: TeamModuleContainerProps) {
    useEffect(() => {
        getTeam(organization.pk);
    }, [organization, getTeam]);

    const active = !!invitations && !loading;
    return (
        <div>
            {active ? (
                <Team
                    loading={loading}
                    invitations={invitations}
                    deleteMember={deleteTeamMember}
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                />
            ) : (
                <Loader />
            )}
        </div>
    );
}

function mapStateToProps(state: AppState) {
    return {
        invitations: state.team.invitations,
        loading: state.team.loading,
        successMessage: state.team.successMessage,
        errorMessage: state.team.errorMessage,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
    };
}
const mapDispatchToProps = {
    getTeam: getTeamAction,
    deleteTeamMember: deleteTeamMemberAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamModuleContainer);
