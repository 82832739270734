import React from 'react';
import { AuthorizedView, Roles } from '../../Utilities/AuthorizedView';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import AGMessage from 'components/AGMessage/AGMessage';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Button/IconButton';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import InvitationForm from '../InvitationForm/InvitationForm';

type TeamModuleProps = {
    loading: boolean;
    successMessage: string;
    errorMessage: string;
    invitations: any[] | null;
    deleteMember: (pk: number) => void;
};

function TeamModule(props: TeamModuleProps) {
    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                {props.loading && <Loader />}
                {props.successMessage && <AGMessage color="success">{props.successMessage}</AGMessage>}
                {props.errorMessage && <AGMessage color="error">{props.errorMessage}</AGMessage>}

                <AuthorizedView allowed={Roles.Admin}>
                    <InvitationForm />
                </AuthorizedView>
            </div>
            <div>
                {props.invitations && props.invitations.length > 0 ? (
                    <AGTAbleWrapper>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Status</th>
                                <AuthorizedView allowed={Roles.Admin}>
                                    <th>Remove</th>
                                </AuthorizedView>
                            </tr>
                        </thead>
                        <tbody>
                            {props.invitations.map((invitation: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{`${invitation.first_name} ${invitation.last_name}`}</td>
                                        <td>{invitation.email}</td>
                                        <td>{invitation.role}</td>
                                        <td>{invitation.status}</td>
                                        <AuthorizedView allowed={Roles.Admin}>
                                            <td>
                                                <IconButton onClick={() => props.deleteMember(invitation.pk)}>
                                                    <TrashIcon width={12} fill="var(--ag-error)" />
                                                </IconButton>
                                            </td>
                                        </AuthorizedView>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </AGTAbleWrapper>
                ) : (
                    <p>No team members invited.</p>
                )}
            </div>
        </div>
    );
}

export default TeamModule;
