export function isImageLandscape(imageUrl: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            if (img.width > img.height) {
                resolve(true);
            } else {
                resolve(false);
            }
        };

        img.onerror = function () {
            reject(new Error('Failed to load the image from the URL: ' + imageUrl));
        };

        img.src = imageUrl;
    });
}
