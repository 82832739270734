import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trackingClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';
import { CameraImage, CameraOrientation } from 'types/CarTracking';
import { getFormattedDateAndTime } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import ImageCarousel, { CarouselImage } from 'components/ImageCarousel/ImageCarousel';
import AGMessage from 'components/AGMessage/AGMessage';
import styles from './VisibilityCameras.module.css';

type VisibilityCamerasProps = {
    spotUuid: string;
};

type CameraType = 'overheadImages' | 'lprEntryImages' | 'lprExitImages';

type Cameras = Record<CameraType, CameraImage[]>;

function getImagesInfo(images: CameraImage[], cameraType: string): [boolean, CarouselImage[]] {
    const hasImages = images.length > 0;
    const formattedImages = images.map((img) => {
        return {
            src: img.url,
            labels: [
                { text: cameraType, position: { top: 10, left: 10 } },
                {
                    text: 'Last updated: ' + getFormattedDateAndTime(new Date(img.event_datetime * 1000)),
                    position: { bottom: 10, left: 10 },
                },
            ],
        };
    });
    return [hasImages, formattedImages];
}

function VisibilityCameras({ spotUuid }: VisibilityCamerasProps): JSX.Element {
    const [cameras, setCameras] = useState<Cameras>({
        overheadImages: [],
        lprEntryImages: [],
        lprExitImages: [],
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dimensions, setDimensions] = useState({ overHead: {}, lpr: {}, lprContainer: {} });

    const [hasOverheadImages, overheadImages] = useMemo(
        () => getImagesInfo(cameras.overheadImages, 'Aerial Camera'),
        [cameras.overheadImages]
    );

    const [hasLPREntryImages, lprEntryImages] = useMemo(
        () => getImagesInfo(cameras.lprEntryImages, 'Entrance Camera'),
        [cameras.lprEntryImages]
    );

    const [hasLPRExitImages, lprExitImages] = useMemo(
        () => getImagesInfo(cameras.lprExitImages, 'Exit Camera'),
        [cameras.lprExitImages]
    );

    const containerRef = useRef<HTMLDivElement>(null);

    const getRecentCameraImages = useCallback(
        async function _getRecentCameraImages() {
            setLoading(true);
            try {
                const response = await trackingClient.getRecentCameraImages(spotUuid);

                const { overhead_images: overheadImages, lpr_images } = response;

                const cameras: Cameras = {
                    overheadImages,
                    lprEntryImages:
                        lpr_images.length > 0
                            ? lpr_images.filter((i) => i.orientation === CameraOrientation.ENTRANCE)
                            : [],
                    lprExitImages:
                        lpr_images.length > 0 ? lpr_images.filter((i) => i.orientation === CameraOrientation.EXIT) : [],
                };

                setCameras(cameras);
            } catch (e) {
                if (HTTPError.isHTTPError(e)) {
                    setErrorMessage(e.message);
                } else {
                    setErrorMessage(e?.toString() || 'Something went wrong');
                }
            } finally {
                setLoading(false);
            }
        },
        [spotUuid]
    );

    useEffect(() => {
        getRecentCameraImages();
    }, [spotUuid, getRecentCameraImages]);

    const onResize = useCallback(() => {
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.offsetWidth;
        if (!hasOverheadImages && (hasLPREntryImages || hasLPRExitImages)) {
            const height = containerWidth / 3.4;
            const width = (containerWidth - 60) / 2;
            setDimensions({
                lprContainer: { height },
                lpr: { height, width },
                overHead: {},
            });
        } else if (hasOverheadImages && !(hasLPREntryImages || hasLPRExitImages)) {
            setDimensions({
                lprContainer: {},
                lpr: {},
                overHead: { height: containerWidth / 2.5, width: containerWidth - 40 },
            });
        } else {
            const overHeadHeight = containerWidth / 2.3;
            const overHeadWidth = overHeadHeight * 1.3;
            const lprWidth = containerWidth - overHeadWidth - 60;
            setDimensions({
                lprContainer: { height: overHeadHeight, width: lprWidth },
                lpr: { height: overHeadHeight / 2 - 10, width: lprWidth },
                overHead: { height: overHeadHeight, width: overHeadWidth },
            });
        }
    }, [hasOverheadImages, hasLPREntryImages, hasLPRExitImages]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    return (
        <div ref={containerRef} className="visibility-section">
            {loading ? (
                <Loader />
            ) : hasOverheadImages || hasLPREntryImages || hasLPRExitImages ? (
                <div className={styles.cameras}>
                    {errorMessage && <AGMessage color="error" title={errorMessage} />}
                    {hasOverheadImages && (
                        <div className={styles.overheadImages} style={dimensions.overHead}>
                            <ImageCarousel
                                key="overheadImages"
                                images={overheadImages}
                                maxImagesToShow={1}
                                infinite={false}
                            />
                        </div>
                    )}
                    {(hasLPREntryImages || hasLPRExitImages) && (
                        <div
                            className={hasOverheadImages ? styles.lprImages : styles.lprImagesOnly}
                            style={dimensions.lprContainer}
                        >
                            {hasLPREntryImages && (
                                <div className={styles.lpr} style={dimensions.lpr}>
                                    <ImageCarousel
                                        key="lprEntryImages"
                                        images={lprEntryImages}
                                        maxImagesToShow={1}
                                        infinite={false}
                                    />
                                </div>
                            )}
                            {hasLPRExitImages && (
                                <div className={styles.lpr} style={dimensions.lpr}>
                                    <ImageCarousel
                                        key="lprExitImages"
                                        images={lprExitImages}
                                        maxImagesToShow={1}
                                        infinite={false}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <p>Camera images will show up here</p>
            )}
        </div>
    );
}

export default VisibilityCameras;
