import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SpotInterface, PricingPayload } from 'types';
import { PricingStrategy, RentalType } from 'utils/constants';
import WidgetDataLabel from '../widgets/WidgetDataLabel';
import WidgetData from '../widgets/WidgetData';
import WidgetSubheader from '../widgets/WidgetSubheader';
import styles from './PricingWidget.module.css';
import LightingIcon from 'components/Icons/LightingIcon';

type Tooltip = { title: string; message: string };
const DYNAMIC_TOOLTIP = {
    title: 'Hourly Rate',
    message:
        'This is the current hourly rate in effect. This rate changes based on demand. Drivers will not pay more than the daily max for the day.',
};
const HOURLY_TOOLTIP = {
    title: 'Hourly Rate',
    message: 'This is the rate drivers will pay per hour.',
};
const MINIMUM_TOOLTIP = {
    title: 'Minimum',
    message: (dailyMinHours: number) =>
        `Drivers must pay the Hourly Rate for a minimum of ${dailyMinHours} hours, even if they stay for less than ${dailyMinHours} hours.`,
};
const FLAT_TOOLTIP = {
    title: 'Flat Rate',
    message:
        'When flat rate is in effect, drivers pay a single rate to park for the day. Flat rate overrides hourly rate.',
};
const MAX_TOOLTIP = {
    title: 'Daily Max',
    message:
        'This is the rate drivers pay to use the lot for the entire day, regardless of how long they end up staying.',
};
const MULTI_DAY_TOOLTIP = {
    title: 'Multi-day',
    message:
        'This is the rate drivers pay to use the lot for multiple days, regardless of how often they park within that time period.',
};
const MONTHLY_TOOLTIP = {
    title: 'Monthly Rate',
    message:
        'This is the rate drivers pay to use the lot for an entire month, regardless of how often they park within that time period.',
};

export type CurrentRatesProps = {
    spot: SpotInterface;
    rates: PricingPayload[] | null;
};

function CurrentRates({ spot, rates }: CurrentRatesProps): JSX.Element {
    const {
        active_rate: activeRate,
        daily_min: dailyMin,
        display_daily_max: dailyMax,
        display_price: displayPrice,
        display_price_daily: dailyPrice,
        display_price_monthly: monthlyPrice,
        dynamic_pricing_strategy: dynamicPricingStrategy,
        price_hourly: priceHourly,
    } = spot;
    const isDynamic = rates !== null && dynamicPricingStrategy === PricingStrategy.RATE;
    const dailyMinHours = dailyMin && dailyMin / priceHourly;

    const ratesToDisplay: { label: string; rate: string; detail?: string; tooltip: Tooltip }[] = [];
    if (displayPrice) {
        // We have hourly, but there are different flavors:
        if (activeRate?.unit === RentalType.Flat) {
            ratesToDisplay.push({
                label: FLAT_TOOLTIP.title,
                rate: displayPrice,
                detail: 'flat',
                tooltip: FLAT_TOOLTIP,
            });
        } else {
            if (isDynamic) {
                ratesToDisplay.push({
                    label: DYNAMIC_TOOLTIP.title,
                    rate: displayPrice,
                    detail: 'per hour',
                    tooltip: DYNAMIC_TOOLTIP,
                });
            } else {
                ratesToDisplay.push({
                    label: HOURLY_TOOLTIP.title,
                    rate: displayPrice,
                    detail: 'per hour',
                    tooltip: HOURLY_TOOLTIP,
                });
            }
            if (dailyMinHours) {
                ratesToDisplay.push({
                    label: MINIMUM_TOOLTIP.title,
                    rate: `${dailyMinHours} hrs`,
                    tooltip: { ...MINIMUM_TOOLTIP, message: MINIMUM_TOOLTIP.message(dailyMinHours) },
                });
            }
            if (dailyMax) {
                ratesToDisplay.push({
                    label: MAX_TOOLTIP.title,
                    rate: dailyMax,
                    tooltip: MAX_TOOLTIP,
                });
            }
        }
    }
    if (dailyPrice) {
        ratesToDisplay.push({
            label: MULTI_DAY_TOOLTIP.title,
            rate: dailyPrice,
            detail: 'per day',
            tooltip: MULTI_DAY_TOOLTIP,
        });
    }
    if (monthlyPrice) {
        ratesToDisplay.push({
            label: MONTHLY_TOOLTIP.title,
            rate: monthlyPrice,
            detail: 'per month',
            tooltip: MONTHLY_TOOLTIP,
        });
    }
    return (
        <>
            <WidgetSubheader icon={<LightingIcon />} label="Current Rates" />
            {/* The column count below changes based on how many rates we are showing - range from 1 to 6 */}
            <Grid columns={ratesToDisplay.length as 1 | 2 | 3 | 4 | 5 | 6}>
                {ratesToDisplay.map((r) => (
                    <Grid.Column key={r.label}>
                        <WidgetDataLabel label={r.label} tooltip={r.tooltip} />
                        <WidgetData>
                            {r.rate.replace('.00', '')}
                            <br className={styles.smallLabelDivider} />
                            {r.detail && <span className={styles.smallLabel}>{r.detail}</span>}
                        </WidgetData>
                    </Grid.Column>
                ))}
            </Grid>
        </>
    );
}

export default CurrentRates;
