import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { DiscountCodeFormData } from './DiscountCodeModalForm';

export enum DiscountType {
    DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
    PERCENTAGE = 'PERCENTAGE',
    TIME_BASED = 'TIME_BASED',
}

export const discountTypeOptions: SelectOption[] = [
    {
        label: 'Dollar amount discount',
        value: DiscountType.DOLLAR_AMOUNT,
    },
    {
        label: 'Percentage discount',
        value: DiscountType.PERCENTAGE,
    },
    {
        label: 'Time-based discount',
        value: DiscountType.TIME_BASED,
    },
];

export function sanitizeDiscountValues(values: DiscountCodeFormData) {
    return {
        sanitizedDiscountAmount: values.discountAmount ? values.discountAmount.replace(/[$\s]/g, '') : '',
        sanitizedDiscountPercentage: values.discountPercentage ? values.discountPercentage.replace(/[%\s]/g, '') : '',
        sanitizedDiscountTime: values.discountTime ? values.discountTime.replace(/[\smin]/g, '') : '',
    };
}
