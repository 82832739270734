import { DailyOccupancyReport, MonthlyOccupancyReport } from 'types';
import { HeatMapGraphProps } from './HeatMap';
import { dayCompleteName, monthCompleteName } from 'utils/constants';

const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const hourLabels = [
    { label: '6 - 8 am', key: '06am' },
    { label: '8 - 10 am', key: '08am' },
    { label: '10 - 12 pm', key: '10am' },
    { label: '12 - 2 pm', key: '12pm' },
    { label: '2 - 4 pm', key: '02pm' },
    { label: '4 - 6 pm', key: '04pm' },
    { label: '6 - 8 pm', key: '06pm' },
    { label: '8 - 10 pm', key: '08pm' },
    { label: '10 - 12 am', key: '10pm' },
];

export function getMonthlyDataForHeatmap(completeReport: MonthlyOccupancyReport): HeatMapGraphProps {
    let maxValue = { copy: '', value: 0, coordinates: '' };
    const report = completeReport.data;
    const data: Record<string, number[]> = {};

    for (const month of monthLabels) {
        data[month] = [];
        for (const day of dayLabels) {
            const key = `${month}-${day}`;
            let value = 0;
            if (report[key]) value = Math.ceil(report[key].average_rentals);
            data[month].push(value);

            if (value > maxValue.value)
                maxValue = {
                    copy: `You get the most rentals on ${dayCompleteName[day] || day}s in ${
                        monthCompleteName[month] || month
                    }`,
                    value,
                    coordinates: `${month}-${data[month].length - 1}`, // for example: 'Jan-2'
                };
        }
    }

    return { maxValue, data, xLabels: dayLabels, lastUpdatedAt: completeReport.last_updated_at };
}

export function getDailyDataForHeatmap(completeReport: DailyOccupancyReport): HeatMapGraphProps {
    let maxValue = { copy: '', value: 0, coordinates: '' };
    const report = completeReport.data;
    // daysPerHourData example = {
    //     '06am': {
    //         'Mon': value,
    //         'Tue': value,
    //         ...
    //     },
    //     '08am': {
    //         ...
    //     },
    //     ...
    // }
    const daysPerHourData: Record<string, Record<string, number>> = {};
    // averageRentalsByInterval example = {
    //     '2 - 4 pm': [0, 1, 1, 8, 3, 1, 10],
    //     '4 - 6 pm': [...],
    //     ...
    // }
    // each element of the arrays signifies the week day. So: averageRentalsByInterval['2 - 4 pm'][3]
    // means "average rentals on a Wednesday between 2pm and 4pm".
    const averageRentalsByInterval: Record<string, number[]> = {};

    for (const hour of hourLabels) {
        daysPerHourData[hour.key] = {};
        averageRentalsByInterval[hour.label] = [];
    }

    for (const dataPoint of report) {
        if (daysPerHourData[dataPoint.hour]) daysPerHourData[dataPoint.hour][dataPoint.dow] = dataPoint.average_rentals;
    }

    for (const hour of hourLabels) {
        for (const day of dayLabels) {
            let value = 0;
            const hourDayValue = daysPerHourData[hour.key][day];
            if (hourDayValue) value = Math.ceil(hourDayValue);

            averageRentalsByInterval[hour.label].push(value);

            if (value > maxValue.value)
                maxValue = {
                    copy: `You get the most rentals on ${dayCompleteName[day]}s from ${hour.label}`,
                    value,
                    coordinates: `${hour.label}-${averageRentalsByInterval[hour.label].length - 1}`, // for example: '8 - 10 am-0'
                };
        }
    }

    return {
        maxValue,
        data: averageRentalsByInterval,
        xLabels: dayLabels,
        lastUpdatedAt: completeReport.last_updated_at,
    };
}

// normalizeOpacity is a Sigmoid function
export function normalizeOpacity(value: number, maxValue: number): number {
    // apply sigmoid function for opacity:
    const normalizedValue = value / maxValue;
    const x = 8 * (normalizedValue - 0.5);
    const opacity = 1 / (1 + Math.exp(-x));
    return opacity;
}
