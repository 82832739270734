import React from 'react';
import { Calendar, CalendarProps, Culture, DateLocalizer, DateRange, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './react-big-calendar.css';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

function WeekDay(props: any) {
    const label = props.label as string;
    const [day, number] = label.split(' ');
    return (
        <span>
            {day.toUpperCase()}
            <br />
            {number}
        </span>
    );
}

function CalendarWrapper(props: Omit<CalendarProps, 'localizer'>) {
    const { components, formats, ...restProps } = props;

    function getDateHeaderForCalendar(
        { start, end }: DateRange,
        culture: string | undefined,
        localizer: object | undefined
    ) {
        const l = localizer as DateLocalizer;
        const c = culture as Culture;

        const [startMonth, startDay, startYear] = l.format(start, 'MMM D YYYY', c).split(' ');
        const [endMonth, endDay, endYear] = l.format(end, 'MMM D YYYY', c).split(' ');

        return `${startMonth} ${startDay}${startYear !== endYear ? ', ' + startYear : ''} - 
      ${endMonth !== startMonth ? endMonth + ' ' : ''}${endDay}, ${endYear}`;
    }

    return (
        <Calendar
            localizer={localizer}
            components={{ week: { header: WeekDay }, ...components }}
            formats={{
                timeGutterFormat: (date, culture, localizer) =>
                    (localizer as DateLocalizer).format(date, 'h A', culture as Culture),
                dayFormat: (date, culture, localizer) =>
                    (localizer as DateLocalizer).format(date, 'ddd DD', culture as Culture),
                dayRangeHeaderFormat: getDateHeaderForCalendar,
                agendaHeaderFormat: getDateHeaderForCalendar,
                ...formats,
            }}
            {...restProps}
        />
    );
}

export default CalendarWrapper;
