import React from 'react';
import { PagedResponse } from 'types/Pagination';
import { SlotInterface } from 'types';
import { dateFormatter } from 'utils/helpers';
import { anonymousUsername } from 'utils/constants';
import { SLOT_PAGE_SIZE } from 'clients/SlotClient';
import AGTableWrapper from 'components/AGTable/AGTableWrapper';
import Pagination from 'components/Pagination/Pagination';
import IconButton from 'components/Button/IconButton';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import styles from './MonthlyRentals.module.css';
import { convertCentsToDisplayPrice } from 'utils/currency';

type MonthlyRentalsTableProps = {
    paginatedMonthlyRentals: PagedResponse<SlotInterface>;
    page: string;
    setPage: (page: string) => void;
    deleteRental: (rental: SlotInterface) => void;
    editRental: (rental: SlotInterface) => void;
};

function MonthlyRentalsTable({
    paginatedMonthlyRentals,
    page,
    setPage,
    editRental,
    deleteRental,
}: MonthlyRentalsTableProps) {
    const { results: rentals } = paginatedMonthlyRentals;
    const totalPages = Math.ceil(paginatedMonthlyRentals.count / SLOT_PAGE_SIZE);

    return (
        <AGTableWrapper>
            <thead>
                <tr>
                    <th>License Plate</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Duration</th>
                    <th>Monthly Rate</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {rentals.length > 0 ? (
                    rentals.map((rental) => {
                        const rentalCars = rental.cars_detail
                            .map((car: any) => `${car.plate}${car.state ? ` - ${car.state}` : ''}`)
                            .join(', ');
                        const rate = rental.price_monthly_after_promo || rental.paid || rental.price_monthly || 0;
                        return (
                            <tr key={rental.pk}>
                                <td>{rentalCars}</td>
                                <td>
                                    {(rental.renter_first_name ? `${rental.renter_first_name} ` : '') +
                                        (rental.renter_last_name || '')}
                                </td>
                                <td>{rental.renter_email !== anonymousUsername && rental.renter_email}</td>
                                <td>
                                    {dateFormatter(rental.start_date)}
                                    {' - '}
                                    {rental.end_date ? dateFormatter(rental.end_date) : 'until they cancel'}
                                </td>
                                <td>{convertCentsToDisplayPrice(rate)}</td>
                                <td>
                                    <AuthorizedView allowed={[Roles.Superuser, Roles.Admin]}>
                                        <div className={styles.actionItems}>
                                            <IconButton onClick={() => editRental(rental)}>
                                                <EditIcon width={16} fill="#808080" />
                                            </IconButton>
                                            <IconButton onClick={() => deleteRental(rental)}>
                                                <TrashIcon width={16} fill="#808080" />
                                            </IconButton>
                                        </div>
                                    </AuthorizedView>
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={6}>Monthly drivers' information will show up here</td>
                    </tr>
                )}
            </tbody>
            {totalPages > 1 && (
                <tfoot>
                    <tr>
                        <td colSpan={6}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination currentPage={Number(page)} onPageChange={setPage} totalPages={totalPages} />
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </AGTableWrapper>
    );
}

export default MonthlyRentalsTable;
