export enum EventInterval {
    OneTime = 'one_time',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export type Event = {
    name: string;
    date_string: string;
    date: string | null;
    codes: number[];
    spot: number;
    quantity: number | null;
    price: number | null;
    max_price: number | null;
    start_time: string;
    end_time: string;
    interval: EventInterval;
    repeat_on_day: number | null;
    repeat_on_week: number | null;
    display_price: string;
    start: string;
    end: string;
    hidden: boolean;
    timezone: string;
    pk: number;
    is_lot_closed: boolean;
};
