import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { SpotInterface } from 'types';
import WidgetBox from '../widgets/WidgetBox';
import StarIcon from 'components/Icons/StarIcon';
import styles from './ReviewWidget.module.css';
import RatingGraph from 'components/RatingGraph/RatingGraph';
import EmptyStarIcon from 'components/Icons/EmptyStarIcon';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import AGMessage from 'components/AGMessage/AGMessage';
import { useReviewReport } from 'contexts/ReviewReportContext';
import { DAYS_FOR_REVIEW_REPORT } from 'utils/constants';

type ReviewWidgetProps = {
    currentSpot: SpotInterface;
};

const WIDGET_TITLE = 'Drivers Love Your Property';

function ReviewWidget({ currentSpot }: ReviewWidgetProps): JSX.Element {
    const { getReviewReports, reviewReport, loading, errorMessage } = useReviewReport();

    useEffect(() => {
        if (currentSpot) {
            getReviewReports(currentSpot.uuid, DAYS_FOR_REVIEW_REPORT);
        }
    }, [currentSpot, getReviewReports]);

    if (errorMessage && !reviewReport) {
        return (
            <WidgetBox title={WIDGET_TITLE} icon={<EmptyStarIcon />}>
                <AGMessage color="error">{errorMessage}</AGMessage>
            </WidgetBox>
        );
    }

    if (loading || !reviewReport) {
        return (
            <WidgetBox title={WIDGET_TITLE} icon={<EmptyStarIcon />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    const { last_positive_comments: positiveComments, top_3_reasons: topReasons } = reviewReport;

    const emptyReview = positiveComments.length === 0 && topReasons.length === 0;

    return (
        <WidgetBox title={WIDGET_TITLE} icon={<EmptyStarIcon />}>
            <>
                {!!errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}
                <RatingGraph ratingReport={reviewReport.rating_report} graphKey={currentSpot.uuid} />
                {emptyReview && (
                    <div className={styles.emptyStateMessage}>
                        <p>Your driver review analysis will show up here</p>
                        <p>
                            Driver review analysis will appear after <br />
                            gathering sufficient data
                        </p>
                    </div>
                )}
                {positiveComments.length > 0 && (
                    <>
                        <h3 className={styles.sectionTitle}>What They Say</h3>
                        {positiveComments.map((comment, i) => {
                            return (
                                <div className={styles.commentSection} key={i}>
                                    <p dangerouslySetInnerHTML={{ __html: `"${comment}"` }}></p>
                                    <div>
                                        {Array.from(Array(5).keys()).map((num) => (
                                            <StarIcon color="#D1581F" key={num} />
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
                {topReasons.length > 0 && (
                    <>
                        <h3 className={styles.sectionTitle}>
                            Why They Love Your Property
                            <InfoTooltip text="Top 3 reasons why drivers love your property, data retrieved from driver reviews" />
                        </h3>
                        {topReasons.map((reason, i) => (
                            <p className={styles.reasonList} key={i}>
                                <span className={styles.listNumber}>{i + 1}.</span>
                                <span className={styles.reason}>{reason}</span>
                            </p>
                        ))}
                    </>
                )}
            </>
        </WidgetBox>
    );
}

export default ReviewWidget;
