import React from 'react';
import { Form } from 'semantic-ui-react';
import StateField from '../StateField/StateField';

const ValidationForm = (props: any) => {
    const { handleInput, inputs, handleAddVisitorValidation, addVisitorRequested, handleValueChange } = props;
    return (
        <Form>
            <Form.Input
                fluid
                name="plate"
                value={inputs.plate}
                onChange={handleInput}
                type="text"
                placeholder="ABC123"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            />
            <StateField
                handleValueChange={handleValueChange}
                state={inputs.state}
                country={inputs.country}
                style={{ marginBottom: '1em' }}
            />

            <Form.Button fluid onClick={handleAddVisitorValidation} content="Add plate" loading={addVisitorRequested} />
        </Form>
    );
};

export default ValidationForm;
