import { logoutAction } from 'actions/actions';
import logo from 'assets/AirGarage.png';
import { ReactComponent as CarKeyIcon } from 'assets/icons/carKey.svg';
import { ReactComponent as CheckOutIcon } from 'assets/icons/checkout.svg';
import Button from 'components/Button/Button';
import Layout from 'components/ValetDashboard/Layout/Layout';
import type { ModalRef } from 'components/Modal/Modal';
import Modal from 'components/Modal/Modal';
import Paginated from 'components/ValetDashboard/Paginated/Paginated';
import Steps from 'components/ValetDashboard/Steps/Steps';
import Table from 'components/ValetDashboard/Table/Table';
import { push } from 'connected-react-router';
import usePaginatedFiltersWithSearch from 'hooks/usePaginatedFiltersWithSearch';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { valetSessionClient } from 'clients';
import SpotsService from 'services/SpotsService';
import { CheckInPayload, EditValetSessionPayload, GetValetSessionsParams } from 'clients/ValetSessionClient';
import { SpotInterface } from 'types';
import { ValetSession, ValetSessionStatus } from 'types/ValetSession';
import { ValetSessionStatusFilter } from 'utils/valetSessions';
import CarCodeForm from './components/CarCodeForm/CarCodeForm';
import CarDetailForm from './components/CarDetailForm/CarDetailForm';
import CheckBalanceForm from './components/CheckBalanceForm/CheckBalanceForm';
import EditValetSessionForm from './components/EditValetSessionForm/EditValetSessionForm';
import SuccessCheckIn from './components/SuccessCheckIn/SuccessCheckIn';
import SuccessCheckOut from './components/SuccessCheckOut/SuccessCheckOut';
import ValetTableHeader from './components/ValetTableHeader/ValetTableHeader';
import styles from './ValetDashboard.module.css';

interface ValetDashboardProps {
    push: typeof push;
    logoutAction: typeof logoutAction;
}

const ValetDashboard = ({ push, logoutAction }: ValetDashboardProps) => {
    const checkInModal = useRef<ModalRef>();
    const checkOutModal = useRef<ModalRef>();
    const editModalRef = useRef<ModalRef>();
    const [loading, setLoading] = useState(false);
    const [valetSessions, setValetSessions] = useState<ValetSession[]>([]);
    const [totalValetSessions, setTotalValetSessions] = useState(0);
    const [valetSessionOnCheckIn, setValetSessionOnCheckIn] = useState<ValetSession | null>(null);
    const [valetSessionOnCheckOut, setValetSessionOnCheckOut] = useState<ValetSession | null>(null);
    const [valetSessionOnEdit, setValetSessionOnEdit] = useState<ValetSession | null>(null);
    const [spot, setSpot] = useState<SpotInterface | null>(null);
    const params = useParams<{ orgId: string }>();
    const { filters, urlUpdater } = usePaginatedFiltersWithSearch<{
        status: ValetSessionStatusFilter;
    }>({
        filterValidators: {
            status: (current) => {
                return current === ValetSessionStatus.Active ||
                    current === ValetSessionStatus.Ended ||
                    current === ValetSessionStatus.Pending
                    ? current
                    : '';
            },
        },
        push,
    });

    // TODO: Move this to a custom hook to extract fetching logic
    const refreshValetSessions = useCallback(async function refreshValetSessions(params: GetValetSessionsParams = {}) {
        setLoading(true);
        const valetSessions = await valetSessionClient.getValetSessions(params);
        setValetSessions(valetSessions.results);
        setTotalValetSessions(valetSessions.count);
        setLoading(false);
    }, []);

    // TODO: Move this to a custom hook to extract fetching logic
    async function getParkingSpot(orgId: number) {
        const token = Cookies.get('airgarage_auth') || '';
        const spots = await SpotsService.getSpotsInfo(orgId, token);
        // TODO: Handle case where there are multiple spots in one org
        setSpot(spots.filter((s) => !s.hidden)[0]);
    }

    // TODO: Move this to a custom hook to extract fetching logic
    async function checkInCar(payload: CheckInPayload) {
        if (!valetSessionOnCheckIn) return;
        const updatedValetSessions = await valetSessionClient.checkInValetSession(valetSessionOnCheckIn.uuid, payload);
        refreshValetSessions({
            status: filters.status,
            search: filters.searchTerm,
            page: filters.page,
        });
        setValetSessionOnCheckIn(updatedValetSessions);
        return updatedValetSessions;
    }

    async function checkOutCar(uuid: string) {
        if (!valetSessionOnCheckOut) return;
        const updatedValetSessions = await valetSessionClient.checkOutValetSession(uuid);
        refreshValetSessions({
            status: filters.status,
            search: filters.searchTerm,
            page: filters.page,
        });
        setValetSessionOnCheckOut(updatedValetSessions);
        return updatedValetSessions;
    }

    // TODO: Move this to a custom hook to extract fetching logic
    async function payValetSession(uuid: string) {
        if (!valetSessionOnCheckOut) return;
        const updatedValetSessions = await valetSessionClient.payValetSession(uuid);
        setValetSessionOnCheckOut(updatedValetSessions);
        return updatedValetSessions;
    }

    async function editValetSession(uuid: string, payload: EditValetSessionPayload) {
        const updatedValetSessions = await valetSessionClient.editValetSession(uuid, payload);
        refreshValetSessions({
            status: filters.status,
            search: filters.searchTerm,
            page: filters.page,
        });
        return updatedValetSessions;
    }

    useEffect(() => {
        refreshValetSessions({
            status: filters.status,
            search: filters.searchTerm,
            page: filters.page,
        });
    }, [filters.status, filters.searchTerm, filters.page, refreshValetSessions]);

    useEffect(() => {
        getParkingSpot(Number(params.orgId));
    }, [params.orgId]);

    function handlePageChange(newPage: number) {
        urlUpdater.page(String(newPage));
    }

    function onCloseCheckInModal() {
        setValetSessionOnCheckIn(null);
        checkInModal.current?.closeModal();
    }

    function onCloseCheckOutModal() {
        setValetSessionOnCheckOut(null);
        checkOutModal.current?.closeModal();
    }

    function onOpenEditModal(valetSession: ValetSession) {
        setValetSessionOnEdit(valetSession);
        editModalRef.current?.openModal();
    }

    function onCloseEditModal() {
        setValetSessionOnEdit(null);
        editModalRef.current?.closeModal();
    }

    function logout() {
        logoutAction();
        push('/login');
    }

    const VALET_PAGE_SIZE = 25;

    return (
        <Layout>
            <header className={styles.dashboardHeader}>
                <img src={logo} alt="AirGarage Logo" className={styles.dashboardLogo} />

                <div className={styles.logoutWrapper}>
                    <Button className={styles.baseButton} color="secondary" size="sm" onClick={logout}>
                        Logout
                    </Button>
                </div>
            </header>

            <section className={styles.detailSection__wrapper}>
                <h1 className={styles.detailSectionAddress}>{spot?.name}</h1>
                <h3 className={styles.detailSectionTitle}>Valet Dashboard</h3>
            </section>

            <section className={styles.tableWrapper}>
                <ValetTableHeader
                    total={spot?.quantity || 0}
                    active={spot?.active_rentals || 0}
                    onCheckIn={() => checkInModal.current?.openModal()}
                    onCheckOut={() => checkOutModal.current?.openModal()}
                    filters={filters}
                    urlUpdater={urlUpdater}
                />

                <Paginated
                    currentPage={Number(filters.page)}
                    totalPages={Math.ceil(totalValetSessions / VALET_PAGE_SIZE)}
                    onPageChange={handlePageChange}
                >
                    <Table valetRentals={valetSessions} onOpenEditModal={onOpenEditModal} loading={loading} />
                </Paginated>
            </section>

            <Modal ref={checkInModal} title="Check in" icon={<CarKeyIcon />}>
                <Steps
                    steps={[
                        {
                            component: CarCodeForm,
                            props: {
                                onCodeVerify: (valetSession: ValetSession) => setValetSessionOnCheckIn(valetSession),
                            },
                        },
                        {
                            component: CarDetailForm,
                            props: {
                                checkInCar,
                            },
                        },
                        {
                            component: SuccessCheckIn,
                            props: {
                                valetSessionOnCheckIn,
                                onCloseModal: onCloseCheckInModal,
                            },
                        },
                    ]}
                />
            </Modal>
            <Modal ref={checkOutModal} title="Check Out" icon={<CheckOutIcon />}>
                <Steps
                    steps={[
                        {
                            component: CarCodeForm,
                            props: {
                                onCodeVerify: (valetSession: ValetSession) => setValetSessionOnCheckOut(valetSession),
                            },
                        },
                        {
                            component: CheckBalanceForm,
                            props: {
                                valetSessionOnCheckOut,
                                payValetSession,
                                checkOutCar,
                            },
                        },
                        {
                            component: SuccessCheckOut,
                            props: {
                                valetSessionOnCheckOut,
                                onCloseModal: onCloseCheckOutModal,
                            },
                        },
                    ]}
                />
            </Modal>

            <Modal ref={editModalRef} title="Modify Valet Session">
                {valetSessionOnEdit && (
                    <EditValetSessionForm
                        editValetSession={editValetSession}
                        valetSessionOnEdit={valetSessionOnEdit}
                        onCloseEditModal={onCloseEditModal}
                    />
                )}
            </Modal>
        </Layout>
    );
};

const mapDispatchToProps = {
    logoutAction: logoutAction,
    push: push,
};

export default connect(null, mapDispatchToProps)(ValetDashboard);
