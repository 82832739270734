import React from 'react';
import { ExperimentCalendarRecord } from 'types/Experiment';
import { formatDate } from 'utils/helpers';
import { convertCentsToDisplayPrice } from 'utils/currency';
import styles from './RatesCalendar.module.css';

interface ExperimentInfoInterface {
    experiment: ExperimentCalendarRecord;
}

function ExperimentInfo({ experiment }: ExperimentInfoInterface) {
    const startDate = new Date(experiment.start);
    return (
        <div className={styles.modal}>
            <p className={styles.date}>
                {formatDate(startDate)}, {startDate.getFullYear()}
            </p>
            <p className={styles.modalContentTitle}>Active Segments</p>
            {experiment.segments.map((segment) => (
                <div key={segment.uuid} className={styles.content}>
                    <p>
                        {segment.name} <span>({segment.percentage}%)</span>
                    </p>
                    <ul>
                        {segment.rates.map((rate) => {
                            const name = rate.name
                                .split(' - ')
                                .filter((name) => !name.includes('$') && !name.includes(rate.unit))
                                .join(' - ');

                            return (
                                <li key={rate.id}>
                                    {name}: {convertCentsToDisplayPrice(rate.price)} {rate.unit}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default ExperimentInfo;
