import React, { useMemo, useContext, createContext, useCallback } from 'react';
import incomeStatementReducer, { IncomeStatementState, initialIncomeStatementState } from './reducer';
import { INCOME_STATEMENT_FAILED, INCOME_STATEMENT_REQUESTED, INCOME_STATEMENT_SUCCESS } from './constants';
import { reportClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';

interface IncomeStatementActions {
    getIncomeStatement: (orgUuid: string, startDate: string, endDate: string, dateRangeKey: string) => void;
}

interface IncomeStatementContextType extends IncomeStatementState, IncomeStatementActions {}

export const IncomeStatementContext = createContext<IncomeStatementContextType | null>(null);

export const IncomeStatementProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        incomeStatementReducer,
        {
            ...initialIncomeStatementState,
        },
        [],
        [unauthorized]
    );

    const getIncomeStatement = useCallback(
        async (orgUuid: string, startDate: string, endDate: string, dateRangeKey: string) => {
            dispatch({ type: INCOME_STATEMENT_REQUESTED });
            try {
                const report = await reportClient.getIncomeStatementReport(orgUuid, startDate, endDate);

                dispatch({
                    type: INCOME_STATEMENT_SUCCESS,
                    payload: { dateRangeKey, report },
                });
            } catch (error) {
                dispatch({
                    type: INCOME_STATEMENT_FAILED,
                    payload: {
                        error,
                        message: error?.toString() || 'Could not fetch reports',
                    },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<IncomeStatementContextType>(() => {
        return {
            ...state,
            getIncomeStatement,
        };
    }, [state, getIncomeStatement]);

    return <IncomeStatementContext.Provider value={value}>{children}</IncomeStatementContext.Provider>;
};

export const useIncomeStatement = () => {
    const context = useContext(IncomeStatementContext);

    if (!context) {
        throw new Error('Error: useIncomeStatement should be wrapped by IncomeStatementProvider.');
    }

    return context;
};
