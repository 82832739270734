import React, { useState } from 'react';
import { Table, Image, Button, Header, Card } from 'semantic-ui-react';
import useModal from '../../../hooks/useModal';
import PlateImage from '../PlateImage/PlateImage';
import styles from '../SpotCard/SpotCard.module.css';

const CurrentlyParked = (props: any) => {
    const { records, addEnforcementPenalty } = props;
    const { isShowing, toggle } = useModal();
    const [id, setEnforcementId] = useState(0);
    return (
        <>
            <Table basic="very" selectable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Time in</Table.HeaderCell>
                        <Table.HeaderCell>Image</Table.HeaderCell>
                        <Table.HeaderCell>Plate</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Past Violations</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {records?.length > 0 && (
                    <PlateImage
                        toggle={toggle}
                        isShowing={isShowing}
                        enforcement={records[id]}
                        addEnforcementPenalty={addEnforcementPenalty}
                    />
                )}
                {records?.length > 0 ? (
                    records.map((record: any, index: any) => {
                        return (
                            <Table.Body key={record.pk}>
                                <Table.Row>
                                    <Table.Cell>{record.created_at}</Table.Cell>
                                    <Table.Cell>
                                        <Card
                                            onClick={() => {
                                                toggle();
                                                setEnforcementId(index);
                                            }}
                                        >
                                            <Image
                                                size="tiny"
                                                className={styles.plateImage}
                                                src={record.image_url}
                                                rounded
                                            />
                                        </Card>
                                    </Table.Cell>
                                    <Table.Cell>{record.plate}</Table.Cell>
                                    <Table.Cell>{record.status}</Table.Cell>
                                    <Table.Cell>{record.previous_violations}</Table.Cell>
                                    <Table.Cell>
                                        {record.status === 'violator' && record.penalty !== 'ticket issued' && (
                                            <Button
                                                color="blue"
                                                content="Issue ticket"
                                                onClick={() =>
                                                    addEnforcementPenalty({
                                                        parent_enforcement: record.enforcement,
                                                        penalty_type: 'ticket issued',
                                                    })
                                                }
                                            />
                                        )}
                                        {record.status === 'violator' && record.penalty === 'ticket issued' && (
                                            <>Ticket issued by {record.owner}</>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        );
                    })
                ) : (
                    <Header content="No cars are currently parked in the lot and meet the filter criteria." as="h4" />
                )}
            </Table>
        </>
    );
};

export default CurrentlyParked;
