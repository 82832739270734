import React from 'react';
import { Form } from 'semantic-ui-react';
import styles from './../ListForm/ListForm.module.css';
import { lotSource, lotTier } from './../../utils/constants';

const SpotOpsFields = (props: any) => {
    const { handleInput, handleDropdown, fields } = props;

    return (
        <>
            <p className={styles.tabHeading}>Ops</p>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        name="visitor_registration_phone"
                        icon="phone"
                        iconPosition="left"
                        placeholder="6029874242"
                        onChange={handleInput}
                        value={fields.visitor_registration_phone}
                        label="Visitor phone"
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        name="payment_phone"
                        icon="phone"
                        iconPosition="left"
                        placeholder="6028894242"
                        onChange={handleInput}
                        value={fields.payment_phone}
                        label="Payment phone"
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        name="services_agreement_date"
                        icon="calendar alternate outline"
                        iconPosition="left"
                        placeholder="2021-01-31"
                        onChange={handleInput}
                        value={fields.services_agreement_date}
                        label="Date of services agreement (yyyy-mm-dd)"
                        required
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Select
                        label="Tier"
                        name="tier"
                        placeholder="Select tier"
                        selection
                        fluid
                        options={lotTier}
                        onChange={handleDropdown}
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Select
                        label="Acqusition source"
                        name="source"
                        placeholder="Select source"
                        selection
                        fluid
                        options={lotSource}
                        onChange={handleDropdown}
                        required
                    />
                </Form.Field>
            </Form.Group>
        </>
    );
};

export default SpotOpsFields;
