import React, { useRef, useState } from 'react';
import { CountryOption, getSelectionFromPhoneCountryCode, optionsForSelect } from 'assets/data/countriesPhoneCodes';
import { ReactComponent as ArrowsIcon } from 'assets/icons/arrows.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { Errors, getErrorMessage } from 'components/ValetDashboard/utils/errors';
import { useClickOutside } from 'hooks/useClickOutside';
import { Option } from 'types/SelectInputs';
import Dropdown from '../SelectInput/Dropdown';
import styles from './PhoneInput.module.css';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Label from '../Label/Label';

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onPhoneChange: (phone: string) => void;
    phoneNumber: string;
    onCountryCodeChange: (code: CountryOption | undefined) => void;
    countryCode: CountryOption | undefined;
    errors: Errors;
    disabled?: boolean;
    focusOnMount?: boolean;
}

function PhoneInput({
    errors,
    disabled = false,
    focusOnMount = false,
    onPhoneChange,
    phoneNumber,
    onCountryCodeChange,
    countryCode,
    ...props
}: PhoneInputProps): JSX.Element {
    const errorMessage = getErrorMessage(errors);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [completePhoneNumber, setCompletePhoneNumber] = useState(`${countryCode?.value || ''}${phoneNumber}`);
    const [isInputFocus, setIsInputFocus] = useState(false);

    const inputElRef = useRef<HTMLInputElement>(null);
    const phoneParser = new AsYouType(countryCode?.countryISOCode as CountryCode);

    function closeDropdown(): void {
        setDropdownOpen(false);
    }

    function handleSelect(option: Option | undefined): void {
        onCountryCodeChange(option as CountryOption);
        inputElRef?.current?.focus();
        phoneParser.reset();
        const newCompletePhoneNumber = `${option?.value || ''}${phoneNumber}`;
        setCompletePhoneNumber(phoneParser.input(newCompletePhoneNumber));
        setDropdownOpen(false);
    }

    function handleInput(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = '+' + event.target.value.replace(' ', '');

        phoneParser.reset();
        const completePhoneNumber = phoneParser.input(value);
        const possibleCountry = phoneParser.getCountry();
        const phone = phoneParser.getNationalNumber();
        const possibleNewCountryCode = getSelectionFromPhoneCountryCode(value, possibleCountry);

        if (
            (possibleNewCountryCode && countryCode !== possibleNewCountryCode) ||
            (countryCode && value.length < countryCode?.value.length)
        )
            onCountryCodeChange(possibleNewCountryCode);
        if (phone !== phoneNumber) onPhoneChange(phone);

        setCompletePhoneNumber(completePhoneNumber);
    }

    function handleInputFocus() {
        closeDropdown();
        setIsInputFocus(true);
    }

    function handleInputBlur() {
        setIsInputFocus(false);
    }

    function handleCountryClick() {
        setDropdownOpen(true);
    }

    const clickOutsideRef = useClickOutside(closeDropdown);

    return (
        <div className={['input-wrapper', styles.inputWrapper].join(' ')}>
            <Label icon={<PhoneIcon />} label="Phone Number" />

            <div
                className={[
                    'div-as-input no-padding',
                    errorMessage && styles.error,
                    disabled && 'disabled',
                    dropdownOpen || (isInputFocus && 'focus'),
                ].join(' ')}
            >
                <div className={styles.countryAndPhoneWrapper} ref={clickOutsideRef}>
                    <button
                        onClick={handleCountryClick}
                        className={styles.countryButton}
                        disabled={disabled}
                        type="button"
                    >
                        <span>{countryCode?.countryISOCode.toUpperCase() || '-'}</span>
                        <ArrowsIcon className={styles.arrowsIcon} />
                    </button>
                    <span className={styles.plus} />
                    <input
                        value={completePhoneNumber.replace('+', '')}
                        onChange={handleInput}
                        className={styles.combinedInput}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={disabled}
                        type="tel"
                        pattern="[0-9 ]+"
                        placeholder="Enter phone number"
                        data-testid="complete-phone-number-input"
                        ref={inputElRef}
                        {...props}
                    />
                </div>
            </div>
            <Dropdown
                options={optionsForSelect}
                selectedOption={countryCode}
                handleSelectOption={handleSelect}
                visible={dropdownOpen}
            />
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
}

export default PhoneInput;
