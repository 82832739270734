import React, { ButtonHTMLAttributes } from 'react';
import Loader from 'components/Loader/Loader';
import styles from './Button.module.css';

type Color = 'primary' | 'secondary' | 'error-outline' | 'info';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: Color;
    size?: 'sm' | 'md';
    loading?: boolean;
}

function Button({ color = 'primary', size = 'md', loading = false, className, ...restProps }: ButtonProps) {
    const invertedColor: Color = color === 'primary' ? 'secondary' : 'primary';

    return (
        <button
            className={`${styles.wrapper} ${styles[color]} ${styles[size]} ${className} ${
                restProps.disabled ? 'disabled' : ''
            }`}
            type="button"
            {...restProps}
            disabled={restProps.disabled || loading}
        >
            <div className={styles.buttonContent}>
                {loading ? <Loader size="sm" color={invertedColor} /> : restProps.children}
            </div>
        </button>
    );
}

export default Button;
