import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { getDailyReportsAction } from 'actions/actions';
import Reports from 'components/Dashboard/Modules/Reports';
import ReportMenu from 'components/Dashboard/Modules/ReportMenu';
import useFilters from 'hooks/useFilters';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId, selectedSpotInfo } from 'selectors';
import { trackPageViewed } from 'utils/analytics';
import { rentalTypesForReports } from 'utils/constants';
import { isValidDateForReports, isValidRentalTypeForReports } from 'utils/helpers';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';

type ReportsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ReportsModuleContainer = (props: ReportsModuleContainerProps) => {
    const monthYearUtil = new MonthYearUtil();
    const {
        selectedDailyReports,
        selectedSpot,
        selectedSpotPk,
        getDailyReports,
        loading,
        push: connectedPush,
        profile,
        organizationId,
    } = props;
    const { filters, urlUpdater } = useFilters<{
        monthYear: MonthYear['value'];
        rentalType: keyof typeof rentalTypesForReports;
    }>({
        filterValidators: {
            monthYear(currentValue: string | null): string {
                return isValidDateForReports(currentValue) ? currentValue : monthYearUtil.currentMonthYear.value;
            },
            rentalType(currentValue: string | null): keyof typeof rentalTypesForReports {
                return isValidRentalTypeForReports(currentValue) ? currentValue : '';
            },
        },
        push: connectedPush,
    });
    useEffect(() => {
        const [month, year] = filters.monthYear.split(':');
        getDailyReports({
            rentalType: filters.rentalType,
            month,
            year,
        });
    }, [getDailyReports, filters.monthYear, filters.rentalType, selectedSpot]);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Reports',
            organizationId,
            spotId: selectedSpotPk,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, selectedSpotPk, profile]);

    if (!selectedSpot) {
        return <Loader active inline />;
    }

    function handleDateFilterSelected(value?: string): void {
        urlUpdater.monthYear(value ? value : monthYearUtil.currentMonthYear.value);
    }

    function handleRentalTypeFilterSelected(value: keyof typeof rentalTypesForReports): void {
        urlUpdater.rentalType(value);
    }

    return (
        <>
            <ReportMenu
                spot={selectedSpot}
                handleDateFilterSelected={handleDateFilterSelected}
                handleRentalTypeFilterSelected={handleRentalTypeFilterSelected}
                fields={filters}
            />
            {!loading ? (
                <Reports selectedDailyReports={selectedDailyReports} filters={filters} />
            ) : (
                <Loader active inline />
            )}
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.reports.errorMessage,
        loading: state.reports.loading,
        selectedDailyReports: state.reports.selectedDailyReports,
        selectedSpot: selectedSpotInfo(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpotPk: selectedSpotId(state)!,
    };
}

const mapDispatchToProps = {
    getDailyReports: getDailyReportsAction,
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsModuleContainer);
