import React from 'react';

const InfoIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M5.875 3.875H7.125V5.125H5.875V3.875ZM5.875 6.375H7.125V10.125H5.875V6.375ZM6.5 0.75C3.05 0.75 0.25 3.55 0.25 7C0.25 10.45 3.05 13.25 6.5 13.25C9.95 13.25 12.75 10.45 12.75 7C12.75 3.55 9.95 0.75 6.5 0.75ZM6.5 12C3.74375 12 1.5 9.75625 1.5 7C1.5 4.24375 3.74375 2 6.5 2C9.25625 2 11.5 4.24375 11.5 7C11.5 9.75625 9.25625 12 6.5 12Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default InfoIcon;
