import APIClient from './APIClient';

class ValidationRequestsService extends Object {
    async getVisitorsToValidate(spot: number, token: string) {
        const result = await APIClient.get(`spots/${spot}/visitors/`, token);
        return result;
    }
    async validateVisitor(data: any, token: string) {
        const result = await APIClient.post(`validate`, data, token);
        return result;
    }
}

export default new ValidationRequestsService();
