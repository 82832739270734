import { VisitorCode } from 'types/VisitorCode';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import cache from './cache';

type AddOrEditVisitorCodePayload = {
    code: string;
    name: string;
    spot: number;
    hours_valid?: number | null;
    days_valid?: number | null;
    events?: number[];
    expiration_date?: string | null;
    always_valid?: boolean;
    custom?: boolean;
};

type VisitorCodeClientParams = HTTPClientParameters;

class VisitorCodeClient extends HTTPClient {
    constructor(params: VisitorCodeClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    _getVisitorCodes(spotPk: number): Promise<VisitorCode[]> {
        return this.get(`codes/?spot=${spotPk}`);
    }
    @cache(5 * 60000) // 5 min cache
    getVisitorCodes(spotPk: number): Promise<VisitorCode[]> {
        return this._getVisitorCodes(spotPk);
    }
    @cache(0)
    force__getVisitorCodes(spotPk: number): Promise<VisitorCode[]> {
        return this._getVisitorCodes(spotPk);
    }
    addVisitorCode(data: AddOrEditVisitorCodePayload): Promise<VisitorCode> {
        return this.post(`codes/`, data);
    }
    editVisitorCode(data: AddOrEditVisitorCodePayload, visitorCodePk: number): Promise<VisitorCode> {
        return this.patch(`codes/${visitorCodePk}/`, data);
    }
    refreshVisitorCodes(spotPk: number): Promise<VisitorCode[]> {
        return this.get(`refreshCodes?spot=${spotPk}`);
    }
    deleteVisitorCode(pk: number) {
        return this.delete(`codes/${pk}/`);
    }
}

export default VisitorCodeClient;
