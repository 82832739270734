import APIClient from './APIClient';

class EnforcementsService extends Object {
    async getEnforcements(spotPk: number, filteredUrl: string, token: string): Promise<object> {
        const result = await APIClient.get(`enforcements/?spot=${spotPk}${filteredUrl}`, token);
        return result['results'];
    }
    async updateEnforcement(enforcement: number, body: any, token: string) {
        const result = await APIClient.patch(`enforcements/${enforcement}/`, body, token);
        return result;
    }
    async addEnforcementPenalty(body: any, token: string) {
        const result = await APIClient.post(`enforcement_penalty/`, body, token);
        return result;
    }
    async getCurrentlyParked(spotPk: number, violatorsOnly: boolean, token: string): Promise<object> {
        let url = `currently_parked/?spot=${spotPk}`;
        if (violatorsOnly) {
            url += '&status=violator';
        }
        const result = await APIClient.get(url, token);
        return result;
    }
    async checkPlate(body: any, token: string): Promise<object> {
        const result = await APIClient.post(`enforcement`, body, token);
        return result;
    }
}

export default new EnforcementsService();
