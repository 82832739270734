import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Loader, Message, Card, Form, Container } from 'semantic-ui-react';
import { addEnforcementPenaltyAction, checkPlateAction } from 'actions/actions';
import CheckPlateCard from 'components/Dashboard/CheckPlateCard/CheckPlateCard';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type CheckPlateModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const CheckPlateModuleContainer = (props: CheckPlateModuleContainerProps) => {
    const {
        errorMessage,
        successMessage,
        loading,
        checkPlate,
        checkPlateResults,
        addEnforcementPenalty,
        organizationId,
        selectedSpot,
        profile,
    } = props;
    const [plate, setPlate] = useState('');

    useEffect(() => {
        trackPageViewed({
            pageName: 'Check Plate',
            organizationId,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, selectedSpot, profile]);
    return (
        <>
            {errorMessage && <Message negative content={errorMessage} />}
            {successMessage && <Message positive content={successMessage} />}
            <h4>Look up the current status and learn more about any plate.</h4>
            <Divider hidden />
            <Container>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            size="large"
                            placeholder="Plate"
                            value={plate}
                            onChange={(event) => setPlate(event.target.value)}
                            autoFocus
                        />
                        <Form.Button
                            fluid
                            icon="search"
                            content="Check Plate"
                            onClick={() => {
                                checkPlate(plate);
                                setPlate('');
                            }}
                        />
                    </Form.Group>
                </Form>
            </Container>
            <Divider hidden />
            {loading && <Loader active />}
            <Card.Group>
                {checkPlateResults.map((enforcement: any, index: number) => (
                    <CheckPlateCard
                        enforcement={enforcement}
                        addPenalty={addEnforcementPenalty}
                        key={enforcement.pk}
                        index={index}
                    />
                ))}
            </Card.Group>

            <Divider hidden />
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        loading: state.enforcements.loading,
        successMessage: state.enforcements.successMessage,
        errorMessage: state.enforcements.errorMessage,
        checkPlateResults: state.enforcements.checkPlateResults,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    checkPlate: checkPlateAction,
    addEnforcementPenalty: addEnforcementPenaltyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckPlateModuleContainer);
