import React from 'react';
import { connect } from 'react-redux';
import EventCalendar from 'components/Dashboard/Modules/EventCalendar';
import { AppState } from 'reducers/reducers';
import { selectedSpotId } from 'selectors';
import { VisitorCodesProvider } from 'contexts/VisitorCodesContext';
import { EventsProvider } from 'contexts/EventsContext';
import Loader from 'components/Loader/Loader';

type CalendarModuleContainerProps = ReturnType<typeof mapStateToProps>;

function CalendarModuleContainer({ selectedSpot }: CalendarModuleContainerProps) {
    return selectedSpot ? (
        <VisitorCodesProvider>
            <EventsProvider>
                <EventCalendar selectedSpot={selectedSpot} />
            </EventsProvider>
        </VisitorCodesProvider>
    ) : (
        <Loader />
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
    };
}

export default connect(mapStateToProps, {})(CalendarModuleContainer);
