import React, { useState, useEffect, useRef, FC } from 'react';
import Dropdown from './Dropdown';
import { ReactComponent as ArrowsIcon } from 'assets/icons/arrows.svg';
import { valueToSelectOptionTranslator } from './utils';
import { Option, Options } from 'types/SelectInputs';
import styles from './SimpleSelect.module.css';
import { useClickOutside } from 'hooks/useClickOutside';

interface SimpleSelectProps {
    selected?: Option;
    options: Options;
    label?: string;
    disabled?: boolean;
    onChange?: (selected: Option) => void;
}

const SimpleSelect: FC<SimpleSelectProps> = ({
    selected: initialSelected,
    options,
    label,
    disabled = false,
    onChange,
}) => {
    const [selected, setSelected] = useState<Option | undefined>(initialSelected);
    const [dropdownVisible, setDropdownVisible] = React.useState(false);
    const inputEl = useRef<HTMLInputElement | null>(null);
    const selectEl = useRef<HTMLSelectElement | null>(null);

    const closeDropdown = () => {
        setDropdownVisible(false);
        inputEl.current && inputEl.current.blur();
    };

    const focusInput = () => {
        setDropdownVisible(true);
        if (inputEl.current && inputEl.current !== document.activeElement) inputEl.current.focus();
    };

    const handleSelect = (option: Option) => {
        setSelected(option);
        if (onChange) onChange(option);
        closeDropdown();
    };

    useEffect(() => {
        if (selectEl.current && selectEl.current.value) {
            setSelected(valueToSelectOptionTranslator(selectEl.current.value, options));
        }
    }, [options]);

    const ref = useClickOutside(() => setDropdownVisible(false));

    return (
        <div ref={ref} className={[styles.inputWrapper, `${disabled ? 'disabled' : ''}`].join(' ')}>
            <label className="default-input-label">{label}</label>

            <input
                className={styles.input}
                type="text"
                placeholder={initialSelected?.label || 'Select an option'}
                value={selected?.label || ''}
                disabled={disabled}
                readOnly
                onFocus={focusInput}
                ref={inputEl}
            />
            <button className={styles.clickableArrows} onClick={focusInput}>
                <ArrowsIcon />
            </button>

            <Dropdown
                options={options}
                selectedOption={selected}
                visible={dropdownVisible}
                handleSelectOption={handleSelect}
            />
        </div>
    );
};

export default SimpleSelect;
