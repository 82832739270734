import React from 'react';
import { Modal, Form, Divider, Dropdown } from 'semantic-ui-react';
import Button from 'components/Button/Button';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './TicketModal.module.css';

const WAIVE_OPTIONS = [
    { value: 'Typo', text: 'Driver or enforcer typo', key: 0 },
    { value: 'Wrongfully issued', text: 'Driver should have visitor validation', key: 0 },
    { value: 'Correctly issued', text: 'Correctly issued, feeling forgiving', key: 1 },
    { value: 'Other', text: 'Other', key: 2 },
];

interface TicketLookupModalProps {
    waiveTicket: () => void;
    isShowing: boolean;
    toggle: () => void;
}

function TicketLookupModal({ waiveTicket, isShowing, toggle }: TicketLookupModalProps) {
    return (
        <div>
            <Modal as={Form} open={isShowing} onClose={toggle} size="tiny" style={{ maxWidth: 400, borderRadius: 50 }}>
                <Modal.Header>
                    Waive Violation
                    <button className={styles.closeButton} onClick={toggle}>
                        <CloseIcon className={styles.closeIcon} />
                    </button>
                </Modal.Header>
                <Modal.Content>
                    <p className={styles.paragraph}>Waiving a violation means a driver will not have to pay the fee.</p>
                    <label>Reason</label>
                    <Form>
                        <Dropdown
                            style={{ width: '100%' }}
                            selection
                            options={WAIVE_OPTIONS}
                            placeholder="Select a reason"
                        />
                        <Divider hidden />
                        <Button size="sm" onClick={waiveTicket}>
                            Waive
                        </Button>
                    </Form>
                </Modal.Content>
            </Modal>
        </div>
    );
}

export default TicketLookupModal;
