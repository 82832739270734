import Button from 'components/Button/Button';
import React from 'react';
import styles from './ValetTableHeader.module.css';
import { ReactComponent as CheckOutIcon } from 'assets/icons/checkout.svg';
import { ReactComponent as CheckInIcon } from 'assets/icons/carKey.svg';
import SearchBox from '../SearchBox/SearchBox';
import SimpleSelect from 'components/AGForm/Inputs/SelectInput/SimpleSelect';
import { getValetSessionStatusOptionFromValue, valetSessionFilterOptions } from 'utils/valetSessions';

interface Filters {
    searchTerm: string;
    status: string;
}

interface URLUpdater {
    searchTerm: (v: string) => void;
    status: (v: string) => void;
    page: (v: string) => void;
}
interface ValetTableHeaderProps {
    total: number;
    active: number;
    onCheckOut: () => void;
    onCheckIn: () => void;
    filters: Filters;
    urlUpdater: URLUpdater;
}

function ValetTableHeader({ total, active, onCheckIn, onCheckOut, filters, urlUpdater }: ValetTableHeaderProps) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.statsWrapper}>
                <span className={styles.totalText}>Total:</span>
                <span className={styles.activeText}>
                    {active} active vehicles / {total} spaces
                </span>
            </div>
            <div className={styles.controlsWrapper}>
                <SearchBox
                    initialValue={filters.searchTerm}
                    onChange={(term) => {
                        urlUpdater.page('1');
                        urlUpdater.searchTerm(term);
                    }}
                />
                <SimpleSelect
                    selected={getValetSessionStatusOptionFromValue(filters.status)}
                    options={valetSessionFilterOptions}
                    onChange={(option) => {
                        urlUpdater.page('1');
                        urlUpdater.status(option.value);
                    }}
                />
                <Button type="button" color="secondary" size="sm" onClick={onCheckOut}>
                    <div className={styles.buttonContent}>
                        Check Out
                        <CheckOutIcon />
                    </div>
                </Button>
                <Button type="button" size="sm" onClick={onCheckIn}>
                    <div className={styles.buttonContent}>
                        Check In
                        <CheckInIcon />
                    </div>
                </Button>
            </div>
        </div>
    );
}

export default ValetTableHeader;
