import React from 'react';

type ArrowDirection = 'up' | 'down' | 'left' | 'right';

interface ArrowProps extends React.SVGProps<SVGSVGElement> {
    color?: string;
    direction?: ArrowDirection;
}

const getRotation = (direction: ArrowDirection): string => {
    switch (direction) {
        case 'up':
            return 'rotate(180deg)';
        case 'left':
            return 'rotate(90deg)';
        case 'right':
            return 'rotate(-90deg)';
        case 'down':
        default:
            return 'rotate(0deg)';
    }
};

export default function Arrow({ color, direction = 'down', ...restProps }: ArrowProps) {
    const rotation = getRotation(direction);

    return (
        <svg
            width="28"
            height="18"
            viewBox="0 0 28 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: rotation, display: 'block', margin: 'auto' }}
            {...restProps}
        >
            <path
                d="M3.51216 0.97132L13.7249 11.2002L24.0674 1.1025L27.2046 4.25151L13.6848 17.4801L0.335013 4.08004L3.51216 0.97132Z"
                fill={color || '#F7F8F9'}
            />
        </svg>
    );
}
