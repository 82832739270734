import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AppState } from 'reducers/reducers';
import { selectedSpotInfo } from 'selectors';
import { CarTrackingStatus } from 'types/CarTracking';
import usePaginatedFiltersWithSearch from 'hooks/usePaginatedFiltersWithSearch';
import { isValidDateForCarTrackingTable } from 'utils/helpers';
import { RentalType } from 'utils/constants';
import Loader from 'components/Loader/Loader';
import DriverLog from 'components/DriverLog/DriverLog';
import VisibilityCameras from 'components/VisibilityCameras/VisibilityCameras';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import HeroStatsVisibility from 'components/HeroStatsVisibility/HeroStatsVisibility';
import './VisibilityPageContainer.css';
import Toggle from 'components/AGForm/Inputs/Toggle/Toggle';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';

type VisibilityPageContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ENABLE_HERO_STATS_SPOT_PKS = [
    1030, // Washington Hill
];

const ENABLE_DRIVER_LOG_SPOT_PKS = [
    1030, // Washington Hill
];

function VisibilityPageContainer({ spot, loadingSpots, push, profile }: VisibilityPageContainerProps): JSX.Element {
    const [superuserView, setSuperUserView] = useState(false);
    const { filters, urlUpdater } = usePaginatedFiltersWithSearch<{
        date: string;
        status: CarTrackingStatus;
        rentalType: RentalType;
    }>({
        filterValidators: {
            date(current) {
                return current && isValidDateForCarTrackingTable(current) ? current : new Date().toLocaleDateString();
            },
            status(current) {
                return current && Object.values(CarTrackingStatus).includes(current as CarTrackingStatus)
                    ? current
                    : '';
            },
            rentalType(current) {
                if (!current) return '';
                const rentalTypes = current.split(',').map((rt) => {
                    if (Object.values(RentalType).includes(rt as RentalType)) return rt;
                    else return null;
                });
                return rentalTypes.filter((rt) => rt !== null).join(',');
            },
        },
        push,
    });

    if (!spot || loadingSpots) return <Loader />;

    const isHeroStatsEnabled = ENABLE_HERO_STATS_SPOT_PKS.includes(spot.pk);
    const isDriverLogEnabled = ENABLE_DRIVER_LOG_SPOT_PKS.includes(spot.pk);

    return (
        <div className="visibilityPage">
            <AuthorizedView allowed={[Roles.Superuser]}>
                <div className="superuserToggle">
                    <Toggle checked={superuserView} onChange={() => setSuperUserView(!superuserView)} />
                    <InfoTooltip text="Enable extended Superuser view for more information" />
                </div>
            </AuthorizedView>
            <VisibilityCameras spotUuid={spot.uuid} />
            {(isHeroStatsEnabled || profile.superuser) && (
                <HeroStatsVisibility spotUuid={spot.uuid} isHidden={!isHeroStatsEnabled && !superuserView} />
            )}
            {(isDriverLogEnabled || profile.superuser) && (
                <DriverLog
                    spot={spot}
                    filters={filters}
                    urlUpdater={urlUpdater}
                    timezone={spot.timezone}
                    superuserView={superuserView}
                    isEnabled={isDriverLogEnabled}
                />
            )}
        </div>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spot: selectedSpotInfo(state)!,
        loadingSpots: state.spots.orgSpotsLoading,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityPageContainer);
