import { ExperimentCalendarRecord } from 'types/Experiment';
import { RateCalendarRecord } from 'types/Rate';
import { getTimezoneOffset } from 'utils/helpers';

export const transformEvents = (ratesOrExperiments: Array<RateCalendarRecord | ExperimentCalendarRecord>) => {
    return ratesOrExperiments.map((rateOrExperiment: RateCalendarRecord | ExperimentCalendarRecord) => {
        const offset = getTimezoneOffset();
        return {
            ...rateOrExperiment,
            start: new Date(rateOrExperiment.start + offset),
            end: new Date(rateOrExperiment.end + offset),
            title: `${rateOrExperiment.name}`,
        };
    });
};
