import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { SpotInterface } from 'types';
import { useDriversFrequencyReport } from 'contexts/DriversFrequencyReportContext';
import WidgetBox from 'components/widgets/WidgetBox';
import PersonIcon from 'components/Icons/PersonIcon';
import styles from './DriversFrequencyWidget.module.css';
import BreakdownGraph from './BreakdownGraph';
import { WIDGET_TITLE, getStartDate } from './utils';
import LastUpdatedDate from 'components/widgets/LastUpdatedDate';
import AGMessage from 'components/AGMessage/AGMessage';
import FrequencyGraph from './FrequencyGraph';

type DriversFrequencyWidgetProps = {
    currentSpot: SpotInterface;
};

type DaysIntervalOptions = 180 | 90;

function DriversFrequencyWidget({ currentSpot }: DriversFrequencyWidgetProps): JSX.Element {
    const { getFrequencyReports, loading, errorMessage, reports } = useDriversFrequencyReport();

    const [daysInterval, setDaysInterval] = useState<DaysIntervalOptions>(90);
    const report = reports[daysInterval];

    useEffect(() => {
        if (currentSpot) {
            const startDate = getStartDate(daysInterval);
            getFrequencyReports(currentSpot.pk, `${daysInterval}`, startDate);
        }
    }, [currentSpot, daysInterval, getFrequencyReports]);

    if (errorMessage && !report) {
        return (
            <WidgetBox title={WIDGET_TITLE} icon={<PersonIcon />}>
                <AGMessage color="error">{errorMessage}</AGMessage>
            </WidgetBox>
        );
    }

    if (loading || !report) {
        return (
            <WidgetBox title={WIDGET_TITLE} icon={<PersonIcon />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    function handleChange(e: React.ChangeEvent) {
        const newValue = (e.target as HTMLSelectElement).value;
        setDaysInterval(parseInt(newValue) as DaysIntervalOptions);
    }

    return (
        <WidgetBox
            title={WIDGET_TITLE}
            icon={<PersonIcon />}
            dropdown={
                <select onChange={handleChange} value={daysInterval}>
                    <option value={90}>Last 90 Days</option>
                    <option value={180}>Last 180 Days</option>
                </select>
            }
        >
            <>
                {!!errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}
                <FrequencyGraph report={report} />
                <div className={styles.divider} />
                <h3 className={styles.subtitle}>Breakdown by day</h3>
                <BreakdownGraph report={report.breakdownReport} />
                <LastUpdatedDate lastUpdatedAt={report.lastUpdatedAt} />
            </>
        </WidgetBox>
    );
}

export default DriversFrequencyWidget;
