import { ReactComponent as OrangeCar } from 'assets/orangeCar.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import Button from 'components/Button/Button';
import { StepComponentProps } from 'components/ValetDashboard/Steps/Steps';
import React from 'react';
import { ValetSession } from 'types/ValetSession';
import styles from './SuccessCheckIn.module.css';

interface SuccessCheckInProps extends StepComponentProps {
    onCloseModal: () => void;
    valetSessionOnCheckIn: ValetSession;
}

function SuccessCheckIn(props: SuccessCheckInProps) {
    const car = props.valetSessionOnCheckIn.car_details;
    return (
        <div>
            <div className={styles.contentWrapper}>
                <OrangeCar />
                <h3 className={styles.message}>
                    {car?.plate} - {car?.state} <br /> checked in successfully!
                </h3>
            </div>

            <Button onClick={props.onCloseModal}>
                Done
                <CheckIcon />
            </Button>
        </div>
    );
}

export default SuccessCheckIn;
