import React from 'react';
import styles from './WidgetSubheader.module.css';
import InfoTooltip from '../Dashboard/SpotCard/InfoTooltip/InfoTooltip';

type WidgetSubheaderProps = {
    icon: JSX.Element;
    label: string;
    tooltip?: {
        title: string;
        message: string;
    };
};

function WidgetSubheader({ icon, label, tooltip }: WidgetSubheaderProps): JSX.Element {
    return (
        <div className={styles.wrapper}>
            {icon}
            <span className={styles.subheaderLabel}>{label}</span>
            {tooltip && <InfoTooltip title={tooltip.title} text={tooltip.message} />}
        </div>
    );
}

export default WidgetSubheader;
