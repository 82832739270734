import { ChartEvent, Chart as ChartJS } from 'chart.js';
import { usdFormatter } from 'components/Dashboard/SpotGraphs/utils';
import { PricingPayload } from 'types';
import { PricingStrategy } from 'utils/constants';

export const BASELINE_DATASET_INDEX = 0;
export const COLORS = ['#A1A6AF', '#D1581F', '#FA824C', '#2C508B', '#7FB1E3', '#3C91E6', '#B9D0E6', '#E9E9E9'];

export function dynamicPricingOpacityOnMouseOut(chartId: string) {
    return {
        id: `change-opacity-on-mouseout-${chartId}`,
        beforeEvent(
            chart: ChartJS<'line'>,
            arg: { cancelable: boolean; changed: boolean; event: ChartEvent; inChartArea: boolean; replay: boolean }
        ): void {
            if (!arg.replay) {
                if (arg.event.type === 'mouseout') {
                    // i starts with 1 to avoid baseLine dataset (index 0)
                    for (let i = 1; i < chart.data.datasets.length; i++) {
                        chart.data.datasets[i].borderColor = COLORS[i];
                    }
                    chart.update();
                }
            }
        },
    };
}

export function dynamicPricingBaselineLabel(chartId: string) {
    return {
        id: `baseline-label-${chartId}`,
        afterDraw(chart: ChartJS<'line'>) {
            const { ctx } = chart;
            if (ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }
            const { controller, data } = chart.getDatasetMeta(BASELINE_DATASET_INDEX);
            if (controller === null || data.length === 0) {
                return;
            }
            ctx.save();
            ctx.textAlign = 'left';
            const styles = controller.getStyle(0, false);
            if (styles) {
                ctx.fillStyle = styles['borderColor'] as string;
                ctx.font = '18px';
                const { x, y } = data[0];
                const firstBaselineRate = `${usdFormatter.format(
                    (chart.data.datasets[BASELINE_DATASET_INDEX].data[0] as number) / 100
                )}`;
                ctx.fillText(`${firstBaselineRate} Baseline Hourly Rate`, x + 2, y + 20);
                ctx.restore();
            }
        },
    };
}

export function dynamicPricingHighLowLabels(chartId: string) {
    return {
        id: `high-and-low-labels-${chartId}`,
        afterDraw(chart: ChartJS<'line'>) {
            const { ctx } = chart;
            if (ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }

            ctx.save();
            ctx.textAlign = 'left';
            ctx.font = '24px';
            ctx.fillStyle = '#717680';
            const right = chart.chartArea.right;
            const top = chart.chartArea.top;
            const bottom = chart.chartArea.bottom;

            ctx.fillText('High Demand', right + 30, top);
            ctx.fillText('Low Demand', right + 30, bottom);

            ctx.restore();
        },
    };
}

export function dynamicPricingVerticalHoverLine(chartId: string) {
    return {
        id: `grey-vertical-hover-line-${chartId}`,
        beforeDraw(chart: ChartJS<'line'>): void {
            if (chart.ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }
            const activeElements = chart.getActiveElements();
            if (activeElements.length === 0) {
                return;
            }
            const activePoint = activeElements[0];
            const ctx = chart.ctx;
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = '#333333';
            ctx.setLineDash([5, 2]);
            ctx.stroke();
            ctx.restore();
        },
    };
}

export type DailyPricingData = { price: number | null; tooltipLabel: string }[];

// converts something like this: '2023-07-11T13:00:00-04:00'
// into hour:minute:seconds e.g. '13:00:00'
function localTimeToTimeOnly(localTime: string): string {
    return localTime.split('T')[1].split('-')[0];
}

function generateTimeToPricingModelMap(pricing: PricingPayload): Record<string, PricingPayload[string]> {
    const pricingMap: Record<string, PricingPayload[string]> = {};
    for (const pricingModel of Object.values(pricing)) {
        const { local_time: localTime } = pricingModel;
        const time = localTimeToTimeOnly(localTime);
        pricingMap[time] = pricingModel;
    }
    return pricingMap;
}

export function calculateDynamicPricingData(
    priceInfo: PricingPayload,
    xAxisTimes: string[]
): { pricingData: DailyPricingData; maxPrice: number } {
    const pricingData: DailyPricingData = [];
    let maxPrice = 0;

    const pricingMap = generateTimeToPricingModelMap(priceInfo);
    for (const xAxisTime of xAxisTimes) {
        if (xAxisTime in pricingMap) {
            const pricingModel = pricingMap[xAxisTime];
            const formattedPrice = pricingModel.price_formatted.replace('.00', '');
            switch (pricingModel.strategy) {
                case PricingStrategy.EVENT: {
                    // In order to keep the line smooth, we create auxiliary data points with previous data point or baseline price (if the chart is starting with an event)

                    // Collecting previous data points.
                    const previousDataPoint = pricingData[pricingData.length - 1] || null;

                    let price = pricingModel.base_price;
                    if (previousDataPoint?.price) {
                        price = previousDataPoint.price;
                    }
                    const tooltipLabel = `$${formattedPrice} flat`;

                    pricingData.push({ price, tooltipLabel });
                    break;
                }
                case PricingStrategy.STANDARD: {
                    const { price } = pricingModel;
                    pricingData.push({
                        price,
                        tooltipLabel: `$${formattedPrice}/hr - 1x`,
                    });
                    if (price > maxPrice) maxPrice = price;
                    break;
                }
                case PricingStrategy.RATE: {
                    const { price } = pricingModel;
                    pricingData.push({
                        price,
                        tooltipLabel: `$${formattedPrice}/hr - ${pricingModel.multiplier}x`,
                    });
                    if (price > maxPrice) maxPrice = price;
                    break;
                }
            }
        } else {
            pricingData.push({ price: null, tooltipLabel: '' });
        }
    }
    return { pricingData, maxPrice };
}

export function calculateDynamicBaseLineData(priceInfo: PricingPayload, xAxisTimes: string[]): (number | null)[] {
    const basePrices: (number | null)[] = [];
    const pricingMap = generateTimeToPricingModelMap(priceInfo);
    for (const xAxisTime of xAxisTimes) {
        if (xAxisTime in pricingMap) {
            basePrices.push(pricingMap[xAxisTime].base_price);
        } else {
            basePrices.push(null);
        }
    }
    return basePrices;
}

export function uniqueDailyPriceTimes(prices: PricingPayload[]): string[] {
    const times = new Set<string>();
    for (const pricingPayload of prices) {
        for (const { local_time: localTime } of Object.values(pricingPayload)) {
            const time = localTimeToTimeOnly(localTime);
            times.add(time);
        }
    }
    return Array.from(times).sort();
}

const monthsAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function calculateAllDaysLabels(multipleDaysInfo: PricingPayload[]): string[] {
    const today = new Date();
    const labels: string[] = multipleDaysInfo.map((oneDay) => {
        const firstDataPointKey = Object.keys(oneDay)[0];
        const localTime = oneDay[firstDataPointKey].local_time;
        const date = new Date(localTime);
        const isToday = date.toDateString() === today.toDateString();
        const label = isToday ? 'Today' : `${monthsAbbr[date.getMonth()]} ${date.getDate()}`;
        return label;
    });

    return labels;
}

export function calculateGraphData(rates: PricingPayload[]): {
    allDaysLabels: string[];
    xAxisLabels: string[];
    multipleDaysData: DailyPricingData[];
    basePrices: (null | number)[];
    maxPrice: number;
} {
    const oldestDay = rates[0];
    const oldestLocalTime = Object.values(oldestDay)[0].local_time;

    const times = uniqueDailyPriceTimes(rates);

    let maxPriceForAllDays = 0;
    const xAxisLabels = times.map((t) => oldestLocalTime.replace(/T(.*?)(?=-)/gm, 'T' + t));
    const multipleDaysData: DailyPricingData[] = rates.map((oneDayPricingPayload) => {
        const { pricingData, maxPrice } = calculateDynamicPricingData(oneDayPricingPayload, times);
        if (maxPrice > maxPriceForAllDays) maxPriceForAllDays = maxPrice;
        return pricingData;
    });
    const basePrices = calculateDynamicBaseLineData(oldestDay, times);
    const allDaysLabels = calculateAllDaysLabels(rates);

    return {
        xAxisLabels,
        multipleDaysData,
        basePrices,
        allDaysLabels,
        maxPrice: maxPriceForAllDays,
    };
}
