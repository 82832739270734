import { ReactComponent as ArrowsIcon } from 'assets/icons/arrows.svg';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { Errors } from 'components/ValetDashboard/utils/errors';
import { useClickOutside } from 'hooks/useClickOutside';
import useErrorMessage from 'hooks/useErrorMessages';
import React, { useEffect } from 'react';
import { GroupedOption, Option, Options } from 'types/SelectInputs';
import Dropdown from './Dropdown';
import styles from './SearchSelect.module.css';
import { isGroupedOptions } from './utils';

interface SearchSelectInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    options: Options;
    errors?: Errors;
    icon?: React.ReactNode;
    selected?: Option;
    onSelected: (value: string) => void;
}

function SearchSelectInput({
    label,
    errors,
    options,
    selected,
    onSelected,
    ...props
}: SearchSelectInputProps): JSX.Element {
    const emptyOption: Option = { value: '', label: '' };
    const [search, setSearch] = React.useState('');
    const [selectedOption, setSelected] = React.useState<Option | undefined>(selected || emptyOption);
    const [results, setResults] = React.useState<Options>(options);
    const [dropdownVisible, setDropdownVisible] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const errorMessage = useErrorMessage(errors);

    function filterResults(options: Options, query: string): Options {
        if (isGroupedOptions(options[0])) {
            return (options as GroupedOption[])
                .map((optionOrGroup) => {
                    const groupe = optionOrGroup as GroupedOption;
                    const filteredOptions = groupe.options.filter((option) =>
                        option.label.toLowerCase().includes(query.toLowerCase())
                    );
                    return filteredOptions.length > 0 ? { ...groupe, options: filteredOptions } : null;
                })
                .filter((optionOrGroup) => optionOrGroup !== null) as Options;
        }

        return (options as Option[]).filter((option) => {
            return option.label.toLowerCase().includes(query.toLowerCase());
        });
    }

    function handleSelectOption(option: Option | undefined) {
        setSelected(option);
        setSearch(option?.label || '');
        onSelected(option?.value || '');
        setDropdownVisible(false);
    }

    useEffect(() => {
        const res = filterResults(options, search);
        setResults(res);
    }, [search, options]);

    useEffect(() => {
        if (dropdownVisible) {
            setSearch('');
        } else {
            setSearch(selectedOption?.label || '');
        }
    }, [dropdownVisible, selectedOption]);

    const ref = useClickOutside(() => setDropdownVisible(false));

    return (
        <div className={['select-wrapper', errorMessage && 'error', props.disabled && 'disabled'].join(' ')}>
            {label !== undefined && (
                <div className="label-wrapper">
                    {props.icon && <div className="icon-wrapper">{props.icon}</div>}
                    <label className="select-label">{label}</label>
                </div>
            )}

            <div ref={ref} className={styles.searchSelectWrapper}>
                <div>
                    <input
                        value={search}
                        type="text"
                        onChange={(event) => setSearch(event.target.value)}
                        onFocus={() => setDropdownVisible(true)}
                        ref={inputRef}
                        {...props}
                    />
                    <button className={styles.arrowsButton} type="button" onClick={() => inputRef.current?.focus()}>
                        <ArrowsIcon />
                    </button>
                </div>

                {dropdownVisible && (
                    <Dropdown
                        options={results}
                        visible={dropdownVisible}
                        selectedOption={selectedOption}
                        handleSelectOption={handleSelectOption}
                    />
                )}

                <input type="hidden" name={label} value={selectedOption?.value} />
            </div>

            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
}

export default SearchSelectInput;
