import React from 'react';

export type Action = { type: string; payload?: object };

type Middleware<S, A extends Action> = (action: A, state: S, dispatch: React.Dispatch<A>) => void;

export default function useReducerWithMiddleware<S, A extends Action>(
    reducer: React.Reducer<S, A>,
    initialState: S,
    precedingMiddleware: Middleware<S, A>[] = [],
    succeedingMiddleware: Middleware<S, A>[] = []
): [S, React.Dispatch<A>] {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const actionRef = React.useRef<A | null>(null);

    const dispatchWithMiddleware = (action: A) => {
        precedingMiddleware.forEach((pm) => pm(action, state, dispatch));
        actionRef.current = action;
        dispatch(action);
    };

    React.useEffect(() => {
        if (actionRef.current === null) return;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        succeedingMiddleware.forEach((sm) => sm(actionRef.current!, state, dispatch));
        actionRef.current = null;
    }, [succeedingMiddleware, state]);

    return [state, dispatchWithMiddleware];
}
