import {
    SPOT_LIST_REQUESTED,
    SPOT_LIST_SUCCESS,
    SPOT_LIST_FAILED,
    ORGANIZATION_SPOTS_REQUESTED,
    ORGANIZATION_SPOTS_SUCCESS,
    ORGANIZATION_SPOTS_FAILED,
    ADD_SPOT_REQUESTED,
    ADD_SPOT_SUCCESS,
    ADD_SPOT_FAILED,
    SET_ORGANIZATION,
    SPOT_PRICING_SUCCESS,
    SPOT_PRICING_REQUESTED,
    SPOT_PRICING_FAILED,
    SPOT_MULTIDAY_PRICING_REQUESTED,
    SPOT_MULTIDAY_PRICING_SUCCESS,
    SPOT_MULTIDAY_PRICING_FAILED,
    ORGANIZATION_REQUESTED,
} from '../actions/constants';
import { PricingPayload, SpotInterface, SpotMetadata } from 'types';

export type SpotsState = {
    orgSpotsLoading: boolean;
    orgSpotsErrorMessage: string;
    orgSpots: {
        [index: string]: SpotInterface;
    } | null;
    addSpotSuccessMessage: unknown | null;
    addSpotErrorMessage: unknown | null;
    addSpotLoading: boolean;
    spot: SpotInterface | null;
    spotPricing: PricingPayload | null;
    spotPricingLoading: boolean;
    spotPricingErrorMessage: string;
    spotMultidayPricing: PricingPayload[] | null;
    spotMultidayPricingLoading: boolean;
    spotMultidayPricingErrorMessage: string;
    spotList: SpotMetadata[] | null;
    spotListLoading: boolean;
    spotListErrorMessage: string;
};

export const initialSpotState: SpotsState = {
    orgSpotsLoading: false,
    orgSpotsErrorMessage: '',
    orgSpots: null,
    addSpotSuccessMessage: null,
    addSpotErrorMessage: null,
    spot: null,
    spotPricingLoading: false,
    spotPricingErrorMessage: '',
    spotPricing: null,
    spotMultidayPricing: null,
    spotMultidayPricingLoading: false,
    spotMultidayPricingErrorMessage: '',
    spotList: null,
    spotListErrorMessage: '',
    spotListLoading: false,
    addSpotLoading: false,
};

export default function spotsReducer(state: SpotsState = initialSpotState, action: any): SpotsState {
    const newState: SpotsState = { ...state };
    switch (action.type) {
        case SPOT_LIST_REQUESTED:
            newState.spotListLoading = true;
            return newState;
        case SPOT_LIST_SUCCESS:
            newState.spotListLoading = false;
            newState.spotList = action.payload.spotList;
            return newState;
        case SPOT_LIST_FAILED:
            newState.spotListErrorMessage = action.message;
            newState.spotList = null;
            newState.spotListLoading = false;
            return newState;
        case ORGANIZATION_REQUESTED:
            newState.orgSpots = null;
            newState.orgSpotsErrorMessage = '';
            return newState;
        case ORGANIZATION_SPOTS_REQUESTED:
            newState.orgSpotsLoading = true;
            return newState;
        case ORGANIZATION_SPOTS_SUCCESS:
            newState.orgSpotsLoading = false;
            newState.orgSpots = action.payload.spots;
            return newState;
        case ORGANIZATION_SPOTS_FAILED:
            newState.orgSpotsErrorMessage = action.message;
            newState.orgSpots = null;
            newState.orgSpotsLoading = false;
            return newState;
        case SPOT_PRICING_REQUESTED:
            newState.spotPricing = null;
            newState.spotPricingLoading = true;
            newState.spotPricingErrorMessage = '';
            return newState;
        case SPOT_PRICING_SUCCESS:
            newState.spotPricing = action.payload.pricing;
            newState.spotPricingLoading = false;
            return newState;
        case SPOT_PRICING_FAILED:
            newState.spotPricingErrorMessage = action.message;
            newState.spotPricingLoading = false;
            return newState;
        case SPOT_MULTIDAY_PRICING_REQUESTED:
            newState.spotMultidayPricing = null;
            newState.spotMultidayPricingLoading = true;
            newState.spotMultidayPricingErrorMessage = '';
            return newState;
        case SPOT_MULTIDAY_PRICING_SUCCESS:
            newState.spotMultidayPricing = action.payload.pricing;
            newState.spotMultidayPricingLoading = false;
            return newState;
        case SPOT_MULTIDAY_PRICING_FAILED:
            newState.spotMultidayPricingErrorMessage = action.message;
            newState.spotMultidayPricingLoading = false;
            return newState;
        case ADD_SPOT_REQUESTED:
            newState.addSpotLoading = true;
            newState.addSpotSuccessMessage = '';
            newState.addSpotErrorMessage = '';
            return newState;
        case ADD_SPOT_SUCCESS:
            newState.addSpotLoading = false;
            newState.addSpotSuccessMessage = 'Spot created!';
            return newState;
        case ADD_SPOT_FAILED:
            newState.addSpotLoading = false;
            newState.addSpotErrorMessage = action.errorMessage;
            return newState;
        case SET_ORGANIZATION:
            return { ...initialSpotState, spotList: state.spotList };
        default:
            return state || initialSpotState;
    }
}
