import React, { SyntheticEvent } from 'react';
import { Table, Label, Dropdown, Loader, Header } from 'semantic-ui-react';
import { AggregatorReportInterface } from 'types';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';
import BarGraph from '../SpotGraphs/BarGraph';

type RentalsModuleProps = {
    loading: boolean;
    reports: AggregatorReportInterface;
    fields: { monthYear: MonthYear['value'] };
    handleDropdown: (event: SyntheticEvent<HTMLElement>, data: any) => void;
};

const RentalsModule = (props: RentalsModuleProps) => {
    const monthYearUtil = new MonthYearUtil();
    const { reports, fields, handleDropdown, loading } = props;
    const total = reports && reports.aggregator_rentals.total;
    const getColor = (rentals: number) => {
        if (rentals < 100) {
            return 'pink';
        } else if (rentals < 200) {
            return 'blue';
        } else if (rentals < 500) {
            return 'green';
        }
    };

    return (
        <>
            <Header as="h4" content="Select month" />
            <Dropdown
                name="monthYear"
                placeholder="Select month and year"
                selection
                onChange={handleDropdown}
                options={monthYearUtil.monthYearsWithOneMonthLookahead}
                value={fields.monthYear}
            />
            {reports.aggregator_rentals && reports.aggregator_rentals.rentals && (
                <div style={{ width: '100%', height: 200, marginTop: 10 }}>
                    <BarGraph
                        title="Total active rentals by day"
                        dataLabel="Total active rentals"
                        description={'This is total number of active rentals by day across all aggregators.'}
                        xData={reports.aggregator_rentals.rentals.map((report: any) => report.short_date)}
                        yData={reports.aggregator_rentals.rentals.map((report: any) => report.total_rentals)}
                    />
                </div>
            )}

            <div style={{ width: '100%', height: 60 }}></div>
            {loading ? (
                <Loader active inline="centered" />
            ) : (
                <Table basic="very" selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>SpotHero</Table.HeaderCell>
                            <Table.HeaderCell>Cheap Airport Parking</Table.HeaderCell>
                            <Table.HeaderCell>Airport Parking Reservations</Table.HeaderCell>
                            <Table.HeaderCell>ParkWhiz</Table.HeaderCell>
                            <Table.HeaderCell>Parkway</Table.HeaderCell>
                            <Table.HeaderCell>Noson</Table.HeaderCell>
                            <Table.HeaderCell>AirGarage</Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">Active Rentals</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {reports &&
                            reports.aggregator_rentals.rentals.map((report: any) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell>{report.date}</Table.Cell>
                                        <Table.Cell>{report.spothero}</Table.Cell>
                                        <Table.Cell>{report.cheap_airport}</Table.Cell>
                                        <Table.Cell>{report.airport_parking}</Table.Cell>
                                        <Table.Cell>{report.parkwhiz}</Table.Cell>
                                        <Table.Cell>{report.parkway}</Table.Cell>
                                        <Table.Cell>{report.noson}</Table.Cell>
                                        <Table.Cell>{report.airgarage}</Table.Cell>
                                        <Table.Cell textAlign="right">
                                            <Label
                                                color={getColor(report.total_rentals)}
                                                content={report.total_rentals}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        {total && (
                            <Table.Row>
                                <Table.Cell>{total.date}</Table.Cell>
                                <Table.Cell>{total.spothero}</Table.Cell>
                                <Table.Cell>{total.cheap_airport}</Table.Cell>
                                <Table.Cell>{total.airport_parking}</Table.Cell>
                                <Table.Cell>{total.parkwhiz}</Table.Cell>
                                <Table.Cell>{total.parkway}</Table.Cell>
                                <Table.Cell>{total.noson}</Table.Cell>
                                <Table.Cell>{total.airgarage}</Table.Cell>
                                <Table.Cell textAlign="right">{total.total_rentals}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            )}
        </>
    );
};

export default RentalsModule;
