import { SlotInterface, SlotsOverview } from 'types';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { PagedResponse } from 'types/Pagination';
import { RentalType, SlotState } from 'utils/constants';
import cache from './cache';

export const SLOT_PAGE_SIZE = 20;

type SlotClientParams = HTTPClientParameters;

export type PaginatedRentals = PagedResponse<SlotInterface>;

type UpdateSlotWithCarsParams = {
    cars: number[];
};

export type AddSlotParams = {
    first_name: string;
    last_name: string;
    cars: { plate: string; state: string; country: string }[];
    email: string;
};

type AddSlotPayload = AddSlotParams & { spot: string };

type GetSlotPayload = {
    rentalType: RentalType;
    spotId: number;
    state?: SlotState;
    page?: number;
    searchTerm?: string;
};

class SlotClient extends HTTPClient {
    constructor(params: SlotClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    updateCarsOfRental(uuid: string, params: UpdateSlotWithCarsParams): Promise<SlotInterface> {
        return this.patch(`slots/${uuid}/`, params);
    }

    deleteSlot(uuid: string): Promise<SlotInterface | undefined> {
        return this.delete(`slots/${uuid}/`);
    }

    addSlot(payload: AddSlotPayload): Promise<SlotInterface> {
        return this.post(`managed_monthly_slots/`, payload);
    }

    _getSlots({
        rentalType,
        spotId,
        page = 1,
        state = SlotState.Active,
        searchTerm,
    }: GetSlotPayload): Promise<PaginatedRentals> {
        const params = new URLSearchParams();
        params.set('ended', 'false');
        if (state === SlotState.Active) params.set('ordering', '-start_date');
        params.set('page_size', SLOT_PAGE_SIZE.toString());
        params.set('rental_type__in', rentalType);
        params.set('spot', spotId.toString());
        params.set('page', page.toString());
        params.set('state', state);
        if (searchTerm) {
            params.set('search', searchTerm);
        }
        return this.get(`slots?${params.toString()}`);
    }

    @cache(1 * 60000) // 1min of cache
    getSlots(payload: GetSlotPayload): Promise<PaginatedRentals> {
        return this._getSlots(payload);
    }

    @cache(0)
    force__getSlots(payload: GetSlotPayload): Promise<PaginatedRentals> {
        return this._getSlots(payload);
    }

    @cache(5 * 60000)
    getSlotOverview(spotId: string, startDateFrom: number, startDateTo: number): Promise<SlotsOverview> {
        return this.get(`slots/overview?spot=${spotId}&start_date_from=${startDateFrom}&start_date_to=${startDateTo}`);
    }
}

export default SlotClient;
