import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'reducers/reducers';

export const Roles = {
    Admin: 'Admin',
    Employee: 'Employee',
    Enforcer: 'Enforcer',
    Superuser: 'Superuser',
};

type AllowedRole = (typeof Roles)[keyof typeof Roles];
type AllowedFunction = (...args: unknown[]) => AllowedRole | AllowedRole[];
type OwnProps = { allowed: AllowedRole | AllowedRole[] | AllowedFunction; children: React.ReactNode };
type ConditionalViewProps = ReturnType<typeof mapStateToProps> & OwnProps;

function ConditionalView({ allowed, children, profile }: ConditionalViewProps): JSX.Element {
    if (profile === null) return <></>;
    let isAuthorized = false;
    if (typeof allowed === 'function') {
        allowed = allowed();
    } else {
        allowed = Array.isArray(allowed) ? allowed : [allowed];
    }
    isAuthorized = allowed.includes(profile.affiliation_role) || profile.superuser;
    if (isAuthorized) {
        return <>{children}</>;
    }
    return <></>;
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

export const AuthorizedView = connect(mapStateToProps, {})(ConditionalView);
