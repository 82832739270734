import {
    SET_AUTH_TOKEN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_REQUESTED,
    PROFILE_REQUESTED,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    SET_ORGANIZATION,
    LOGOUT,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_FAILED,
    SIGN_UP_FAILED,
    SIGN_UP_SUCCESS,
} from 'actions/constants';
import Cookies from 'js-cookie';
import { ProfileInterface } from 'types';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

type AuthState = {
    token: string;
    profile: null | ProfileInterface;
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    verificationCodeSent: boolean;
    username: string;
};

export const initialAuthState: AuthState = {
    token: '',
    profile: null,
    loading: false,
    errorMessage: '',
    successMessage: '',
    verificationCodeSent: false,
    username: '',
};

export default function authReducer(state: AuthState = initialAuthState, action: any): AuthState {
    const newState = cloneObject(state);
    switch (action.type) {
        case LOGIN_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            newState.successMessage = '';
            return newState;
        case LOGIN_SUCCESS:
            newState.loading = false;
            newState.token = action.payload.token;
            newState.successMessage = 'Logging in...';
            return newState;
        case LOGIN_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            newState.successMessage = '';
            return newState;
        case SEND_VERIFICATION_CODE_SUCCESS: {
            newState.verificationCodeSent = true;
            const username = action.payload.username;
            const sentVerb = action.payload.username.includes('@') ? 'emailed' : 'texted';
            newState.successMessage = `Enter the verification code we ${
                state.successMessage ? 're-' : ''
            } ${sentVerb} to ${username}`;
            newState.errorMessage = '';
            return newState;
        }
        case SEND_VERIFICATION_CODE_FAILED:
            newState.errorMessage = action.message;
            return newState;
        case SET_AUTH_TOKEN:
            newState.token = state.token || Cookies.get('airgarage_auth');
            return newState;
        case PROFILE_REQUESTED:
            newState.loading = true;
            return newState;
        case PROFILE_SUCCESS:
            newState.loading = false;
            newState.profile = action.payload.profile;
            return newState;
        case PROFILE_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case SET_ORGANIZATION:
            newState.profile.affiliation = action.payload.organization;
            return newState;
        case SIGN_UP_SUCCESS:
            newState.errorMessage = '';
            return newState;
        case SIGN_UP_FAILED:
            newState.errorMessage = action.message;
            return newState;
        case LOGOUT:
            Cookies.remove('airgarage_auth', { path: '/', expires: 2000 });
            Cookies.remove('airgarage_auth', { domain: 'airgara.ge', path: '/', expires: 2000 });
            Cookies.remove('airgarage_auth', { domain: 'airgarage.com', path: '/', expires: 2000 });
            return { ...initialAuthState };
        default:
            return state || initialAuthState;
    }
}
