import { REVIEW_REPORTS_REQUESTED, REVIEW_REPORTS_FAILED, REVIEW_REPORTS_SUCCESS } from './constants';
import { ReviewReportInterface } from 'types';
import { Action } from '../useReducerWithMiddleware';

export type ReviewReportState = {
    loading: boolean;
    errorMessage: string;
    reviewReport: ReviewReportInterface | null;
};

export const initialReviewReportState: ReviewReportState = {
    loading: false,
    errorMessage: '',
    reviewReport: null,
};

export default function reviewReportReducer(
    state: ReviewReportState = initialReviewReportState,
    action: Action
): ReviewReportState {
    switch (action.type) {
        case REVIEW_REPORTS_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case REVIEW_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                reviewReport: (action.payload as { report: ReviewReportInterface }).report,
            };
        case REVIEW_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialReviewReportState;
    }
}
