import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'reducers/reducers';
import { push } from 'connected-react-router';
import IncomeStatement from 'components/IncomeStatement/IncomeStatement';
import { getSpotsAction } from 'actions/actions';
import { Loader } from 'semantic-ui-react';
import { IncomeStatementProvider } from 'contexts/IncomeStatementContext';

type IncomeStatementContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const IncomeStatementContainer = ({
    push,
    organization,
    orgSpots,
    getSpots,
    loadingSpots,
}: IncomeStatementContainerProps) => {
    useEffect(() => {
        getSpots();
    }, [getSpots, organization.pk]);

    if (!orgSpots || loadingSpots) return <Loader active inline="centered" />;
    return (
        <IncomeStatementProvider>
            <IncomeStatement connectedPush={push} orgSpots={orgSpots} organization={organization} />
        </IncomeStatementProvider>
    );
};

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
        loadingSpots: state.spots.orgSpotsLoading,
        orgSpots: state.spots.orgSpots,
    };
}

const mapDispatchToProps = {
    push: push,
    getSpots: getSpotsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomeStatementContainer);
