import React from 'react';
import styles from './RowSkeleton.module.css';

function RowSkeleton() {
    return (
        <tr className={styles.rowLoader}>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
            <td>
                <div className={styles.skeleton} />
            </td>
        </tr>
    );
}

export default RowSkeleton;
