import React from 'react';

import WidgetTitle from 'components/widgets/WidgetTitle';
import TimeLineImageCarousel from '../TimelineImageCarousel';
import { ReactComponent as ToolIcon } from 'assets/icons/maintenance.svg';
import { RegularMaintenanceEntry } from 'types/LotTimeline';
import styles from '../timeline.module.css';
import { getFormattedDateAndTime } from 'utils/helpers';

type RegularMaintenanceProps = {
    entry: RegularMaintenanceEntry;
};

function RegularMaintenance({ entry }: RegularMaintenanceProps) {
    const formattedDateAndTime = getFormattedDateAndTime(new Date(entry.timestamp));
    return (
        <div className={styles.maintenanceEvent}>
            <WidgetTitle title={entry.details.title} icon={<ToolIcon />} lastUpdatedLabel={formattedDateAndTime} />
            <p>{entry.details.description}</p>
            <TimeLineImageCarousel images={entry.details.images} />
        </div>
    );
}

export default RegularMaintenance;
