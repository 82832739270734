import {
    REFUNDS_REQUESTED,
    REFUNDS_SUCCESS,
    REFUNDS_FAILED,
    ISSUE_REFUND_REQUESTED,
    ISSUE_REFUND_SUCCESS,
    ISSUE_REFUND_FAILED,
    IGNORE_REFUND_FAILED,
    IGNORE_REFUND_REQUESTED,
    IGNORE_REFUND_SUCCESS,
} from '../actions/constants';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

export const initialRefundState = {
    refunds: null,
    errorMessage: '',
    successMessage: '',
    loading: false,
};

export default function teamReducer(state = initialRefundState, action: any) {
    const newState = cloneObject(state);
    switch (action.type) {
        case REFUNDS_REQUESTED:
            newState.loading = true;
            return newState;
        case REFUNDS_SUCCESS:
            newState.loading = false;
            newState.refunds = action.payload.refunds;
            return newState;
        case REFUNDS_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case ISSUE_REFUND_REQUESTED:
            newState.loading = true;
            return newState;
        case ISSUE_REFUND_SUCCESS:
            newState.loading = false;
            newState.refunds = action.payload.refunds;
            newState.successMessage = 'Issued refund and sent confirmation message.';
            return newState;
        case ISSUE_REFUND_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case IGNORE_REFUND_REQUESTED:
            newState.loading = true;
            return newState;
        case IGNORE_REFUND_SUCCESS:
            newState.loading = false;
            newState.refunds = action.payload.refunds;
            newState.successMessage = 'Ignored refund.';
            return newState;
        case IGNORE_REFUND_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialRefundState;
    }
}
