import { Car } from './Car';

export enum ValetSessionStatus {
    Active = 'active',
    Ended = 'ended',
    Pending = 'pending',
}

export interface ValetSession {
    uuid: string;
    check_in_time: string;
    check_out_time: null | string;
    car_details: Car | null;
    phone: string;
    status: ValetSessionStatus;
    notes: null | string;
    outstanding_balance: number;
    spot: number;
    make?: string;
    model?: string;
    color?: string;
}
