import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sliderStyle.css';
import styles from './timeline.module.css';
import { isImageLandscape } from 'utils/files';
import ImageWithFallback from 'components/ImageWithFallback/ImageWithFallback';
import { BucketNames, getTimelineThumbnailFromBuckets } from './entries/utils';

const MAX_IMAGES_PER_SLIDE = 2;

type TimeLineImageCarouselProps = {
    images: string[];
};

type CarouselState = {
    slidesToScroll: number;
    slidesToShow: number;
    showArrowsAndDots: boolean;
    isLandscape: boolean;
};

const TimeLineImageCarousel: React.FC<TimeLineImageCarouselProps> = ({ images }) => {
    const [carouselState, setCarouselState] = useState<CarouselState>({
        isLandscape: false,
        slidesToScroll: MAX_IMAGES_PER_SLIDE,
        slidesToShow: MAX_IMAGES_PER_SLIDE,
        showArrowsAndDots: images.length > MAX_IMAGES_PER_SLIDE,
    });

    const checkFirstImageOrientation = useCallback(async () => {
        if (images.length) {
            try {
                const isLandscape = await isImageLandscape(
                    getTimelineThumbnailFromBuckets(images[0], [
                        BucketNames.MissionSubmission,
                        BucketNames.OperationEvent,
                    ])
                );
                const newMaxImagesPerSlide = isLandscape
                    ? Math.min(images.length, MAX_IMAGES_PER_SLIDE)
                    : MAX_IMAGES_PER_SLIDE;
                setCarouselState({
                    slidesToScroll: Math.min(images.length, newMaxImagesPerSlide),
                    slidesToShow: newMaxImagesPerSlide,
                    showArrowsAndDots: images.length > newMaxImagesPerSlide,
                    isLandscape,
                });
            } catch (error) {
                console.error('Error loading image:', error);
            }
        }
    }, [images]);

    useEffect(() => {
        checkFirstImageOrientation();
    }, [images, checkFirstImageOrientation]);

    const settings: Settings = useMemo(
        () => ({
            infinite: false,
            speed: 500,
            lazyLoad: 'progressive',
            slidesToScroll: carouselState.slidesToScroll,
            slidesToShow: carouselState.slidesToShow,
            arrows: carouselState.showArrowsAndDots,
            dots: carouselState.showArrowsAndDots,
            className: styles.snapshotWrapper,
        }),
        [carouselState]
    );

    return (
        <Slider {...settings}>
            {images.map((imageUrl, i) => (
                <div
                    className={[
                        styles.imageWrapper,
                        carouselState.isLandscape ? styles.landscapeRatio : styles.portraitRatio,
                    ].join(' ')}
                    key={`snapshot-${i}-${Date.now()}`}
                >
                    <ImageWithFallback
                        src={getTimelineThumbnailFromBuckets(imageUrl, [
                            BucketNames.MissionSubmission,
                            BucketNames.OperationEvent,
                        ])}
                        fallbackSrc={imageUrl}
                        alt={`snapshot-${i}`}
                        className={styles.snapshotImg}
                        width="100%"
                        height="100%"
                        loading="lazy"
                    />
                </div>
            ))}
        </Slider>
    );
};

export default TimeLineImageCarousel;
