import Cookies from 'js-cookie';

export type HTTPClientParameters = { baseUrl: URL };

export class HTTPError<CauseType = unknown> extends Error {
    public static isHTTPError(e: unknown): e is HTTPError {
        return e instanceof Error && (e as HTTPError).isHTTPError;
    }

    isHTTPError = true;
    cause: CauseType;
    status: number;

    constructor(message: string, status: number, cause: CauseType) {
        super(message);
        this.status = status;
        this.cause = cause;
    }
}

// HTTPClient is an abstraction over browser fetch.
// It handles errors and provides all REST methods.
// NOTE: use only for JSON data transfer.
class HTTPClient {
    public headers: Headers;

    constructor({ baseUrl }: HTTPClientParameters) {
        this.origin = baseUrl.origin;
        this.pathname = baseUrl.pathname;
        this.headers = new Headers();
    }

    async get<R>(path: string): Promise<R> {
        const response = await this.getRaw(path);
        const body = await response.json();
        if (!response.ok) {
            console.log('HTTPClient.get error:', path, response.status, response.statusText);
            throw new HTTPError(response.statusText, response.status, body);
        }
        return body as R;
    }

    async getRaw(path: string): Promise<Response> {
        const token = Cookies.get('airgarage_auth') || '';
        this.headers.set('Authorization', `Token ${token}`);
        const reqUrl = new URL(this.composePath(path), this.origin);
        return fetch(reqUrl.toString(), {
            method: 'GET',
            credentials: 'include',
            headers: this.headers,
        });
    }

    protected async post<P, R>(path: string, payload: P): Promise<R> {
        const token = Cookies.get('airgarage_auth') || '';
        this.headers.set('Authorization', `Token ${token}`);
        const reqUrl = new URL(this.composePath(path), this.origin);
        const response = await fetch(reqUrl.toString(), {
            method: 'POST',
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify(payload),
        });
        const body = await response.json();

        if (!response.ok) {
            console.log('HTTPClient.post error:', path, response.status, response.statusText);
            throw new HTTPError(response.statusText, response.status, body);
        }
        return body as R;
    }

    protected async put<P, R>(path: string, payload: P): Promise<R> {
        const token = Cookies.get('airgarage_auth') || '';
        this.headers.set('Authorization', `Token ${token}`);
        const reqUrl = new URL(this.composePath(path), this.origin);
        const response = await fetch(reqUrl.toString(), {
            method: 'PUT',
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify(payload),
        });
        const body = await response.json();
        if (!response.ok) {
            console.log('HTTPClient.put error:', path, response.status, response.statusText);
            throw new HTTPError(response.statusText, response.status, body);
        }
        return body as R;
    }

    protected async patch<P, R>(path: string, payload: P): Promise<R> {
        const token = Cookies.get('airgarage_auth') || '';
        this.headers.set('Authorization', `Token ${token}`);
        const reqUrl = new URL(this.composePath(path), this.origin);
        const response = await fetch(reqUrl.toString(), {
            method: 'PATCH',
            credentials: 'include',
            headers: this.headers,
            body: JSON.stringify(payload),
        });
        const body = await response.json();
        if (!response.ok) {
            console.log('HTTPClient.patch error:', path, response.status, response.statusText);
            throw new HTTPError(response.statusText, response.status, body);
        }
        return body as R;
    }

    protected async delete<R>(path: string): Promise<R | undefined> {
        const token = Cookies.get('airgarage_auth') || '';
        this.headers.set('Authorization', `Token ${token}`);
        const reqUrl = new URL(this.composePath(path), this.origin);
        const response = await fetch(reqUrl.toString(), {
            method: 'DELETE',
            credentials: 'include',
            headers: this.headers,
        });
        if (response.status === 204) return;
        const body = await response.json();
        if (!response.ok) {
            console.log('HTTPClient.delete error:', path, response.status, response.statusText);
            throw new HTTPError(response.statusText, response.status, body);
        }
        return body as R;
    }

    private origin: string;
    private pathname: string;

    private composePath(path: string): string {
        return this.pathname.endsWith('/') ? this.pathname + path : this.pathname + '/' + path;
    }
}

export default HTTPClient;
