import React from 'react';
import styles from './MetricCard.module.css';
import { Image, Label, Popup } from 'semantic-ui-react';

const MetricCard = (props: any) => {
    const { metric, header, oldMetric, image, format } = props;

    const growthCalc = (old: any, now: any) => {
        if (old !== 0) {
            return Math.round(((now - old) / old) * 100);
        } else return Math.round(((now - 1) / 1) * 100);
    };

    const formatMetric = (metric: any) => {
        if (format === 'currency') {
            return '$' + (metric / 100).toFixed(2);
        } else return metric;
    };

    return (
        <div className={styles.card}>
            <Image src={image} style={{ width: 50 }} />
            <h2>{formatMetric(metric)}</h2>
            <p>{header}</p>
            <Popup
                trigger={
                    <Label
                        content={growthCalc(oldMetric, metric) + '% from ' + formatMetric(oldMetric)}
                        color={growthCalc(oldMetric, metric) > 0 ? 'green' : 'pink'}
                    />
                }
                content={
                    'To account for daily seasonality, we compare metrics on this day to last week on this day. Ex: this Thursday vs last Thursday.'
                }
                on="click"
                inverted
            />
        </div>
    );
};

export default MetricCard;
