import React, { useState } from 'react';
import AGDropdown, { AGDropdownWrapperProps } from 'components/AGDropdown/AGDropdown';
import styles from '../AGSelect.module.css';

export type SelectOption<O = object> = {
    value: string;
    label: string;
} & O;

type AGSimpleSelectProps = AGDropdownWrapperProps & {
    selected: string;
    onSelect: (value: string) => void;
    options: SelectOption[];
    placeholder?: string;
    disabled?: boolean;
};

const AGSimpleSelect = ({ selected, onSelect, options, placeholder = '', ...restProps }: AGSimpleSelectProps) => {
    const [openDropdown, toggleOpenDropdown] = useState(false);

    function handleSelect(value: string) {
        onSelect(value);
        toggleOpenDropdown(false);
    }

    let labelToShow = placeholder;
    const selectedOption = options.find((o) => o.value === selected);
    if (selectedOption) {
        labelToShow = selectedOption.label;
    }

    return (
        <AGDropdown text={labelToShow} open={openDropdown} toggleOpen={toggleOpenDropdown} {...restProps}>
            <ul className={styles.selectorOptions}>
                {options.map((option) => {
                    return (
                        <li key={option.value} className={selected === option.value ? styles.selected : ''}>
                            <button onClick={() => handleSelect(option.value)} type="button">
                                {option.label}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </AGDropdown>
    );
};

export default AGSimpleSelect;
