import React from 'react';
import styles from './TenantsParking.module.css';
import { currency } from 'utils/currency';
import { TenantsParkingData } from 'types';
import { getDate, getStatusColor, parseInvoicesStatus, capitalizeStatus } from './utils';
import { InvoiceStatus } from 'utils/constants';
import AGTableWrapper from 'components/AGTable/AGTableWrapper';

type TenantsParkingTableProps = {
    data: TenantsParkingData[] | null;
};

type TenantsParkingRowProps = {
    rowData: TenantsParkingData | null;
};

const TableRow = ({ rowData }: TenantsParkingRowProps) => {
    if (!rowData) return <></>;
    const totalAmount = (rowData.price_per_slot / 100) * rowData.slot_count;
    const totalAmountContent = <td>{currency.format(totalAmount)}</td>;
    const contact = rowData.owner.email ? rowData.owner.email : '';
    const startDate = getDate(rowData.start_date);
    const endDate = rowData.end_date ? getDate(rowData.end_date) : 'until they cancel';
    const status: InvoiceStatus = rowData.invoice_status
        ? parseInvoicesStatus(rowData.invoice_status)
        : InvoiceStatus.NA;

    const statusColor = getStatusColor(status);
    const capitalizedStatus = status === InvoiceStatus.NA ? InvoiceStatus.NA : capitalizeStatus(status);

    return (
        <tr>
            <td>{rowData.description}</td>
            <td>
                {rowData.active_rentals}/{rowData.slot_count}
            </td>
            {totalAmountContent}
            <td>
                <span className={styles.dot} style={{ backgroundColor: statusColor }}></span>
                {capitalizedStatus}
            </td>
            <td>{contact}</td>
            <td>
                {startDate} - {endDate}
            </td>
        </tr>
    );
};

const Table = ({ data }: TenantsParkingTableProps) => {
    return (
        <AGTableWrapper>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Spaces</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Contact</th>
                    <th>Period</th>
                </tr>
            </thead>
            <tbody>
                {data && data.length > 0 && data.map((rowData) => <TableRow key={rowData.uuid} rowData={rowData} />)}
            </tbody>
        </AGTableWrapper>
    );
};

export default Table;
