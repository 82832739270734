import {
    canadaStates,
    CountryAbbreviation,
    CountryName,
    mexicoStates,
    StateAbbreviation,
    StateName,
    usaStates,
} from '../constants/states';

export function getStateNameFromAbbreviation(
    abbv: string,
    country: CountryAbbreviation | string
): StateName | undefined {
    switch (country) {
        case CountryAbbreviation.UnitedStates:
            return usaStates.find((s) => s.abbreviation === abbv)?.name;
        case CountryAbbreviation.Canada:
            return canadaStates.find((s) => s.abbreviation === abbv)?.name;
        case CountryAbbreviation.Mexico:
            return mexicoStates.find((s) => s.abbreviation === abbv)?.name;
        default:
            return;
    }
}

export function getCountryNameFromAbbreviation(country: string | CountryAbbreviation): CountryName | undefined {
    switch (country) {
        case CountryAbbreviation.UnitedStates:
            return CountryName.UnitedStates;
        case CountryAbbreviation.Canada:
            return CountryName.Canada;
        case CountryAbbreviation.Mexico:
            return CountryName.Mexico;
        default:
            return;
    }
}

export function getCountryAbbreviationFromName(country: string | CountryName): CountryAbbreviation | undefined {
    switch (country) {
        case CountryName.UnitedStates:
            return CountryAbbreviation.UnitedStates;
        case CountryName.Canada:
            return CountryAbbreviation.Canada;
        case CountryName.Mexico:
            return CountryAbbreviation.Mexico;
        default:
            return;
    }
}

export function isCountryAbbv(x: string): x is CountryAbbreviation {
    return Object.values(CountryAbbreviation as object).includes(x);
}

export function isStateAbbv(x: string): x is StateAbbreviation {
    return Object.values(StateAbbreviation as object).includes(x);
}
