export const customStyles = {
    placeholder: (provided: any, _state: any) => ({
        ...provided,
        marginLeft: 5,
        textAlign: 'left',
    }),
    input: (provided: any, _state: any) => ({
        ...provided,
        paddingLeft: -5,
        textAlign: 'left',
    }),
    menu: (provided: any, _state: any) => ({
        ...provided,
        textAlign: 'left',
    }),
    valueContainer: (provided: any, _state: any) => ({
        ...provided,
        textAlign: 'left',
        paddingRight: '0',
    }),
    container: (provided: any, _state: any) => ({
        ...provided,
        height: '100%',
    }),
    control: (provided: any, _state: any) => ({
        ...provided,
        height: '100%',
    }),
};

export const customTheme = (theme: any) => ({
    ...theme,
    textAlign: 'left',
    colors: {
        ...theme.colors,
        primary25: '#ffeecc',
        primary: 'hsl(0, 0%, 80%)',
        neutral0: '#F2F2F2',
        neutral20: '#F2F2F2',
    },
});

export const countryCodeTheme = (theme: any) => ({
    ...theme,
    textAlign: 'left',
    colors: {
        ...theme.colors,
        primary25: '#ffeecc',
        primary: 'hsl(0, 0%, 80%)',
    },
});

export const countryCodeStyles = {
    control: (provided: any, _state: any) => ({
        ...provided,
        backgroundColor: '#F2F2F2',
        boxShadow: 'none',
        borderLeft: 0,
        borderTop: 0,
        borderBottom: 0,
        borderTopLeftRadius: 4.5,
        borderBottomLeftRadius: 4.5,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    }),
    menu: ({ width, ...css }: any) => ({ ...css }),
    container: (provided: any, _state: any) => ({
        ...provided,
        height: '100%',
        minWidth: '65px',
    }),
};
