import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { PagedResponse } from 'types/Pagination';
import { Visitor } from 'types/Visitor';

type VisitorClientParams = HTTPClientParameters;

export type PaginatedVisitors = PagedResponse<Visitor>;

export type VisitorCreateOrEditPayload = {
    codes: string[];
    phone: string;
    plate: string;
    spot: number;
    state: string;
    country: string;
    validation_source?: string;
    name: string;
};

class VisitorClient extends HTTPClient {
    constructor(params: VisitorClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }
    getVisitors(spot: number, page: number = 1, expiring?: boolean, searchTerm?: string): Promise<PaginatedVisitors> {
        let url = `visitors?spot=${spot}&page=${page}`;
        if (expiring) url += '&expiring=True';
        if (searchTerm) url += `&search=${searchTerm}`;
        return this.get(url);
    }
    addVisitor(visitorInfo: VisitorCreateOrEditPayload): Promise<Visitor> {
        return this.post(`visitors/`, visitorInfo);
    }
    editVisitor(visitorInfo: VisitorCreateOrEditPayload, pk: number): Promise<Visitor> {
        return this.patch(`visitors/${pk}/`, visitorInfo);
    }
    deleteVisitor(pk: number): Promise<unknown> {
        return this.delete(`visitors/${pk}/`);
    }
}

export default VisitorClient;
