import { PaginatedRentals } from 'clients/SlotClient';
import {
    MONTHLY_RENTALS_SUCCESS,
    MONTHLY_RENTALS_FAILED,
    EDIT_RENTAL_REQUESTED,
    EDIT_RENTAL_FAILED,
    EDIT_RENTAL_SUCCESS,
    DELETE_RENTAL_REQUESTED,
    DELETE_RENTAL_FAILED,
    DELETE_RENTAL_SUCCESS,
    MONTHLY_RENTALS_REQUESTED,
    ADD_RENTAL_REQUESTED,
    ADD_RENTAL_SUCCESS,
    ADD_RENTAL_FAILED,
} from './constants';
import { Action } from 'contexts/useReducerWithMiddleware';

export type MonthlyRentalsState = {
    loading: boolean;
    errorMessage: string;
    monthlyRentals: null | PaginatedRentals;
    rentalOperationLoading: boolean;
    rentalOperationErrorMessage: string;
};

export const initialMonthlyRentalsState: MonthlyRentalsState = {
    loading: false,
    errorMessage: '',
    monthlyRentals: null,
    rentalOperationLoading: false,
    rentalOperationErrorMessage: '',
};

export default function monthlyRentalsReducer(state = initialMonthlyRentalsState, action: Action) {
    switch (action.type) {
        case MONTHLY_RENTALS_REQUESTED:
            return {
                ...state,
                loading: true,
                rentalOperationErrorMessage: '',
                errorMessage: '',
            };
        case MONTHLY_RENTALS_SUCCESS:
            return {
                ...state,
                loading: false,
                monthlyRentals: (action.payload as { monthlyRentals: PaginatedRentals }).monthlyRentals,
            };
        case MONTHLY_RENTALS_FAILED:
            return {
                ...state,
                errorMessage: (action.payload as { message: string }).message,
                loading: false,
            };
        case EDIT_RENTAL_REQUESTED:
        case DELETE_RENTAL_REQUESTED:
        case ADD_RENTAL_REQUESTED:
            return {
                ...state,
                rentalOperationLoading: true,
                rentalOperationErrorMessage: '',
            };
        case EDIT_RENTAL_SUCCESS:
        case DELETE_RENTAL_SUCCESS:
        case ADD_RENTAL_SUCCESS:
            return {
                ...state,
                rentalOperationLoading: false,
            };
        case EDIT_RENTAL_FAILED:
        case DELETE_RENTAL_FAILED:
        case ADD_RENTAL_FAILED:
            return {
                ...state,
                rentalOperationLoading: false,
                rentalOperationErrorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialMonthlyRentalsState;
    }
}
