export const OccupancyCopies = {
    monthlyRentals: {
        title: 'Active Monthly Rentals',
        tooltip: 'This is the number of total monthly rentals currently active at your lot.',
    },
    monthlySpaces: {
        title: 'Total Monthly Spaces',
        tooltip: 'This is the total number of monthly spaces currently listed at your lot.',
    },
    tenantRentals: {
        title: 'Active Tenant Rentals',
        tooltip: 'This is the number of currently active rentals by tenants at your location.',
    },
    tenantSpaces: {
        title: 'Total Tenant Spaces',
        tooltip:
            'This is the number of monthly spaces currently listed and being paid for by tenants at your location.',
    },
};

export const dynamicHelpCenterLink =
    'https://airgarage.notion.site/airgarage/Dynamic-Pricing-7c5880c4042c4870a5f8d3e16b3149f7';
