import React, { useState } from 'react';

interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string | undefined;
    fallbackSrc: string | undefined;
}

export default function ImageWithFallback({ src, fallbackSrc, onError, ...props }: ImageWithFallbackProps) {
    const [imgSrc, setImgSrc] = useState(src);

    return (
        /* eslint-disable-next-line jsx-a11y/alt-text */
        <img
            {...props}
            src={imgSrc}
            onError={(e) => {
                setImgSrc(fallbackSrc);
                if (onError) {
                    onError(e);
                }
            }}
        />
    );
}
