import HttpClient, { HTTPClientParameters } from './HTTPClient';

type TicketClientParameters = HTTPClientParameters;

class TicketClient extends HttpClient {
    constructor(params: TicketClientParameters) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    getTicket(identifier: string) {
        return this.get(`tickets/code/${identifier}/`);
    }

    getTicketByPlate(identifier: string) {
        return this.get(`tickets/plate?plate=${identifier}`);
    }

    waiveTicket(pk: string) {
        const params = { waived_reason: 'Ownership or tenant request' };
        return this.patch(`tickets/${pk}/waive/`, params);
    }
}

export default TicketClient;
