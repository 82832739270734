import React from 'react';
import styles from './DriversFrequencyWidget.module.css';
import { ONE_TIME_COLOR, FREQUENT_COLOR, RETURN_COLOR } from './utils';
import { CompleteFrequencyReport } from 'contexts/DriversFrequencyReportContext/reducer';
import InfoTooltip from '../SpotCard/InfoTooltip/InfoTooltip';
import { Bar } from 'react-chartjs-2';
import { BarElement, Chart as ChartJS, ChartData, ChartOptions, TooltipItem } from 'chart.js';

ChartJS.register(BarElement);

type DriversFrequencyWidgetProps = {
    report: CompleteFrequencyReport;
};

function FrequencyGraph({ report }: DriversFrequencyWidgetProps): JSX.Element {
    const {
        power_drivers: frequentDrivers,
        repeat_drivers: returnDrivers,
        one_time_drivers: onetimeDrivers,
    } = report.overallReport;

    const overallData = [
        {
            label: 'Frequent',
            value: frequentDrivers.percentage / 100,
            percentageLabel: `${Math.round(frequentDrivers.percentage)}%`,
            backgroundColor: FREQUENT_COLOR,
            tooltip: `Frequent: Drivers who parked more than ${frequentDrivers.threshold} times`,
            thresholdCopy: `more than ${frequentDrivers.threshold} times`,
        },
        {
            label: 'Return',
            value: returnDrivers.percentage / 100,
            percentageLabel: `${Math.round(returnDrivers.percentage)}%`,
            backgroundColor: RETURN_COLOR,
            tooltip: `Return: Drivers who parked ${returnDrivers.threshold + 1}-${frequentDrivers.threshold} times`,
            thresholdCopy: `${returnDrivers.threshold + 1}-${frequentDrivers.threshold} times`,
        },
        {
            label: 'One-time',
            value: onetimeDrivers.percentage / 100,
            percentageLabel: `${Math.round(onetimeDrivers.percentage)}%`,
            backgroundColor: ONE_TIME_COLOR,
            tooltip: 'One-time: Drivers who parked once and new drivers',
            thresholdCopy: 'one time',
        },
    ];

    const sortedOverallData = overallData.sort((a, b) => b.value - a.value);
    const labels = [];
    const datasetsData = [];
    const backgroundColor = [];

    for (const data of sortedOverallData) {
        labels.push(data.label);
        datasetsData.push(data.value);
        backgroundColor.push(data.backgroundColor);
    }

    const data: ChartData<'bar', number[]> = {
        labels,
        datasets: [
            {
                data: datasetsData,
                backgroundColor,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        scales: {
            x: {
                grid: { display: false, drawBorder: false },
            },
            y: {
                grid: { display: false, drawBorder: false },
                ticks: {
                    format: {
                        style: 'percent',
                    },
                    maxTicksLimit: 4,
                    callback(tickValue) {
                        return typeof tickValue === 'number' ? tickValue * 100 + '%' : '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label(tooltipItem: TooltipItem<'bar'>): string | string[] {
                        return 'Percent of drivers: ' + tooltipItem.formattedValue;
                    },
                },
            },
        },
    };
    return (
        <div className={styles.barsContainer}>
            <div>
                <p className={styles.detail}>
                    Most drivers are parking <b>{sortedOverallData[0].thresholdCopy}</b>
                </p>
                <Bar options={options} data={data} />
            </div>
            <div className={styles.legendContainer}>
                {sortedOverallData.map((data) => {
                    const { label, backgroundColor, tooltip } = data;
                    return (
                        <div className={styles.legend} key={label}>
                            <div className={styles.legendCircle} style={{ backgroundColor }} />
                            {label}
                            <InfoTooltip title="" text={tooltip} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FrequencyGraph;
