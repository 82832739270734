import { AddOrEditEventPayload } from 'clients/EventClient';
import { Event, EventInterval } from 'types/Event';
import {
    dayNumberForPython,
    getFormattedDateYYYY_MM_DD,
    getOrdinalDayOfWeekInMonth,
    getTimezoneOffset,
} from 'utils/helpers';
import { EventFormInputs } from '.';

export function getEventData(newEvent: EventFormInputs, spotId: number): AddOrEditEventPayload {
    const event: AddOrEditEventPayload = {
        start_time: newEvent.startTime,
        end_time: newEvent.endTime,
        spot: spotId,
        interval: newEvent.recurrence,
        quantity: newEvent.numSpotsAvailable,
        codes: newEvent.existingVisitorCodePks.map((c) => Number(c)),
        name: newEvent.name,
        is_lot_closed: newEvent.is_lot_closed,
    };

    switch (newEvent.recurrence) {
        case EventInterval.OneTime:
            event.date = getFormattedDateYYYY_MM_DD(newEvent.selectedDate);
            break;
        case EventInterval.Weekly:
            event.repeat_on_day = dayNumberForPython(newEvent.selectedDate);
            break;
        case EventInterval.Monthly:
            event.repeat_on_day = dayNumberForPython(newEvent.selectedDate);
            event.repeat_on_week = getOrdinalDayOfWeekInMonth(newEvent.selectedDate);
            break;
    }

    return event;
}

export type FormattedEvent = Omit<Event, 'start' | 'end'> & {
    start: Date;
    end: Date;
    title: string;
};

export function formatEvents(events: Event[]): FormattedEvent[] {
    return events.map((event: Event) => {
        const offset = getTimezoneOffset();
        return {
            ...event,
            start: new Date(event.start + offset),
            end: new Date(event.end + offset),
            title: `${event.name} \n\n(${event.quantity})`,
        };
    });
}
