import {
    REPORTS_REQUESTED,
    REPORTS_SUCCESS,
    REPORTS_FAILED,
    DAILY_REPORTS_REQUESTED,
    DAILY_REPORTS_SUCCESS,
    DAILY_REPORTS_FAILED,
    AGGREGATOR_REPORTS_SUCCESS,
    AGGREGATOR_REPORTS_REQUESTED,
    AGGREGATOR_REPORTS_FAILED,
    PROMO_CODE_REPORTS_REQUESTED,
    PROMO_CODE_REPORTS_SUCCESS,
    PROMO_CODE_REPORTS_FAILED,
    UPDATE_REPORTS_REQUESTED,
    UPDATE_REPORTS_SUCCESS,
    UPDATE_REPORTS_FAILED,
} from '../actions/constants';
import { AggregatorReportInterface, DailyReportInterface, PromoCodeReportInterface, ReportInterface } from 'types';

type ReportsState = {
    loading: boolean;
    errorMessage: string;
    reports: Record<string, ReportInterface>;
    aggregatorReports: Record<number, AggregatorReportInterface>;
    selectedDailyReports: DailyReportInterface | null;
    promoCodeReports: Record<number, PromoCodeReportInterface>;
    loadingUpdate: boolean;
    reportsLastUpdatedAt: Record<string, Date>;
};

export const initialReportsState: ReportsState = {
    loading: false,
    errorMessage: '',
    reports: {},
    aggregatorReports: {},
    selectedDailyReports: null,
    promoCodeReports: {},
    loadingUpdate: false,
    reportsLastUpdatedAt: {},
};

export default function reportsReducer(state: ReportsState = initialReportsState, action: any): ReportsState {
    const newState = { ...state };
    const now = new Date();
    switch (action.type) {
        case REPORTS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case REPORTS_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.reports[action.selectedSpotID] = action.payload.reports;
            newState.reportsLastUpdatedAt[action.selectedSpotID] = now;
            return newState;
        case REPORTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case UPDATE_REPORTS_REQUESTED:
            newState.loadingUpdate = true;
            return newState;
        case UPDATE_REPORTS_SUCCESS:
            newState.loadingUpdate = false;
            newState.reports[action.selectedSpotID] = action.payload.reports;
            newState.reportsLastUpdatedAt[action.selectedSpotID] = now;
            return newState;
        case UPDATE_REPORTS_FAILED:
            newState.loadingUpdate = false;
            return newState;
        case AGGREGATOR_REPORTS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case AGGREGATOR_REPORTS_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.aggregatorReports[action.selectedSpotID] = action.payload.aggregatorReports;
            return newState;
        case AGGREGATOR_REPORTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case PROMO_CODE_REPORTS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case PROMO_CODE_REPORTS_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.promoCodeReports[action.selectedSpotID] = action.payload.promoCodeReports;
            return newState;
        case PROMO_CODE_REPORTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case DAILY_REPORTS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            return newState;
        case DAILY_REPORTS_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.selectedDailyReports = action.payload.reports;
            return newState;
        case DAILY_REPORTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        default:
            return state || initialReportsState;
    }
}
