import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Divider, Input } from 'semantic-ui-react';
import useForm from 'hooks/useForm';
import useRadioSelect from 'hooks/useRadioSelect';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type ValidationGeneratorModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ValidationGeneratorModuleContainer = ({
    push,
    organizationId,
    spotId,
    profile,
}: ValidationGeneratorModuleContainerProps) => {
    const calculateMinutes = () => {
        let minutes = parseInt(inputs.hours || 0) * 60 + parseInt(inputs.minutes || 0);
        if (minutes === 0) {
            minutes = 120;
        }
        return minutes;
    };

    const { handleInput, inputs } = useForm();
    useEffect(() => {
        window.scrollTo(0, 0);
        trackPageViewed({
            pageName: 'Kiosk Generator',
            organizationId,
            spotId,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, spotId, profile]);
    const { selectedItem, setSelectedItem } = useRadioSelect('minutes');
    return (
        <div>
            <Button.Group>
                <Button type="button" onClick={() => setSelectedItem('minutes')} active={selectedItem === 'minutes'}>
                    Hours Mode
                </Button>
                <Button.Or />
                <Button type="button" onClick={() => setSelectedItem('calendar')} active={selectedItem === 'calendar'}>
                    Calendar mode
                </Button>
            </Button.Group>
            <Divider hidden />
            {selectedItem === 'minutes' ? (
                <>
                    <p>Validate guests for:</p>
                    <div style={{ display: 'flex' }}>
                        <Input name="hours" type="number" value={inputs.hours} onChange={handleInput} />
                        <p style={{ margin: '10px' }}> Hours</p>
                        <Input name="minutes" type="number" value={inputs.minutes} onChange={handleInput} />
                        <p style={{ margin: '10px' }}> Minutes</p>
                    </div>
                </>
            ) : (
                <>
                    <br />
                </>
            )}
            <Divider hidden />
            <Button
                content="Start kiosk"
                color="green"
                icon="external"
                onClick={() =>
                    push(
                        `/organization/${organizationId}/spots/${spotId}/validation?${
                            selectedItem === 'minutes' ? `&minutes=${calculateMinutes()}` : '&calendar=true'
                        }`
                    )
                }
            ></Button>
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spotId: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationGeneratorModuleContainer);
