import React, { useMemo, useContext, createContext, useCallback } from 'react';
import driverFrequencyReportReducer, { DriversFrequencyState, initialDriversFrequencyState } from './reducer';
import {
    DRIVERS_FREQUENCY_REPORTS_SUCCESS,
    DRIVERS_FREQUENCY_REPORTS_FAILED,
    DRIVERS_FREQUENCY_REPORTS_REQUESTED,
} from './constants';
import { reportClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';

interface DriversFrequencyReportActions {
    getFrequencyReports: (spotId: number, timeKey: string, startDate: string | undefined) => void;
}

interface DriversFrequencyContextType extends DriversFrequencyState, DriversFrequencyReportActions {}

export const DriversFrequencyReportContext = createContext<DriversFrequencyContextType | null>(null);

export const DriversFrequencyReportProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({
    children,
}) => {
    const [state, dispatch] = useReducerWithMiddleware(
        driverFrequencyReportReducer,
        {
            ...initialDriversFrequencyState,
        },
        [],
        [unauthorized]
    );

    const getFrequencyReports = useCallback(
        async (spotId: number, timeKey: string, startDate: string | undefined = undefined) => {
            dispatch({ type: DRIVERS_FREQUENCY_REPORTS_REQUESTED });
            try {
                const date = new Date();
                const endDate = date.toISOString().split('T')[0];

                const [overallReport, breakdownReport] = await Promise.all([
                    reportClient.getOverallFrequency(spotId, startDate, endDate),
                    reportClient.getBreakdownFrequency(spotId, startDate, endDate),
                ]);

                dispatch({
                    type: DRIVERS_FREQUENCY_REPORTS_SUCCESS,
                    payload: {
                        overallReport: overallReport.data,
                        lastUpdatedAt: overallReport.last_updated_at,
                        breakdownReport: breakdownReport.data,
                        timeKey,
                    },
                });
            } catch (error) {
                dispatch({
                    type: DRIVERS_FREQUENCY_REPORTS_FAILED,
                    payload: {
                        error,
                        message: error?.toString() || 'Could not fetch reports',
                    },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<DriversFrequencyContextType>(() => {
        return {
            ...state,
            getFrequencyReports,
        };
    }, [state, getFrequencyReports]);

    return <DriversFrequencyReportContext.Provider value={value}>{children}</DriversFrequencyReportContext.Provider>;
};

export const useDriversFrequencyReport = () => {
    const context = useContext(DriversFrequencyReportContext);

    if (!context) {
        throw new Error('Error: useDriversFrequency should be wrapped by DriversFrequencyProvider.');
    }

    return context;
};
