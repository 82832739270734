import { PaginatedRentals } from 'clients/SlotClient';
import {
    DAILY_RENTALS_REQUESTED,
    DAILY_RENTALS_SUCCESS,
    DAILY_RENTALS_FAILED,
    EDIT_DAILY_RENTAL_REQUESTED,
    EDIT_DAILY_RENTAL_SUCCESS,
    EDIT_DAILY_RENTAL_FAILED,
} from './constants';
import { Action } from 'contexts/useReducerWithMiddleware';

export type DailyRentalsState = {
    loading: boolean;
    errorMessage: string;
    dailyRentals: null | PaginatedRentals;
    rentalOperationLoading: boolean;
    rentalOperationErrorMessage: string;
};

export const initialDailyRentalsState: DailyRentalsState = {
    loading: false,
    errorMessage: '',
    dailyRentals: null,
    rentalOperationLoading: false,
    rentalOperationErrorMessage: '',
};

export default function dailyRentalsReducer(state = initialDailyRentalsState, action: Action): DailyRentalsState {
    switch (action.type) {
        case DAILY_RENTALS_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case DAILY_RENTALS_SUCCESS:
            return {
                ...state,
                loading: false,
                dailyRentals: (action.payload as { dailyRentals: PaginatedRentals }).dailyRentals,
            };
        case DAILY_RENTALS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        case EDIT_DAILY_RENTAL_REQUESTED:
            return {
                ...state,
                rentalOperationLoading: true,
                rentalOperationErrorMessage: '',
            };
        case EDIT_DAILY_RENTAL_SUCCESS:
            return {
                ...state,
                rentalOperationLoading: false,
            };
        case EDIT_DAILY_RENTAL_FAILED:
            return {
                ...state,
                rentalOperationLoading: false,
                rentalOperationErrorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialDailyRentalsState;
    }
}
