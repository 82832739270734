import React from 'react';
import { Header, Grid, Loader, Message } from 'semantic-ui-react';
import VisitorPlate from './VisitorPlate';

const VisitorPlates = (props: any) => {
    const {
        visitors,
        validateVisitor,
        calendar,
        validateVisitorWithDate,
        minutes,
        loading,
        successMessage,
        errorMessage,
        spot,
    } = props;
    return (
        <>
            <Grid.Row centered>
                <Header
                    as="h2"
                    content={
                        (visitors && visitors.length > 0) || loading
                            ? 'Tap license plate to validate'
                            : 'There are no visitors currently requesting validation'
                    }
                />
            </Grid.Row>
            {!loading ? (
                <>
                    <Grid.Row centered>
                        {errorMessage && <Message negative content={errorMessage} />}
                        {successMessage && <Message positive content={successMessage} />}
                    </Grid.Row>
                    <Grid.Row centered>
                        {visitors &&
                            visitors.length > 0 &&
                            visitors.map((visitor: any, index: number) => {
                                return !loading ? (
                                    <VisitorPlate
                                        key={index}
                                        index={index}
                                        calendar={calendar}
                                        car={visitor.car_details}
                                        visitor_pk={visitor.pk}
                                        minutes={minutes}
                                        spot={spot}
                                        validateVisitor={validateVisitor}
                                        validateVisitorWithDate={validateVisitorWithDate}
                                    ></VisitorPlate>
                                ) : (
                                    <Loader active inline="centered" />
                                );
                            })}
                    </Grid.Row>
                </>
            ) : (
                <Loader active inline="centered" />
            )}
        </>
    );
};

export default VisitorPlates;
