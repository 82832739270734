import APIClient from './APIClient';

class UploadService extends Object {
    getBase64 = (file: any) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onerror = () => {
                reader.abort();
                reject();
            };

            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file);
        });
    };
    async uploadFile(file: any, token: string) {
        try {
            const base64: any = await this.getBase64(file);
            const base64PlainString = base64.split(',')[1];
            const result = await APIClient.postFile(`upload`, base64PlainString, file.name, token);
            return result;
        } catch (error) {
            console.log('error: ', error);
        }
    }
}

export default new UploadService();
