import React from 'react';
import { Menu, Divider, Grid } from 'semantic-ui-react';
import ProfileBoxContainer from 'containers/ProfileBoxContainer';
import MainMenu from 'components/Dashboard/Modules/MainMenu';
import SpotMenu from 'components/Dashboard/Modules/SpotMenu';
import SuperuserMenuContainer from 'containers/SuperuserMenuContainer';
import { Organization, SpotInterface } from 'types';

type SidebarDesktopProps = {
    children: React.ReactNode | React.ReactNode[];
    organization: Organization | null;
    superuser: boolean;
    spots: SpotInterface[] | null;
    selectedSpotInfo: SpotInterface | null;
    navigateToTab: (tabUrl: string) => void;
    logout: () => void;
};

const SidebarDesktop = ({
    spots,
    navigateToTab,
    logout,
    children,
    selectedSpotInfo,
    organization,
    superuser,
}: SidebarDesktopProps) => {
    return (
        <Grid padded stackable>
            <Grid.Column width={3}>
                <ProfileBoxContainer />
                <Divider hidden />
                {superuser && (
                    <SuperuserMenuContainer navigateToTab={navigateToTab} selectedSpotInfo={selectedSpotInfo} />
                )}
                <Menu secondary fluid vertical>
                    {organization && <MainMenu navigateToTab={navigateToTab} organization={organization} />}
                    <Divider hidden />
                    {spots && organization && (
                        <SpotMenu
                            selectedSpotInfo={selectedSpotInfo}
                            spots={spots}
                            navigateToTab={navigateToTab}
                            organization={organization}
                        />
                    )}
                    <Divider hidden />
                    <Menu.Item name="Help Center" onClick={() => navigateToTab('/help-center')} />
                    <Menu.Item name="Logout" onClick={logout} />
                </Menu>
            </Grid.Column>
            <Grid.Column width={13}>{children}</Grid.Column>
        </Grid>
    );
};

export default SidebarDesktop;
