import React, { HTMLAttributes } from 'react';
import styles from './Loader.module.css';

type Size = 'sm' | 'md' | 'lg';
type Color = 'primary' | 'secondary';

interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
    size?: Size;
    color?: Color;
}

const Loader: React.FC<LoaderProps> = ({ size = 'sm', color = 'primary', ...props }) => {
    return (
        <div {...props} className={`${styles.loader} ${styles[size]} ${styles[color]} ${props.className}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default Loader;
