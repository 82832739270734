import React from 'react';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import Button from 'components/Button/Button';
import CarIcon from 'components/Icons/CarIcon';
import TagIcon from 'components/Icons/TagIcon';
import { CountryAbbreviation, StateAbbreviation } from 'constants/states';
import useForm from 'hooks/useForm';
import { SlotInterface } from 'types';
import {
    getOptionFromValues,
    states,
    stringRepresentationToValues,
    valuesToStringRepresentation,
} from 'utils/statesSelect';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';

export type Changes = { state?: string; country?: string; plate?: string; discountCode?: string };

type EditRenterFormProps = {
    rental: SlotInterface;
    updateRental(changes: Changes): void;
    loading: boolean;
};

// EditRenterForm is in progress.
// It's been descoped, but it's here for whenever we're ready.
function EditRenterForm(props: EditRenterFormProps) {
    const {
        rental: { promo_code_info, cars_detail },
        updateRental,
    } = props;
    const car = cars_detail.length > 0 ? cars_detail[0] : undefined;

    const carState = car
        ? valuesToStringRepresentation(car.country as CountryAbbreviation, car.state as StateAbbreviation)
        : '';

    const form = useForm({
        plate: car?.plate || '',
        state: carState,
        discountCode: promo_code_info || '',
    });

    const clean = React.useMemo(() => {
        const stateOption = stringRepresentationToValues(form.inputs.state);
        return (
            (car?.plate || null) === (form.inputs.plate || null) &&
            (car?.state || null) === (stateOption?.state || null) &&
            (car?.country || 'US') === (stateOption?.country || 'US') &&
            (promo_code_info || null) === (form.inputs.discountCode || null)
        );
    }, [
        car?.state,
        car?.plate,
        car?.country,
        promo_code_info,
        form.inputs.discountCode,
        form.inputs.plate,
        form.inputs.state,
    ]);

    function onUpdateForm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        const changes: Changes = {};
        let carHasChanged = false;
        if ((car?.plate || null) !== (form.inputs.plate || null)) {
            carHasChanged = true;
        }
        if (!carHasChanged && carState !== form.inputs.state) {
            carHasChanged = true;
        }

        if (carHasChanged) {
            const stateOption = stringRepresentationToValues(form.inputs.state);
            changes['plate'] = form.inputs.plate;
            changes['state'] = stateOption?.state;
            changes['country'] = stateOption?.country;
        }
        if ((promo_code_info || null) !== (form.inputs.discountCode || null)) {
            changes['discountCode'] = form.inputs.discountCode;
        }
        updateRental(changes);
    }

    return (
        <AGForm>
            <TextInput
                label="License Plate"
                onChange={form.handleInput}
                placeholder="Optional"
                errors={null}
                icon={<CarIcon height={20} width={20} />}
                isUppercase={true}
                name="plate"
                value={form.inputs.plate}
            />
            <SearchSelectInput
                placeholder="Enter license state"
                label="License State"
                options={states}
                errors={null}
                icon={<CarIcon height={20} width={20} />}
                onSelected={(value) => {
                    form.handleValueChange('state', value);
                }}
                selected={
                    car
                        ? getOptionFromValues(car.country as CountryAbbreviation, car.state as StateAbbreviation)
                        : undefined
                }
                name="state"
            />
            {promo_code_info ? (
                <TextInput
                    label="Discount Code"
                    errors={null}
                    onChange={() => {}}
                    icon={<TagIcon height={20} width={20} />}
                    disabled={true}
                    value={promo_code_info}
                />
            ) : (
                <TextInput
                    label="Discount Code"
                    onChange={form.handleInput}
                    placeholder="Apply a discount code"
                    errors={null}
                    icon={<TagIcon height={20} width={20} />}
                    isUppercase={true}
                    name="discountCode"
                    value={form.inputs.discountCode}
                />
            )}

            <Button type="button" onClick={onUpdateForm} loading={props.loading} disabled={clean}>
                Update Renter
                <CheckIcon />
            </Button>
        </AGForm>
    );
}

export default EditRenterForm;
