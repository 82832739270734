import React, { useEffect, useState } from 'react';
import { loginAction, sendVerificationCodeAction, getProfileAction } from './../../actions/actions';
import { Form, Message, Loader, Icon } from 'semantic-ui-react';
import styles from './LoginForm.module.css';
import { connect } from 'react-redux';
import useForm from './../../hooks/useForm';
import OtpInput from 'react-otp-input';
import Select from 'react-select';
import { isPhone, validateEmail } from 'utils/helpers';
import { getLables, optionsForSelect, usOption } from 'assets/data/countriesPhoneCodes';
import { countryCodeStyles, countryCodeTheme, customStyles } from 'utils/reactSelectStyles';

const LoginForm = (props: any) => {
    const {
        getProfile,
        login,
        verificationCodeSent,
        sendVerificationCode,
        successMessage,
        errorMessage,
        loading,
        setShowHeader,
    } = props;
    const { username, code } = props.urlParams;
    const { inputs, handleInput, handleValueChange } = useForm({ username: '', phoneCode: '+1', password: '' });
    const [verificationCode, setVerificationCode] = useState('');
    const [showUsernameField, setShowUsernameField] = useState(true);
    const [showVerificationCodeField, setShowVerificationCodeField] = useState(false);
    const [hasOnlyNumbers, setHasOnlyNumbers] = useState(false);
    const fields = inputs as any;

    useEffect(() => {
        if (successMessage === 'Logging in...') {
            getProfile();
        }
    }, [successMessage, getProfile]);

    useEffect(() => {
        setShowVerificationCodeField(verificationCodeSent);
        if (successMessage) {
            setShowHeader(false);
            setShowUsernameField(false);
        }
    }, [verificationCodeSent, successMessage, setShowHeader]);

    useEffect(() => {
        // Login user via code in url params if available
        if (code) {
            login({ username, password: code });
        }
    }, [login, username, code]);

    useEffect(() => {
        if (isPhone(inputs.username)) {
            setHasOnlyNumbers(true);
        } else setHasOnlyNumbers(false);
    }, [inputs.username]);

    const handleSubmit = ({ username }: any) => {
        const phoneCode = hasOnlyNumbers ? fields.phoneCode : '';
        const completeUsername = phoneCode + username;
        if (verificationCode) {
            fields.password = verificationCode;
            login({ ...fields, username: completeUsername });
        } else {
            sendVerificationCode({ username: completeUsername, phoneCode });
        }
    };

    const resendCode = ({ username }: any) => {
        const phoneCode = hasOnlyNumbers ? fields.phoneCode : '';
        const completeUsername = phoneCode + username;
        sendVerificationCode({ username: completeUsername, phoneCode });
        setVerificationCode('');
    };

    const shouldDisableButton = (): boolean => {
        return (
            (verificationCodeSent && verificationCode.length !== 6) ||
            (!verificationCodeSent && !hasOnlyNumbers && !validateEmail(inputs.username)) ||
            (!verificationCodeSent && hasOnlyNumbers && inputs.username.length < 4) ||
            (!verificationCodeSent && hasOnlyNumbers && inputs.phoneCode === '+1' && inputs.username.length !== 10)
        );
    };

    const onChange = (event: any) => {
        handleValueChange('phoneCode', event.value);
    };

    return (
        <div>
            <Loader active={loading} />
            <Form className={styles.loginForm} hidden={loading}>
                {successMessage && showVerificationCodeField && <h1>{successMessage}</h1>}
                {errorMessage && (
                    <Message
                        onClick={() => window.open('https://help.airgarage.com/hc/en-us/requests/new')}
                        header={errorMessage}
                        color="red"
                        content="Having trouble logging in? Click here to message Support."
                    />
                )}

                {showUsernameField && (
                    <Form.Field>
                        <label>Email or Phone Number</label>
                        <div className={styles.usernameField}>
                            {!hasOnlyNumbers && (
                                <Icon
                                    name={inputs.username === '' ? 'user circle' : 'mail outline'}
                                    className={styles.fieldIcon}
                                />
                            )}
                            {hasOnlyNumbers && (
                                <div>
                                    <Select
                                        options={optionsForSelect}
                                        onChange={onChange}
                                        isSearchable={true}
                                        name="phoneCode"
                                        placeholder=""
                                        theme={countryCodeTheme}
                                        styles={{ ...customStyles, ...countryCodeStyles }}
                                        defaultValue={usOption}
                                        formatOptionLabel={getLables}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                    />
                                </div>
                            )}
                            <div className={styles.usernameInput}>
                                <Form.Input
                                    name="username"
                                    placeholder="Email or Phone"
                                    onChange={handleInput}
                                    value={fields.username}
                                />
                            </div>
                        </div>
                    </Form.Field>
                )}

                {showVerificationCodeField && (
                    <div data-sentry-block>
                        <OtpInput
                            numInputs={6}
                            value={verificationCode}
                            onChange={setVerificationCode}
                            containerStyle={{ flex: 1, justifyContent: 'space-between' }}
                            inputStyle={styles.codeInputs}
                            shouldAutoFocus
                            renderInput={(props) => <input {...props} type={'number'} />}
                        />
                    </div>
                )}

                <Form.Button
                    fluid
                    content={verificationCodeSent ? 'Log in' : 'Get verification code'}
                    onClick={() => handleSubmit(fields)}
                    disabled={shouldDisableButton()}
                />

                {showVerificationCodeField && (
                    <p>
                        Didn't receive a verification code?
                        <a href="# " onClick={() => resendCode({ username: inputs.username })}>
                            {' '}
                            Resend to {inputs.username}
                        </a>
                    </p>
                )}
            </Form>
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        loading: state.auth.loading,
        verificationCodeSent: state.auth.verificationCodeSent,
    };
}

const mapDispatchToProps = {
    login: loginAction,
    sendVerificationCode: sendVerificationCodeAction,
    getProfile: getProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
