import { useEffect, useRef } from 'react';

export function useClickOutside(callback: () => void): React.MutableRefObject<any> {
    const ref = useRef<any>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target) && !event.defaultPrevented) {
                callback();
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [callback]);

    return ref;
}
