import React from 'react';

import {
    isDailySummary,
    isEnforcementEntry,
    isRegularMaintenanceEntry,
    isSnapshotEntry,
    TimelineEntry,
} from 'types/LotTimeline';
import Enforcement from './entries/Enforcement';
import DailySummary from './entries/DailySummary';
import Snapshot from './entries/Snapshot';
import RegularMaintenance from './entries/RegularMaintenance';

type TimelineEventProps = { entry: TimelineEntry };

function TimelineEvent({ entry }: TimelineEventProps): JSX.Element {
    if (isDailySummary(entry)) {
        return <DailySummary entry={entry} />;
    }
    if (isEnforcementEntry(entry)) {
        return <Enforcement entry={entry} />;
    }
    if (isSnapshotEntry(entry)) {
        return <Snapshot entry={entry} />;
    }
    if (isRegularMaintenanceEntry(entry)) {
        return <RegularMaintenance entry={entry} />;
    }
    console.warn('unrecognized entry:', entry);
    return <></>;
}

export default TimelineEvent;
