import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import organizationReducer, { initialOrganizationState } from './organization.reducers';
import payoutsReducer, { initialPayoutsState } from './payouts.reducers';
import spotsReducer, { initialSpotState } from './spots.reducers';
import reportsReducer, { initialReportsState } from './reports.reducers';
import enforcementsReducer, { initialEnforcementsState } from './enforcements.reducers';
import visitorsReducer, { initialVisitorsState } from './visitors.reducers';
import teamReducer, { initialTeamState } from './team.reducers';
import ticketReducer, { initialTicketState } from './tickets.reducers';
import refundReducer, { initialRefundState } from './refund.reducers';
import uploadReducer, { initialUploadState } from './upload.reducers';
import promoCodeReducer, { initialPromoCodeState } from './promoCodes.reducers';
import authReducer from './auth.reducers';
import { CLEAR_DATA } from '../actions/constants';
const history = createBrowserHistory();

export type AppState = {
    router: RouterState;
    auth: ReturnType<typeof authReducer>;
    organization: ReturnType<typeof organizationReducer>;
    spots: ReturnType<typeof spotsReducer>;
    team: ReturnType<typeof teamReducer>;
    tickets: ReturnType<typeof ticketReducer>;
    payouts: ReturnType<typeof payoutsReducer>;
    reports: ReturnType<typeof reportsReducer>;
    enforcements: ReturnType<typeof enforcementsReducer>;
    promoCodes: ReturnType<typeof promoCodeReducer>;
    visitors: ReturnType<typeof visitorsReducer>;
    refunds: ReturnType<typeof refundReducer>;
    upload: ReturnType<typeof uploadReducer>;
};

const appReducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    organization: organizationReducer,
    spots: spotsReducer,
    team: teamReducer,
    tickets: ticketReducer,
    payouts: payoutsReducer,
    reports: reportsReducer,
    enforcements: enforcementsReducer,
    promoCodes: promoCodeReducer,
    visitors: visitorsReducer,
    refunds: refundReducer,
    upload: uploadReducer,
});

const rootReducer = (state: AppState | undefined, action: any): AppState => {
    switch (action.type) {
        case CLEAR_DATA:
            return {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ...state!,
                team: initialTeamState,
                tickets: initialTicketState,
                reports: initialReportsState,
                enforcements: initialEnforcementsState,
                organization: initialOrganizationState,
                spots: initialSpotState,
                visitors: initialVisitorsState,
                promoCodes: initialPromoCodeState,
                refunds: initialRefundState,
                payouts: initialPayoutsState,
                upload: initialUploadState,
            };
    }
    return appReducer(state, action);
};

export default rootReducer;
