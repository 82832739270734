import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import OrganizationForm from 'components/OrganizationForm/OrganizationForm';
import { getSalesRepsAction } from 'actions/actions';
import { selectSalesRepDropdown } from 'selectors';
import { AppState } from '../reducers/reducers';
import { Redirect } from 'react-router-dom';

type CreateOrganizationViewProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const CreateOrganizationView = (props: CreateOrganizationViewProps) => {
    const { getSalesReps, salesReps, superuser } = props;

    useEffect(() => {
        getSalesReps();
    }, [getSalesReps]);

    return superuser ? <OrganizationForm salesReps={salesReps} /> : <Redirect to="/" />;
};

function mapStateToProps(state: AppState) {
    return {
        salesReps: selectSalesRepDropdown(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        superuser: state.auth.profile!.superuser,
    };
}

const mapDispatchToProps = {
    getSalesReps: getSalesRepsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizationView);
