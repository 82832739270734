import React from 'react';
import Logo from '../../../assets/videoPoster.png';
import HELP_VIDEOS from './constants';
import styles from './HelpCenter.module.css';

const HelpCenter = () => {
    return (
        <div className={styles.container}>
            <div className={styles.grid}>
                {HELP_VIDEOS.map((video, index) => (
                    <div className={styles.item} key={index}>
                        <h3 className={styles.itemTitle}>{video.title}</h3>
                        <div className={styles.videoContainer}>
                            <video
                                className={styles.video}
                                width="320"
                                height="240"
                                controls
                                preload="none"
                                poster={Logo}
                            >
                                <source src={video.url} type="video/mp4" />
                                <track kind="captions" />
                            </video>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.row}>
                <div className={styles.cardContainer}>
                    <h3 className={styles.cardTitle}>Write to us</h3>
                    <p className={styles.cardDescription}>Get quick support for your account.</p>
                    <a href="mailto:partners@airgarage.com" className={styles.email}>
                        partners@airgarage.com
                    </a>
                    <span className={styles.cardFooter}>We'll reply as soon as possible</span>
                </div>
                <div className={styles.cardContainer}>
                    <h3 className={styles.cardTitle}>Call</h3>
                    <p className={styles.cardDescription}>Need to unlock an issue quickly.</p>
                    <span className={styles.phone}>+1 (628) 561-2221</span>
                    <span className={styles.cardFooter}>Mon to Fri 7:00AM - 5:30PM PT</span>
                </div>
            </div>
        </div>
    );
};

export default HelpCenter;
