import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader, Checkbox } from 'semantic-ui-react';
import { getEnforcementsAction, updateEnforcementAction } from 'actions/actions';
import Enforcements from 'components/Dashboard/Modules/Enforcements';
import useForm from 'hooks/useForm';
import { getEnforcementURL } from 'utils/helpers';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type EnforcementsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const EnforcementsModuleContainer = (props: EnforcementsModuleContainerProps) => {
    const { enforcements, selectedSpot, getEnforcements, updateEnforcement, loading, organizationId, profile } = props;
    const [currentlyParkedViolators, toggleCurrentlyParkedViolators] = useState(true);
    const { inputs, handleDropdown } = useForm({ status: 'Not found' });
    const fields = inputs as any;

    useEffect(() => {
        let filteredUrl = '';
        filteredUrl += getEnforcementURL(fields.status, currentlyParkedViolators);
        getEnforcements(filteredUrl);
    }, [selectedSpot, getEnforcements, fields, currentlyParkedViolators]);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Enforcements',
            organizationId,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, selectedSpot, profile]);

    const spotEnforcements = enforcements && enforcements[selectedSpot];
    return (
        <div>
            {selectedSpot && profile.superuser && (
                <>
                    <Checkbox
                        toggle
                        defaultChecked
                        label="Currently Parked Violators"
                        style={{ marginBottom: '20px', marginRight: '20px' }}
                        onClick={() => toggleCurrentlyParkedViolators(!currentlyParkedViolators)}
                    >
                        {' '}
                    </Checkbox>
                </>
            )}
            {!loading ? (
                <div>
                    <Enforcements
                        enforcements={spotEnforcements}
                        updateEnforcement={updateEnforcement}
                        handleDropdown={handleDropdown}
                        fields={fields}
                        superuser={profile.superuser}
                    />
                </div>
            ) : (
                <Loader active inline />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.enforcements.errorMessage,
        loading: state.enforcements.loading,
        enforcements: state.enforcements.enforcements,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getEnforcements: getEnforcementsAction,
    updateEnforcement: updateEnforcementAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnforcementsModuleContainer);
