import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader, Grid, Image, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    getVisitorsToValidateAction,
    addVisitorToValidateAction,
    validateVisitorAction,
    getSpotsAction,
} from 'actions/actions';
import ValidationForm from 'components/Dashboard/ValidationForm/ValidationForm';
import VisitorPlates from 'components/Dashboard/VisitorPlates/VisitorPlates';
import useForm from 'hooks/useForm';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId, selectedSpotInfo } from 'selectors';
import { trackPageViewed } from 'utils/analytics';
import { VisitorValidationSource } from 'utils/constants';
import socket from 'utils/socket-client';
import { parseQueryParams } from 'utils/helpers';

type ValidationRequestsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const ValidationRequestsModuleContainer = (props: ValidationRequestsModuleContainerProps) => {
    const {
        getVisitorsToValidate,
        addVisitorToValidate,
        validateVisitor,
        router,
        spotId,
        orgId,
        spot,
        orgSpotsLoading,
        getSpots,
        loading,
        successMessage,
        errorMessage,
        visitors,
        profile,
    } = props;

    useEffect(() => {
        if (!spot && !orgSpotsLoading) {
            getSpots();
        }
    }, [spot, orgSpotsLoading, getSpots]);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Kiosk',
            spotId,
            organizationId: orgId,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [spotId, orgId, profile]);

    const { minutes, calendar } = parseQueryParams(router.location.search) as { minutes?: number; calendar?: boolean };

    const initialInputs = { plate: '', state: '', country: '' };
    const { handleInput, inputs, handleValueChange } = useForm(initialInputs);
    const validateVisitorWithDate = (visitor_pk: number, date: Date) => {
        const now = moment(new Date());
        const valid_until = moment(date).endOf('day');
        const prettyDate = moment(date).format('MMMM Do');
        const duration = moment.duration(valid_until.diff(now));
        const minutes = duration.asMinutes();
        validateVisitor(visitor_pk, minutes, spotId, VisitorValidationSource.KIOSK, prettyDate);
    };

    useEffect(() => {
        getVisitorsToValidate(spotId);
    }, [getVisitorsToValidate, spotId]);

    useEffect(() => {
        socket.on('server:newVisitor', (spotPk: number) => {
            if (spotPk === Number(spotId)) {
                getVisitorsToValidate(spotId);
            }
        });

        return () => {
            socket.close();
        };
    }, [getVisitorsToValidate, spotId]);

    return (
        <Grid padded>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Image
                        size="medium"
                        wrapped
                        src="https://assets.website-files.com/5d55f1425cb6b7a18aa77528/5d55f8a49a649a59aab64b1c_airgarage-logo.png"
                    />
                </Grid.Column>
                <Grid.Column width={4} floated="right" textAlign="right">
                    {spot ? <Header as="h3" content={spot.name} /> : <Loader inline active />}
                    <ValidationForm
                        inputs={inputs}
                        handleInput={handleInput}
                        handleAddVisitorValidation={() => {
                            addVisitorToValidate(
                                spotId,
                                inputs.plate,
                                VisitorValidationSource.KIOSK,
                                inputs.state,
                                inputs.country
                            );
                            inputs.plate = '';
                            inputs.state = '';
                            inputs.country = '';
                        }}
                        validateVisitor={validateVisitor}
                        handleValueChange={handleValueChange}
                    />
                </Grid.Column>
            </Grid.Row>
            {visitors ? (
                <VisitorPlates
                    visitors={visitors}
                    spot={spotId}
                    minutes={minutes}
                    validateVisitor={validateVisitor}
                    loading={loading}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    validateVisitorWithDate={validateVisitorWithDate}
                    calendar={calendar}
                />
            ) : (
                <Loader active inline="centered" />
            )}
        </Grid>
    );
};

function mapStateToProps(state: AppState) {
    return {
        loading: state.visitors.loading,
        addVisitorLoading: state.visitors.addVisitorLoading,
        visitors: state.visitors.requestingValidation,
        errorMessage: state.visitors.errorMessage,
        successMessage: state.visitors.successMessage,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spotId: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        orgId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        spot: selectedSpotInfo(state),
        orgSpotsLoading: state.spots.orgSpotsLoading,
        router: state.router,
    };
}
const mapDispatchToProps = {
    getVisitorsToValidate: getVisitorsToValidateAction,
    addVisitorToValidate: addVisitorToValidateAction,
    validateVisitor: validateVisitorAction,
    getSpots: getSpotsAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValidationRequestsModuleContainer));
