import React from 'react';
import { Grid } from 'semantic-ui-react';
import WidgetDataLabel from '../widgets/WidgetDataLabel';
import WidgetData from '../widgets/WidgetData';

type RevenueAndCarsProps = { revenue: string; cars: string };

function RevenueAndCars({ revenue, cars }: RevenueAndCarsProps) {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <WidgetDataLabel
                        label="Auto-Started Revenue"
                        tooltip={{
                            title: 'Auto-Started Revenue',
                            message:
                                'As license plates are scanned, rentals are started automatically for drivers with plates already on file with AirGarage',
                        }}
                    />
                    <WidgetData>{revenue}</WidgetData>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <WidgetDataLabel label="Cars Auto-Started" />
                    <WidgetData>{cars}</WidgetData>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default RevenueAndCars;
