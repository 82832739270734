import React, { useState, useEffect } from 'react';
import { Grid, Header, Loader } from 'semantic-ui-react';
import { BarElement, Chart as ChartJS, ChartData, ChartDataset, InteractionMode, TooltipItem } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ReportInterface } from 'types';
import { usdFormatterWithoutCents } from 'utils/helpers';
import WidgetBox from '../widgets/WidgetBox';
import EmptyWidgetMessage from 'components/widgets/EmptyWidgetMessage';
import styles from './RevenueWidget.module.css';
import { calculateUplift, REVENUE_COLORS, REVENUE_TYPE_LABELS } from './chartHelpers';
import Uplift, { UpliftProps } from './Uplift';
import Legend from './Legend';
import BarsIcon from 'components/Icons/BarsIcon';
import LiveUpdateLabel from 'components/widgets/LiveUpdateLabel';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';

ChartJS.register(BarElement);

type RevenueWidgetProps = {
    reportError: string;
    report: ReportInterface | null;
    isReportLoading: boolean;
    updateReportsLoading: boolean;
};

type ChartLabels = string;

const MONTHLY_REVENUE = 'Monthly Revenue';
const REVENUE_UPLIFT = 'Revenue Uplift';
const windowWidthBreakingPoint = 1700;

function RevenueWidget({
    report,
    reportError,
    isReportLoading,
    updateReportsLoading,
}: RevenueWidgetProps): JSX.Element {
    const [width, setWindowWidth] = useState(0);

    const updateDimensions = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    if (reportError) {
        return (
            <WidgetBox title="Revenue" icon={<BarsIcon />}>
                <>
                    <Header as="h1">Something went wrong</Header>
                    <Header as="h3">Please refresh the page</Header>
                </>
            </WidgetBox>
        );
    }

    if (isReportLoading || report === null) {
        return (
            <WidgetBox title="Revenue" icon={<BarsIcon />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    const months = report.monthly.slice(-6);
    const hasRevenueToShow = months.length > 0;
    const sumOfEachCategory = {
        gross_revenue: 0,
        enforcement_revenue: 0,
        marketing_revenue: 0,
        dynamic_pricing_revenue: 0,
    };

    for (const month of months) {
        sumOfEachCategory.gross_revenue += month.gross_revenue;
        sumOfEachCategory.enforcement_revenue += month.enforcement_revenue || 0;
        sumOfEachCategory.dynamic_pricing_revenue += month.dynamic_pricing_revenue || 0;
        sumOfEachCategory.marketing_revenue += month.marketing_revenue || 0;
    }
    const totalUplift = calculateUplift(sumOfEachCategory);
    const upliftProps: UpliftProps = { months: months.length };
    if (totalUplift.enforcement_revenue) {
        upliftProps.enforcement = `${totalUplift.enforcement_revenue}%`;
    }
    if (totalUplift.dynamic_pricing_revenue) {
        upliftProps.dynamicPricing = `${totalUplift.dynamic_pricing_revenue}%`;
    }
    if (totalUplift.marketing_revenue) {
        upliftProps.marketing = `${totalUplift.marketing_revenue}%`;
    }

    const data: ChartData<'bar', number[], ChartLabels> = { labels: [], datasets: [] };
    const baselineDataset: ChartDataset<'bar', number[]> = {
        label: REVENUE_TYPE_LABELS.baseline,
        data: [],
        backgroundColor: REVENUE_COLORS.baseline,
    };

    const enforcementDataset: ChartDataset<'bar', number[]> = {
        label: REVENUE_TYPE_LABELS.enforcement,
        data: [],
        backgroundColor: REVENUE_COLORS.enforcement,
    };

    const dynamicPricingDataset: ChartDataset<'bar', number[]> = {
        label: REVENUE_TYPE_LABELS.dynamicPricing,
        data: [],
        backgroundColor: REVENUE_COLORS.dynamicPricing,
    };

    const marketingDataSet: ChartDataset<'bar', number[]> = {
        label: REVENUE_TYPE_LABELS.marketing,
        data: [],
        backgroundColor: REVENUE_COLORS.marketing,
    };

    let hasMarketingData = false;
    let hasDynamicPricingData = false;
    let hasEnforcementData = false;
    for (const month of months) {
        const { gross_revenue, marketing_revenue, dynamic_pricing_revenue, enforcement_revenue, date } = month;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data.labels!.push(date);
        let baseline = gross_revenue;
        marketingDataSet.data.push(marketing_revenue || 0);
        if (marketing_revenue !== 0 && marketing_revenue !== undefined) {
            baseline -= marketing_revenue;
            hasMarketingData = true;
        }
        dynamicPricingDataset.data.push(dynamic_pricing_revenue || 0);
        if (dynamic_pricing_revenue !== 0 && dynamic_pricing_revenue !== undefined) {
            baseline -= dynamic_pricing_revenue;
            hasDynamicPricingData = true;
        }
        enforcementDataset.data.push(enforcement_revenue || 0);
        if (enforcement_revenue !== 0 && enforcement_revenue !== undefined) {
            baseline -= enforcement_revenue;
            hasEnforcementData = true;
        }
        baselineDataset.data.push(baseline);
    }

    data.datasets.push(baselineDataset);
    if (hasEnforcementData) {
        data.datasets.push(enforcementDataset);
    }
    if (hasDynamicPricingData) {
        data.datasets.push(dynamicPricingDataset);
    }
    if (hasMarketingData) {
        data.datasets.push(marketingDataSet);
    }
    const onlyGrossRevenue = !hasEnforcementData && !hasDynamicPricingData && !hasMarketingData;
    if (onlyGrossRevenue) {
        baselineDataset.backgroundColor = REVENUE_COLORS.gross;
    }

    return (
        <WidgetBox
            title={onlyGrossRevenue ? MONTHLY_REVENUE : REVENUE_UPLIFT}
            icon={<BarsIcon />}
            updatesLabel={<LiveUpdateLabel loading={updateReportsLoading} />}
        >
            {hasRevenueToShow ? (
                <Grid>
                    <Grid.Column width={onlyGrossRevenue || width < windowWidthBreakingPoint ? 16 : 11}>
                        <Bar
                            options={{
                                responsive: true,
                                scales: {
                                    x: { stacked: true, grid: { display: false } },
                                    y: {
                                        stacked: true,
                                        grid: { display: false },
                                        ticks: {
                                            callback(value: string | number): string {
                                                return usdFormatterWithoutCents(value as number);
                                            },
                                        },
                                    },
                                },
                                interaction: {
                                    mode: 'index' as InteractionMode,
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        callbacks: {
                                            footer(tooltipItems: TooltipItem<'bar'>[]): string | string[] {
                                                if (onlyGrossRevenue) return '';
                                                return `Total Revenue: ${usdFormatterWithoutCents(
                                                    tooltipItems.reduce((x, item) => x + (item.raw as number), 0)
                                                )}`;
                                            },
                                            label(tooltipItem: TooltipItem<'bar'>): string | string[] {
                                                const { dataIndex, dataset } = tooltipItem;
                                                const raw = dataset.data[dataIndex];
                                                const rev = usdFormatterWithoutCents(raw);
                                                if (raw === 0) return ``;
                                                if (onlyGrossRevenue) return `Revenue: ${rev}`;
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                return `${dataset.label!}: ${rev}`;
                                            },
                                        },
                                    },
                                },
                            }}
                            data={data}
                        />
                    </Grid.Column>
                    {!onlyGrossRevenue && (
                        <Grid.Column width={width < windowWidthBreakingPoint ? 16 : 5}>
                            <Grid className={styles.spaceBetween}>
                                <Grid.Column
                                    computer={width < windowWidthBreakingPoint ? 8 : 16}
                                    tablet={8}
                                    mobile={16}
                                >
                                    <Uplift {...upliftProps} />
                                </Grid.Column>
                                <Grid.Column
                                    computer={width < windowWidthBreakingPoint ? 8 : 16}
                                    tablet={8}
                                    mobile={16}
                                >
                                    <Legend
                                        showDynamicPricing={hasDynamicPricingData}
                                        showEnforcement={hasEnforcementData}
                                        showMarketing={hasMarketingData}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    )}
                </Grid>
            ) : (
                <EmptyWidgetMessage
                    title="Your monthly revenue will show up here"
                    message="Revenue will appear after you get your first rental"
                />
            )}
        </WidgetBox>
    );
}

function RevenueWidgetWrapper(props: RevenueWidgetProps): JSX.Element {
    return (
        <AuthorizedView allowed={Roles.Admin}>
            <RevenueWidget {...props} />
        </AuthorizedView>
    );
}

export default RevenueWidgetWrapper;
