import { TimelineEntry } from 'types';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import cache from './cache';
import { formatToISOWithoutMillisecondsAndTimezone } from 'utils/helpers';

type TimelineClientParams = HTTPClientParameters;

const fifteenMinutesInMilliseconds = 15 * 60000;

export type TimelineResponse = {
    next: boolean;
    events: TimelineEntry[];
};

class TimelineClient extends HTTPClient {
    constructor(params: TimelineClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    @cache(fifteenMinutesInMilliseconds)
    getLotTimelineEvents(spotUuid: string, offset?: string): Promise<TimelineResponse> {
        let path = `spots/${spotUuid}/timeline/`;
        if (offset) {
            path += `?offset=${formatToISOWithoutMillisecondsAndTimezone(offset)}`;
        }
        return this.get(path);
    }
}

export default TimelineClient;
