import { carClient, discountCodeClient, slotClient } from 'clients';
import { Changes } from 'components/RenterList/EditRenterForm';
import { SlotInterface } from 'types';

export async function editRental(slot: SlotInterface, changes: Changes) {
    if (changes.discountCode) {
        await discountCodeClient.applyDiscountCode(slot.pk, changes.discountCode);
    }

    let newCar;
    if (changes.plate) {
        newCar = await carClient.getOrCreateCar({
            plate: changes.plate,
            state: changes.state || '',
            country: changes.country || '',
        });

        await slotClient.updateCarsOfRental(slot.uuid, {
            cars: [newCar.pk],
        });
    }
}
