import React from 'react';

import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as NextIcon } from 'assets/icons/next.svg';
import { HTTPError } from 'clients/HTTPClient';
import Button from 'components/Button/Button';
import CarIcon from 'components/Icons/CarIcon';
import AGForm from 'components/AGForm/AGForm';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import { StepComponentProps } from 'components/ValetDashboard/Steps/Steps';
import WarningBox from 'components/ValetDashboard/WarningBox/WarningBox';
import { CountryAbbreviation, StateAbbreviation } from 'constants/states';
import { ValetSession } from 'types/ValetSession';
import { convertCentsToDisplayPrice } from 'utils/currency';
import { getOptionFromValues, states } from 'utils/statesSelect';
import styles from './CheckBalanceForm.module.css';

interface CheckBalanceFormProps extends StepComponentProps {
    valetSessionOnCheckOut: ValetSession;
    payValetSession: (uuid: string) => Promise<ValetSession>;
    checkOutCar: (uuid: string) => Promise<ValetSession>;
}

function CheckBalanceForm(props: CheckBalanceFormProps) {
    const [driverNeedPaymentMethod, setDriverNeedPaymentMethod] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    async function handleSubmit() {
        setLoading(true);
        if (props.valetSessionOnCheckOut.outstanding_balance === 0) {
            try {
                await props.checkOutCar(props.valetSessionOnCheckOut.uuid);
                props.goNext();
            } catch (err) {
                setUnknownError(true);
            }
        } else {
            try {
                setDriverNeedPaymentMethod(false);
                await props.payValetSession(props.valetSessionOnCheckOut.uuid);
            } catch (err) {
                if (HTTPError.isHTTPError(err)) {
                    if (err.status === 400) {
                        setDriverNeedPaymentMethod(true);
                        setLoading(false);
                        return;
                    }
                }
                setUnknownError(true);
            }
        }
        setLoading(false);
    }

    const car = props.valetSessionOnCheckOut.car_details;
    const { outstanding_balance: balance } = props.valetSessionOnCheckOut;
    return (
        <div>
            {driverNeedPaymentMethod && (
                <div className={styles.warningBoxWrapper}>
                    <WarningBox type="error">
                        Ask the driver to add a payment method with the manage rental link
                    </WarningBox>
                </div>
            )}

            {unknownError && (
                <div className={styles.warningBoxWrapper}>
                    <WarningBox type="error" title="Something went wrong!">
                        Please retry later
                    </WarningBox>
                </div>
            )}

            <div className={styles.balanceWrapper}>
                <span className={styles.balance}>
                    <span
                        className={`
                            ${styles.balanceAmount} ${balance === 0 ? styles.zeroBalance : ''}
                        `}
                    >
                        {convertCentsToDisplayPrice(balance)}
                    </span>
                    outstanding balance
                </span>
            </div>

            <AGForm>
                <TextInput
                    type="text"
                    label="Phone Number"
                    onChange={() => {}}
                    errors={null}
                    placeholder="(555) 555-1234"
                    value={props.valetSessionOnCheckOut.phone}
                    icon={<PhoneIcon />}
                    disabled={true}
                />

                <TextInput
                    label="License Plate"
                    onChange={() => {}}
                    errors={null}
                    placeholder="Enter license plate number"
                    isUppercase={true}
                    value={props.valetSessionOnCheckOut.car_details?.plate}
                    icon={<CarIcon width={20} />}
                    disabled={true}
                />

                <SearchSelectInput
                    placeholder="Enter license state"
                    label="License State"
                    options={states}
                    errors={null}
                    icon={<CarIcon width={20} />}
                    onSelected={() => {}}
                    selected={
                        car
                            ? getOptionFromValues(car.country as CountryAbbreviation, car.state as StateAbbreviation)
                            : undefined
                    }
                    disabled={true}
                />

                <Button
                    type="button"
                    onClick={handleSubmit}
                    className={balance === 0 ? styles.greenButton : ''}
                    loading={loading}
                    disabled={loading}
                >
                    {balance === 0 ? 'End Rental' : 'Pay Balance'}
                    <NextIcon />
                </Button>
            </AGForm>
        </div>
    );
}

export default CheckBalanceForm;
