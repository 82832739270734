import React from 'react';
import RatesCalendar from 'components/Dashboard/RatesCalendar/RatesCalendar';
import { RatesCalendarProvider } from 'contexts/RatesCalendarContext';
import { connect } from 'react-redux';
import { selectedSpotId } from 'selectors';
import { AppState } from 'reducers/reducers';
import { ExperimentCalendarProvider } from 'contexts/ExperimentsCalendarContext';

type RatesCalendarModuleContainerProps = ReturnType<typeof mapStateToProps>;

function RatesCalendarModuleContainer({ selectedSpot }: RatesCalendarModuleContainerProps) {
    return (
        <RatesCalendarProvider>
            <ExperimentCalendarProvider>
                <RatesCalendar selectedSpot={selectedSpot} />
            </ExperimentCalendarProvider>
        </RatesCalendarProvider>
    );
}

function mapStateToProps(state: AppState) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedSpot = selectedSpotId(state)!;
    return {
        selectedSpot,
    };
}

export default connect(mapStateToProps)(RatesCalendarModuleContainer);
