export type Day = 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su';
export const days: Day[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export type Month = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec';
const months: Month[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export type MonthYear = { month: number; year: number };

export function getDayName(date: Date): Day {
    const day = date.getDay();
    return days[day];
}

export function getMonthName(mIndex: number): Month {
    return months[mIndex];
}

export function isTheSameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export function isBefore(d1: Date, d2: Date, including = false): boolean {
    if (isTheSameDay(d1, d2)) return including;
    return d1.getTime() < d2.getTime();
}

export function isAfter(d1: Date, d2: Date, including = false): boolean {
    if (isTheSameDay(d1, d2)) return including;
    return d1.getTime() > d2.getTime();
}

export function isInBetweenIncluding(d: Date, start: Date, end: Date) {
    return isAfter(d, start, true) && isBefore(d, end, true);
}

export function isToday(date: Date): boolean {
    return isTheSameDay(new Date(), date);
}

export function daysAfterDate(d: Date, days: number): Date {
    const newDate = new Date(d.getTime());
    const newDay = d.getDate() + days;
    newDate.setDate(newDay);
    return newDate;
}

export function getMonthGrid(year: number, month: number): number[][] {
    const rows = [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
    ];
    const firstDayStr = getDayName(new Date(year, month));
    const lastDayInMonth = new Date(year, month + 1, 0).getDate();

    let iRow = 0;
    let iCol = 0;
    let start = false;
    let cpt = 0;
    for (iRow = 0; iRow < 6; iRow++) {
        if (iRow >= rows.length && cpt <= lastDayInMonth) {
            rows.push([0, 0, 0, 0, 0, 0, 0]);
        }
        for (const dayStr of days) {
            if (cpt > lastDayInMonth) {
                continue;
            }
            if (!start && dayStr === firstDayStr) {
                cpt++;
                start = true;
            }
            rows[iRow][iCol] = cpt;
            iCol++;
            if (start) {
                cpt++;
            }
        }
        iCol = 0;
    }
    return rows;
}

export function getNextMonthYear(my: MonthYear): MonthYear {
    const newMonthYear: MonthYear = { ...my };
    if (my.month === 11) {
        newMonthYear.year++;
        newMonthYear.month = 0;
    } else {
        newMonthYear.month++;
    }
    return newMonthYear;
}

export function getPreviousMonthYear(my: MonthYear): MonthYear {
    const newMonthYear: MonthYear = { ...my };
    if (my.month === 0) {
        newMonthYear.year--;
        newMonthYear.month = 11;
    } else {
        newMonthYear.month--;
    }
    return newMonthYear;
}

export function convertToMonthYear(d: Date): MonthYear {
    return { month: d.getMonth(), year: d.getFullYear() };
}

export function XDaysAgo(d: Date, days: number) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - days);
}
