import React, { useEffect, useRef, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ChartData,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Popup, Icon, Label } from 'semantic-ui-react';
import { createChartGradient, DataTypeOptions, getLabel, lineChartOptions } from './utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type LineGraphProps = {
    title?: string;
    description?: string;
    xData: string[];
    yData: number[];
    dataLabel: string;
    dataType?: DataTypeOptions;
};

const LineGraph = (props: LineGraphProps) => {
    const { title, description, xData, yData, dataLabel, dataType } = props;

    const chartRef = useRef<ChartJS<'line'>>(null);
    const [chartData, setChartData] = useState<ChartData<'line'>>({
        datasets: [],
    });

    useEffect(() => {
        const chart = chartRef.current;
        let chartData = {
            labels: xData,
            datasets: [
                {
                    label: dataLabel,
                    data: yData,
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderWidth: 4,
                    pointBackgroundColor: 'rgba(0,0,0,0)',
                    pointRadius: 1,
                    pointHoverBorderColor: '#fff',
                    pointHoverBorderWidth: 4,
                    pointHoverRadius: 12,
                    pointHitRadius: 10,
                },
            ],
        };

        if (chart) {
            chartData = {
                ...chartData,
                datasets: chartData.datasets.map((dataset) => ({
                    ...dataset,
                    borderColor: createChartGradient(chart.ctx),
                    pointHoverBackgroundColor: createChartGradient(chart.ctx),
                })),
            };
        }
        setChartData(chartData);
    }, [xData, yData, dataLabel]);

    return (
        <>
            {title && (
                <h4 style={{ marginTop: 0 }}>
                    {title}
                    {yData.length > 0 && (
                        <Label
                            style={{ marginLeft: 5 }}
                            content={getLabel(yData, dataType)}
                            color="green"
                            size="tiny"
                        />
                    )}
                </h4>
            )}

            {description && (
                <Popup trigger={<Icon name="info circle" />} content={description} on="click" pinned inverted />
            )}

            <Line ref={chartRef} data={chartData} options={lineChartOptions} />
        </>
    );
};

export default LineGraph;
