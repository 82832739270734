import React from 'react';
import env from 'env';
import { EFEKTA_ORG_PK, SlotState } from 'utils/constants';
import { useMonthlyRentals } from 'contexts/MonthlyRentersContext';
import { SlotInterface, SpotInterface } from 'types';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import { AddSlotParams } from 'clients/SlotClient';
import SearchInput from 'components/AGForm/Inputs/SearchInput/SearchInput';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Modal, { ModalRef } from 'components/Modal/Modal';
import EditRenterForm, { Changes } from 'components/RenterList/EditRenterForm';
import AddRenterForm from 'components/RenterList/AddRenterForm';
import EmailAndCheckIcon from 'components/Icons/EmailAndCheckIcon';
import AGMessage from 'components/AGMessage/AGMessage';
import DeleteRenterForm from 'components/RenterList/DeleteRenterForm';
import MonthlyRentalsTable from './MonthlyRentalsTable';
import styles from './MonthlyRentals.module.css';

type MonthlyRentalsProps = {
    selectedSpot: SpotInterface;
    organizationId: number;
    filters: {
        page: string;
        searchTerm: string;
        slotState: SlotState;
        currentSearchTerm: string;
    };
    urlUpdater: {
        page: (page: string | null) => void;
        searchTerm: (search: string) => void;
        slotState: (state: SlotState) => void;
    };
};

function MonthlyRentals({ filters, urlUpdater, selectedSpot, organizationId }: MonthlyRentalsProps) {
    const [rentalBeingEdited, setRentalBeingEdited] = React.useState<SlotInterface | null>(null);
    const [rentalBeingDeleted, setRentalBeingDeleted] = React.useState<SlotInterface | null>(null);

    const editRentalModal = React.useRef<ModalRef>();
    const addRentalModal = React.useRef<ModalRef>();
    const addSuccessModal = React.useRef<ModalRef>();
    const deleteRentalModal = React.useRef<ModalRef>();

    const {
        monthlyRentals,
        loading,
        errorMessage,
        rentalOperationErrorMessage,
        rentalOperationLoading,
        getMonthlyRentals,
        editMonthlyRental,
        addMonthlyRental,
        deleteMonthlyRental,
    } = useMonthlyRentals();

    React.useEffect(() => {
        getMonthlyRentals(selectedSpot.pk, {
            page: Number(filters.page),
            search: filters.searchTerm,
            slotState: filters.slotState,
        });
    }, [filters.slotState, filters.page, filters.searchTerm, getMonthlyRentals, selectedSpot]);

    function handleSlotStateChange(slotState: SlotState): void {
        urlUpdater.slotState(slotState);
        urlUpdater.page(null);
    }

    function searchRentals(event: React.ChangeEvent<HTMLInputElement>) {
        urlUpdater.searchTerm(event.target.value);
    }

    function authorizationFunction() {
        let roles = [Roles.Superuser, Roles.Admin, Roles.Employee];
        if (env.NODE_ENV === 'production' && organizationId !== EFEKTA_ORG_PK) {
            roles = [];
        }
        return roles;
    }

    async function onAddRental(rental: AddSlotParams) {
        await addMonthlyRental(rental, selectedSpot, {
            page: Number(filters.page),
            search: filters.searchTerm,
            slotState: filters.slotState || SlotState.Active,
        });
        addRentalModal.current?.closeModal();
        if (!rentalOperationErrorMessage) addSuccessModal.current?.openModal();
    }

    function addAnotherDriver() {
        addSuccessModal.current?.closeModal();
        addRentalModal.current?.openModal();
    }

    async function onDeleteRental() {
        if (rentalBeingDeleted) {
            await deleteMonthlyRental(rentalBeingDeleted?.uuid, selectedSpot.pk, {
                page: Number(filters.page),
                search: filters.searchTerm,
                slotState: filters.slotState,
            });
            deleteRentalModal.current?.closeModal();
            setRentalBeingDeleted(null);
        }
    }

    function deleteRental(rental: SlotInterface) {
        setRentalBeingDeleted(rental);
        deleteRentalModal.current?.openModal();
    }

    async function onEditRental(changes: Changes) {
        if (rentalBeingEdited) {
            await editMonthlyRental(
                selectedSpot.pk,
                rentalBeingEdited,
                {
                    page: Number(filters.page),
                    search: filters.searchTerm,
                    slotState: filters.slotState,
                },
                changes
            );
            editRentalModal.current?.closeModal();
            setRentalBeingEdited(null);
        }
    }

    function editRental(rental: SlotInterface) {
        setRentalBeingEdited(rental);
        editRentalModal.current?.openModal();
    }
    return (
        <div style={{ width: '100%' }}>
            {errorMessage && <AGMessage color="error" title={errorMessage} />}
            {rentalOperationErrorMessage && <AGMessage color="error" title={rentalOperationErrorMessage} />}

            <div className={styles.options}>
                <div className={styles.filters}>
                    <SearchInput
                        onChange={searchRentals}
                        value={filters.currentSearchTerm}
                        placeholder="Search by plate, name, or phone"
                        autoFocus
                        type="text"
                    />
                    <AGSimpleSelect
                        selected={filters.slotState}
                        onSelect={(selection) => handleSlotStateChange(selection as SlotState)}
                        options={[
                            { value: SlotState.Active, label: 'Active' },
                            { value: SlotState.Upcoming, label: 'Upcoming' },
                        ]}
                        icon={<Lines />}
                    />
                </div>
                <AuthorizedView allowed={authorizationFunction}>
                    <Button
                        size="sm"
                        onClick={() => {
                            addRentalModal.current?.openModal();
                        }}
                        className={styles.primaryButton}
                    >
                        <PlusIcon />
                        Add Driver
                    </Button>
                </AuthorizedView>
            </div>
            {loading || !monthlyRentals ? (
                <Loader />
            ) : (
                <MonthlyRentalsTable
                    paginatedMonthlyRentals={monthlyRentals}
                    page={filters.page}
                    setPage={urlUpdater.page}
                    editRental={editRental}
                    deleteRental={deleteRental}
                />
            )}

            {/* Add rental modal */}
            <Modal ref={addRentalModal} title="Add a renter">
                <AddRenterForm addRenter={onAddRental} loading={rentalOperationLoading} />
            </Modal>

            {/* Add rental success modal */}
            <Modal ref={addSuccessModal} title={rentalOperationLoading ? '' : 'Driver added'}>
                {rentalOperationLoading ? (
                    <div className={styles.emailSentContainer}>
                        <Loader className={styles.loader} />
                    </div>
                ) : (
                    <>
                        <div className={styles.emailSentContainer}>
                            <EmailAndCheckIcon width={200} height={160} />
                            <p className={styles.emailSentText}>Email sent</p>
                        </div>
                        <Button type="button" onClick={addAnotherDriver}>
                            Add Another Driver
                        </Button>
                    </>
                )}
            </Modal>

            {/* Edit rental modal */}
            <Modal ref={editRentalModal} title="Update renter">
                {rentalBeingEdited && (
                    <EditRenterForm
                        rental={rentalBeingEdited}
                        updateRental={onEditRental}
                        loading={rentalOperationLoading}
                    />
                )}
            </Modal>

            {/* Delete rental modal */}
            <Modal
                ref={deleteRentalModal}
                title={`Remove ${rentalBeingDeleted ? rentalBeingDeleted.renter_email : ''}?`}
            >
                <DeleteRenterForm deleteRental={onDeleteRental} loading={rentalOperationLoading} />
            </Modal>
        </div>
    );
}

export default MonthlyRentals;
