import React from 'react';

const BarsIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M0.666992 6.26699H4.66699V19.3337H0.666992V6.26699ZM8.13366 0.666992H11.867V19.3337H8.13366V0.666992ZM15.6003 11.3337H19.3337V19.3337H15.6003V11.3337Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default BarsIcon;
