import React, { InputHTMLAttributes } from 'react';
import styles from './Toggle.module.css';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

function Toggle({ label, disabled, className, ...props }: ToggleProps): JSX.Element {
    return (
        <div className={`${styles.toggleWrapper} ${disabled ? styles.disabled : ''} ${className}`}>
            <input type="checkbox" {...props} />
            {!!label && (
                <label className="default-toggle-label" htmlFor={props.id}>
                    {label}
                </label>
            )}
        </div>
    );
}

export default Toggle;
