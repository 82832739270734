import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { EXPERIMENT_CAL_REQUESTED, EXPERIMENT_CAL_SUCCESS, EXPERIMENT_CAL_FAILED } from './constants';
import experimentCalendarReducer, { ExperimentsCalendarState, initialExperimentsCalendarState } from './reducer';
import useReducerWithMiddleware from 'contexts/useReducerWithMiddleware';
import unauthorized from 'contexts/unauthorized';
import { experimentsCalendarClient } from 'clients';

interface ExperimentCalendarActions {
    getExperiment: (spotPk: number, start: number, end: number) => void;
}

interface ExperimentCalendarContextType extends ExperimentCalendarActions, ExperimentsCalendarState {}

export const ExperimentCalendarContext = createContext<ExperimentCalendarContextType | null>(null);

export const ExperimentCalendarProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        experimentCalendarReducer,
        { ...initialExperimentsCalendarState },
        [],
        [unauthorized]
    );

    const getExperiment = useCallback(
        async (spotPk: number, start: number, end: number) => {
            dispatch({ type: EXPERIMENT_CAL_REQUESTED });
            try {
                const experiments = await experimentsCalendarClient.getExperiments(spotPk, start, end);
                dispatch({ type: EXPERIMENT_CAL_SUCCESS, payload: { experiments } });
            } catch (error) {
                dispatch({
                    type: EXPERIMENT_CAL_FAILED,
                    payload: { error },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<ExperimentCalendarContextType>(() => {
        return {
            ...state,
            getExperiment,
        };
    }, [state, getExperiment]);

    return <ExperimentCalendarContext.Provider value={value}>{children}</ExperimentCalendarContext.Provider>;
};

export const useExperimentCalendar = () => {
    const context = useContext(ExperimentCalendarContext);
    if (!context) {
        throw new Error('Error: useExperimentCalendar should be wrapped by ExperimentCalendarProvider.');
    }
    return context;
};
