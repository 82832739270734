import React, { useEffect } from 'react';
import { Table, Loader, Popup } from 'semantic-ui-react';
import { DailyReportInterface } from 'types';
import { MonthYear } from 'utils/monthYear';

type ReportsModuleProps = {
    selectedDailyReports: DailyReportInterface | null;
    filters: {
        monthYear: MonthYear['value'];
    };
};

const ReportsModule = (props: ReportsModuleProps) => {
    const {
        selectedDailyReports,
        filters: { monthYear },
    } = props;
    const [showTaxesColumn, setShowTaxesColumn] = React.useState(false);
    const total = selectedDailyReports && selectedDailyReports.total;

    useEffect(() => {
        if (!selectedDailyReports) {
            return;
        }
        const [month, year] = monthYear.split(':');
        if (month && year) {
            // We only want to show the taxes column for reports after May 1, 2023 12 am UTC
            const cutOffDate = new Date('2023-05-01T00:00:00Z'); // May 1, 12 am UTC
            const selectedDate = new Date(`${year}-${month}-01T00:00:00Z`);

            if (selectedDailyReports.total.tax_amount > 0 && selectedDate >= cutOffDate) {
                // If the total taxes amount is greater than 0, and the selected date is
                // after the cut off date, show the taxes column.
                setShowTaxesColumn(true);
            }
        }
    }, [monthYear, selectedDailyReports]);

    return (
        <>
            {selectedDailyReports ? (
                <Table basic="very" selectable striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Count of Rentals</Table.HeaderCell>
                            <Popup
                                trigger={<Table.HeaderCell content="Gross Revenue" />}
                                content={
                                    'This is the topline booking value before refunds and AirGarage commission are accounted for.'
                                }
                                on="hover"
                                pinned
                                inverted
                                position="top left"
                            />
                            <Popup
                                trigger={<Table.HeaderCell content="Count of Refunds" />}
                                content={
                                    'Refunds are issued to maintain a high bar for quality service. Refunds can be given for customer support issues or cancelled rentals.'
                                }
                                on="hover"
                                pinned
                                inverted
                                position="top left"
                            />
                            <Table.HeaderCell>Refund Amount</Table.HeaderCell>
                            {showTaxesColumn && (
                                <Popup
                                    trigger={<Table.HeaderCell content="Tax Amount" />}
                                    content={`This is the value of taxes collected from Adjusted Revenue.`}
                                    on="hover"
                                    pinned
                                    inverted
                                    position="top right"
                                />
                            )}
                            <Popup
                                trigger={<Table.HeaderCell content="Adjusted Revenue" />}
                                content={'This value is gross revenue minus the refunded amount.'}
                                on="hover"
                                pinned
                                inverted
                                position="top left"
                            />
                            <Popup
                                trigger={<Table.HeaderCell content="AirGarage Commission" />}
                                content={`This is the value of AirGarage's take from Adjusted Revenue minus Tax Amount, which includes payment processing.`}
                                on="hover"
                                pinned
                                inverted
                                position="top left"
                            />
                            <Popup
                                trigger={<Table.HeaderCell content="Net Revenue" />}
                                content={`This is the value of your take from Adjusted Revenue.`}
                                on="hover"
                                pinned
                                inverted
                                position="top right"
                            />
                        </Table.Row>
                    </Table.Header>
                    {selectedDailyReports.daily_reports.map((report) => {
                        return (
                            <Table.Body key={`report_${report.date}`}>
                                <Table.Row>
                                    <Table.Cell>{report.date}</Table.Cell>
                                    <Table.Cell>{report.count_of_rentals}</Table.Cell>
                                    <Table.Cell>{report.gross_revenue_formatted}</Table.Cell>
                                    <Table.Cell>{report.count_of_refunds}</Table.Cell>
                                    <Table.Cell>{report.refund_amount_formatted}</Table.Cell>
                                    {showTaxesColumn && <Table.Cell>{report.tax_amount_formatted}</Table.Cell>}
                                    <Table.Cell>{report.adjusted_revenue_formatted}</Table.Cell>
                                    <Table.Cell>{report.contribution_amount_exclude_tax_formatted}</Table.Cell>
                                    <Table.Cell textAlign="right">{report.net_revenue_formatted}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        );
                    })}
                    {total && (
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell>{total.date}</Table.HeaderCell>
                                <Table.HeaderCell>{total.count_of_rentals}</Table.HeaderCell>
                                <Table.HeaderCell>{total.gross_revenue_formatted}</Table.HeaderCell>
                                <Table.HeaderCell>{total.count_of_refunds}</Table.HeaderCell>
                                <Table.HeaderCell>{total.refund_amount_formatted}</Table.HeaderCell>
                                {showTaxesColumn && <Table.HeaderCell>{total.tax_amount_formatted}</Table.HeaderCell>}
                                <Table.HeaderCell>{total.adjusted_revenue_formatted}</Table.HeaderCell>
                                <Table.HeaderCell>{total.contribution_amount_exclude_tax_formatted}</Table.HeaderCell>
                                <Table.HeaderCell textAlign="right">{total.net_revenue_formatted}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    )}
                </Table>
            ) : (
                <Loader active inline />
            )}
        </>
    );
};

export default ReportsModule;
