import React from 'react';
import { Card, Divider } from 'semantic-ui-react';
import styles from './DashboardContent.module.css';

type DashboardContentProps = {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
};

const DashboardContent = ({ title, subtitle, children }: DashboardContentProps) => (
    <div className={styles.contentView}>
        <Card fluid>
            <Card.Content>
                <Card.Header>
                    <div style={{ paddingBlock: '20px' }}>
                        <span style={{ fontSize: '36px', fontWeight: 500 }}>{title}</span>
                        {subtitle ? (
                            <>
                                <span style={{ fontSize: '20px', marginLeft: '1em' }}>{subtitle}</span>
                            </>
                        ) : (
                            <Divider hidden />
                        )}
                    </div>
                </Card.Header>
                <div>{children}</div>
            </Card.Content>
        </Card>
    </div>
);

export default DashboardContent;
