import React from 'react';

const PersonIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M10 3C11.2834 3 12.3334 4.05 12.3334 5.33333C12.3334 6.61666 11.2834 7.66666 10 7.66666C8.71669 7.66666 7.66669 6.61666 7.66669 5.33333C7.66669 4.05 8.71669 3 10 3ZM10 14.6667C13.15 14.6667 16.7667 16.1717 17 17H3.00002C3.26835 16.16 6.86169 14.6667 10 14.6667ZM10 0.666664C7.42169 0.666664 5.33335 2.755 5.33335 5.33333C5.33335 7.91166 7.42169 10 10 10C12.5784 10 14.6667 7.91166 14.6667 5.33333C14.6667 2.755 12.5784 0.666664 10 0.666664ZM10 12.3333C6.88502 12.3333 0.666687 13.8967 0.666687 17V19.3333H19.3334V17C19.3334 13.8967 13.115 12.3333 10 12.3333Z"
                fill={color || '#333333'}
            />
        </svg>
    );
};

export default PersonIcon;
