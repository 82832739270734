import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import CarIcon from 'components/Icons/CarIcon';
import LastUpdatedDate from 'components/widgets/LastUpdatedDate';
import WidgetBox from 'components/widgets/WidgetBox';
import AGMessage from 'components/AGMessage/AGMessage';
import { useOccupancyReport } from 'contexts/OccupancyReportContext';
import { SpotInterface } from 'types';
import HeatMapGraph, { HeatMapGraphProps } from './HeatMap';
import styles from './LotDemandWidget.module.css';
import { getDailyDataForHeatmap, getMonthlyDataForHeatmap } from './utils';

type LotDemandWidgetProps = {
    currentSpot: SpotInterface;
};

const WIDGET_TITLE = 'Peak Rental Times';
type IntervalOptions = 'monthly' | 'daily';

function LotDemandWidget({ currentSpot }: LotDemandWidgetProps): JSX.Element {
    const [chartData, setChartData] = useState<HeatMapGraphProps | null>(null);
    const [selectedInterval, setSelectedInterval] = useState<IntervalOptions>('daily');
    const {
        getMonthlyOccupancyReports,
        getDailyOccupancyReports,
        monthly,
        daily,
        loadingMonthly,
        loadingDaily,
        errorMessageMonthly,
        errorMessageDaily,
    } = useOccupancyReport();
    const [loading, setLoading] = useState(loadingMonthly);
    const [errorMessage, setErrorMessage] = useState(errorMessageMonthly);

    useEffect(() => {
        if (selectedInterval === 'monthly' && monthly)
            setChartData({ ...getMonthlyDataForHeatmap(monthly), yLabelsStyle: { width: '30px' } });
        if (selectedInterval === 'daily' && daily)
            setChartData({ ...getDailyDataForHeatmap(daily), yLabelsStyle: { width: '62px' } });
    }, [selectedInterval, monthly, daily]);

    useEffect(() => {
        if (selectedInterval === 'monthly') {
            setLoading(loadingMonthly);
            setErrorMessage(errorMessageMonthly);
        }
        if (selectedInterval === 'daily') {
            setLoading(loadingDaily);
            setErrorMessage(errorMessageDaily);
        }
    }, [selectedInterval, loadingDaily, loadingMonthly, errorMessageDaily, errorMessageMonthly]);

    useEffect(() => {
        if (currentSpot) {
            getDailyOccupancyReports(currentSpot.pk);
            getMonthlyOccupancyReports(currentSpot.pk);
        }
    }, [currentSpot, getMonthlyOccupancyReports, getDailyOccupancyReports]);

    if (loading) {
        return (
            <WidgetBox title={WIDGET_TITLE} icon={<CarIcon width={21} />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    function handleChange(e: React.ChangeEvent) {
        const newValue = (e.target as HTMLSelectElement).value;
        setSelectedInterval(newValue as IntervalOptions);
    }

    return (
        <WidgetBox
            title={WIDGET_TITLE}
            icon={<CarIcon width={21} />}
            dropdown={
                monthly && daily ? (
                    <select onChange={handleChange} value={selectedInterval}>
                        <option value={'daily'}>Daily</option>
                        <option value={'monthly'}>Monthly</option>
                    </select>
                ) : undefined
            }
        >
            <>
                {!!errorMessage && <AGMessage color="error">{errorMessage}</AGMessage>}
                {chartData ? (
                    <>
                        <p className={styles.detail}>
                            {chartData.maxValue.copy}, with an average of <b>{chartData.maxValue.value} rentals</b>
                        </p>
                        <HeatMapGraph {...chartData} />
                        <LastUpdatedDate lastUpdatedAt={chartData.lastUpdatedAt} />
                    </>
                ) : (
                    <div className={styles.emptyStateMessage}>
                        <p>Your seasonal occupancy chart will show up here</p>
                        <p>Seasonal occupancy will appear after gathering sufficient data</p>
                    </div>
                )}
            </>
        </WidgetBox>
    );
}

export default LotDemandWidget;
