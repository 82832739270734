import React, { useMemo, useContext, createContext, useCallback } from 'react';

import { timelineClient } from 'clients';
import unauthorized from '../unauthorized';
import {
    TIMELINE_REQUESTED,
    TIMELINE_FAILED,
    TIMELINE_SUCCESS,
    FILTER_TIMELINE_REQUESTED,
    FILTER_TIMELINE_SUCCESS,
    FILTER_TIMELINE_FAILED,
} from './constants';
import lotTimelineReducer, { initialLotTimelineState, LotTimelineState } from './reducer';
import useReducerWithMiddleware from '../useReducerWithMiddleware';

type LotTimelineContextType = LotTimelineState & {
    getLotTimeline: (spotUuid: string, offset?: string) => Promise<void>;
    getFilteredLotTimeline: (spotUuid: string, offset?: string) => Promise<void>;
};

export const LotTimelineContext = createContext<LotTimelineContextType | null>(null);

export const LotTimelineContextProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        lotTimelineReducer,
        {
            ...initialLotTimelineState,
        },
        [],
        [unauthorized]
    );

    const getLotTimeline = useCallback(
        async function getLotTimelineContextAction(spotUuid: string, offset?: string): Promise<void> {
            dispatch({ type: TIMELINE_REQUESTED, payload: { offset } });
            try {
                const response = await timelineClient.getLotTimelineEvents(spotUuid, offset);
                dispatch({
                    type: TIMELINE_SUCCESS,
                    payload: response,
                });
            } catch (error) {
                dispatch({
                    type: TIMELINE_FAILED,
                    payload: {
                        error,
                        message: error?.toString() || 'Could not fetch timeline events',
                    },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getFilteredLotTimeline = useCallback(
        async function getLotTimelineContextAction(spotUuid: string, offset?: string): Promise<void> {
            dispatch({ type: FILTER_TIMELINE_REQUESTED, payload: { offset } });
            try {
                const response = await timelineClient.getLotTimelineEvents(spotUuid, offset);
                dispatch({
                    type: FILTER_TIMELINE_SUCCESS,
                    payload: response,
                });
            } catch (error) {
                dispatch({
                    type: FILTER_TIMELINE_FAILED,
                    payload: {
                        error,
                        message: error?.toString() || 'Could not fetch timeline events',
                    },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo(() => {
        return {
            ...state,
            getLotTimeline,
            getFilteredLotTimeline,
        };
    }, [state, getLotTimeline, getFilteredLotTimeline]);

    return <LotTimelineContext.Provider value={value}>{children}</LotTimelineContext.Provider>;
};

export function useLotTimeline() {
    const context = useContext(LotTimelineContext);
    if (!context) {
        throw new Error('Error: useLotTimeline should be wrapped by LotTimelineContextProvider.');
    }
    return context;
}
