type TimelineEntryBase = {
    timestamp: string; // e.g. 2023-12-06T23:59:59
    event: string;
    details: Record<string, unknown>;
};

function isTimelineEntry(x: unknown): x is TimelineEntryBase {
    return typeof x === 'object' && x !== null && 'timestamp' in x && 'event' in x && 'details' in x;
}

export type DailySummaryEntry = TimelineEntryBase & {
    event: 'revenue_daily_summary';
    details: {
        gmv: string;
        income: string;
        total_rentals: number;
        last_updated_at: string;
    };
};

export function isDailySummary(x: unknown): x is DailySummaryEntry {
    return isTimelineEntry(x) && x.event === 'revenue_daily_summary';
}

export type EnforcementSummaryEntry = TimelineEntryBase & {
    event: 'enforcement_daily_summary';
    details: {
        autostarted_revenue: string;
        autostarted_count: number;
        tickets: number;
        immobilized: number;
        total_penalties: number;
        last_updated_at?: string | null;
        images: string[];
    };
};

export function isEnforcementEntry(x: unknown): x is EnforcementSummaryEntry {
    return isTimelineEntry(x) && x.event === 'enforcement_daily_summary';
}

type Images = string[]; // urls

export type SnapshotEntry = TimelineEntryBase & {
    details: {
        images: Images;
    };
};

export function isSnapshotEntry(x: unknown): x is SnapshotEntry {
    return isTimelineEntry(x) && x.event === 'snapshot';
}

export type RegularMaintenanceEntry = TimelineEntryBase & {
    details: {
        images: Images;
        title: string;
        description: string;
    };
};

export function isRegularMaintenanceEntry(x: unknown): x is RegularMaintenanceEntry {
    return isTimelineEntry(x) && x.event === 'regular_maintenance';
}

export type TimelineEntry = DailySummaryEntry | EnforcementSummaryEntry | RegularMaintenanceEntry | SnapshotEntry;
