// States //
export interface State {
    name: StateName;
    abbreviation: StateAbbreviation;
}

export enum StateAbbreviation {
    // US
    Alabama = 'AL',
    Alaska = 'AK',
    AmericanSamoa = 'AS',
    Arizona = 'AZ',
    Arkansas = 'AR',
    California = 'CA',
    Colorado = 'CO',
    Connecticut = 'CT',
    Delaware = 'DE',
    DC = 'DC',
    Florida = 'FL',
    Georgia = 'GA',
    Guam = 'GU',
    Hawaii = 'HI',
    Idaho = 'ID',
    Illinois = 'IL',
    Indiana = 'IN',
    Iowa = 'IA',
    Kansas = 'KS',
    Kentucky = 'KY',
    Louisiana = 'LA',
    Maine = 'ME',
    Maryland = 'MD',
    Massachusetts = 'MA',
    Michigan = 'MI',
    Minnesota = 'MN',
    Mississippi = 'MS',
    Missouri = 'MO',
    Montana = 'MT',
    Nebraska = 'NE',
    Nevada = 'NV',
    NewHampshire = 'NH',
    NewJersey = 'NJ',
    NewMexico = 'NM',
    NewYork = 'NY',
    NorthCarolina = 'NC',
    NorthDakota = 'ND',
    NorthernMarianaIslands = 'MP',
    Ohio = 'OH',
    Oklahoma = 'OK',
    Oregon = 'OR',
    Pennsylvania = 'PA',
    PuertoRico = 'PR',
    RhodeIsland = 'RI',
    SouthCarolina = 'SC',
    SouthDakota = 'SD',
    Tennessee = 'TN',
    Texas = 'TX',
    Utah = 'UT',
    Vermont = 'VT',
    VirginIslands = 'VI',
    Virginia = 'VA',
    Washington = 'WA',
    WestVirginia = 'WV',
    Wisconsin = 'WI',
    Wyoming = 'WY',
    USGovernment = 'GV',
    // Mexico
    Aguascalientes = 'AG',
    BajaCalifornia = 'BC',
    BajaCaliforniaSur = 'BS',
    Campeche = 'CM',
    Chiapas = 'CS',
    Chihuahua = 'CH',
    Coahuila = 'CO',
    Colima = 'CL',
    FederalDistrict = 'DF',
    Durango = 'DG',
    Guanajuato = 'GT',
    Guerrero = 'GR',
    Hidalgo = 'HG',
    Jalisco = 'JA',
    Mexico = 'EM',
    Michoacan = 'MI',
    Morelos = 'MO',
    Nayarit = 'NA',
    NuevoLeon = 'NL',
    Oaxaca = 'OA',
    Puebla = 'PU',
    Queretaro = 'QT',
    QuintanaRoo = 'QR',
    SanLuisPotosi = 'SL',
    Sinaloa = 'SI',
    Sonora = 'SO',
    Tabasco = 'TB',
    Tamaulipas = 'TM',
    Tlaxcala = 'TL',
    Veracruz = 'VE',
    Yucatan = 'YU',
    Zacatecas = 'ZA',
    // Canada
    Alberta = 'AB',
    BritishColumbia = 'BC',
    Manitoba = 'MB',
    NewBrunswick = 'NB',
    NewfoundlandAndLabrador = 'NL',
    NovaScotia = 'NS',
    Nunavut = 'NU',
    NorthwestTerritories = 'NT',
    Ontario = 'ON',
    PrinceEdwardIsland = 'PE',
    Quebec = 'QC',
    Saskatchewan = 'SK',
    Yukon = 'YT',
}

export enum StateName {
    // US
    Alabama = 'Alabama',
    Alaska = 'Alaska',
    AmericanSamoa = 'American Samoa',
    Arizona = 'Arizona',
    Arkansas = 'Arkansas',
    California = 'California',
    Colorado = 'Colorado',
    Connecticut = 'Connecticut',
    Delaware = 'Delaware',
    DC = 'District Of Columbia',
    Florida = 'Florida',
    Georgia = 'Georgia',
    Guam = 'Guam',
    Hawaii = 'Hawaii',
    Idaho = 'Idaho',
    Illinois = 'Illinois',
    Indiana = 'Indiana',
    Iowa = 'Iowa',
    Kansas = 'Kansas',
    Kentucky = 'Kentucky',
    Louisiana = 'Louisiana',
    Maine = 'Maine',
    Maryland = 'Maryland',
    Massachusetts = 'Massachusetts',
    Michigan = 'Michigan',
    Minnesota = 'Minnesota',
    Mississippi = 'Mississippi',
    Missouri = 'Missouri',
    Montana = 'Montana',
    Nebraska = 'Nebraska',
    Nevada = 'Nevada',
    NewHampshire = 'New Hampshire',
    NewJersey = 'New Jersey',
    NewMexico = 'New Mexico',
    NewYork = 'New York',
    NorthCarolina = 'North Carolina',
    NorthDakota = 'North Dakota',
    NorthernMarianaIslands = 'Northern Mariana Islands',
    Ohio = 'Ohio',
    Oklahoma = 'Oklahoma',
    Oregon = 'Oregon',
    Pennsylvania = 'Pennsylvania',
    PuertoRico = 'Puerto Rico',
    RhodeIsland = 'Rhode Island',
    SouthCarolina = 'South Carolina',
    SouthDakota = 'South Dakota',
    Tennessee = 'Tennessee',
    Texas = 'Texas',
    Utah = 'Utah',
    Vermont = 'Vermont',
    VirginIslands = 'Virgin Islands',
    Virginia = 'Virginia',
    Washington = 'Washington',
    WestVirginia = 'West Virginia',
    Wisconsin = 'Wisconsin',
    Wyoming = 'Wyoming',
    USGovernment = 'US Government',
    // Mexico
    Aguascalientes = 'Aguascalientes',
    BajaCalifornia = 'Baja California',
    BajaCaliforniaSur = 'Baja California Sur',
    Campeche = 'Campeche',
    Chiapas = 'Chiapas',
    Chihuahua = 'Chihuahua',
    Coahuila = 'Coahuila',
    Colima = 'Colima',
    FederalDistrict = 'Federal District',
    Durango = 'Durango',
    Guanajuato = 'Guanajuato',
    Guerrero = 'Guerrero',
    Hidalgo = 'Hidalgo',
    Jalisco = 'Jalisco',
    Mexico = 'Mexico',
    Michoacan = 'Michoacán',
    Morelos = 'Morelos',
    Nayarit = 'Nayarit',
    NuevoLeon = 'Nuevo León',
    Oaxaca = 'Oaxaca',
    Puebla = 'Puebla',
    Queretaro = 'Querétaro',
    QuintanaRoo = 'Quintana Roo',
    SanLuisPotosi = 'San Luis Potosí',
    Sinaloa = 'Sinaloa',
    Sonora = 'Sonora',
    Tabasco = 'Tabasco',
    Tamaulipas = 'Tamaulipas',
    Tlaxcala = 'Tlaxcala',
    Veracruz = 'Veracruz',
    Yucatan = 'Yucatán',
    Zacatecas = 'Zacatecas',
    // Canada
    Alberta = 'Alberta',
    BritishColumbia = 'British Columbia',
    Manitoba = 'Manitoba',
    NewBrunswick = 'New Brunswick',
    NewfoundlandAndLabrador = 'Newfoundland and Labrador',
    NovaScotia = 'Nova Scotia',
    Nunavut = 'Nunavut',
    NorthwestTerritories = 'Northwest Territories',
    Ontario = 'Ontario',
    PrinceEdwardIsland = 'Prince Edward Island',
    Quebec = 'Quebec',
    Saskatchewan = 'Saskatchewan',
    Yukon = 'Yukon',
}

export const usaStates: State[] = [
    {
        name: StateName.Alabama,
        abbreviation: StateAbbreviation.Alabama,
    },
    {
        name: StateName.Alaska,
        abbreviation: StateAbbreviation.Alaska,
    },
    {
        name: StateName.AmericanSamoa,
        abbreviation: StateAbbreviation.AmericanSamoa,
    },
    {
        name: StateName.Arizona,
        abbreviation: StateAbbreviation.Arizona,
    },
    {
        name: StateName.Arkansas,
        abbreviation: StateAbbreviation.Arkansas,
    },
    {
        name: StateName.California,
        abbreviation: StateAbbreviation.California,
    },
    {
        name: StateName.Colorado,
        abbreviation: StateAbbreviation.Colorado,
    },
    {
        name: StateName.Connecticut,
        abbreviation: StateAbbreviation.Connecticut,
    },
    {
        name: StateName.Delaware,
        abbreviation: StateAbbreviation.Delaware,
    },
    {
        name: StateName.DC,
        abbreviation: StateAbbreviation.DC,
    },
    {
        name: StateName.Florida,
        abbreviation: StateAbbreviation.Florida,
    },
    {
        name: StateName.Georgia,
        abbreviation: StateAbbreviation.Georgia,
    },
    {
        name: StateName.Guam,
        abbreviation: StateAbbreviation.Guam,
    },
    {
        name: StateName.Hawaii,
        abbreviation: StateAbbreviation.Hawaii,
    },
    {
        name: StateName.Idaho,
        abbreviation: StateAbbreviation.Idaho,
    },
    {
        name: StateName.Illinois,
        abbreviation: StateAbbreviation.Illinois,
    },
    {
        name: StateName.Indiana,
        abbreviation: StateAbbreviation.Indiana,
    },
    {
        name: StateName.Iowa,
        abbreviation: StateAbbreviation.Iowa,
    },
    {
        name: StateName.Kansas,
        abbreviation: StateAbbreviation.Kansas,
    },
    {
        name: StateName.Kentucky,
        abbreviation: StateAbbreviation.Kentucky,
    },
    {
        name: StateName.Louisiana,
        abbreviation: StateAbbreviation.Louisiana,
    },
    {
        name: StateName.Maine,
        abbreviation: StateAbbreviation.Maine,
    },
    {
        name: StateName.Maryland,
        abbreviation: StateAbbreviation.Maryland,
    },
    {
        name: StateName.Massachusetts,
        abbreviation: StateAbbreviation.Massachusetts,
    },
    {
        name: StateName.Michigan,
        abbreviation: StateAbbreviation.Michigan,
    },
    {
        name: StateName.Minnesota,
        abbreviation: StateAbbreviation.Minnesota,
    },
    {
        name: StateName.Mississippi,
        abbreviation: StateAbbreviation.Mississippi,
    },
    {
        name: StateName.Missouri,
        abbreviation: StateAbbreviation.Missouri,
    },
    {
        name: StateName.Montana,
        abbreviation: StateAbbreviation.Montana,
    },
    {
        name: StateName.Nebraska,
        abbreviation: StateAbbreviation.Nebraska,
    },
    {
        name: StateName.Nevada,
        abbreviation: StateAbbreviation.Nevada,
    },
    {
        name: StateName.NewHampshire,
        abbreviation: StateAbbreviation.NewHampshire,
    },
    {
        name: StateName.NewJersey,
        abbreviation: StateAbbreviation.NewJersey,
    },
    {
        name: StateName.NewMexico,
        abbreviation: StateAbbreviation.NewMexico,
    },
    {
        name: StateName.NewYork,
        abbreviation: StateAbbreviation.NewYork,
    },
    {
        name: StateName.NorthCarolina,
        abbreviation: StateAbbreviation.NorthCarolina,
    },
    {
        name: StateName.NorthDakota,
        abbreviation: StateAbbreviation.NorthDakota,
    },
    {
        name: StateName.NorthernMarianaIslands,
        abbreviation: StateAbbreviation.NorthernMarianaIslands,
    },
    {
        name: StateName.Ohio,
        abbreviation: StateAbbreviation.Ohio,
    },
    {
        name: StateName.Oklahoma,
        abbreviation: StateAbbreviation.Oklahoma,
    },
    {
        name: StateName.Oregon,
        abbreviation: StateAbbreviation.Oregon,
    },
    {
        name: StateName.Pennsylvania,
        abbreviation: StateAbbreviation.Pennsylvania,
    },
    {
        name: StateName.PuertoRico,
        abbreviation: StateAbbreviation.PuertoRico,
    },
    {
        name: StateName.RhodeIsland,
        abbreviation: StateAbbreviation.RhodeIsland,
    },
    {
        name: StateName.SouthCarolina,
        abbreviation: StateAbbreviation.SouthCarolina,
    },
    {
        name: StateName.SouthDakota,
        abbreviation: StateAbbreviation.SouthDakota,
    },
    {
        name: StateName.Tennessee,
        abbreviation: StateAbbreviation.Tennessee,
    },
    {
        name: StateName.Texas,
        abbreviation: StateAbbreviation.Texas,
    },
    {
        name: StateName.Utah,
        abbreviation: StateAbbreviation.Utah,
    },
    {
        name: StateName.Vermont,
        abbreviation: StateAbbreviation.Vermont,
    },
    {
        name: StateName.VirginIslands,
        abbreviation: StateAbbreviation.VirginIslands,
    },
    {
        name: StateName.Virginia,
        abbreviation: StateAbbreviation.Virginia,
    },
    {
        name: StateName.Washington,
        abbreviation: StateAbbreviation.Washington,
    },
    {
        name: StateName.WestVirginia,
        abbreviation: StateAbbreviation.WestVirginia,
    },
    {
        name: StateName.Wisconsin,
        abbreviation: StateAbbreviation.Wisconsin,
    },
    {
        name: StateName.Wyoming,
        abbreviation: StateAbbreviation.Wyoming,
    },
    {
        name: StateName.USGovernment,
        abbreviation: StateAbbreviation.USGovernment,
    },
];

export const mexicoStates: State[] = [
    {
        name: StateName.Aguascalientes,
        abbreviation: StateAbbreviation.Aguascalientes,
    },
    {
        name: StateName.BajaCalifornia,
        abbreviation: StateAbbreviation.BajaCalifornia,
    },
    {
        name: StateName.BajaCaliforniaSur,
        abbreviation: StateAbbreviation.BajaCaliforniaSur,
    },
    {
        name: StateName.Campeche,
        abbreviation: StateAbbreviation.Campeche,
    },
    {
        name: StateName.Chiapas,
        abbreviation: StateAbbreviation.Chiapas,
    },
    {
        name: StateName.Chihuahua,
        abbreviation: StateAbbreviation.Chihuahua,
    },
    {
        name: StateName.Coahuila,
        abbreviation: StateAbbreviation.Coahuila,
    },
    {
        name: StateName.Colima,
        abbreviation: StateAbbreviation.Colima,
    },
    {
        name: StateName.FederalDistrict,
        abbreviation: StateAbbreviation.FederalDistrict,
    },
    {
        name: StateName.Durango,
        abbreviation: StateAbbreviation.Durango,
    },
    {
        name: StateName.Guanajuato,
        abbreviation: StateAbbreviation.Guanajuato,
    },
    {
        name: StateName.Guerrero,
        abbreviation: StateAbbreviation.Guerrero,
    },
    {
        name: StateName.Hidalgo,
        abbreviation: StateAbbreviation.Hidalgo,
    },
    {
        name: StateName.Jalisco,
        abbreviation: StateAbbreviation.Jalisco,
    },
    {
        name: StateName.Mexico,
        abbreviation: StateAbbreviation.Mexico,
    },
    {
        name: StateName.Michoacan,
        abbreviation: StateAbbreviation.Michoacan,
    },
    {
        name: StateName.Morelos,
        abbreviation: StateAbbreviation.Morelos,
    },
    {
        name: StateName.Nayarit,
        abbreviation: StateAbbreviation.Nayarit,
    },
    {
        name: StateName.NuevoLeon,
        abbreviation: StateAbbreviation.NuevoLeon,
    },
    {
        name: StateName.Oaxaca,
        abbreviation: StateAbbreviation.Oaxaca,
    },
    {
        name: StateName.Puebla,
        abbreviation: StateAbbreviation.Puebla,
    },
    {
        name: StateName.Queretaro,
        abbreviation: StateAbbreviation.Queretaro,
    },
    {
        name: StateName.QuintanaRoo,
        abbreviation: StateAbbreviation.QuintanaRoo,
    },
    {
        name: StateName.SanLuisPotosi,
        abbreviation: StateAbbreviation.SanLuisPotosi,
    },
    {
        name: StateName.Sinaloa,
        abbreviation: StateAbbreviation.Sinaloa,
    },
    {
        name: StateName.Sonora,
        abbreviation: StateAbbreviation.Sonora,
    },
    {
        name: StateName.Tabasco,
        abbreviation: StateAbbreviation.Tabasco,
    },
    {
        name: StateName.Tamaulipas,
        abbreviation: StateAbbreviation.Tamaulipas,
    },
    {
        name: StateName.Tlaxcala,
        abbreviation: StateAbbreviation.Tlaxcala,
    },
    {
        name: StateName.Veracruz,
        abbreviation: StateAbbreviation.Veracruz,
    },
    {
        name: StateName.Yucatan,
        abbreviation: StateAbbreviation.Yucatan,
    },
    {
        name: StateName.Zacatecas,
        abbreviation: StateAbbreviation.Zacatecas,
    },
];

export const canadaStates: State[] = [
    {
        name: StateName.Alberta,
        abbreviation: StateAbbreviation.Alberta,
    },
    {
        name: StateName.BritishColumbia,
        abbreviation: StateAbbreviation.BritishColumbia,
    },
    {
        name: StateName.Manitoba,
        abbreviation: StateAbbreviation.Manitoba,
    },
    {
        name: StateName.NewBrunswick,
        abbreviation: StateAbbreviation.NewBrunswick,
    },
    {
        name: StateName.NewfoundlandAndLabrador,
        abbreviation: StateAbbreviation.NewfoundlandAndLabrador,
    },
    {
        name: StateName.NovaScotia,
        abbreviation: StateAbbreviation.NovaScotia,
    },
    {
        name: StateName.Nunavut,
        abbreviation: StateAbbreviation.Nunavut,
    },
    {
        name: StateName.NorthwestTerritories,
        abbreviation: StateAbbreviation.NorthwestTerritories,
    },
    {
        name: StateName.Ontario,
        abbreviation: StateAbbreviation.Ontario,
    },
    {
        name: StateName.PrinceEdwardIsland,
        abbreviation: StateAbbreviation.PrinceEdwardIsland,
    },
    {
        name: StateName.Quebec,
        abbreviation: StateAbbreviation.Quebec,
    },
    {
        name: StateName.Saskatchewan,
        abbreviation: StateAbbreviation.Saskatchewan,
    },
    {
        name: StateName.Yukon,
        abbreviation: StateAbbreviation.Yukon,
    },
];

// Countries //
export interface Country {
    name: CountryName;
    abbreviation: CountryAbbreviation;
}

export enum CountryName {
    UnitedStates = 'United States',
    Mexico = 'Mexico',
    Canada = 'Canada',
}

export enum CountryAbbreviation {
    UnitedStates = 'US',
    Mexico = 'MX',
    Canada = 'CA',
}

export const unitedStates: Country = {
    name: CountryName.UnitedStates,
    abbreviation: CountryAbbreviation.UnitedStates,
};

export const mexico: Country = {
    name: CountryName.Mexico,
    abbreviation: CountryAbbreviation.Mexico,
};

export const canada: Country = {
    name: CountryName.Canada,
    abbreviation: CountryAbbreviation.Canada,
};
