import React from 'react';

const LineUpIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M2.35449 12.7194L8.10449 6.95986L11.9378 10.7932L20.0837 1.63152L18.7324 0.280273L11.9378 7.91819L8.10449 4.08486L0.916992 11.2819L2.35449 12.7194Z"
                fill={color || '#1F2227'}
            />
        </svg>
    );
};

export default LineUpIcon;
