import React, { useState } from 'react';
import { Button, Modal, Form, Header, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useModal from 'hooks/useModal';
import ReportsService from 'services/ReportsService';
import { SpotInterface } from 'types';
import { rentalTypes } from 'utils/constants';
import { getFirstDayOfMonth } from 'utils/helpers';
import { useCallback } from 'react';

type DownloadReportModalProps = {
    spot: SpotInterface;
};
const DownloadReportModal = (props: DownloadReportModalProps) => {
    const { spot } = props;
    const { isShowing, toggle } = useModal();
    const [startDate, setStartDate] = useState<Date>(getFirstDayOfMonth(new Date()));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [rentalType, setRentalType] = useState('');

    const downloadReport = () => {
        void ReportsService.downloadDailyReports(spot.uuid, {
            rentalType: rentalType ? rentalType : undefined,
            startDate,
            endDate,
        });
    };

    const handleStartDateChange = useCallback((date: Date | null) => {
        if (date) setStartDate(date);
    }, []);

    const handleEndDateChange = useCallback((date: Date | null) => {
        if (date) setEndDate(date);
    }, []);

    return (
        <>
            <Button icon="download" onClick={() => toggle()} />
            <Modal as={Form} open={isShowing} onClose={toggle} onSubmit={toggle} size="tiny">
                <Header content={'Generate Report'} as="h2" />
                <Header
                    icon="info circle"
                    content={
                        'Get a CSV file containing daily gross revenue, net revenue, and fees & payment processing.'
                    }
                    as="p"
                />
                <Modal.Content>
                    <p>Dates</p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 30,
                        }}
                    >
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            calendarClassName="activeDates"
                        />
                        <p> T O </p>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                        />
                    </div>
                    <p>Rental type</p>
                    <Dropdown
                        name="rentalTypeDropdown"
                        placeholder="Filter rental type"
                        fluid
                        selection
                        onChange={(e, data: any) => setRentalType(data.value)}
                        options={rentalTypes}
                        value={rentalType}
                    />
                    <p></p>
                    <Button
                        disabled={startDate > endDate}
                        color="green"
                        icon="download"
                        content="Download Report"
                        fluid
                        onClick={() => downloadReport()}
                    />
                </Modal.Content>
            </Modal>
        </>
    );
};

export default DownloadReportModal;
