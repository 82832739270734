import React, { forwardRef, HTMLProps, Ref } from 'react';
import './AGForm.css';

interface AGFormProps extends HTMLProps<HTMLFormElement> {
    children?: React.ReactNode;
}

const AGForm = forwardRef(({ children, ...rest }: AGFormProps, ref: Ref<HTMLFormElement>) => {
    return (
        <form className="ag-form-wrapper" ref={ref} {...rest}>
            {children}
        </form>
    );
});

export default AGForm;
