import React from 'react';

const EmptyStarIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 28 28" fill="none" {...restProps}>
            <path
                d="M25.6667 11.3634L17.2784 10.64L14 2.91669L10.7217 10.6517L2.33337 11.3634L8.70337 16.8817L6.79004 25.0834L14 20.7317L21.21 25.0834L19.3084 16.8817L25.6667 11.3634ZM14 18.55L9.61337 21.1984L10.78 16.205L6.90671 12.845L12.0167 12.4017L14 7.70002L15.995 12.4134L21.105 12.8567L17.2317 16.2167L18.3984 21.21L14 18.55Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default EmptyStarIcon;
