import React from 'react';
import Confetti from 'react-dom-confetti';

const ConfettiAnimation = (props: any) => {
    const { active } = props;

    const config = {
        angle: 90,
        spread: 60,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 4000,
        stagger: 30,
        width: '10px',
        height: '10px',
        perspective: '800px',
        colors: ['#FC2F00', '#C200FB', '#ec0868', '#EC7D10', '#FFBC0A'],
    };

    return <Confetti active={active} config={config} />;
};

export default ConfettiAnimation;
