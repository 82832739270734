export enum CarTrackingStatus {
    RENTER = 'renter',
    VISITOR = 'visitor',
    ENFORCED = 'enforced',
    GRACE_PERIOD = 'grace period',
    VIOLATOR = 'violator',
}

export type CarTracking = {
    pk: number;
    spot_id: number;
    plate: string;
    state: string;
    creation_datetime: string;
    entrance_datetime: string | null;
    entrance_timestamp: number | null;
    entrance_image: string | null;
    entrance_license_plate_image: string | null;
    exit_datetime: string | null;
    exit_timestamp: number | null;
    exit_image: string | null;
    exit_license_plate_image: string | null;
    visit_count: number;
    rental_count: number;
    status: CarTrackingStatus | null;
    priority: number;
    is_cached: boolean;
    is_duplicate: boolean;
};

export type CameraImage = {
    name: string;
    url: string;
    event_datetime: number;
};

export enum CameraOrientation {
    ENTRANCE = 'Entrance',
    EXIT = 'Exit',
}

type LPRImage = CameraImage & { orientation: CameraOrientation };

export type RecentCameraImages = {
    overhead_images: CameraImage[];
    lpr_images: LPRImage[];
};

export type HeroStats = {
    vehicles_seen: number;
    occupancy: number;
    peak: number;
    transient_rentals: number;
    max_capacity: number;
};
