import React, { useEffect } from 'react';
import { useTenantsParking } from 'contexts/TenantsParkingContext';
import { SpotInterface } from 'types';
import Loader from 'components/Loader/Loader';
import AGMessage from 'components/AGMessage/AGMessage';
import BuildingIcon from 'components/Icons/BuildingIcon';
import styles from './TenantsParking.module.css';
import Table from './Table';

type TenantsParkingProps = {
    spot: SpotInterface;
};

const TenantsParking = ({ spot }: TenantsParkingProps) => {
    const { getTenantsParking, loading, errorMessage, tenantsParkings } = useTenantsParking();

    useEffect(() => {
        getTenantsParking(spot.uuid);
    }, [spot.uuid, getTenantsParking]);

    if (loading) return <Loader />;

    if (tenantsParkings.length === 0)
        return (
            <div className={styles.noData}>
                <div className={styles.noDataHeader}>
                    <BuildingIcon /> <p>Tenant billing is not enabled at your property</p>
                </div>
                <div className={styles.noDataContent}>
                    <p>What’s a tenant?</p>
                    <p>A tenant is a business account that uses your parking facility each month.</p>
                    <p>Reach out to partners@airgarage.com if you think your property may be eligible.</p>
                </div>
            </div>
        );

    return (
        <div style={{ width: '100%' }}>
            {!!errorMessage && <AGMessage color="error" title={errorMessage} />}
            <Table data={tenantsParkings} />
        </div>
    );
};

export default TenantsParking;
