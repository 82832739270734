import React from 'react';

const PersonOffIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M6.01995 2.83172C6.55245 2.00672 7.48245 1.46672 8.53245 1.46672C10.19 1.46672 11.5325 2.80922 11.5325 4.46672C11.5325 5.51672 10.9925 6.44672 10.1675 6.97922L6.01995 2.83172ZM14.5325 11.3442C14.5175 10.5192 14.06 9.76172 13.325 9.37922C12.92 9.16922 12.4775 8.97422 11.9975 8.80922L14.5325 11.3442ZM15.425 14.3592L1.63995 0.574219L0.574951 1.63172L7.24245 8.29922C5.88495 8.47172 4.69995 8.89172 3.73995 9.38672C2.98995 9.76922 2.53245 10.5417 2.53245 11.3817V13.4667H12.41L14.3675 15.4242L15.425 14.3592Z"
                fill={color || 'black'}
            />
        </svg>
    );
};

export default PersonOffIcon;
