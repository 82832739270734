import React from 'react';

const TagIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M19.5178 10.0978L10.8928 1.47283C10.5478 1.12783 10.0686 0.916992 9.5415 0.916992H2.83317C1.779 0.916992 0.916504 1.77949 0.916504 2.83366V9.54199C0.916504 10.0691 1.12734 10.5482 1.48192 10.9028L10.1069 19.5278C10.4519 19.8728 10.9311 20.0837 11.4582 20.0837C11.9853 20.0837 12.4644 19.8728 12.8094 19.5182L19.5178 12.8099C19.8723 12.4649 20.0832 11.9857 20.0832 11.4587C20.0832 10.9316 19.8628 10.4428 19.5178 10.0978ZM11.4582 18.1766L2.83317 9.54199V2.83366H9.5415V2.82408L18.1665 11.4491L11.4582 18.1766Z"
                fill={color || '#333333'}
            />
            <path
                d="M5.229 6.66699C6.02291 6.66699 6.6665 6.0234 6.6665 5.22949C6.6665 4.43558 6.02291 3.79199 5.229 3.79199C4.43509 3.79199 3.7915 4.43558 3.7915 5.22949C3.7915 6.0234 4.43509 6.66699 5.229 6.66699Z"
                fill={color || '#333333'}
            />
        </svg>
    );
};

export default TagIcon;
