import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import styles from './SearchInput.module.css';

export default function SearchInput({ className, ...props }: React.InputHTMLAttributes<HTMLInputElement>): JSX.Element {
    return (
        <div className={`${styles.searchInputWrapper} ${className}`}>
            <SearchIcon />
            <input className={styles.searchInput} {...props} />
        </div>
    );
}
