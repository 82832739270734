import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Visitors from 'components/Dashboard/Modules/Visitors';
import usePaginatedFiltersWithSearch from 'hooks/usePaginatedFiltersWithSearch';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { VisitorsProvider } from 'contexts/VisitorsContext';
import { VisitorCodesProvider } from 'contexts/VisitorCodesContext';

type VisitorsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function VisitorsModuleContainer({ selectedSpot, push: connectedPush, organizationId }: VisitorsModuleContainerProps) {
    const { filters, urlUpdater } = usePaginatedFiltersWithSearch<{
        expiring: 'true' | 'false';
    }>({
        filterValidators: {
            expiring(current) {
                return current === 'true' || current === 'false' ? current : 'false';
            },
        },
        push: connectedPush,
    });

    const navigateTo = (path: string): void => {
        connectedPush(`/organization/${organizationId}/spots/${selectedSpot}/${path}`);
    };

    const handleToggleExpiringVisitors = (show: boolean): void => {
        urlUpdater.expiring(String(show));
        urlUpdater.page('1');
    };

    return (
        <VisitorCodesProvider>
            <VisitorsProvider>
                <div style={{ width: '100%' }}>
                    <Visitors
                        navigateTo={navigateTo}
                        setShowExpiringVisitors={handleToggleExpiringVisitors}
                        setSearchTerm={urlUpdater.searchTerm}
                        setPage={urlUpdater.page}
                        filters={filters}
                        selectedSpotPk={selectedSpot}
                    />
                </div>
            </VisitorsProvider>
        </VisitorCodesProvider>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
    };
}

const mapDispatchToProps = {
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorsModuleContainer);
