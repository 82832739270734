import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { eventClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';
import {
    ADD_EVENT_FAILED,
    ADD_EVENT_REQUESTED,
    ADD_EVENT_SUCCESS,
    DELETE_EVENT_FAILED,
    DELETE_EVENT_REQUESTED,
    DELETE_EVENT_SUCCESS,
    EDIT_EVENT_FAILED,
    EDIT_EVENT_REQUESTED,
    EDIT_EVENT_SUCCESS,
    EVENTS_FAILED,
    EVENTS_REQUESTED,
    EVENTS_SUCCESS,
} from './constants';
import unauthorized from '../unauthorized';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import eventsReducer, { EventsState, initialEventsState } from './reducer';
import { EventInterval } from 'types/Event';
import { getEventData } from './utils';
import { useVisitorsCode } from 'contexts/VisitorCodesContext';

export type EventFormInputs = {
    name: string;
    selectedDate: Date;
    startTime: string;
    endTime: string;
    recurrence: EventInterval;
    numSpotsAvailable: number;
    existingVisitorCodePks: string[];
    is_lot_closed: boolean;
    newVisitorCodeName: string;
    newVisitorCodeCode: string;
};

interface EventsActions {
    getEvents: (spotPk: number, currentFilters: EventFilter, force?: boolean) => Promise<void>;
    editEvent: (event: EventFormInputs, eventPk: number, spotPk: number, currentFilters: EventFilter) => Promise<void>;
    addEvent: (newEvent: EventFormInputs, spotPk: number, currentFilters: EventFilter) => Promise<void>;
    deleteEvent: (eventPk: number, spotPk: number, currentFilters: EventFilter) => Promise<void>;
}

export type EventFilter = {
    start: number;
    end: number;
};

interface EventsContextType extends EventsState, EventsActions {}

const EventsContext = createContext<EventsContextType | null>(null);

export const EventsProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(eventsReducer, { ...initialEventsState }, [], [unauthorized]);

    const { addVisitorCode } = useVisitorsCode();

    const getEvents = useCallback(
        async (spotPk: number, currentFilters: EventFilter, force: boolean = false) => {
            const { start, end } = currentFilters;
            dispatch({ type: EVENTS_REQUESTED });

            try {
                const payload = {
                    spot: spotPk,
                    start,
                    end,
                };

                let events;
                if (force) events = await eventClient.force__getEvents(payload);
                else events = await eventClient.getEvents(payload);

                dispatch({ type: EVENTS_SUCCESS, payload: { events } });
            } catch (error) {
                dispatch({
                    type: EVENTS_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch events' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addEvent = useCallback(
        async (event: EventFormInputs, spotPk: number, currentFilters: EventFilter) => {
            dispatch({ type: ADD_EVENT_REQUESTED });

            try {
                const eventData = getEventData(event, spotPk);
                const newEvent = await eventClient.addEvent(eventData);

                if (event.newVisitorCodeCode) {
                    await addVisitorCode(
                        {
                            name: event.newVisitorCodeName,
                            code: event.newVisitorCodeCode,
                            events: [String(newEvent.pk)],
                            pk: 0,
                        },
                        spotPk
                    );
                }

                dispatch({ type: ADD_EVENT_SUCCESS });
                getEvents(spotPk, currentFilters, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not add event';
                }
                dispatch({ type: ADD_EVENT_FAILED, payload: { message: errorMessage } });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [addVisitorCode]
    );

    const editEvent = useCallback(
        async (event: EventFormInputs, eventPk: number, spotPk: number, currentFilters: EventFilter) => {
            dispatch({ type: EDIT_EVENT_REQUESTED });
            try {
                const eventData = getEventData(event, spotPk);
                const newEvent = await eventClient.editEvent(eventPk, eventData);

                if (event.newVisitorCodeCode) {
                    await addVisitorCode(
                        {
                            name: event.newVisitorCodeName,
                            code: event.newVisitorCodeCode,
                            events: [String(newEvent.pk)],
                            pk: 0,
                        },
                        spotPk
                    );
                }

                dispatch({ type: EDIT_EVENT_SUCCESS });
                getEvents(spotPk, currentFilters, true);
            } catch (e) {
                let errorMessage;
                if (HTTPError.isHTTPError(e)) {
                    errorMessage = e.message;
                } else {
                    errorMessage = e?.toString() || 'Could not update event';
                }
                dispatch({ type: EDIT_EVENT_FAILED, payload: { message: errorMessage } });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [addVisitorCode]
    );

    const deleteEvent = useCallback(
        async (eventPk: number, spotPk: number, currentFilters: EventFilter) => {
            dispatch({ type: DELETE_EVENT_REQUESTED });
            try {
                await eventClient.deleteEvent(eventPk);
                dispatch({ type: DELETE_EVENT_SUCCESS });
                getEvents(spotPk, currentFilters, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not delete event';
                }
                dispatch({
                    type: DELETE_EVENT_FAILED,
                    payload: { message: errorMessage },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<EventsContextType>(() => {
        return {
            ...state,
            getEvents,
            addEvent,
            deleteEvent,
            editEvent,
        };
    }, [state, getEvents, addEvent, deleteEvent, editEvent]);

    return <EventsContext.Provider value={value}>{children}</EventsContext.Provider>;
};

export const useEvents = () => {
    const context = useContext(EventsContext);
    if (!context) {
        throw new Error('Error: useEvents should be wrapped by EventsProvider.');
    }
    return context;
};
