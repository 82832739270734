import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import {
    getPayoutsAction,
    getSelectedPayoutDetailsAction,
    getPayoutAccountInfoAction,
    addPayoutAccountInfoAction,
} from 'actions/actions';
import Payouts from 'components/Dashboard/Modules/Payouts';
import { AppState } from 'reducers/reducers';
import { trackPageViewed } from 'utils/analytics';

type PayoutsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PayoutsModuleContainer = ({
    payoutAccountInfo,
    getPayoutAccountInfo,
    getPayouts,
    getSelectedPayoutDetails,
    loading,
    payouts,
    selectedPayoutDetails,
    errorMessage,
    successMessage,
    addPayoutAccountInfo,
    organization,
    payoutDetailsLoading,
    profile,
}: PayoutsModuleContainerProps) => {
    useEffect(() => {
        getPayoutAccountInfo();
        getPayouts();
        trackPageViewed({
            pageName: 'Payouts',
            organizationId: organization.pk,
            spotId: null,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organization, profile, getPayoutAccountInfo, getPayouts]);

    return (
        <div>
            {!loading ? (
                <Payouts
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    addPayoutAccountInfo={addPayoutAccountInfo}
                    payoutAccountInfo={payoutAccountInfo}
                    organization={organization}
                    payouts={payouts}
                    loading={loading}
                    selectedPayoutDetails={selectedPayoutDetails}
                    getSelectedPayoutDetails={getSelectedPayoutDetails}
                    payoutDetailsLoading={payoutDetailsLoading}
                />
            ) : (
                <Loader active inline />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.payouts.errorMessage,
        successMessage: state.payouts.successMessage,
        loading: state.payouts.loading,
        payouts: state.payouts.payouts,
        selectedPayoutDetails: state.payouts.selectedPayoutDetails,
        payoutAccountInfo: state.payouts.payoutAccountInfo,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
        payoutDetailsLoading: state.payouts.payoutDetailsLoading,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getPayouts: getPayoutsAction,
    getSelectedPayoutDetails: getSelectedPayoutDetailsAction,
    getPayoutAccountInfo: getPayoutAccountInfoAction,
    addPayoutAccountInfo: addPayoutAccountInfoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutsModuleContainer);
