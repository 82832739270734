import React from 'react';
import { Icon, Menu, Sidebar, Divider, Image } from 'semantic-ui-react';
import MainMenu from 'components/Dashboard/Modules/MainMenu';
import SpotMenu from 'components/Dashboard/Modules/SpotMenu';
import SuperuserMenuContainer from 'containers/SuperuserMenuContainer';
import { Organization, SpotInterface } from 'types';

type SidebarMobileProps = {
    children: React.ReactNode | React.ReactNode[];
    organization: Organization | null;
    superuser: boolean;
    spots: SpotInterface[] | null;
    selectedSpotInfo: SpotInterface | null;
    logo?: string;
    visible: boolean;
    onToggle: () => void;
    onPusherClick: () => void;
    navigateToTab: (tabUrl: string) => void;
    logout: () => void;
};

const SidebarMobile = ({
    children,
    onPusherClick,
    onToggle,
    visible,
    spots,
    organization,
    selectedSpotInfo,
    navigateToTab,
    logo,
    superuser,
    logout,
}: SidebarMobileProps) => {
    return (
        <>
            <Menu borderless>
                <Menu.Item onClick={onToggle}>
                    <Icon name="sidebar" />
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Image src={logo} alt="logo" style={{ maxHeight: '3em' }} />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Sidebar.Pushable>
                <Sidebar as={Menu} animation="overlay" vertical visible={visible} width="thin" borderless>
                    {superuser && (
                        <SuperuserMenuContainer navigateToTab={navigateToTab} selectedSpotInfo={selectedSpotInfo} />
                    )}
                    {organization && <MainMenu navigateToTab={navigateToTab} organization={organization} />}
                    <Divider hidden />
                    {spots && organization && (
                        <SpotMenu
                            selectedSpotInfo={selectedSpotInfo}
                            spots={spots}
                            navigateToTab={navigateToTab}
                            organization={organization}
                        />
                    )}
                    <Divider hidden />
                    <Menu.Item name="Help Center" onClick={() => navigateToTab('/help-center')} />
                    <Menu.Item name="Logout" onClick={logout} />
                </Sidebar>
                <Sidebar.Pusher style={{ minHeight: '100vh' }} onClick={onPusherClick}>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
};

export default SidebarMobile;
