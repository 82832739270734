import React, { useState } from 'react';
import { loginAction, sendVerificationCodeAction, getProfileAction } from '../../actions/actions';
import { Button, Form, Message, Loader, Checkbox } from 'semantic-ui-react';
import styles from './SignupForm.module.css';
import { connect } from 'react-redux';
import useForm from '../../hooks/useForm';

interface InitialInputs {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const SignupForm = (props: any) => {
    const { firstName, lastName, email } = props.urlParams;

    const initialInputs: InitialInputs = {
        firstName: firstName || '',
        lastName: lastName || '',
        email: email || '',
        password: '',
    };
    const { inputs, handleInput } = useForm(initialInputs);
    const [acceptedAgreement, updateAcceptedAgreement] = useState(false);
    const fields = inputs as any;
    const handleSubmit = () => {
        props.signup(fields, acceptedAgreement);
    };
    return (
        <div>
            <Loader active={props.loading} />
            <Form className={styles.loginForm} hidden={props.loading} onSubmit={handleSubmit}>
                {props.successMessage && <Message color="green">{props.successMessage}</Message>}
                {props.errorMessage && <Message color="red">{props.errorMessage}</Message>}
                <h5>First name</h5>
                <Form.Field>
                    <Form.Input
                        name="firstName"
                        value={fields.firstName}
                        icon="user circle"
                        iconPosition="left"
                        placeholder="First name"
                        onChange={(event) => {
                            handleInput(event);
                        }}
                        required
                    />
                </Form.Field>
                <h5>Last name</h5>
                <Form.Field>
                    <Form.Input
                        name="lastName"
                        value={fields.lastName}
                        icon="user circle"
                        iconPosition="left"
                        placeholder="Last name"
                        onChange={(event) => {
                            handleInput(event);
                        }}
                        required
                    />
                </Form.Field>
                <h5>Email</h5>
                <Form.Field>
                    <Form.Input
                        name="email"
                        icon="at"
                        value={fields.email}
                        iconPosition="left"
                        placeholder="Email"
                        onChange={(event) => {
                            handleInput(event);
                        }}
                        required
                    />
                </Form.Field>
                <h5>Password</h5>
                <Form.Field>
                    <Form.Input
                        name="password"
                        icon="lock"
                        iconPosition="left"
                        type="password"
                        placeholder="Password"
                        onChange={handleInput}
                        required
                        minLength={8}
                    />
                </Form.Field>
                <h5>I agree to the:</h5>
                <Form.Field>
                    <Checkbox
                        checked={acceptedAgreement}
                        onChange={() => updateAcceptedAgreement(!acceptedAgreement)}
                        label={
                            <label>
                                Lot Owner{' '}
                                <a href="https://airgarage.com/agreement" target="_blank" rel="noopener noreferrer">
                                    Service Agreement
                                </a>
                            </label>
                        }
                    />
                </Form.Field>

                <Button fluid type="submit" content="Submit" />
            </Form>
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        loading: state.auth.loading,
        verificationCodeSent: state.auth.verificationCodeSent,
        authToken: state.auth.token,
    };
}

const mapDispatchToProps = {
    login: loginAction,
    sendVerificationCode: sendVerificationCodeAction,
    getProfile: getProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
