import React from 'react';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import { DiscountType } from './utils';

type DiscountAmountInputProps = {
    discountType: DiscountType;
    errors: string[] | undefined | null;
};

export default function DiscountAmountInput({ discountType, errors }: DiscountAmountInputProps) {
    switch (discountType) {
        case DiscountType.DOLLAR_AMOUNT:
            return (
                <TextInput
                    key={discountType}
                    name="discountAmount"
                    label="Discount Amount"
                    onChange={(e) => {
                        const value = e.target.value.replace(/\$/g, '').trim();
                        // Allow the user to type numbers
                        const sanitizedValue = value.replace(/[^\d]/g, '');
                        e.target.value = sanitizedValue ? `$ ${sanitizedValue}` : '';
                    }}
                    type="text"
                    placeholder="e.g. $5"
                    errors={errors?.[0] ?? null}
                    required
                />
            );

        case DiscountType.PERCENTAGE:
            return (
                <TextInput
                    key={discountType}
                    name="discountPercentage"
                    label="Discount percentage"
                    onChange={(e) => {
                        const value = e.target.value.replace(/%/g, '').trim();
                        // Allow only numeric input
                        const sanitizedValue = value.replace(/[^\d]/g, '');
                        const cursorPosition = e.target.selectionStart || 0;
                        e.target.value = sanitizedValue ? `${sanitizedValue} %` : '';
                        e.target.setSelectionRange(cursorPosition, cursorPosition);
                    }}
                    type="text"
                    placeholder="e.g. 10%"
                    errors={errors?.[0] ?? null}
                    required
                />
            );
        case DiscountType.TIME_BASED:
            return (
                <TextInput
                    key={discountType}
                    name="discountTime"
                    label="Discount Time in min"
                    onChange={(e) => {
                        const value = e.target.value.replace(/%/g, '').trim();
                        // Allow only numeric input
                        const sanitizedValue = value.replace(/[^\d]/g, '');
                        const cursorPosition = e.target.selectionStart || 0;
                        e.target.value = sanitizedValue ? `${sanitizedValue} min` : '';
                        e.target.setSelectionRange(cursorPosition, cursorPosition);
                    }}
                    type="text"
                    placeholder="e.g. 60 min"
                    errors={errors?.[0] ?? null}
                    required
                />
            );
        default:
            return <></>;
    }
}
