import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { Organization, OrganizationListObj, SpotInterface, SpotMetadata } from 'types';
import styles from '../SpotCard/SpotCard.module.css';

type OrgDropdownList = {
    text: string;
    key: number;
    value: number;
}[];

type SpotDropdownList = {
    text: string;
    key: number;
    value: string;
}[];

type SuperuserMenuProps = {
    organization: Organization | null;
    organizations: OrganizationListObj[] | null;
    spotList: SpotMetadata[] | null;
    spot: SpotInterface | null;
    navigateToTab: (tabUrl: string) => void;
};

const SuperuserMenu = (props: SuperuserMenuProps) => {
    const { organizations, navigateToTab, organization, spotList, spot } = props;

    const dropdownOrgArray = (): OrgDropdownList =>
        organizations ? organizations.map((org) => ({ text: org.name, value: org.pk, key: org.pk })) : [];
    const dropdownSpotArray = (): SpotDropdownList =>
        spotList
            ? spotList.map((spot) => ({
                  text: spot.name,
                  value: `${spot.organization}:${spot.pk}`,
                  key: spot.pk,
              }))
            : [];

    return (
        <Menu vertical>
            <Menu.Item header className={styles.gradientText}>
                Superuser menu
            </Menu.Item>
            <Menu.Item>
                <Dropdown
                    fluid
                    icon={'search'}
                    text={organization ? organization.name : 'Search orgs'}
                    selection
                    search
                    options={dropdownOrgArray()}
                    onChange={(event, data) => {
                        navigateToTab(`/organization/${data.value}`);
                    }}
                />
            </Menu.Item>
            <Menu.Item>
                <Dropdown
                    loading={!spotList}
                    fluid
                    icon={'search'}
                    text={spot ? spot.name : 'Search properties'}
                    selection
                    search
                    options={dropdownSpotArray()}
                    onChange={(event, data) => {
                        if (!data.value) {
                            navigateToTab('/');
                        } else {
                            const [orgId, spotId] = (data.value as string).split(':');
                            navigateToTab(`/organization/${orgId}/spots/${spotId}/detail`);
                        }
                    }}
                />
            </Menu.Item>

            <Menu.Item name="Add new organization" onClick={() => navigateToTab('/organization/new')} />
            <Dropdown item text="Enforcement">
                <Dropdown.Menu>
                    <Dropdown.Item
                        icon="id card outline"
                        text="Plate lookup"
                        disabled={organization === null || spot === null}
                        onClick={() => navigateToTab(`/organization/${organization?.pk}/spots/${spot?.pk}/check-plate`)}
                    />
                    <Dropdown.Item
                        icon="camera"
                        text="Camera enforcement"
                        disabled={organization === null || spot === null}
                        onClick={() => navigateToTab(`/organization/${organization?.pk}/spots/${spot?.pk}/parked`)}
                    />
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text="Billing">
                <Dropdown.Menu>
                    <Dropdown.Item
                        icon="edit outline"
                        text="Manage refunds"
                        onClick={() => navigateToTab('/refunds')}
                    />
                    <Dropdown.Item
                        icon="file outline"
                        text="Create invoice"
                        disabled={organization === null || spot === null}
                        onClick={() => navigateToTab(`/organization/${organization?.pk}/spots/${spot?.pk}/invoice`)}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </Menu>
    );
};

export default SuperuserMenu;
