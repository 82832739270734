import React from 'react';
import { Icon } from 'semantic-ui-react';

import WidgetTitle from 'components/widgets/WidgetTitle';
import TimeLineImageCarousel from '../TimelineImageCarousel';
import { SnapshotEntry } from 'types/LotTimeline';
import styles from '../timeline.module.css';
import { getFormattedDateAndTime } from 'utils/helpers';

type SnapshotProps = {
    entry: SnapshotEntry;
};

function Snapshot({ entry }: SnapshotProps) {
    const formattedDateAndTime = getFormattedDateAndTime(new Date(entry.timestamp));

    return (
        <div className={styles.enforcementEvent}>
            <WidgetTitle title="Snapshot" icon={<Icon name="camera" />} lastUpdatedLabel={formattedDateAndTime} />
            <TimeLineImageCarousel images={entry.details.images} />
        </div>
    );
}

export default Snapshot;
