import React from 'react';

import Button from 'components/Button/Button';
import styles from './RenterList.module.css';

type DeleteRenterFormProps = {
    deleteRental(): void;
    loading: boolean;
};

function DeleteRenterForm(props: DeleteRenterFormProps) {
    const { deleteRental } = props;

    return (
        <div className={styles.deleteRentalFormWrapper}>
            <p className={styles.deleteRentalFormP}>
                This rental will end immediately and they will no longer have valid access to your property.
            </p>
            <Button onClick={deleteRental} size="md" color="error-outline" loading={props.loading}>
                Remove
            </Button>
        </div>
    );
}

export default DeleteRenterForm;
