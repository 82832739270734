import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import styles from './../ListForm/ListForm.module.css';

const SpotPricingFields = (props: any) => {
    const { handleInput, fields } = props;
    const [hasMonthly, setHasMonthly] = useState(false);
    const [hasDaily, setHasDaily] = useState(false);

    return (
        <>
            <p className={styles.tabHeading}>Pricing</p>
            <Form.Group widths="equal">
                <Form.Checkbox
                    label="Has monthly spots"
                    onChange={() => setHasMonthly(!hasMonthly)}
                    checked={hasMonthly}
                />

                <Form.Checkbox label="Has daily spots" onChange={() => setHasDaily(!hasDaily)} checked={hasDaily} />
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Input
                        name="quantity"
                        placeholder="10"
                        onChange={handleInput}
                        value={fields.quantity}
                        label="Hourly spot quantity"
                        type="number"
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        icon="dollar sign"
                        iconPosition="left"
                        name="price_hourly"
                        placeholder="2"
                        onChange={handleInput}
                        value={fields.price_hourly}
                        label="Hourly rate (in dollars)"
                        type="number"
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        icon="dollar sign"
                        iconPosition="left"
                        name="daily_max"
                        placeholder="12"
                        onChange={handleInput}
                        value={fields.daily_max}
                        label="Max daily rate (in dollars)"
                        type="number"
                        required
                    />
                </Form.Field>
            </Form.Group>

            {hasMonthly && (
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            name="quantity_monthly"
                            placeholder="10"
                            onChange={handleInput}
                            value={fields.quantity_monthly}
                            label="Monthly spot quantity"
                            type="number"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            icon="dollar sign"
                            iconPosition="left"
                            name="price_monthly"
                            placeholder="200"
                            onChange={handleInput}
                            value={fields.price_mourly}
                            label="Monthly rate (in dollars)"
                            type="number"
                        />
                    </Form.Field>
                </Form.Group>
            )}

            {hasDaily && (
                <Form.Group widths="equal">
                    <Form.Field>
                        <Form.Input
                            icon="dollar sign"
                            iconPosition="left"
                            name="price_daily"
                            placeholder="20"
                            onChange={handleInput}
                            value={fields.price_daily}
                            label="Daily rate (in dollars)"
                            type="number"
                        />
                    </Form.Field>
                </Form.Group>
            )}
        </>
    );
};

export default SpotPricingFields;
