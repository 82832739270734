import React from 'react';
import { useForm } from '@felte/react';
import AGForm from 'components/AGForm/AGForm';
import Label from 'components/AGForm/Inputs/Label/Label';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import Button from 'components/Button/Button';
import SingleDateSelector from 'components/SingleDateSelector/SingleDateSelector';
import {
    validateDescription,
    validateDiscountAmount,
    validateDiscountCode,
    validateDiscountPercentage,
    validateDiscountTime,
    validateExpirationDate,
    validateMaxNumberOfRentals,
    validateNumberOfUses,
} from 'utils/validations/discountCodeValidations';
import { PromoCodeFormInputs } from '../PromoCodeForm/PromoCodeForm';
import DiscountAmountInput from './DiscountAmountInput';
import styles from './DiscountCodeModalForm.module.css';
import { DiscountType, discountTypeOptions, sanitizeDiscountValues } from './utils';

type DiscountCodeModalFormProps = {
    loading: boolean;
    addPromoCode: (inputs: PromoCodeFormInputs) => void;
};

export type DiscountCodeFormData = {
    discountCode: string;
    description: string;
    discountType: DiscountType;
    discountAmount?: string;
    discountPercentage?: string;
    discountTime?: string;
    expirationDate?: Date | null;
    numberOfUses: string;
    maxNumberOfRentals: string;
};

type DiscountCodeFormDataErrors = {
    discountCode?: string;
    description?: string;
    discountAmount?: string;
    discountPercentage?: string;
    discountTime?: string;
    expirationDate?: string | null;
    numberOfUses?: string;
    maxNumberOfRentals?: string;
};

export default function DiscountCodeModalForm({ loading, addPromoCode }: DiscountCodeModalFormProps) {
    const { form, errors, data, setFields, setErrors, isValid, isDirty } = useForm<DiscountCodeFormData>({
        initialValues: {
            discountCode: '',
            description: '',
            discountType: DiscountType.DOLLAR_AMOUNT,
            discountAmount: undefined,
            discountPercentage: undefined,
            discountTime: undefined,
            expirationDate: null,
            numberOfUses: '',
            maxNumberOfRentals: '',
        },
        onSubmit: (values) => {
            const { sanitizedDiscountAmount, sanitizedDiscountPercentage, sanitizedDiscountTime } =
                sanitizeDiscountValues(values);

            addPromoCode({
                code: values.discountCode.toUpperCase(),
                notes: values.description.trim(),
                value:
                    values.discountType === DiscountType.DOLLAR_AMOUNT && sanitizedDiscountAmount
                        ? String(sanitizedDiscountAmount)
                        : null,
                percentage_discount:
                    values.discountType === DiscountType.PERCENTAGE && sanitizedDiscountPercentage
                        ? String(sanitizedDiscountPercentage)
                        : null,
                minutes_to_discount:
                    values.discountType === DiscountType.TIME_BASED && Number(sanitizedDiscountTime)
                        ? Number(sanitizedDiscountTime)
                        : null,
                expiration: values.expirationDate ?? null,
                number_of_uses: values.numberOfUses ? String(values.numberOfUses) : null,
                maximum_number_of_simultaneous_rentals: Number(values.maxNumberOfRentals) ?? null,
                hours_to_discount: null,
            });
        },
        validate: [
            (v: DiscountCodeFormData): Pick<DiscountCodeFormDataErrors, 'discountCode'> => {
                return validateDiscountCode(v.discountCode);
            },
            (v: DiscountCodeFormData): Pick<DiscountCodeFormDataErrors, 'description'> => {
                return validateDescription(v.description);
            },
            (
                v: DiscountCodeFormData
            ): Pick<DiscountCodeFormDataErrors, 'discountAmount' | 'discountPercentage' | 'discountTime'> => {
                const { sanitizedDiscountAmount, sanitizedDiscountPercentage, sanitizedDiscountTime } =
                    sanitizeDiscountValues(v);
                switch (v.discountType) {
                    case DiscountType.DOLLAR_AMOUNT:
                        return validateDiscountAmount(sanitizedDiscountAmount);
                    case DiscountType.PERCENTAGE:
                        return validateDiscountPercentage(sanitizedDiscountPercentage);
                    case DiscountType.TIME_BASED:
                        return validateDiscountTime(sanitizedDiscountTime);
                    default:
                        return {};
                }
            },
            (v: DiscountCodeFormData): Pick<DiscountCodeFormDataErrors, 'expirationDate'> => {
                return validateExpirationDate(v.expirationDate);
            },
            (v: DiscountCodeFormData): Pick<DiscountCodeFormDataErrors, 'numberOfUses'> => {
                return validateNumberOfUses(v.numberOfUses);
            },
            (v: DiscountCodeFormData): Pick<DiscountCodeFormDataErrors, 'maxNumberOfRentals'> => {
                return validateMaxNumberOfRentals(v.maxNumberOfRentals);
            },
        ],
    });

    return (
        <div className={styles.wrapper}>
            <AGForm ref={form}>
                <div className={styles.row}>
                    <div className={styles.formGroup}>
                        <TextInput
                            name="discountCode"
                            label="Enter your discount code"
                            placeholder="e.g. SAVE20"
                            onChange={(e) => {
                                // Remove any non-alphanumeric characters and spaces
                                const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                e.target.value = sanitizedValue;
                            }}
                            isUppercase
                            required
                            errors={errors((s) => s.discountCode) || null}
                        />

                        <TextInput
                            name="description"
                            label="Description (for your reference only)"
                            placeholder="e.g. Employee Discount"
                            errors={errors((s) => s.description) || null}
                            required
                        />

                        <div>
                            <Label label="Discount Type" />
                            <AGSimpleSelect
                                options={discountTypeOptions}
                                onSelect={(v) => {
                                    setFields('discountType', v as DiscountType);
                                    setErrors({});
                                }}
                                selected={data((s) => s.discountType)}
                                width="100%"
                            />
                        </div>

                        <DiscountAmountInput
                            discountType={data((s) => s.discountType)}
                            errors={errors((s) => s.discountAmount || s.discountPercentage || s.discountTime)}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <div>
                            <Label label="Expiration Date" />
                            <SingleDateSelector
                                date={undefined}
                                onSave={(date) => setFields('expirationDate', date)}
                                placeHolder="Never"
                                dropdownWidth="100%"
                            />
                        </div>

                        <TextInput
                            name="numberOfUses"
                            label="Number of uses"
                            type="number"
                            min="1"
                            placeholder="Infinite"
                            errors={errors((s) => s.numberOfUses) || null}
                        />

                        <TextInput
                            name="maxNumberOfRentals"
                            label="Maximum number of active rentals"
                            type="number"
                            min="1"
                            placeholder="Infinite"
                            errors={errors((s) => s.maxNumberOfRentals) || null}
                        />
                    </div>
                </div>

                <Button type="submit" disabled={!isValid() || !isDirty() || loading} loading={loading}>
                    Add Discount Code
                </Button>
            </AGForm>
        </div>
    );
}
