import { GroupedOption, Option } from 'types/SelectInputs';
import {
    mexicoStates,
    unitedStates,
    usaStates,
    mexico,
    canada,
    canadaStates,
    CountryAbbreviation,
    StateAbbreviation,
    State,
    Country,
    StateName,
} from '../constants/states';
import { isCountryAbbv, isStateAbbv } from './state';

type StatesWithCountry = [State[], Country];

const [usOptions, mexicoOptions, canadaOptions] = (
    [
        [usaStates, unitedStates],
        [mexicoStates, mexico],
        [canadaStates, canada],
    ] as StatesWithCountry[]
).map(([states, country]) =>
    states.map((s) => ({
        value: valuesToStringRepresentation(country.abbreviation, s.abbreviation),
        label: s.name as StateName,
    }))
);

export const states: GroupedOption[] = [
    {
        label: unitedStates.abbreviation,
        options: usOptions,
    },
    {
        label: mexico.abbreviation,
        options: mexicoOptions,
    },
    {
        label: canada.abbreviation,
        options: canadaOptions,
    },
];

export function getOptionFromValues(country: CountryAbbreviation, state: StateAbbreviation): Option | undefined {
    if (!isCountryAbbv(country)) {
        country = CountryAbbreviation.UnitedStates;
    }
    if (!isStateAbbv(state)) {
        return;
    }
    return states
        .find((s) => s.label === country)
        ?.options.find((s) => s.value === valuesToStringRepresentation(country, state));
}

export function stringRepresentationToValues(s: string):
    | {
          country: CountryAbbreviation;
          state: StateAbbreviation;
      }
    | undefined {
    if (!s) {
        return;
    }
    const [country, state] = s.split('_');
    if (isCountryAbbv(country) && isStateAbbv(state)) {
        return {
            country,
            state,
        };
    }
}

export function stringRepresentationToOption(s: string): Option | undefined {
    const cs = stringRepresentationToValues(s);
    if (!cs) {
        return;
    }
    return getOptionFromValues(cs.country, cs.state);
}

export function valuesToStringRepresentation(country: string | null, state: string | null): string {
    if (country && isCountryAbbv(country) && state && isStateAbbv(state)) return `${country}_${state}`;
    else return '';
}
