import React, { useEffect, useState } from 'react';
import { Grid, Header, Divider, Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import LoginForm from 'components/LoginForm/LoginForm';
import { trackPageViewed } from 'utils/analytics';
import { parseQueryParams } from 'utils/helpers';

const LoginView = (props: any) => {
    const urlParams = parseQueryParams(props.location.search);
    const [showHeader, setShowHeader] = useState(true);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Login',
            superuser: null,
            organizationId: null,
            spotId: null,
            affiliationRole: null,
        });
    });

    return (
        <Grid padded style={{ height: '100vh' }}>
            <Grid.Row>
                <Grid.Column tablet={6} computer={6} color="yellow" stretched only="large screen">
                    <Image size="small" wrapped src={require('assets/AirGarage.png')} />
                    <Image size="large" wrapped src={require('assets/money_machine.png')} centered />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={10} computer={10} style={{ backgroundColor: 'white' }}>
                    <Divider style={{ height: 80 }} hidden />

                    {showHeader && (
                        <Header size="large" textAlign="center">
                            Log in to AirGarage
                            <div style={{ fontSize: 15 }}>
                                Or <a href="./signup">signup</a>
                            </div>
                        </Header>
                    )}
                    <Divider hidden />
                    <LoginForm urlParams={urlParams} setShowHeader={setShowHeader} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default withRouter(LoginView);
