export const WIDGET_TITLE = 'Your Transient Drivers';
export const FREQUENT_COLOR = '#A2D5E0';
export const RETURN_COLOR = '#9EA2FF';
export const ONE_TIME_COLOR = '#FA824C';

export function getStartDate(daysToSubtract: number) {
    const date = new Date();
    date.setDate(date.getDate() - daysToSubtract);
    return date.toISOString().split('T')[0];
}
