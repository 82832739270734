import React from 'react';
import styles from './Paginated.module.css';
import { ReactComponent as CaretRight } from 'assets/icons/caretRight.svg';
import { ReactComponent as CaretLeft } from 'assets/icons/caretLeft.svg';

interface PaginatedProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
    children: JSX.Element;
}

const Paginated: React.FC<PaginatedProps> = ({ currentPage, totalPages, onPageChange, children }) => {
    const isFirstP = currentPage === 1;
    const isLastP = currentPage === totalPages;

    const range = Array.from({ length: Math.min(3, totalPages) }, (_, i) =>
        isFirstP ? i + 1 : isLastP ? currentPage - 2 + i : currentPage - 1 + i
    );

    const onNumberedButtonClicked = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        }
    };

    return (
        <>
            {children}
            <div className={styles.paginationWrapper}>
                <button
                    onClick={() => onPageChange(1)}
                    disabled={isFirstP}
                    className={[styles.caretButton, isFirstP ? styles.hidden : ''].join(' ')}
                >
                    <CaretLeft />
                    <CaretLeft />
                </button>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={isFirstP}
                    className={[styles.caretButton, isFirstP ? styles.hidden : ''].join(' ')}
                >
                    <CaretLeft />
                </button>
                {range.map((number) => (
                    <button
                        key={number}
                        onClick={() => onNumberedButtonClicked(number)}
                        disabled={number === currentPage}
                        className={number === currentPage ? styles.selected : styles.pageButton}
                    >
                        <span>{number}</span>
                    </button>
                ))}
                {totalPages >= 3 && (
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={isLastP}
                        className={[styles.caretButton, isLastP ? styles.hidden : ''].join(' ')}
                    >
                        <CaretRight />
                    </button>
                )}
                {totalPages >= 3 && (
                    <button
                        onClick={() => onPageChange(totalPages)}
                        disabled={isLastP}
                        className={[styles.caretButton, isLastP ? styles.hidden : ''].join(' ')}
                    >
                        <CaretRight />
                        <CaretRight />
                    </button>
                )}
            </div>
        </>
    );
};

export default Paginated;
