import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'reducers/reducers';
import { push } from 'connected-react-router';
import { selectedOrganizationId, selectedSpotInfo } from 'selectors';

import usePaginatedFiltersWithSearch from 'hooks/usePaginatedFiltersWithSearch';
import { MonthlyRentalsProvider } from 'contexts/MonthlyRentersContext';
import TabPanel, { TabPanelItems } from 'components/TabPanel/TabPanel';
import Loader from 'components/Loader/Loader';
import MonthlyRentals from 'components/MonthlyRentals/MonthlyRentals';
import TenantsParking from 'components/TenantsParking/TenantsParking';
import { RentalType, SlotState } from 'utils/constants';
import { TenantsParkingProvider } from 'contexts/TenantsParkingContext';
import { DailyRentalsProvider } from 'contexts/DailyRentalsContext';
import DailyRentals from 'components/DailyRentals/DailyRentals';

type DriversContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

enum TabOptions {
    Monthly = 'monthly',
    Transient = 'transient',
    Tenants = 'tenants',
}

function DriversContainer({ loadingSpots, spot, push, organizationId }: DriversContainerProps) {
    const defaultTab = spot.price_monthly ? TabOptions.Monthly : TabOptions.Transient;
    const defaultRentalType = `${RentalType.Flat},${RentalType.Hourly}${
        spot.price_daily ? ',' + RentalType.Daily : ''
    }`;

    const { filters, urlUpdater } = usePaginatedFiltersWithSearch<{
        tab: TabOptions;
        slotState: SlotState;
        rentalType: RentalType;
    }>({
        filterValidators: {
            tab(current) {
                return current === TabOptions.Monthly ||
                    current === TabOptions.Transient ||
                    current === TabOptions.Tenants
                    ? current
                    : defaultTab;
            },
            slotState(current) {
                return current === SlotState.Active || current === SlotState.Upcoming ? current : SlotState.Active;
            },
            rentalType(current) {
                if (!current) return defaultRentalType;
                const rentalTypes = current.split(',').map((rt) => {
                    return rt === RentalType.Daily || rt === RentalType.Flat || rt === RentalType.Hourly ? rt : null;
                });

                return rentalTypes.filter((rt) => rt !== null).join(',');
            },
        },
        push,
    });

    if (!spot || loadingSpots) return <Loader />;

    const items: TabPanelItems<TabOptions> = [
        {
            title: 'Monthly',
            key: TabOptions.Monthly,
            component: (
                <MonthlyRentals
                    selectedSpot={spot}
                    filters={filters}
                    urlUpdater={urlUpdater}
                    organizationId={organizationId}
                />
            ),
        },
        {
            title: 'Transient',
            key: TabOptions.Transient,
            component: (
                <DailyRentals
                    selectedSpot={spot}
                    filters={filters}
                    urlUpdater={urlUpdater}
                    organizationId={organizationId}
                />
            ),
        },
        {
            title: 'Tenants',
            key: TabOptions.Tenants,
            component: (
                <div style={{ marginBlock: '40px' }}>
                    <TenantsParking spot={spot} />
                </div>
            ),
        },
    ];

    return (
        <MonthlyRentalsProvider>
            <DailyRentalsProvider>
                <TenantsParkingProvider>
                    <TabPanel
                        items={items}
                        activeTabKey={filters.tab}
                        setActiveTab={(tab) => {
                            urlUpdater.tab(tab);
                            urlUpdater.page('1');
                            urlUpdater.rentalType(defaultRentalType);
                            urlUpdater.searchTerm('');
                            urlUpdater.slotState(SlotState.Active);
                        }}
                    />
                </TenantsParkingProvider>
            </DailyRentalsProvider>
        </MonthlyRentalsProvider>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spot: selectedSpotInfo(state)!,
        loadingSpots: state.spots.orgSpotsLoading,
    };
}

const mapDispatchToProps = {
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriversContainer);
