import React from 'react';

const CustomReportsModuleContainer = () => {
    return (
        <div
            style={{ width: '100%', height: '100vh' }}
            dangerouslySetInnerHTML={{
                __html: `
      <link rel="stylesheet" href="https://static.hex.site/embed/embedStyles.css">
      <style>
        .hex-embed {
          width: 100%;
          height: 100%;
        }
        .hex-embed iframe {
          width: 100%;
          height: 100%;
          border: none;
        }
      </style>
      <div class="hex-embed">
        <iframe src="https://app.hex.tech/airgarage/app/28e4b20b-3ffd-493f-a9a7-2ad4f7d85ec0/latest?embedded=true"></iframe>
        <a href="https://hex.tech/?embed" target="_blank">
          <img src="https://static.hex.site/embed/hex-logo-embed.png" 
               alt="Hex - a modern data workspace for collaborative notebooks, data apps, dashboards, and reports." />
        </a>
      </div>
    `,
            }}
        />
    );
};

export default CustomReportsModuleContainer;
