export const MONTHLY_RENTALS_REQUESTED = 'MONTHLY_RENTALS_REQUESTED';
export const MONTHLY_RENTALS_SUCCESS = 'MONTHLY_RENTALS_SUCCESS';
export const MONTHLY_RENTALS_FAILED = 'MONTHLY_RENTALS_FAILED';

export const DELETE_RENTAL_REQUESTED = 'DELETE_RENTAL_REQUESTED';
export const DELETE_RENTAL_FAILED = 'DELETE_RENTAL_FAILED';
export const DELETE_RENTAL_SUCCESS = 'DELETE_RENTAL_SUCCESS';

export const EDIT_RENTAL_REQUESTED = 'EDIT_RENTAL_REQUESTED';
export const EDIT_RENTAL_FAILED = 'EDIT_RENTAL_FAILED';
export const EDIT_RENTAL_SUCCESS = 'EDIT_RENTAL_SUCCESS';

export const ADD_RENTAL_REQUESTED = 'ADD_RENTAL_REQUESTED';
export const ADD_RENTAL_FAILED = 'ADD_RENTAL_FAILED';
export const ADD_RENTAL_SUCCESS = 'ADD_RENTAL_SUCCESS';
