const rtf = new (Intl as any).RelativeTimeFormat('en', { numeric: 'auto' });

export function relativeDate(serverTime: string): string {
    if (serverTime === '') return '';
    const today = new Date();
    const thatDay = new Date(serverTime);
    const howManyDaysHasItBeen = (today.getTime() - thatDay.getTime()) / 24 / 60 / 60 / 1000;
    let datePiece = '';
    if (howManyDaysHasItBeen < 2) {
        datePiece = rtf.format(Math.round(-1 * howManyDaysHasItBeen), 'day');
    } else {
        datePiece = `${thatDay.getMonth() + 1}/${thatDay.getDate()}/${thatDay.getFullYear()}`;
    }
    return datePiece;
}

export function formatTime(serverTime: string): string {
    if (serverTime === '') return '';
    const datetime = new Date(serverTime);
    let formatted = datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    formatted = formatted.split(' ').join('').toLowerCase();
    if (formatted[0] === '0') {
        return formatted.substr(1);
    }
    return formatted;
}

export enum BucketNames {
    AirGarage = 'airgarage',
    CameraEvent = 'camera_event',
    MissionSubmission = 'mission_submission',
    OperationEvent = 'operation_event',
    QrCodes = 'ag-qr-codes',
    EnforcementScan = 'enforcement_scan',
    EnforcementTicket = 'enforcement_ticket',
}

export function getTimelineThumbnailFromBuckets(src: string, bucketNames: BucketNames[]): string {
    // Check if the source URL contains the correct bucket name
    const isValidBucket = bucketNames.some((bucketName) => src.includes(`/${bucketName}/`));
    if (!isValidBucket) {
        return src;
    }

    const srcParts = src.split('/');
    const fileName = srcParts.pop();
    const [name, extension] = fileName ? fileName.split('.') : [];

    if (!name || !extension) {
        // We return original src if name or extension is undefined
        return src;
    }

    const newFileName = `${name}_timeline.webp`;
    srcParts.push(newFileName);

    return srcParts.join('/');
}
