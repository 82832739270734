import React from 'react';
import { Flag, FlagNameValues } from 'semantic-ui-react';
import { Option } from 'types/SelectInputs';

interface CountryInformation {
    name: string;
    countryISOCode: FlagNameValues;
    countryPhoneCode: string;
    areaCode?: string;
}

const countriesInformation: CountryInformation[] = [
    {
        name: 'United States',
        countryISOCode: 'us',
        countryPhoneCode: '+1',
    },
    {
        name: 'Afghanistan',
        countryISOCode: 'af',
        countryPhoneCode: '+93',
    },
    {
        name: 'Aland Islands',
        countryISOCode: 'ax',
        countryPhoneCode: '+358',
        areaCode: '18',
    },
    {
        name: 'Albania',
        countryISOCode: 'al',
        countryPhoneCode: '+355',
    },
    {
        name: 'Algeria',
        countryISOCode: 'dz',
        countryPhoneCode: '+213',
    },
    {
        name: 'American Samoa',
        countryISOCode: 'as',
        countryPhoneCode: '+1',
        areaCode: '684',
    },
    {
        name: 'Andorra',
        countryISOCode: 'ad',
        countryPhoneCode: '+376',
    },
    {
        name: 'Angola',
        countryISOCode: 'ao',
        countryPhoneCode: '+244',
    },
    {
        name: 'Anguilla',
        countryISOCode: 'ai',
        countryPhoneCode: '+1',
        areaCode: '264',
    },
    {
        name: 'Antigua',
        countryISOCode: 'ag',
        countryPhoneCode: '+1',
        areaCode: '268',
    },
    {
        name: 'Argentina',
        countryISOCode: 'ar',
        countryPhoneCode: '+54',
    },
    {
        name: 'Armenia',
        countryISOCode: 'am',
        countryPhoneCode: '+374',
    },
    {
        name: 'Aruba',
        countryISOCode: 'aw',
        countryPhoneCode: '+297',
    },
    {
        name: 'Australia',
        countryISOCode: 'au',
        countryPhoneCode: '+61',
    },
    {
        name: 'Austria',
        countryISOCode: 'at',
        countryPhoneCode: '+43',
    },
    {
        name: 'Azerbaijan',
        countryISOCode: 'az',
        countryPhoneCode: '+994',
    },
    {
        name: 'Bahamas',
        countryISOCode: 'bs',
        countryPhoneCode: '+1',
        areaCode: '242',
    },
    {
        name: 'Bahrain',
        countryISOCode: 'bh',
        countryPhoneCode: '+973',
    },
    {
        name: 'Bangladesh',
        countryISOCode: 'bd',
        countryPhoneCode: '+880',
    },
    {
        name: 'Barbados',
        countryISOCode: 'bb',
        countryPhoneCode: '+1',
        areaCode: '246',
    },
    {
        name: 'Belarus',
        countryISOCode: 'by',
        countryPhoneCode: '+375',
    },
    {
        name: 'Belgium',
        countryISOCode: 'be',
        countryPhoneCode: '+32',
    },
    {
        name: 'Belize',
        countryISOCode: 'bz',
        countryPhoneCode: '+501',
    },
    {
        name: 'Benin',
        countryISOCode: 'bj',
        countryPhoneCode: '+229',
    },
    {
        name: 'Bermuda',
        countryISOCode: 'bm',
        countryPhoneCode: '+1',
        areaCode: '441',
    },
    {
        name: 'Bhutan',
        countryISOCode: 'bt',
        countryPhoneCode: '+975',
    },
    {
        name: 'Bolivia',
        countryISOCode: 'bo',
        countryPhoneCode: '+591',
    },
    {
        name: 'Bosnia',
        countryISOCode: 'ba',
        countryPhoneCode: '+387',
    },
    {
        name: 'Botswana',
        countryISOCode: 'bw',
        countryPhoneCode: '+267',
    },
    {
        name: 'Bouvet Island',
        countryISOCode: 'bv',
        countryPhoneCode: '+55',
    },
    {
        name: 'Brazil',
        countryISOCode: 'br',
        countryPhoneCode: '+55',
    },
    {
        name: 'British Virgin Islands',
        countryISOCode: 'vg',
        countryPhoneCode: '+1',
        areaCode: '284',
    },
    {
        name: 'Brunei',
        countryISOCode: 'bn',
        countryPhoneCode: '+673',
    },
    {
        name: 'Bulgaria',
        countryISOCode: 'bg',
        countryPhoneCode: '+359',
    },
    {
        name: 'Burkina Faso',
        countryISOCode: 'bf',
        countryPhoneCode: '+226',
    },
    {
        name: 'Burma',
        countryISOCode: 'mm',
        countryPhoneCode: '+95',
    },
    {
        name: 'Burundi',
        countryISOCode: 'bi',
        countryPhoneCode: '+257',
    },
    {
        name: 'Caicos Islands',
        countryISOCode: 'tc',
        countryPhoneCode: '+1',
        areaCode: '649',
    },
    {
        name: 'Cambodia',
        countryISOCode: 'kh',
        countryPhoneCode: '+855',
    },
    {
        name: 'Cameroon',
        countryISOCode: 'cm',
        countryPhoneCode: '+237',
    },
    {
        name: 'Canada',
        countryISOCode: 'ca',
        countryPhoneCode: '+1',
    },
    {
        name: 'Cape Verde',
        countryISOCode: 'cv',
        countryPhoneCode: '+238',
    },
    {
        name: 'Cayman Islands',
        countryISOCode: 'ky',
        countryPhoneCode: '+1',
        areaCode: '345',
    },
    {
        name: 'Central African Republic',
        countryISOCode: 'cf',
        countryPhoneCode: '+236',
    },
    {
        name: 'Chad',
        countryISOCode: 'td',
        countryPhoneCode: '+235',
    },
    {
        name: 'Chile',
        countryISOCode: 'cl',
        countryPhoneCode: '+56',
    },
    {
        name: 'China',
        countryISOCode: 'cn',
        countryPhoneCode: '+86',
    },
    {
        name: 'Christmas Island',
        countryISOCode: 'cx',
        countryPhoneCode: '+61',
    },
    {
        name: 'Cocos Islands',
        countryISOCode: 'cc',
        countryPhoneCode: '+61',
    },
    {
        name: 'Colombia',
        countryISOCode: 'co',
        countryPhoneCode: '+57',
    },
    {
        name: 'Comoros',
        countryISOCode: 'km',
        countryPhoneCode: '+269',
    },
    {
        name: 'Congo',
        countryISOCode: 'cd',
        countryPhoneCode: '+243',
    },
    {
        name: 'Congo Brazzaville',
        countryISOCode: 'cg',
        countryPhoneCode: '+242',
    },
    {
        name: 'Cook Islands',
        countryISOCode: 'ck',
        countryPhoneCode: '+682',
    },
    {
        name: 'Costa Rica',
        countryISOCode: 'cr',
        countryPhoneCode: '+506',
    },
    {
        name: 'Cote Divoire',
        countryISOCode: 'ci',
        countryPhoneCode: '+225',
    },
    {
        name: 'Croatia',
        countryISOCode: 'hr',
        countryPhoneCode: '+385',
    },
    {
        name: 'Cuba',
        countryISOCode: 'cu',
        countryPhoneCode: '+53',
    },
    {
        name: 'Cyprus',
        countryISOCode: 'cy',
        countryPhoneCode: '+357',
    },
    {
        name: 'Czech Republic',
        countryISOCode: 'cz',
        countryPhoneCode: '+420',
    },
    {
        name: 'Denmark',
        countryISOCode: 'dk',
        countryPhoneCode: '+45',
    },
    {
        name: 'Djibouti',
        countryISOCode: 'dj',
        countryPhoneCode: '+253',
    },
    {
        name: 'Dominica',
        countryISOCode: 'dm',
        countryPhoneCode: '+1',
        areaCode: '767',
    },
    {
        name: 'Dominican Republic',
        countryISOCode: 'do',
        countryPhoneCode: '+1',
        areaCode: '809',
    },
    {
        name: 'Ecuador',
        countryISOCode: 'ec',
        countryPhoneCode: '+593',
    },
    {
        name: 'Egypt',
        countryISOCode: 'eg',
        countryPhoneCode: '+20',
    },
    {
        name: 'El Salvador',
        countryISOCode: 'sv',
        countryPhoneCode: '+503',
    },
    {
        name: 'England',
        countryISOCode: 'gb eng',
        countryPhoneCode: '+44',
    },
    {
        name: 'Equatorial Guinea',
        countryISOCode: 'gq',
        countryPhoneCode: '+240',
    },
    {
        name: 'Eritrea',
        countryISOCode: 'er',
        countryPhoneCode: '+291',
    },
    {
        name: 'Estonia',
        countryISOCode: 'ee',
        countryPhoneCode: '+372',
    },
    {
        name: 'Ethiopia',
        countryISOCode: 'et',
        countryPhoneCode: '+251',
    },
    {
        name: 'Falkland Islands',
        countryISOCode: 'fk',
        countryPhoneCode: '+500',
    },
    {
        name: 'Faroe Islands',
        countryISOCode: 'fo',
        countryPhoneCode: '+298',
    },
    {
        name: 'Fiji',
        countryISOCode: 'fj',
        countryPhoneCode: '+679',
    },
    {
        name: 'Finland',
        countryISOCode: 'fi',
        countryPhoneCode: '+358', // CHECK
    },
    {
        name: 'France',
        countryISOCode: 'fr',
        countryPhoneCode: '+33',
    },
    {
        name: 'French Guiana',
        countryISOCode: 'gf',
        countryPhoneCode: '+594',
    },
    {
        name: 'French Polynesia',
        countryISOCode: 'pf',
        countryPhoneCode: '+689',
    },
    {
        name: 'French Territories',
        countryISOCode: 'tf',
        countryPhoneCode: '+262',
    },
    {
        name: 'Gabon',
        countryISOCode: 'ga',
        countryPhoneCode: '+241',
    },
    {
        name: 'Gambia',
        countryISOCode: 'gm',
        countryPhoneCode: '+220',
    },
    {
        name: 'Georgia',
        countryISOCode: 'ge',
        countryPhoneCode: '+995',
    },
    {
        name: 'Germany',
        countryISOCode: 'de',
        countryPhoneCode: '+49',
    },
    {
        name: 'Ghana',
        countryISOCode: 'gh',
        countryPhoneCode: '+233',
    },
    {
        name: 'Gibraltar',
        countryISOCode: 'gi',
        countryPhoneCode: '+350',
    },
    {
        name: 'Greece',
        countryISOCode: 'gr',
        countryPhoneCode: '+30',
    },
    {
        name: 'Greenland',
        countryISOCode: 'gl',
        countryPhoneCode: '+299',
    },
    {
        name: 'Grenada',
        countryISOCode: 'gd',
        countryPhoneCode: '+1',
        areaCode: '473',
    },
    {
        name: 'Guadeloupe',
        countryISOCode: 'gp',
        countryPhoneCode: '+590',
    },
    {
        name: 'Guam',
        countryISOCode: 'gu',
        countryPhoneCode: '+1',
        areaCode: '671',
    },
    {
        name: 'Guatemala',
        countryISOCode: 'gt',
        countryPhoneCode: '+502',
    },
    {
        name: 'Guinea',
        countryISOCode: 'gn',
        countryPhoneCode: '+224',
    },
    {
        name: 'Guinea-Bissau',
        countryISOCode: 'gw',
        countryPhoneCode: '+245',
    },
    {
        name: 'Guyana',
        countryISOCode: 'gy',
        countryPhoneCode: '+592',
    },
    {
        name: 'Haiti',
        countryISOCode: 'ht',
        countryPhoneCode: '+509',
    },
    {
        name: 'Heard Island',
        countryISOCode: 'hm',
        countryPhoneCode: '+ ',
    },
    {
        name: 'Honduras',
        countryISOCode: 'hn',
        countryPhoneCode: '+504',
    },
    {
        name: 'Hong Kong',
        countryISOCode: 'hk',
        countryPhoneCode: '+852',
    },
    {
        name: 'Hungary',
        countryISOCode: 'hu',
        countryPhoneCode: '+36',
    },
    {
        name: 'Iceland',
        countryISOCode: 'is',
        countryPhoneCode: '+354',
    },
    {
        name: 'India',
        countryISOCode: 'in',
        countryPhoneCode: '+91',
    },
    {
        name: 'Indian Ocean Territory',
        countryISOCode: 'io',
        countryPhoneCode: '+246',
    },
    {
        name: 'Indonesia',
        countryISOCode: 'id',
        countryPhoneCode: '+62',
    },
    {
        name: 'Iran',
        countryISOCode: 'ir',
        countryPhoneCode: '+98',
    },
    {
        name: 'Iraq',
        countryISOCode: 'iq',
        countryPhoneCode: '+964',
    },
    {
        name: 'Ireland',
        countryISOCode: 'ie',
        countryPhoneCode: '+353',
    },
    {
        name: 'Israel',
        countryISOCode: 'il',
        countryPhoneCode: '+972',
    },
    {
        name: 'Italy',
        countryISOCode: 'it',
        countryPhoneCode: '+39',
    },
    {
        name: 'Jamaica',
        countryISOCode: 'jm',
        countryPhoneCode: '+1',
        areaCode: '876',
    },
    {
        name: 'Jan Mayen',
        countryISOCode: 'sj',
        countryPhoneCode: '+47',
    },
    {
        name: 'Japan',
        countryISOCode: 'jp',
        countryPhoneCode: '+81',
    },
    {
        name: 'Jordan',
        countryISOCode: 'jo',
        countryPhoneCode: '+962',
    },
    {
        name: 'Kazakhstan',
        countryISOCode: 'kz',
        countryPhoneCode: '+7',
    },
    {
        name: 'Kenya',
        countryISOCode: 'ke',
        countryPhoneCode: '+254',
    },
    {
        name: 'Kiribati',
        countryISOCode: 'ki',
        countryPhoneCode: '+686',
    },
    {
        name: 'Kuwait',
        countryISOCode: 'kw',
        countryPhoneCode: '+965',
    },
    {
        name: 'Kyrgyzstan',
        countryISOCode: 'kg',
        countryPhoneCode: '+996',
    },
    {
        name: 'Laos',
        countryISOCode: 'la',
        countryPhoneCode: '+856',
    },
    {
        name: 'Latvia',
        countryISOCode: 'lv',
        countryPhoneCode: '+371',
    },
    {
        name: 'Lebanon',
        countryISOCode: 'lb',
        countryPhoneCode: '+961',
    },
    {
        name: 'Lesotho',
        countryISOCode: 'ls',
        countryPhoneCode: '+266',
    },
    {
        name: 'Liberia',
        countryISOCode: 'lr',
        countryPhoneCode: '+231',
    },
    {
        name: 'Libya',
        countryISOCode: 'ly',
        countryPhoneCode: '+218',
    },
    {
        name: 'Liechtenstein',
        countryISOCode: 'li',
        countryPhoneCode: '+423',
    },
    {
        name: 'Lithuania',
        countryISOCode: 'lt',
        countryPhoneCode: '+370',
    },
    {
        name: 'Luxembourg',
        countryISOCode: 'lu',
        countryPhoneCode: '+352',
    },
    {
        name: 'Macau',
        countryISOCode: 'mo',
        countryPhoneCode: '+853',
    },
    {
        name: 'Macedonia',
        countryISOCode: 'mk',
        countryPhoneCode: '+389',
    },
    {
        name: 'Madagascar',
        countryISOCode: 'mg',
        countryPhoneCode: '+261',
    },
    {
        name: 'Malawi',
        countryISOCode: 'mw',
        countryPhoneCode: '+265',
    },
    {
        name: 'Malaysia',
        countryISOCode: 'my',
        countryPhoneCode: '+60',
    },
    {
        name: 'Maldives',
        countryISOCode: 'mv',
        countryPhoneCode: '+960',
    },
    {
        name: 'Mali',
        countryISOCode: 'ml',
        countryPhoneCode: '+223',
    },
    {
        name: 'Malta',
        countryISOCode: 'mt',
        countryPhoneCode: '+356',
    },
    {
        name: 'Marshall Islands',
        countryISOCode: 'mh',
        countryPhoneCode: '+692',
    },
    {
        name: 'Martinique',
        countryISOCode: 'mq',
        countryPhoneCode: '+596',
    },
    {
        name: 'Mauritania',
        countryISOCode: 'mr',
        countryPhoneCode: '+222',
    },
    {
        name: 'Mauritius',
        countryISOCode: 'mu',
        countryPhoneCode: '+230',
    },
    {
        name: 'Mayotte',
        countryISOCode: 'yt',
        countryPhoneCode: '+262',
    },
    {
        name: 'Mexico',
        countryISOCode: 'mx',
        countryPhoneCode: '+52',
    },
    {
        name: 'Micronesia',
        countryISOCode: 'fm',
        countryPhoneCode: '+691',
    },
    {
        name: 'Moldova',
        countryISOCode: 'md',
        countryPhoneCode: '+373',
    },
    {
        name: 'Monaco',
        countryISOCode: 'mc',
        countryPhoneCode: '+377',
    },
    {
        name: 'Mongolia',
        countryISOCode: 'mn',
        countryPhoneCode: '+976',
    },
    {
        name: 'Montenegro',
        countryISOCode: 'me',
        countryPhoneCode: '+382',
    },
    {
        name: 'Montserrat',
        countryISOCode: 'ms',
        countryPhoneCode: '+1',
        areaCode: '664',
    },
    {
        name: 'Morocco',
        countryISOCode: 'ma',
        countryPhoneCode: '+212',
    },
    {
        name: 'Mozambique',
        countryISOCode: 'mz',
        countryPhoneCode: '+258',
    },
    {
        name: 'Namibia',
        countryISOCode: 'na',
        countryPhoneCode: '+264',
    },
    {
        name: 'Nauru',
        countryISOCode: 'nr',
        countryPhoneCode: '+674',
    },
    {
        name: 'Nepal',
        countryISOCode: 'np',
        countryPhoneCode: '+977',
    },
    {
        name: 'Netherlands',
        countryISOCode: 'nl',
        countryPhoneCode: '+31',
    },
    {
        name: 'Netherlandsantilles',
        countryISOCode: 'an',
        countryPhoneCode: '+599',
    },
    {
        name: 'New Caledonia',
        countryISOCode: 'nc',
        countryPhoneCode: '+687',
    },
    {
        name: 'New Guinea',
        countryISOCode: 'pg',
        countryPhoneCode: '+675',
    },
    {
        name: 'New Zealand',
        countryISOCode: 'nz',
        countryPhoneCode: '+64',
    },
    {
        name: 'Nicaragua',
        countryISOCode: 'ni',
        countryPhoneCode: '+505',
    },
    {
        name: 'Niger',
        countryISOCode: 'ne',
        countryPhoneCode: '+227',
    },
    {
        name: 'Nigeria',
        countryISOCode: 'ng',
        countryPhoneCode: '+234',
    },
    {
        name: 'Niue',
        countryISOCode: 'nu',
        countryPhoneCode: '+683',
    },
    {
        name: 'Norfolk Island',
        countryISOCode: 'nf',
        countryPhoneCode: '+672',
    },
    {
        name: 'North Korea',
        countryISOCode: 'kp',
        countryPhoneCode: '+850',
    },
    {
        name: 'Northern Mariana Islands',
        countryISOCode: 'mp',
        countryPhoneCode: '+1',
        areaCode: '670',
    },
    {
        name: 'Norway',
        countryISOCode: 'no',
        countryPhoneCode: '+47',
    },
    {
        name: 'Oman',
        countryISOCode: 'om',
        countryPhoneCode: '+968',
    },
    {
        name: 'Pakistan',
        countryISOCode: 'pk',
        countryPhoneCode: '+92',
    },
    {
        name: 'Palau',
        countryISOCode: 'pw',
        countryPhoneCode: '+680',
    },
    {
        name: 'Palestine',
        countryISOCode: 'ps',
        countryPhoneCode: '+970',
    },
    {
        name: 'Panama',
        countryISOCode: 'pa',
        countryPhoneCode: '+507',
    },
    {
        name: 'Paraguay',
        countryISOCode: 'py',
        countryPhoneCode: '+595',
    },
    {
        name: 'Peru',
        countryISOCode: 'pe',
        countryPhoneCode: '+51',
    },
    {
        name: 'Philippines',
        countryISOCode: 'ph',
        countryPhoneCode: '+63',
    },
    {
        name: 'Pitcairn Islands',
        countryISOCode: 'pn',
        countryPhoneCode: '+870',
    },
    {
        name: 'Poland',
        countryISOCode: 'pl',
        countryPhoneCode: '+48',
    },
    {
        name: 'Portugal',
        countryISOCode: 'pt',
        countryPhoneCode: '+351',
    },
    {
        name: 'Puerto Rico',
        countryISOCode: 'pr',
        countryPhoneCode: '+1',
        areaCode: '787',
    },
    {
        name: 'Qatar',
        countryISOCode: 'qa',
        countryPhoneCode: '+974',
    },
    {
        name: 'Reunion',
        countryISOCode: 're',
        countryPhoneCode: '+262',
    },
    {
        name: 'Romania',
        countryISOCode: 'ro',
        countryPhoneCode: '+40',
    },
    {
        name: 'Russia',
        countryISOCode: 'ru',
        countryPhoneCode: '+7',
    },
    {
        name: 'Rwanda',
        countryISOCode: 'rw',
        countryPhoneCode: '+250',
    },
    {
        name: 'Saint Helena',
        countryISOCode: 'sh',
        countryPhoneCode: '+290',
    },
    {
        name: 'Saint Kitts and Nevis',
        countryISOCode: 'kn',
        countryPhoneCode: '+1',
        areaCode: '869',
    },
    {
        name: 'Saint Lucia',
        countryISOCode: 'lc',
        countryPhoneCode: '+1',
        areaCode: '758',
    },
    {
        name: 'Saint Pierre',
        countryISOCode: 'pm',
        countryPhoneCode: '+508',
    },
    {
        name: 'Saint Vincent',
        countryISOCode: 'vc',
        countryPhoneCode: '+1',
        areaCode: '784',
    },
    {
        name: 'Samoa',
        countryISOCode: 'ws',
        countryPhoneCode: '+685',
    },
    {
        name: 'San Marino',
        countryISOCode: 'sm',
        countryPhoneCode: '+378',
    },
    {
        name: 'Sandwich Islands',
        countryISOCode: 'gs',
        countryPhoneCode: '+500',
    },
    {
        name: 'Sao Tome',
        countryISOCode: 'st',
        countryPhoneCode: '+239',
    },
    {
        name: 'Saudi Arabia',
        countryISOCode: 'sa',
        countryPhoneCode: '+966',
    },
    {
        name: 'Scotland',
        countryISOCode: 'gb sct',
        countryPhoneCode: '+44',
    },
    {
        name: 'Senegal',
        countryISOCode: 'sn',
        countryPhoneCode: '+221',
    },
    {
        name: 'Serbia',
        countryISOCode: 'cs',
        countryPhoneCode: '+381',
    },
    {
        name: 'Seychelles',
        countryISOCode: 'sc',
        countryPhoneCode: '+248',
    },
    {
        name: 'Sierra Leone',
        countryISOCode: 'sl',
        countryPhoneCode: '+232',
    },
    {
        name: 'Singapore',
        countryISOCode: 'sg',
        countryPhoneCode: '+65',
    },
    {
        name: 'Slovakia',
        countryISOCode: 'sk',
        countryPhoneCode: '+421',
    },
    {
        name: 'Slovenia',
        countryISOCode: 'si',
        countryPhoneCode: '+386',
    },
    {
        name: 'Solomon Islands',
        countryISOCode: 'sb',
        countryPhoneCode: '+677',
    },
    {
        name: 'Somalia',
        countryISOCode: 'so',
        countryPhoneCode: '+252',
    },
    {
        name: 'South Africa',
        countryISOCode: 'za',
        countryPhoneCode: '+27',
    },
    {
        name: 'South Korea',
        countryISOCode: 'kr',
        countryPhoneCode: '+82',
    },
    {
        name: 'Spain',
        countryISOCode: 'es',
        countryPhoneCode: '+34',
    },
    {
        name: 'Sri Lanka',
        countryISOCode: 'lk',
        countryPhoneCode: '+94',
    },
    {
        name: 'Sudan',
        countryISOCode: 'sd',
        countryPhoneCode: '+249',
    },
    {
        name: 'Suriname',
        countryISOCode: 'sr',
        countryPhoneCode: '+597',
    },
    {
        name: 'Swaziland',
        countryISOCode: 'sz',
        countryPhoneCode: '+268',
    },
    {
        name: 'Sweden',
        countryISOCode: 'se',
        countryPhoneCode: '+46',
    },
    {
        name: 'Switzerland',
        countryISOCode: 'ch',
        countryPhoneCode: '+41',
    },
    {
        name: 'Syria',
        countryISOCode: 'sy',
        countryPhoneCode: '+963',
    },
    {
        name: 'Taiwan',
        countryISOCode: 'tw',
        countryPhoneCode: '+886',
    },
    {
        name: 'Tajikistan',
        countryISOCode: 'tj',
        countryPhoneCode: '+992',
    },
    {
        name: 'Tanzania',
        countryISOCode: 'tz',
        countryPhoneCode: '+255',
    },
    {
        name: 'Thailand',
        countryISOCode: 'th',
        countryPhoneCode: '+66',
    },
    {
        name: 'Timorleste',
        countryISOCode: 'tl',
        countryPhoneCode: '+670',
    },
    {
        name: 'Togo',
        countryISOCode: 'tg',
        countryPhoneCode: '+228',
    },
    {
        name: 'Tokelau',
        countryISOCode: 'tk',
        countryPhoneCode: '+690',
    },
    {
        name: 'Tonga',
        countryISOCode: 'to',
        countryPhoneCode: '+676',
    },
    {
        name: 'Trinidad',
        countryISOCode: 'tt',
        countryPhoneCode: '+1',
        areaCode: '868',
    },
    {
        name: 'Tunisia',
        countryISOCode: 'tn',
        countryPhoneCode: '+216',
    },
    {
        name: 'Turkey',
        countryISOCode: 'tr',
        countryPhoneCode: '+90',
    },
    {
        name: 'Turkmenistan',
        countryISOCode: 'tm',
        countryPhoneCode: '+993',
    },
    {
        name: 'Tuvalu',
        countryISOCode: 'tv',
        countryPhoneCode: '+688',
    },
    {
        name: 'U.A.E.',
        countryISOCode: 'ae',
        countryPhoneCode: '+971',
    },
    {
        name: 'Uganda',
        countryISOCode: 'ug',
        countryPhoneCode: '+256',
    },
    {
        name: 'Ukraine',
        countryISOCode: 'ua',
        countryPhoneCode: '+380',
    },
    {
        name: 'United Kingdom',
        countryISOCode: 'gb',
        countryPhoneCode: '+44',
    },
    {
        name: 'Uruguay',
        countryISOCode: 'uy',
        countryPhoneCode: '+598',
    },
    {
        name: 'US Minor Islands',
        countryISOCode: 'um',
        countryPhoneCode: '+1',
    },
    {
        name: 'US Virgin Islands',
        countryISOCode: 'vi',
        countryPhoneCode: '+1',
        areaCode: '340',
    },
    {
        name: 'Uzbekistan',
        countryISOCode: 'uz',
        countryPhoneCode: '+998',
    },
    {
        name: 'Vanuatu',
        countryISOCode: 'vu',
        countryPhoneCode: '+678',
    },
    {
        name: 'Vatican City',
        countryISOCode: 'va',
        countryPhoneCode: '+379',
    },
    {
        name: 'Venezuela',
        countryISOCode: 've',
        countryPhoneCode: '+58',
    },
    {
        name: 'Vietnam',
        countryISOCode: 'vn',
        countryPhoneCode: '+84',
    },
    {
        name: 'Wales',
        countryISOCode: 'gb wls',
        countryPhoneCode: '+44',
    },
    {
        name: 'Wallis and Futuna',
        countryISOCode: 'wf',
        countryPhoneCode: '+681',
    },
    {
        name: 'Western Sahara',
        countryISOCode: 'eh',
        countryPhoneCode: '+212',
    },
    {
        name: 'Yemen',
        countryISOCode: 'ye',
        countryPhoneCode: '+967',
    },
    {
        name: 'Zambia',
        countryISOCode: 'zm',
        countryPhoneCode: '+260',
    },
    {
        name: 'Zimbabwe',
        countryISOCode: 'zw',
        countryPhoneCode: '+263',
    },
];

export type CountryOption = Option<{
    countryISOCode: FlagNameValues;
}>;

export const usOption: CountryOption = {
    label: 'United States',
    value: '+1',
    countryISOCode: 'us',
};

export const optionsForSelect = countriesInformation.map((c: CountryInformation) => {
    const country: CountryOption = {
        label: `${c.name} (${c.countryPhoneCode})`,
        value: c.countryPhoneCode,
        countryISOCode: c.countryISOCode,
    };
    return country;
});

export function getSelectionFromPhoneCountryCode(
    phoneCountryCode: string,
    librarySuggestedCountry?: string | undefined
): CountryOption | undefined {
    let selection;
    if (librarySuggestedCountry)
        selection = optionsForSelect.find(
            (country) => country.countryISOCode === librarySuggestedCountry.toLowerCase()
        );
    return selection || optionsForSelect.find((country) => country.value === phoneCountryCode);
}

export const getLables = (option: CountryOption, { context }: any) => {
    const flag = <Flag name={option.countryISOCode} />;
    return context === 'menu' ? (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {flag}({option.value})
        </div>
    ) : (
        flag
    );
};
