import React from 'react';

const CarIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 21 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                d="M18.285 1.63625C18.06 0.9725 17.43 0.5 16.6875 0.5H4.3125C3.57 0.5 2.95125 0.9725 2.715 1.63625L0.375 8.375V17.375C0.375 17.9937 0.88125 18.5 1.5 18.5H2.625C3.24375 18.5 3.75 17.9937 3.75 17.375V16.25H17.25V17.375C17.25 17.9937 17.7563 18.5 18.375 18.5H19.5C20.1187 18.5 20.625 17.9937 20.625 17.375V8.375L18.285 1.63625ZM4.70625 2.75H16.2825L17.4525 6.125H3.53625L4.70625 2.75ZM18.375 14H2.625V8.375H18.375V14Z"
                fill={color || '#717680'}
            />
            <path
                d="M5.4375 12.875C6.36948 12.875 7.125 12.1195 7.125 11.1875C7.125 10.2555 6.36948 9.5 5.4375 9.5C4.50552 9.5 3.75 10.2555 3.75 11.1875C3.75 12.1195 4.50552 12.875 5.4375 12.875Z"
                fill={color || '#717680'}
            />
            <path
                d="M15.5625 12.875C16.4945 12.875 17.25 12.1195 17.25 11.1875C17.25 10.2555 16.4945 9.5 15.5625 9.5C14.6305 9.5 13.875 10.2555 13.875 11.1875C13.875 12.1195 14.6305 12.875 15.5625 12.875Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default CarIcon;
