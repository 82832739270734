import React from 'react';
import { Table, Button, Icon, Popup } from 'semantic-ui-react';

const RefundsModule = (props: any) => (
    <Table basic="very" selectable striped>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Summary</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Reason</Table.HeaderCell>
                <Table.HeaderCell>Slot</Table.HeaderCell>
                <Table.HeaderCell>Amount Requested</Table.HeaderCell>
                <Table.HeaderCell>Refund</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        {props.refunds &&
            props.refunds.reverse().map((refund: any) => {
                return (
                    <Table.Body key={refund.pk}>
                        <Table.Row>
                            <Table.Cell>
                                <Popup
                                    trigger={<Icon name="info circle" />}
                                    content={refund.summary}
                                    on="click"
                                    pinned
                                />
                            </Table.Cell>
                            <Table.Cell>{refund.created_at_formatted}</Table.Cell>
                            <Table.Cell>{refund.summary.substring(0, 10)}</Table.Cell>
                            <Table.Cell>{refund.justification}</Table.Cell>
                            <Table.Cell>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`http://airgarage.com/admin/spots/slot/${refund.slot}/change`}
                                >
                                    {refund.slot}
                                </a>
                            </Table.Cell>
                            <Table.Cell>
                                ${refund.amount / 100} {!refund.adjusted_end_date && <p>(full)</p>}{' '}
                            </Table.Cell>
                            <Table.Cell>
                                <Button color="green" icon="check" onClick={() => props.issueRefund(refund.pk)} />
                            </Table.Cell>
                            <Table.Cell>
                                <Button color={'red'} icon="close" onClick={() => props.ignoreRefund(refund.pk)} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                );
            })}
    </Table>
);
export default RefundsModule;
