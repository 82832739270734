import { DailyOccupancyReport, MonthlyOccupancyReport } from 'types';
import {
    DAILY_OCCUPANCY_REPORTS_FAILED,
    DAILY_OCCUPANCY_REPORTS_REQUESTED,
    DAILY_OCCUPANCY_REPORTS_SUCCESS,
    MONTHLY_OCCUPANCY_REPORTS_FAILED,
    MONTHLY_OCCUPANCY_REPORTS_REQUESTED,
    MONTHLY_OCCUPANCY_REPORTS_SUCCESS,
} from './constants';
import { Action } from '../useReducerWithMiddleware';

export type OccupancyReportState = {
    loadingMonthly: boolean;
    loadingDaily: boolean;
    errorMessageMonthly: string;
    errorMessageDaily: string;
    monthly: MonthlyOccupancyReport | null;
    daily: DailyOccupancyReport | null;
};

export const initialOccupancyReportState: OccupancyReportState = {
    loadingMonthly: false,
    loadingDaily: false,
    errorMessageMonthly: '',
    errorMessageDaily: '',
    monthly: null,
    daily: null,
};

export default function occupancyReportReducer(
    state: OccupancyReportState = initialOccupancyReportState,
    action: Action
): OccupancyReportState {
    switch (action.type) {
        case MONTHLY_OCCUPANCY_REPORTS_REQUESTED:
            return {
                ...state,
                loadingMonthly: true,
                errorMessageMonthly: '',
            };
        case MONTHLY_OCCUPANCY_REPORTS_SUCCESS:
            return {
                ...state,
                loadingMonthly: false,
                errorMessageMonthly: '',
                monthly: (action.payload as { monthly: MonthlyOccupancyReport }).monthly,
            };
        case MONTHLY_OCCUPANCY_REPORTS_FAILED:
            return {
                ...state,
                errorMessageMonthly: (action.payload as { message: string }).message,
                loadingMonthly: false,
            };
        case DAILY_OCCUPANCY_REPORTS_REQUESTED:
            return {
                ...state,
                loadingDaily: true,
                errorMessageDaily: '',
            };
        case DAILY_OCCUPANCY_REPORTS_SUCCESS:
            return {
                ...state,
                loadingDaily: false,
                errorMessageDaily: '',
                daily: (action.payload as { daily: DailyOccupancyReport }).daily,
            };
        case DAILY_OCCUPANCY_REPORTS_FAILED:
            return {
                ...state,
                errorMessageDaily: (action.payload as { message: string }).message,
                loadingDaily: false,
            };
        default:
            return state || initialOccupancyReportState;
    }
}
