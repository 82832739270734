import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ProfileBox from 'components/Dashboard/Sidebar/ProfileBox/ProfileBox';
import { getProfileAction } from 'actions/actions';
import amplitude from 'amplitude-js';
import * as Sentry from '@sentry/react';
import { AppState } from '../reducers/reducers';
import { selectedSpotInfo } from '../selectors';

type ProfileBoxContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const ProfileBoxContainer = (props: ProfileBoxContainerProps) => {
    const { profile, getProfile, selectedSpot } = props;
    useEffect(() => {
        if (profile) {
            const name = profile.first_name + ' ' + profile.last_name;
            amplitude.getInstance().setUserId(profile.email);
            Sentry.setUser({
                name: name,
                username: profile.email,
            });
        } else {
            getProfile();
        }
    });
    return (
        <div>
            {profile ? <ProfileBox profile={profile} selectedSpotInfo={selectedSpot} /> : <Loader active inline />}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        selectedSpot: selectedSpotInfo(state),
    };
}
const mapDispatchToProps = {
    getProfile: getProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBoxContainer);
