import React from 'react';
import { Grid, Button, Dropdown } from 'semantic-ui-react';
import { DailyReportInterface, ReportInterface } from 'types';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';
import MetricCard from '../MetricCard/MetricCard';
import BarGraph from '../SpotGraphs/BarGraph';
import LineGraph from '../SpotGraphs/LineGraph';

type GraphsProps = {
    fields: { monthYear: MonthYear['value']; rentalType: 'daily' | 'monthly' };
    reports: ReportInterface;
    handleRentalTypeFilterSelected: (rentalType: 'daily' | 'monthly') => void;
    handleDateFilterSelected: (monthYear: MonthYear['value']) => void;
    selectedDailyReports: DailyReportInterface['daily_reports'] | null;
};

const Graphs = ({
    selectedDailyReports,
    reports,
    handleDateFilterSelected,
    handleRentalTypeFilterSelected,
    fields,
}: GraphsProps) => {
    const monthYearUtil = new MonthYearUtil();
    const today = new Date().getDate() - 2;
    const lastWeek = today - 7;
    const isDaily = fields.rentalType === 'daily';
    const isMonthly = fields.rentalType === 'monthly';

    function revenueGraph(): JSX.Element {
        if (isDaily && selectedDailyReports) {
            return (
                <BarGraph
                    xData={selectedDailyReports.map((report) => report.date)}
                    yData={selectedDailyReports.map((report) => report.net_revenue / 100)}
                    dataLabel="Net Revenue ($)"
                />
            );
        }
        if (isMonthly) {
            return (
                <BarGraph
                    xData={reports.monthly.map((report) => report.date)}
                    yData={reports.monthly.map((report) => report.gross_revenue)}
                    dataLabel="Gross Revenue ($)"
                />
            );
        }
        return <></>;
    }

    return (
        <Grid.Row>
            <Grid.Column>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {selectedDailyReports && today > 9 && (
                        <>
                            <MetricCard
                                header="Yesterday's Gross Revenue"
                                metric={selectedDailyReports[today].gross_revenue}
                                oldMetric={selectedDailyReports[lastWeek].gross_revenue}
                                image={require('assets/money.png')}
                                format="currency"
                            />
                            <MetricCard
                                header="Yesterday's Net Revenue"
                                metric={selectedDailyReports[today].net_revenue}
                                oldMetric={selectedDailyReports[lastWeek].net_revenue}
                                image={require('assets/money.png')}
                                format="currency"
                            />
                            <MetricCard
                                header="Yesterday's Rentals"
                                metric={selectedDailyReports[today].count_of_rentals}
                                oldMetric={selectedDailyReports[lastWeek].count_of_rentals}
                                image={require('assets/car.png')}
                            />
                        </>
                    )}
                </div>
                {selectedDailyReports && today > 9 && (
                    <p>
                        Comparing {selectedDailyReports[today].date} to {selectedDailyReports[lastWeek].date}
                    </p>
                )}

                <h2>
                    {isMonthly ? 'Gross Revenue' : 'Net Revenue'}
                    <Button.Group floated="right">
                        <Button
                            type="button"
                            onClick={() => handleRentalTypeFilterSelected('daily')}
                            active={isDaily}
                            content="Daily"
                        />
                        <Button.Or />
                        <Button
                            type="button"
                            onClick={() => handleRentalTypeFilterSelected('monthly')}
                            active={isMonthly}
                            content="Monthly"
                        />
                    </Button.Group>
                </h2>
                {isDaily && (
                    <Dropdown
                        name="monthYear"
                        placeholder="Select month and year"
                        compact
                        selection
                        onChange={(e, data) => handleDateFilterSelected(data.value as string)}
                        options={monthYearUtil.monthYears}
                        value={fields.monthYear}
                    />
                )}

                <div style={{ height: 400 }}>{revenueGraph()}</div>

                <div style={{ marginTop: 30, marginBottom: 100 }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <div style={{ height: 300 }}>
                                    <LineGraph
                                        title="Total Rentals by Week Day"
                                        dataLabel="Total Rentals"
                                        description={
                                            'This is the total number of rentals made each day of the week for the last 2 weeks.'
                                        }
                                        xData={Object.keys(reports.weekly_usage)}
                                        yData={Object.values(reports.weekly_usage)}
                                    />
                                </div>
                            </Grid.Column>

                            <Grid.Column width={8}>
                                <div style={{ height: 300 }}>
                                    <LineGraph
                                        title="Total Rentals Started by Hour"
                                        dataLabel="Total Rentals"
                                        description={
                                            'This is the total number of rentals started by the hour for the last 2 weeks.'
                                        }
                                        xData={Object.keys(reports.hourly_usage)}
                                        yData={Object.values(reports.hourly_usage)}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Grid.Column>
        </Grid.Row>
    );
};

export default Graphs;
