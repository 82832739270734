/* Saga Selectors */
import { matchPath } from 'react-router-dom';
import { AppState } from 'reducers/reducers';
import { ReportInterface, SpotInterface } from 'types';

export const tokenState = (state: AppState) => state.auth.token;
export const selectedOrganizationId = (state: AppState): number | null => {
    let match = matchPath(state.router.location.pathname, {
        path: '/organization/:organizationId/*',
    });
    if (match === null) {
        match = matchPath(state.router.location.pathname, {
            path: '/organization/:organizationId',
        });
    }
    const id = match && (match.params as { organizationId: string })['organizationId'];
    return id ? parseInt(id) : null;
};
export const selectedSpotId = (state: AppState): number | null => {
    let match = matchPath(state.router.location.pathname, {
        path: '*/spots/:spotId/*',
    });
    if (!match) {
        match = matchPath(state.router.location.pathname, {
            path: '*/spots/:spotId',
        });
    }
    const id = match && (match.params as { spotId: string })['spotId'];
    return id ? parseInt(id) : null;
};
export const selectedSpotInfo = (state: AppState): SpotInterface | null => {
    const id = selectedSpotId(state);
    const { orgSpots } = state.spots;
    const info = id && orgSpots && orgSpots[id];
    return info || null;
};
export const reportForSelectedSpot = (state: AppState): ReportInterface | null => {
    const id = selectedSpotId(state);
    if (!id) {
        return null;
    }
    return state.reports.reports[id] || null;
};

export const reportLastUpdatedAtForSelectedSpot = (state: AppState): Date | null => {
    const id = selectedSpotId(state);
    if (!id) {
        return null;
    }
    return state.reports.reportsLastUpdatedAt[id] || null;
};

export const expiringState = (state: AppState): boolean | null => {
    const { search } = state.router.location;
    const params = new URLSearchParams(search);
    const expiring = params.get('expiring');
    if (expiring === null) {
        return null;
    }
    if (expiring.toLowerCase() === 'false') {
        return false;
    }
    return Boolean(expiring);
};

export const selectSalesRepDropdown = (state: AppState) => {
    const salesReps = state.organization.salesReps;
    return salesReps && salesReps.map((rep: any) => ({ key: rep.pk, text: rep.email, value: rep.pk }));
};
