import React, { useState } from 'react';

type Step = {
    component: React.FC<any>;
    props?: Record<string, any>;
};

type StepsProps = {
    steps: Step[];
};

export interface StepComponentProps {
    goNext: () => void;
    goPrev: () => void;
    goTo: (step: number) => void;
    currentStep: number;
}

function Steps({ steps }: StepsProps): JSX.Element {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleGoTo = (step: number) => {
        if (step >= 0 && step < steps.length) {
            setCurrentStep(step);
        }
    };

    const renderStep = () => {
        const StepComponent = steps[currentStep].component;
        const props = steps[currentStep].props;
        return (
            <StepComponent
                {...props}
                goNext={handleNext}
                goPrev={handlePrev}
                goTo={handleGoTo}
                currentStep={currentStep}
            />
        );
    };

    return <div>{renderStep()}</div>;
}

export default Steps;
