import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { ExperimentCalendarRecord } from 'types/Experiment';

type ExperimentsCalendarClientParams = HTTPClientParameters;

class ExperimentsCalendarClient extends HTTPClient {
    constructor(params: ExperimentsCalendarClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    getExperiments(spotPk: number, start: number, end: number): Promise<Array<ExperimentCalendarRecord>> {
        return this.get(`experiments_calendar/?spot=${spotPk}&start=${start}&end=${end}`);
    }
}

export default ExperimentsCalendarClient;
