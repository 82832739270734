import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useEffect, useRef, useState } from 'react';
import styles from './SearchBox.module.css';

interface SearchBoxProps {
    onChange: (searchTerm: string) => void;
    initialValue?: string;
}

const SearchBox: React.FC<SearchBoxProps> = ({ onChange, initialValue = '' }) => {
    const [value, setValue] = useState(initialValue);
    const [showInput, setShowInput] = useState(false);
    const inputEl = useRef<HTMLInputElement | null>(null);

    function openInput() {
        setShowInput(true);
        inputEl.current?.focus();
    }

    const onSearchClick = () => {
        openInput();
    };

    const handleValueChange = (value: string) => {
        setValue(value);
        onChange(value);
    };

    const ref = useClickOutside(() => {
        if (!value) {
            setShowInput(false);
        }
    });

    useEffect(() => {
        if (value) {
            openInput();
        }
    }, [value]);

    return (
        <div className={styles.searchBoxContainer} ref={ref}>
            <div className={[styles.searchInputContainer, showInput && styles.showInput].join(' ')}>
                <input
                    placeholder="Type to search"
                    name="search"
                    onChange={(e) => handleValueChange(e.currentTarget.value)}
                    value={value}
                    ref={inputEl}
                />
            </div>

            <div className={styles.searchBoxIcon}>
                {value ? (
                    <button className={styles.clearText} onClick={() => handleValueChange('')}>
                        Clear
                    </button>
                ) : (
                    <button className={styles.iconButton} onClick={onSearchClick} disabled={showInput}>
                        <SearchIcon />
                    </button>
                )}
            </div>
        </div>
    );
};

export default SearchBox;
