import { RateCalendarRecord } from 'types/Rate';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';

type RateCalendarClientParams = HTTPClientParameters;

class RatesCalendarClient extends HTTPClient {
    constructor(params: RateCalendarClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    getRates(spotPk: number, start: number, end: number): Promise<Array<RateCalendarRecord>> {
        return this.get(`rates_calendar/?spot=${spotPk}&start=${start}&end=${end}`);
    }
}

export default RatesCalendarClient;
