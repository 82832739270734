import { useState, SyntheticEvent } from 'react';
import { DropdownProps } from 'semantic-ui-react';

function useForm<T extends Record<string, any>>(state: T = {} as T) {
    const [inputs, setInputs] = useState(state);
    function handleInput(event: SyntheticEvent<HTMLInputElement>): void {
        event.persist();
        const target = event.target as HTMLInputElement;
        const value = target.value;
        setInputs((inputs: T) => ({ ...inputs, [target.name]: value }));
    }
    function handleDropdown(event: SyntheticEvent<HTMLElement>, data: DropdownProps) {
        setInputs((inputs: T) => ({ ...inputs, [data.name]: data.value }));
    }
    // for non-input and non-dropdown changes
    function handleValueChange<U extends keyof T>(name: U, newValue: T[U]): void {
        setInputs((inputs: T) => ({ ...inputs, [name]: newValue }));
    }
    function resetState(inputState: T): void {
        setInputs(() => ({ ...inputState }));
    }
    return { handleInput, handleDropdown, handleValueChange, resetState, inputs };
}

export default useForm;
