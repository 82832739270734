import React, { useState } from 'react';
import { Popup, Table, Icon, Label, Step, Button, Divider, Message } from 'semantic-ui-react';
import OrganizationInfoModalForm from './OrganizationInfoModalForm';
import BankAccountModalForm from './BankAccountModalForm';
import AccountRepresentativeModalForm from './AccountRepresentativeModalForm';

const PayoutAccountInfo = (props: any) => {
    const [showSettings, setShowSettings] = useState(false);

    return (
        <div>
            <Step.Group stackable="tablet">
                <Step>
                    <Icon name="hourglass half" />
                    <Step.Content>
                        <Step.Title>{props.organization.pending_funds}</Step.Title>
                        <Step.Description>Funds processing</Step.Description>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>{props.organization.available_funds} </Step.Title>
                        <Step.Description>Funds ready for payout</Step.Description>
                    </Step.Content>
                </Step>
                <Step>
                    <Icon name="dollar" />
                    <Step.Content>
                        <Step.Title>{props.organization.owed_funds} </Step.Title>
                        <Step.Description>Total future payout</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Button
                icon="cog"
                content={showSettings ? 'Hide' : 'Show banking info'}
                onClick={() => setShowSettings(!showSettings)}
                style={{ float: 'right' }}
            />

            <Divider hidden />

            {showSettings && (
                <>
                    {props.info.verification_status === 'unverified' && (
                        <Message color="orange">
                            Additional verification information is required to enable payout or charge capabilities on
                            this account. Please update your account details below.
                        </Message>
                    )}
                    <Table basic="very">
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon color="grey" name="building" size="large" />
                                    Bank Account
                                </Table.Cell>
                                <Table.Cell>
                                    {props.info.bank_account ? (
                                        <Label icon="checkmark" content={props.info.bank_account} color="green" />
                                    ) : (
                                        <Label icon="x" content="No account added" />
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <BankAccountModalForm addInfo={props.addInfo} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon color="grey" name="user" size="large" />
                                    Account representative{'  '}
                                    <Popup
                                        content="To prevent fraud and abuse, our payment processor requires identity verification from someone who works at the organization. Information like DOB or identity documents may be needed as payout amounts increase."
                                        trigger={<Label pointing="left" icon="question" />}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    {props.info.representative ? (
                                        <Label icon="checkmark" content={props.info.representative} color="green" />
                                    ) : (
                                        <Label icon="x" content="No account representative added" />
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <AccountRepresentativeModalForm addInfo={props.addInfo} />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon color="grey" name="shield" size="large" />
                                    Organization EIN
                                </Table.Cell>
                                <Table.Cell>
                                    {props.info.has_ein ? (
                                        <Label icon="checkmart" content="EIN verified" color="green" />
                                    ) : (
                                        <Label icon="x" content="EIN not verified" />
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <OrganizationInfoModalForm addInfo={props.addInfo} />
                                </Table.Cell>
                            </Table.Row>
                            {props.organization && (
                                <Table.Row>
                                    <Table.Cell>
                                        <Icon color="grey" name="info circle" size="large" />
                                        Payout schedule
                                    </Table.Cell>
                                    <Table.Cell>
                                        {props.organization.payout_schedule
                                            ? `Payouts occur ${props.organization.payout_schedule} and may take a 3-5 days to complete.`
                                            : `Payouts occur by the 7th of each month for the previous month's revenue`}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </>
            )}
        </div>
    );
};

export default PayoutAccountInfo;
