import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

const LinkLikeButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, children, ...restProps }) => {
    return (
        <button type="button" className={styles.linkLikeButton} {...restProps}>
            {children}
        </button>
    );
};

export default LinkLikeButton;
