import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Loader, Card, Icon } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import { getReportsAction, getSpotMultidayPricingAction, updateReportsAction } from 'actions/actions';
import SpotHeader from 'components/Dashboard/SpotWidgets/SpotHeader';
import OverviewWidget from 'components/OverviewWidget/OverviewWidget';
import OccupancyWidget from 'components/OccupancyWidget/OccupancyWidget';
import PayoutWidget from 'components/PayoutWidget/PayoutWidget';
import RevenueWidget from 'components/RevenueWidget/RevenueWidget';
import DynamicPricingWidget from 'components/PricingWidget/DynamicPricingWidget';
import StandardPricingWidget from 'components/PricingWidget/StandardPricingWidget';
import EnforcementWidget from 'components/EnforcementWidget/EnforcementWidget';
import useInterval from 'hooks/useInterval';
import { AppState } from 'reducers/reducers';
import { reportForSelectedSpot, reportLastUpdatedAtForSelectedSpot, selectedSpotId, selectedSpotInfo } from 'selectors';
import { trackPageViewed } from 'utils/analytics';
import styles from './SpotDetailContainer.module.css';
import env from 'env';
import DriverInsightsWidget from 'components/DriverInsightsWidget/DriverInsightsWidget';

type SpotDetailContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const MILLISECONDS_TO_UPDATE_REPORTS = env.MINUTES_TO_UPDATE_REPORTS * 60 * 1000;

const SpotDetailContainer = ({
    orgSpots,
    orgSpotsLoading,
    organization,
    spotId,
    currentSpot,
    push: connectedPush,
    getReports,
    getMultidayPricing,
    updateReports,
    report,
    reportError,
    isReportLoading,
    multidayRates,
    loadingRates,
    rateErrorMessage,
    profile,
    updateReportsLoading,
    lastUpdatedAt,
}: SpotDetailContainerProps): JSX.Element => {
    const [showEnforcementWidget, setShowEnforcementWidget] = useState(false);

    useInterval({
        callback: updateReports,
        intervalTime: MILLISECONDS_TO_UPDATE_REPORTS,
        lastCalledAt: lastUpdatedAt,
    });

    useEffect(() => {
        trackPageViewed({
            pageName: 'Spot Detail',
            organizationId: organization.pk,
            spotId,
            spot_detail_page_version: 'v3',
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [profile, organization, spotId]);

    useEffect(() => {
        if (currentSpot && !report) getReports();
    }, [currentSpot, getReports, report]);

    useEffect(() => {
        if (report) setShowEnforcementWidget(report.enforcement_uplift.year_to_date.enforcement_revenue > 0);
    }, [report]);

    useEffect(() => {
        if (currentSpot?.dynamic_pricing_enabled) {
            getMultidayPricing(6);
        }
    }, [currentSpot, getMultidayPricing]);

    if (orgSpots === null || orgSpotsLoading) {
        return <Loader active inline />;
    }
    // From now on, the spot object is non-null; since the selector uses the orgSpots.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spotArray = Object.values(orgSpots!);
    if (spotArray.length === 0 || !currentSpot) {
        return <></>;
    }

    const onSelectSpot = (pk: number) => {
        if (pk !== currentSpot.pk) {
            connectedPush(`/organization/${organization.pk}/spots/${pk}/detail`);
        }
    };

    const navigateToNewPage = (url: string) => {
        connectedPush(`/organization/${organization.pk}/spots/${currentSpot.pk}/${url}`);
    };

    return (
        <Card fluid>
            <Card.Content>
                <SpotHeader
                    currentSpot={currentSpot}
                    orgSpots={spotArray}
                    onSelectSpot={onSelectSpot}
                    organization={organization}
                />
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column computer={6} tablet={16}>
                            <PayoutWidget organization={organization} />
                            <OverviewWidget
                                reportError={reportError}
                                report={report}
                                isReportLoading={isReportLoading}
                                updateReportsLoading={updateReportsLoading}
                                spotId={currentSpot.uuid}
                                spotTimeZone={currentSpot.timezone}
                            />
                            {showEnforcementWidget && (
                                <EnforcementWidget
                                    report={report}
                                    loading={isReportLoading}
                                    updateReportsLoading={updateReportsLoading}
                                />
                            )}
                            {/* Depending on dynamic pricing being enabled we should render OccupancyWidget here or on the other column */}
                            {(currentSpot.dynamic_pricing_enabled || !showEnforcementWidget) &&
                                currentSpot.display_price_monthly && (
                                    <OccupancyWidget spot={currentSpot} report={report} />
                                )}
                            {currentSpot.dynamic_pricing_enabled && (
                                <DriverInsightsWidget currentSpot={currentSpot} navigateToNewPage={navigateToNewPage} />
                            )}

                            <div className={styles.timezoneWrapperLarge}>
                                <Icon name="clock outline" />
                                <span>Timezone: {currentSpot.timezone}</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={10} tablet={16}>
                            <RevenueWidget
                                report={report}
                                isReportLoading={isReportLoading}
                                reportError={reportError}
                                updateReportsLoading={updateReportsLoading}
                            />
                            {currentSpot.dynamic_pricing_enabled ? (
                                <DynamicPricingWidget
                                    spot={currentSpot}
                                    multidayRates={multidayRates}
                                    loadingRates={loadingRates}
                                    rateErrorMessage={rateErrorMessage}
                                    report={report}
                                    isReportLoading={isReportLoading}
                                    updateReportsLoading={updateReportsLoading}
                                />
                            ) : (
                                <>
                                    <StandardPricingWidget spot={currentSpot} />
                                    {/* Depending on dynamic pricing being enabled we should render OccupancyWidget here or on the other column */}
                                    {showEnforcementWidget && currentSpot.display_price_monthly && (
                                        <OccupancyWidget spot={currentSpot} layout="horizontal" report={report} />
                                    )}
                                    <DriverInsightsWidget
                                        currentSpot={currentSpot}
                                        navigateToNewPage={navigateToNewPage}
                                    />
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <div className={styles.timezoneWrapperSmall}>
                        <Grid.Row>
                            <Grid.Column>
                                <Icon name="clock outline" />
                                <span>Timezone: {currentSpot.timezone}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </div>
                </Grid>
            </Card.Content>
        </Card>
    );
};

function mapStateToProps(state: AppState) {
    return {
        orgSpots: state.spots.orgSpots,
        orgSpotsLoading: state.spots.orgSpotsLoading,
        currentSpot: selectedSpotInfo(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spotId: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        report: reportForSelectedSpot(state),
        reportError: state.reports.errorMessage,
        isReportLoading: state.reports.loading,
        multidayRates: state.spots.spotMultidayPricing,
        loadingRates: state.spots.spotMultidayPricingLoading,
        rateErrorMessage: state.spots.spotMultidayPricingErrorMessage,
        updateReportsLoading: state.reports.loadingUpdate,
        lastUpdatedAt: reportLastUpdatedAtForSelectedSpot(state),
    };
}

const mapDispatchToProps = {
    push,
    getReports: getReportsAction,
    getMultidayPricing: getSpotMultidayPricingAction,
    updateReports: updateReportsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpotDetailContainer);
