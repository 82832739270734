import React, { useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { SpotInterface } from 'types';
import WidgetBox from '../widgets/WidgetBox';
import RatingGraph from 'components/RatingGraph/RatingGraph';
import StarIcon from 'components/Icons/StarIcon';
import WidgetDataLabel from 'components/widgets/WidgetDataLabel';
import { useReviewReport } from 'contexts/ReviewReportContext';
import { DAYS_FOR_REVIEW_REPORT } from 'utils/constants';
import LinkLikeButton from 'components/Button/LinkLikeButton';
import { ReactComponent as CaretRight } from 'assets/icons/caretRight.svg';

type DriverInsightsWidgetProps = {
    currentSpot: SpotInterface;
    navigateToNewPage: (url: string) => void;
};

function DriverInsightsWidget({ currentSpot, navigateToNewPage }: DriverInsightsWidgetProps): JSX.Element {
    const { getReviewReports, reviewReport, loading, errorMessage } = useReviewReport();

    useEffect(() => {
        if (currentSpot) getReviewReports(currentSpot.uuid, DAYS_FOR_REVIEW_REPORT);
    }, [currentSpot, getReviewReports]);

    return (
        <WidgetBox title="Driver Insights" icon={<StarIcon />}>
            {loading || !reviewReport ? (
                <>
                    {errorMessage ? <Message color="red">{errorMessage}</Message> : <Loader active inline="centered" />}
                </>
            ) : (
                <>
                    {!!errorMessage && <Message color="red">{errorMessage}</Message>}
                    <WidgetDataLabel
                        label={`This is the number of drivers who gave their rental in your lot a rating in the last ${DAYS_FOR_REVIEW_REPORT} days.`}
                        tooltip={{
                            title: 'Driver insights',
                            message:
                                'Not all drivers leave feedback but for those who do, we have aggregated their responses here.',
                        }}
                    />
                    <div style={{ marginBlock: '20px' }}>
                        <RatingGraph ratingReport={reviewReport.rating_report} graphKey={currentSpot.uuid} />
                    </div>
                    <LinkLikeButton onClick={() => navigateToNewPage('driver-insights')}>
                        <span>See More Insights</span>
                        <CaretRight />
                    </LinkLikeButton>
                </>
            )}
        </WidgetBox>
    );
}

export default DriverInsightsWidget;
