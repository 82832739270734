import React from 'react';
import { Loader, Tab } from 'semantic-ui-react';
import EnforcementIcon from 'components/Icons/EnforcementIcon';
import { ReportInterface } from 'types';
import { usdFormatterWithoutCents } from 'utils/helpers';
import MonthYearUtil from 'utils/monthYear';
import WidgetBox from '../widgets/WidgetBox';
import RevenueAndCars from './RevenueAndCars';
import LiveUpdateLabel from 'components/widgets/LiveUpdateLabel';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';

type EnforcementWidgetProps = {
    report: ReportInterface | null;
    loading: boolean;
    updateReportsLoading: boolean;
};

function EnforcementWidget({ report, loading, updateReportsLoading }: EnforcementWidgetProps): JSX.Element {
    if (loading || !report) {
        return (
            <WidgetBox title="Enforcement Revenue" icon={<EnforcementIcon />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }

    const monthYearUtils = new MonthYearUtil();

    const { text: currentMonthYearText } = monthYearUtils.currentMonthYear; // This is something like `{ text: "April 2023" }`
    const [currentMonth, currentYear] = currentMonthYearText.split(' ');

    const panes = [];

    if (report.enforcement_uplift.seven_days.enforcement_revenue > 0) {
        panes.push({
            menuItem: 'This Week',
            render(): JSX.Element {
                return (
                    <Tab.Pane attached={false}>
                        <RevenueAndCars
                            revenue={usdFormatterWithoutCents(report.enforcement_uplift.seven_days.enforcement_revenue)}
                            cars={report.enforcement_uplift.seven_days.rental_count.toLocaleString('en-US')}
                        />
                    </Tab.Pane>
                );
            },
        });
    }

    if (report.enforcement_uplift.month_to_date.enforcement_revenue > 0) {
        panes.push({
            menuItem: currentMonth,
            render(): JSX.Element {
                return (
                    <Tab.Pane attached={false}>
                        <RevenueAndCars
                            revenue={usdFormatterWithoutCents(
                                report.enforcement_uplift.month_to_date.enforcement_revenue
                            )}
                            cars={report.enforcement_uplift.month_to_date.rental_count.toLocaleString('en-US')}
                        />
                    </Tab.Pane>
                );
            },
        });
    }

    if (report.enforcement_uplift.year_to_date.enforcement_revenue > 0) {
        panes.push({
            menuItem: currentYear,
            render(): JSX.Element {
                return (
                    <Tab.Pane attached={false}>
                        <RevenueAndCars
                            revenue={usdFormatterWithoutCents(
                                report.enforcement_uplift.year_to_date.enforcement_revenue
                            )}
                            cars={report.enforcement_uplift.year_to_date.rental_count.toLocaleString('en-US')}
                        />
                    </Tab.Pane>
                );
            },
        });
    }

    return (
        <WidgetBox
            title="Enforcement Revenue"
            icon={<EnforcementIcon />}
            updatesLabel={<LiveUpdateLabel loading={updateReportsLoading} />}
        >
            <Tab menu={{ secondary: true }} panes={panes} />
        </WidgetBox>
    );
}

function EnforcementWidgetWrapper(props: EnforcementWidgetProps): JSX.Element {
    return (
        <AuthorizedView allowed={Roles.Admin}>
            <EnforcementWidget {...props} />
        </AuthorizedView>
    );
}

export default EnforcementWidgetWrapper;
