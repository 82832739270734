import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Loader, Checkbox, Button } from 'semantic-ui-react';
import { getCurrentlyParkedAction, addEnforcementPenaltyAction } from 'actions/actions';
import CurrentlyParked from 'components/Dashboard/Modules/CurrentlyParked';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type CurrentlyParkedModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const CurrentlyParkedModuleContainer = (props: CurrentlyParkedModuleContainerProps) => {
    const { records, selectedSpot, getCurrentlyParked, loading, profile, addEnforcementPenalty, organization } = props;
    const [violatorsOnly, setViolatorsOnly] = useState(true);
    useEffect(() => {
        getCurrentlyParked(violatorsOnly); // true - only get violators by
    }, [selectedSpot, getCurrentlyParked, violatorsOnly]);
    useEffect(() => {
        trackPageViewed({
            pageName: 'Currently Parked',
            organizationId: organization,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organization, selectedSpot, profile]);
    const cars = records && records[selectedSpot];
    return (
        <div>
            {selectedSpot && profile.superuser && (
                <>
                    <Checkbox
                        toggle
                        defaultChecked
                        label="Violators only"
                        style={{ marginBottom: '20px', marginRight: '20px' }}
                        onClick={() => setViolatorsOnly(!violatorsOnly)}
                    >
                        {' '}
                    </Checkbox>
                </>
            )}

            <Button
                color="blue"
                content="Check Plate"
                onClick={() => {
                    props.push(`/organization/${organization}/spots/${selectedSpot}/check-plate`);
                }}
            />
            {!loading ? (
                <div>
                    <CurrentlyParked
                        records={cars}
                        addEnforcementPenalty={addEnforcementPenalty}
                        superuser={profile.superuser}
                    />
                </div>
            ) : (
                <Loader active inline />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.enforcements.errorMessage,
        loading: state.enforcements.loading,
        records: state.enforcements.currentlyParked,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getCurrentlyParked: getCurrentlyParkedAction,
    addEnforcementPenalty: addEnforcementPenaltyAction,
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyParkedModuleContainer);
