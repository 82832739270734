import React from 'react';

const EnforcementIcon = ({ color }: { color?: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0952 13.5817L16.184 14.4928L15.1796 13.4884L16.0908 12.5773L17.9358 10.7323L13.133 5.92951L10.3769 8.68565L9.622 7.93077L10.5331 7.01962L4.16392 0.650391L0.650391 4.1614L7.01962 10.5306L7.91942 9.63334L8.6743 10.3882L7.77702 11.288L6.99063 12.0719C5.53128 11.2767 4.2345 11.1381 3.54767 11.8249C2.99191 12.3844 2.95788 13.3523 3.45441 14.5558C3.67873 15.0952 3.99883 15.6623 4.39959 16.2307C4.72851 16.6957 5.1091 17.162 5.53254 17.6106L3.56783 19.5653C3.24143 19.4544 2.86966 19.5312 2.61132 19.7921C2.24459 20.1588 2.24459 20.7549 2.61132 21.1204C2.97804 21.4871 3.57413 21.4871 3.9396 21.1204C4.19795 20.862 4.27735 20.4903 4.16897 20.1664L6.13367 18.2105C7.11539 19.1305 8.17399 19.8488 9.15067 20.2483C9.71526 20.484 10.2294 20.5999 10.6718 20.5999C11.1708 20.5999 11.5829 20.4525 11.8778 20.1538C12.5331 19.501 12.4399 18.2949 11.7442 16.9213L12.5785 16.0895L13.4758 15.1897L14.4802 16.1941L13.5804 17.0914L19.8791 23.3901L23.3926 19.879L17.0952 13.5817ZM11.2767 19.5539C10.9868 19.8438 10.3139 19.8097 9.47707 19.4632C8.98558 19.2615 8.46637 18.9666 7.94967 18.5974C7.54135 18.3113 7.13178 17.9786 6.73732 17.6068L7.85893 16.4915L7.26032 15.8866L6.13619 17.0045C5.27671 16.0908 4.60879 15.1166 4.23954 14.2282C3.89297 13.3914 3.85895 12.7184 4.1488 12.4285C4.28239 12.295 4.48906 12.2294 4.75371 12.2294C5.71023 12.2294 7.41659 13.0889 9.01457 14.6869C11.0549 16.7297 11.8929 18.9402 11.2767 19.5539Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default EnforcementIcon;
