import React from 'react';

function EmailAndCheckIcon({ color, ...restProps }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 152 152" fill="none" {...restProps}>
            <path
                opacity="0.5"
                d="M120.324 126.667H31.657C21.1627 126.667 12.657 118.161 12.657 107.667V44.3336C12.657 33.8393 21.1627 25.3336 31.657 25.3336H120.324C130.818 25.3336 139.324 33.8393 139.324 44.3336V107.667C139.324 118.161 130.818 126.667 120.324 126.667Z"
                fill="#FA824C"
            />
            <path
                d="M62.9563 74.512L12.657 44.3336C12.657 33.8393 21.1627 25.3336 31.657 25.3336H120.324C130.818 25.3336 139.324 33.8393 139.324 44.3336L89.0243 74.512C81 79.3253 70.9807 79.3253 62.9563 74.512Z"
                fill="#FA824C"
            />
            <path
                d="M61.9772 73.3585C59.9438 71.3252 56.6459 71.3252 54.6105 73.3585C52.5751 75.3918 52.5772 78.6897 54.6105 80.7252L64.2876 90.4023C67.3063 93.421 72.198 93.421 75.2168 90.4023L77.1188 88.5002L61.9772 73.3585Z"
                fill="white"
            />
            <path
                d="M97.3933 60.8584C95.3599 58.825 92.062 58.825 90.0266 60.8584L69.7516 81.1334L77.1183 88.5L97.3933 68.225C99.4266 66.1896 99.4266 62.8917 97.3933 60.8584Z"
                fill="white"
            />
        </svg>
    );
}

export default EmailAndCheckIcon;
