import React from 'react';
import { Button, Modal, Form, Header, Divider } from 'semantic-ui-react';
import useForm from '../../../hooks/useForm';
import useModal from '../../../hooks/useModal';

const PayoutAccountModalForm = (props: any) => {
    const { handleInput, inputs } = useForm();
    const { isShowing, toggle } = useModal();
    const fields = inputs as any;
    function addPayoutInfo() {
        props.addInfo(inputs);
        toggle();
    }
    return (
        <div>
            <Button floated="right" icon="pencil alternate" onClick={toggle} />
            <Modal as={Form} open={isShowing} onClose={toggle} onSubmit={addPayoutInfo}>
                <Header content="Update organization" as="h2" />
                <Modal.Content>
                    <Form.Input
                        value={fields.account}
                        name="ein"
                        onChange={handleInput}
                        type="text"
                        placeholder="EIN"
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic floated="left" color="red" icon="times" content="Close" onClick={toggle} />
                    <Button floated="right" type="submit" color="green" icon="save" content="Save" />
                    <Divider hidden />
                    <Divider hidden />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default PayoutAccountModalForm;
