import React from 'react';
import styles from './AGBadge.module.css';

type AGBadgeProps = {
    text: string;
};

const AGBadge = ({ text }: AGBadgeProps) => {
    return <span className={styles.badge}>{text}</span>;
};

export default AGBadge;
