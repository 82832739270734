import { RATES_REQUESTED, RATES_SUCCESS, RATES_FAILED } from './constants';
import { Action } from '../useReducerWithMiddleware';
import { RateCalendarRecord } from 'types/Rate';

export type RatesCalendarState = {
    rates: Array<RateCalendarRecord>;
    errorMessage: string;
    loading: boolean;
};

export const initialRatesCalendarState: RatesCalendarState = {
    rates: [],
    errorMessage: '',
    loading: false,
};

export default function rateCalendarReducer(
    state: RatesCalendarState = initialRatesCalendarState,
    action: Action
): RatesCalendarState {
    switch (action.type) {
        case RATES_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case RATES_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                rates: (action.payload as { rates: Array<RateCalendarRecord> }).rates,
            };
        case RATES_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { error: { message: string } }).error.message,
            };
        default:
            return state;
    }
}
