import React, { useMemo, useContext, createContext, useCallback } from 'react';
import tenantsParkingReducer, { TenantsParkingState, initialTenantsParkingState } from './reducer';
import { TENANTS_PARKING_REQUESTED, TENANTS_PARKING_SUCCESS, TENANTS_PARKING_FAILED } from './constants';
import { tenantsParkingClient } from 'clients';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';

interface TenantsParkingActions {
    getTenantsParking: (spotUuid: string) => void;
}

interface TenantsParkingContextType extends TenantsParkingState, TenantsParkingActions {}

export const TenantsParkingContext = createContext<TenantsParkingContextType | null>(null);

export const TenantsParkingProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        tenantsParkingReducer,
        { ...initialTenantsParkingState },
        [],
        [unauthorized]
    );
    const getTenantsParking = useCallback(
        async (spotUuid: string) => {
            dispatch({ type: TENANTS_PARKING_REQUESTED });
            try {
                const tenantsParkings = await tenantsParkingClient.getTenantsParking(spotUuid);
                dispatch({ type: TENANTS_PARKING_SUCCESS, payload: tenantsParkings });
            } catch (error) {
                dispatch({
                    type: TENANTS_PARKING_FAILED,
                    payload: { error, message: error?.toString() || 'Fetching active tenants failed.' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const value = useMemo<TenantsParkingContextType>(() => {
        return {
            ...state,
            getTenantsParking,
        };
    }, [state, getTenantsParking]);
    return <TenantsParkingContext.Provider value={value}>{children}</TenantsParkingContext.Provider>;
};

export const useTenantsParking = () => {
    const context = useContext(TenantsParkingContext);
    if (!context) {
        throw new Error('Error: useTenantsParking should be wrapped by TenantsParkingProvider.');
    }
    return context;
};
