export const VISITORS_REQUESTED = 'VISITORS_REQUESTED';
export const VISITORS_SUCCESS = 'VISITORS_SUCCESS';
export const VISITORS_FAILED = 'VISITORS_FAILED';

export const ADD_VISITOR_REQUESTED = 'ADD_VISITOR_REQUESTED';
export const ADD_VISITOR_SUCCESS = 'ADD_VISITOR_SUCCESS';
export const ADD_VISITOR_FAILED = 'ADD_VISITOR_FAILED';

export const EDIT_VISITOR_REQUESTED = 'EDIT_VISITOR_REQUESTED';
export const EDIT_VISITOR_SUCCESS = 'EDIT_VISITOR_SUCCESS';
export const EDIT_VISITOR_FAILED = 'EDIT_VISITOR_FAILED';

export const DELETE_VISITOR_REQUESTED = 'DELETE_VISITOR_REQUESTED';
export const DELETE_VISITOR_SUCCESS = 'DELETE_VISITOR_SUCCESS';
export const DELETE_VISITOR_FAILED = 'DELETE_VISITOR_FAILED';
