import React from 'react';
import { Grid, Message } from 'semantic-ui-react';
import { ReportInterface } from 'types';
import { usdFormatterWithoutCents } from 'utils/helpers';
import styles from './PricingWidget.module.css';
import WidgetData from '../widgets/WidgetData';
import WidgetSubheader from '../widgets/WidgetSubheader';
import LineUpIcon from 'components/Icons/LineUpIcon';

const TOOLTIP = {
    title: 'Revenue Uplift',
    message: 'Revenue uplift attributable to rate increases during high-demand periods',
};

type DynamicPricingUpliftProps = {
    report: ReportInterface | null;
};

function DynamicPricingUplift({ report }: DynamicPricingUpliftProps) {
    if (report === null) {
        return (
            <Grid.Row>
                <WidgetSubheader icon={<LineUpIcon />} label="Revenue Uplift" tooltip={TOOLTIP} />
                <Message color="red">Something went wrong. Please refresh the page</Message>
            </Grid.Row>
        );
    }
    const { last_seven_days: sevenDays, last_30_days: thirtyDays, lifetime } = report.dynamic_pricing_uplift;
    if (sevenDays === 0 && thirtyDays === 0 && lifetime === 0) {
        return <></>;
    }
    return (
        <Grid.Row>
            <WidgetSubheader icon={<LineUpIcon />} label="Revenue Uplift" tooltip={TOOLTIP} />
            <Grid columns={3}>
                <Grid.Column>
                    <div className={styles.inline}>
                        <WidgetData>
                            {usdFormatterWithoutCents(sevenDays)}
                            <br className={styles.smallLabelDivider} />
                            <span className={styles.smallLabel}>last 7 days</span>
                        </WidgetData>
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <div className={styles.inline}>
                        <WidgetData>
                            {usdFormatterWithoutCents(thirtyDays)}
                            <br className={styles.smallLabelDivider} />
                            <span className={styles.smallLabel}>last 30 days</span>
                        </WidgetData>
                    </div>
                </Grid.Column>
                <Grid.Column>
                    <div className={styles.inline}>
                        <WidgetData>
                            {usdFormatterWithoutCents(lifetime)}
                            <br className={styles.smallLabelDivider} />
                            <span className={styles.smallLabel}>lifetime</span>
                        </WidgetData>
                    </div>
                </Grid.Column>
            </Grid>
        </Grid.Row>
    );
}

export default DynamicPricingUplift;
