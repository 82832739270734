import { VisitorCode } from 'types/VisitorCode';
import {
    VISITOR_CODES_REQUESTED,
    VISITOR_CODES_SUCCESS,
    VISITOR_CODES_FAILED,
    ADD_VISITOR_CODE_FAILED,
    ADD_VISITOR_CODE_REQUESTED,
    ADD_VISITOR_CODE_SUCCESS,
    EDIT_VISITOR_CODE_REQUESTED,
    EDIT_VISITOR_CODE_SUCCESS,
    EDIT_VISITOR_CODE_FAILED,
    DELETE_VISITOR_CODE_REQUESTED,
    DELETE_VISITOR_CODE_SUCCESS,
    DELETE_VISITOR_CODE_FAILED,
    REFRESH_VISITOR_CODES_REQUESTED,
    REFRESH_VISITOR_CODES_SUCCESS,
    REFRESH_VISITOR_CODES_FAILED,
} from './constants';
import { Action } from 'contexts/useReducerWithMiddleware';
import { FormattedVisitorCode, selectVisitorCodesListWithNames } from './utils';

export type VisitorCodesState = {
    loading: boolean;
    errorMessage: string;
    visitorCodes: VisitorCode[];
    formattedVisitorCodes: FormattedVisitorCode[];
    visitorCodeOperationLoading: boolean;
    visitorCodeOperationErrorMessage: string;
};

export const initialVisitorCodesState: VisitorCodesState = {
    loading: false,
    errorMessage: '',
    visitorCodes: [],
    formattedVisitorCodes: [],
    visitorCodeOperationLoading: false,
    visitorCodeOperationErrorMessage: '',
};

export default function visitorCodesReducer(
    state: VisitorCodesState = initialVisitorCodesState,
    action: Action
): VisitorCodesState {
    switch (action.type) {
        case VISITOR_CODES_REQUESTED:
        case REFRESH_VISITOR_CODES_REQUESTED:
            return {
                ...state,
                loading: true,
                visitorCodes: [],
                formattedVisitorCodes: [],
            };
        case VISITOR_CODES_SUCCESS:
        case REFRESH_VISITOR_CODES_SUCCESS: {
            const visitorCodes = (action.payload as { visitorCodes: VisitorCode[] }).visitorCodes;
            return {
                ...state,
                loading: false,
                errorMessage: '',
                visitorCodes,
                formattedVisitorCodes: selectVisitorCodesListWithNames(visitorCodes),
            };
        }
        case VISITOR_CODES_FAILED:
        case REFRESH_VISITOR_CODES_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        case ADD_VISITOR_CODE_REQUESTED:
        case EDIT_VISITOR_CODE_REQUESTED:
        case DELETE_VISITOR_CODE_REQUESTED:
            return {
                ...state,
                visitorCodeOperationLoading: true,
            };
        case ADD_VISITOR_CODE_SUCCESS:
        case EDIT_VISITOR_CODE_SUCCESS:
        case DELETE_VISITOR_CODE_SUCCESS:
            return {
                ...state,
                visitorCodeOperationLoading: false,
                visitorCodeOperationErrorMessage: '',
            };
        case ADD_VISITOR_CODE_FAILED:
        case EDIT_VISITOR_CODE_FAILED:
        case DELETE_VISITOR_CODE_FAILED:
            return {
                ...state,
                visitorCodeOperationLoading: false,
                visitorCodeOperationErrorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialVisitorCodesState;
    }
}
