import React from 'react';

import WidgetDataLabel from 'components/widgets/WidgetDataLabel';
import WidgetBox from 'components/widgets/WidgetBox';
import WidgetData from 'components/widgets/WidgetData';
import { DailySummaryEntry } from 'types/LotTimeline';
import styles from '../timeline.module.css';
import { getFormattedDateAndTime } from 'utils/helpers';

type DailySummaryProps = {
    entry: DailySummaryEntry;
};

const REVENUE_TITLE = 'Revenue';
const RENTALS_TITLE = 'Rentals';
const INCOME_TITLE = 'Net Income';

function DailySummary({ entry }: DailySummaryProps) {
    const formattedDateAndTime = getFormattedDateAndTime(new Date(entry.timestamp));
    return (
        <WidgetBox title="Revenue" lastUpdatedLabel={formattedDateAndTime}>
            <div className={styles.dailyReportWrapper}>
                <div>
                    <WidgetDataLabel label={REVENUE_TITLE} />
                    <WidgetData>{entry.details.gmv}</WidgetData>
                </div>
                <div>
                    <WidgetDataLabel label={RENTALS_TITLE} />
                    <WidgetData>{entry.details.total_rentals}</WidgetData>
                </div>
                <div>
                    <WidgetDataLabel label={INCOME_TITLE} />
                    <WidgetData>{entry.details.income}</WidgetData>
                </div>
            </div>
        </WidgetBox>
    );
}

export default DailySummary;
