import {
    VISITORS_TO_VALIDATE_REQUESTED,
    VISITORS_TO_VALIDATE_SUCCESS,
    VISITORS_TO_VALIDATE_FAILED,
    ADD_VISITOR_TO_VALIDATE_REQUESTED,
    ADD_VISITOR_TO_VALIDATE_SUCCESS,
    ADD_VISITOR_TO_VALIDATE_FAILED,
    VALIDATE_VISITOR_REQUESTED,
    VALIDATE_VISITOR_SUCCESS,
    VALIDATE_VISITOR_FAILED,
    UPLOAD_VISITOR_CSV_REQUESTED,
    UPLOAD_VISITOR_CSV_SUCCESS,
    UPLOAD_VISITOR_CSV_FAILED,
    PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED,
    PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS,
    PROGRESS_UPLOAD_VISITOR_CSV_FAILED,
} from '../actions/constants';
import { GetVisitorsResponse } from '../services/VisitorService';
import { Visitor } from '../types/Visitor';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

type VisitorsState = {
    loading: boolean;
    errorMessage: string;
    visitors: GetVisitorsResponse | null;
    searchLoading: boolean;
    searchResults: GetVisitorsResponse | null;
    searchResultErrorMessage: string;
    addVisitorLoading: boolean;
    successMessage: string;
    uploadCsvProgress: {
        value: number;
        max: number;
    };
    requestingValidation: Visitor[] | null;
};

export const initialVisitorsState: VisitorsState = {
    loading: false,
    errorMessage: '',
    addVisitorLoading: false,
    searchLoading: false,
    searchResultErrorMessage: '',
    successMessage: '',
    visitors: null,
    searchResults: null,
    requestingValidation: null,
    uploadCsvProgress: {
        value: 0,
        max: 0,
    },
};

export default function visitorsReducer(state: VisitorsState = initialVisitorsState, action: any): VisitorsState {
    const newState: VisitorsState = cloneObject(state);
    switch (action.type) {
        case VISITORS_TO_VALIDATE_REQUESTED:
            newState.errorMessage = '';
            newState.loading = true;
            return newState;
        case VISITORS_TO_VALIDATE_SUCCESS:
            newState.loading = false;
            newState.requestingValidation = action.payload.visitors;
            return newState;
        case VISITORS_TO_VALIDATE_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case ADD_VISITOR_TO_VALIDATE_REQUESTED:
            newState.errorMessage = '';
            newState.addVisitorLoading = true;
            return newState;
        case ADD_VISITOR_TO_VALIDATE_SUCCESS:
            newState.requestingValidation = action.payload.visitors;
            newState.successMessage = 'Added visitor with plate ' + action.payload.newVisitor.car_details.plate;
            newState.addVisitorLoading = false;
            return newState;
        case ADD_VISITOR_TO_VALIDATE_FAILED:
            newState.errorMessage = action.message;
            newState.addVisitorLoading = false;
            return newState;
        case VALIDATE_VISITOR_REQUESTED:
            newState.errorMessage = '';
            newState.loading = true;
            return newState;
        case VALIDATE_VISITOR_SUCCESS:
            newState.requestingValidation = action.payload.visitors;
            newState.successMessage = `Validated visitor with plate ${action.payload.plate} ${action.payload.validUntil}`;
            newState.loading = false;
            return newState;
        case VALIDATE_VISITOR_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case UPLOAD_VISITOR_CSV_REQUESTED:
            newState.successMessage = '';
            newState.errorMessage = '';
            newState.loading = true;
            return newState;
        case UPLOAD_VISITOR_CSV_SUCCESS:
            newState.successMessage = action.message;
            newState.loading = false;
            return newState;
        case UPLOAD_VISITOR_CSV_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED:
            newState.errorMessage = '';
            return newState;
        case PROGRESS_UPLOAD_VISITOR_CSV_SUCCESS:
            newState.uploadCsvProgress.value = action.payload.value;
            newState.uploadCsvProgress.max = action.payload.max;
            return newState;
        case PROGRESS_UPLOAD_VISITOR_CSV_FAILED:
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialVisitorsState;
    }
}
