import { FormErrors } from 'constants/formErrors';

export function hasDigitsOnly(s: string): boolean {
    return /^\d*\.?\d*$/.test(s);
}

export function validatePhone(phoneNumber?: string): { phoneNumber: string | null } {
    if (!phoneNumber) {
        return { phoneNumber: FormErrors.InvalidPhoneNumber };
    }
    const ok = hasDigitsOnly(phoneNumber);
    if (!ok) {
        return { phoneNumber: FormErrors.InvalidPhoneNumber };
    }
    return { phoneNumber: null };
}

export function validatePhoneCountryCode(phoneCountryCode?: string): { phoneCountryCode: string | null } {
    if (!phoneCountryCode) {
        return { phoneCountryCode: FormErrors.InvalidPhoneNumber };
    }
    return { phoneCountryCode: null };
}
