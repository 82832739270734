import React from 'react';

const PencilIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M0.998779 15.461V18.501C0.998779 18.781 1.21878 19.001 1.49878 19.001H4.53878C4.66878 19.001 4.79878 18.951 4.88878 18.851L15.8088 7.94103L12.0588 4.19104L1.14878 15.101C1.04878 15.201 0.998779 15.321 0.998779 15.461Z"
                fill={color || '#A1A6AF'}
            />
            <path
                d="M18.7088 3.63104L16.3688 1.29104C15.9788 0.901035 15.3488 0.901035 14.9588 1.29104L13.1288 3.12104L16.8788 6.87104L18.7088 5.04104C19.0988 4.65104 19.0988 4.02104 18.7088 3.63104Z"
                fill={color || '#A1A6AF'}
            />
        </svg>
    );
};

export default PencilIcon;
