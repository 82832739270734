import React from 'react';
import styles from './WidgetBox.module.css';
import { Loader } from 'semantic-ui-react';

export type LiveUpdateLabelProps = {
    loading: boolean;
};

function LiveUpdateLabel({ loading }: LiveUpdateLabelProps): JSX.Element {
    return (
        <>
            {loading ? <Loader active inline size="mini" /> : <div className={styles.updateLiveCircle} />}
            <span>Updating live</span>
        </>
    );
}

export default LiveUpdateLabel;
