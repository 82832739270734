import HTTPClient, { HTTPClientParameters } from './HTTPClient';
import { PagedResponse } from 'types/Pagination';
import { CarTracking, HeroStats, RecentCameraImages } from 'types/CarTracking';
import cache from './cache';

export const CAR_TRACKING_PAGE_SIZE = 20; // should be in sync with backend

type TrackingClientParams = HTTPClientParameters;

type CarTrackingFilters = {
    page: number;
    start: number;
    end: number;
    plate?: string;
    status?: string;
};

class TrackingClient extends HTTPClient {
    constructor(params: TrackingClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    @cache(5 * 60000)
    getCarTrackingEvents(spotUuid: string, filters: CarTrackingFilters): Promise<PagedResponse<CarTracking>> {
        let path = `car_tracking?uuid=${spotUuid}&page=${filters.page}&end=${filters.end}&start=${filters.start}`;
        if (filters.plate) path += `&plate=${filters.plate}`;
        if (filters.status) path += `&status=${filters.status}`;

        return this.get(path);
    }

    getRecentCameraImages(spotUuid: string): Promise<RecentCameraImages> {
        return this.get(`camera/recent_images/${spotUuid}`);
    }

    getHeroStats(spotUuid: string): Promise<HeroStats> {
        return this.get(`hero_stats?uuid=${spotUuid}`);
    }
}

export default TrackingClient;
