import React, { useMemo, useState } from 'react';
import { isDailySummary, isEnforcementEntry } from 'types';
import styles from './collapsible.module.css';
import TimelineEvent from '../TimelineEvent';
import ArrowDown from 'components/Icons/ArrowDown';
import AGMessage from 'components/AGMessage/AGMessage';
import { formatDateRange } from 'utils/helpers';
import { motion, AnimatePresence, Transition, Variants } from 'framer-motion';

type CollapsibleListProps = {
    items: DayBlock[];
};

const COLLAPSIBLE_ITEMS_NUMBER = 5;
const NON_COLLAPSIBLE_DAYS = 5;

const itemVariants: Variants = {
    hidden: { opacity: 0, height: '0', y: -100 },
    visible: { opacity: 1, height: 'auto', y: 0 },
    exit: { opacity: 0, height: 0, y: 0 },
};

const itemTransition: Transition = {
    type: 'spring',
    stiffness: 50,
    damping: 12,
    staggerChildren: 0.05,
};

export default function CollapsibleList({ items }: CollapsibleListProps) {
    const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});
    const [animationComplete, setAnimationComplete] = useState(false);

    const handleToggleCollapse = (index: number) => {
        setExpandedGroups((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    function collapseDayBlocks(dayBlocks: DayBlock[]): CollapsibleDayBlock[] {
        // Directly push the first NON_COLLAPSIBLE_DAYS
        const collapsedBlocks: CollapsibleDayBlock[] = [...dayBlocks.slice(0, NON_COLLAPSIBLE_DAYS)];
        let temp: DayBlock[] = [];

        for (let i = NON_COLLAPSIBLE_DAYS; i < dayBlocks.length; i++) {
            const block = dayBlocks[i];
            const areEntriesCollapsible = block.entries.every((e) => isDailySummary(e) || isEnforcementEntry(e));

            if (areEntriesCollapsible) {
                temp.push(block);
                if (temp.length === COLLAPSIBLE_ITEMS_NUMBER) {
                    collapsedBlocks.push(temp);
                    temp = [];
                }
            } else {
                collapsedBlocks.push(...temp, block);
                temp = [];
            }
        }

        collapsedBlocks.push(...temp);

        return collapsedBlocks;
    }

    function renderDayBlock(block: DayBlock, index: number) {
        const { date, dayName, dayNum, entries } = block;

        return (
            <motion.li
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={itemVariants}
                transition={itemTransition}
                key={`${index}-${date.toISOString()}`}
                className={styles.listItem}
                layout
            >
                <div className={styles.dayWrapper}>
                    <div className={styles.dateWrapper}>
                        <span className={styles.dayName}>{dayName}</span>
                        <span className={styles.dateNum}>{dayNum}</span>
                    </div>
                    <ul className={styles.entryWrapper}>
                        {entries.map((e) => (
                            <li key={e.event + e.timestamp} className="timeline-entry">
                                <TimelineEvent entry={e} />
                            </li>
                        ))}
                    </ul>
                </div>
            </motion.li>
        );
    }

    const collapsedDayBlocks = useMemo(() => collapseDayBlocks(items), [items]);

    return (
        <>
            {collapsedDayBlocks.map((block: CollapsibleDayBlock, index) => {
                if (Array.isArray(block)) {
                    const collapsedDayBlocks = block as DayBlock[];
                    const isExpanded = expandedGroups[index];
                    const firstBlock = collapsedDayBlocks[0];
                    const lastBlock = collapsedDayBlocks[collapsedDayBlocks.length - 1];

                    return (
                        <div key={index}>
                            <AnimatePresence initial={true}>
                                <motion.div layout className={styles.collapsibleWrapper}>
                                    <motion.button
                                        layout
                                        initial={{ opacity: 0, y: 0 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 1, y: 0 }}
                                        transition={{ opacity: { duration: 0.8 } }}
                                        onClick={() => handleToggleCollapse(index)}
                                        className={styles.collapseButton}
                                    >
                                        <ArrowDown direction={isExpanded ? 'up' : 'down'} />
                                    </motion.button>
                                    {isExpanded && animationComplete && <div className={styles.line} />}
                                    {!isExpanded && (
                                        <div className={styles.collapsedCardWrapper} key={index}>
                                            <motion.div
                                                initial={{ y: -50, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: 0, opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                                onAnimationStart={() => setAnimationComplete(false)}
                                                onAnimationComplete={() => setAnimationComplete(true)}
                                                className={[styles.collapsedCard, styles.collapsedCard1].join(' ')}
                                            >
                                                <div>
                                                    <h3 className={styles.rangeTitle}>
                                                        {formatDateRange(lastBlock.date, firstBlock.date)}
                                                    </h3>
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -100, height: 'auto' }}
                                                        animate={{ opacity: 1, y: 0, height: 'auto' }}
                                                        exit={{ opacity: 0, height: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                        className={styles.alertWrapper}
                                                    >
                                                        <AGMessage title="No highlights for these days.">
                                                            Expand to see daily revenue and enforcement data.
                                                        </AGMessage>
                                                    </motion.div>
                                                </div>
                                            </motion.div>
                                            <motion.div
                                                initial={{ y: -100, opacity: 0, scale: 0.95 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: -100, opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                                className={[styles.collapsedCard, styles.collapsedCard2].join(' ')}
                                            />
                                            <motion.div
                                                initial={{ y: -100, opacity: 0, scale: 0.9 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: -100, opacity: 0 }}
                                                transition={{ duration: 0.5 }}
                                                className={[styles.collapsedCard, styles.collapsedCard3].join(' ')}
                                            />
                                        </div>
                                    )}
                                </motion.div>
                            </AnimatePresence>
                            <AnimatePresence initial={false}>
                                {isExpanded && collapsedDayBlocks.map((b, i) => renderDayBlock(b, i))}
                            </AnimatePresence>
                        </div>
                    );
                }

                return renderDayBlock(block as DayBlock, index);
            })}
        </>
    );
}
