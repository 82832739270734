import React from 'react';
import styles from './Table.module.css';
import { ValetSessionStatus } from 'types/ValetSession';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

type StateTagProps = {
    valetSessionStatus: ValetSessionStatus;
};

function StateTag({ valetSessionStatus }: StateTagProps): JSX.Element {
    const classes = [styles.stateTag, styles.stateValetTag];
    if (valetSessionStatus === ValetSessionStatus.Active) {
        classes.push(styles.stateActive);
    } else if (valetSessionStatus === ValetSessionStatus.Pending) {
        classes.push(styles.statePending);
    } else {
        classes.push(styles.stateEnded);
    }
    const icons = {
        [ValetSessionStatus.Active]: <CheckIcon />,
        [ValetSessionStatus.Pending]: <PersonIcon />,
        [ValetSessionStatus.Ended]: <ClockIcon />,
    };

    return (
        <button className={classes.join(' ')}>
            <span className={styles.icon}>{icons[valetSessionStatus]}</span>
            <span className={styles.text}>{valetSessionStatus}</span>
        </button>
    );
}

export default StateTag;
