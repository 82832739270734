import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {
    CountryCode,
    getStateFromValue,
    mapStateNameToCountry,
    stateOptions,
} from '../../../assets/data/countriesAndStates';
import Select from 'react-select';
import { customStyles, customTheme } from '../../../utils/reactSelectStyles';

interface Props {
    handleValueChange: (name: 'state' | 'country', value: any) => void;
    state: string;
    style?: object;
    country?: CountryCode;
}

const StateField = ({ handleValueChange, state, style, country }: Props) => {
    const stateValue = state && country ? getStateFromValue(state, country) : null;

    const onSelect = (event: any) => {
        handleValueChange('state', event.value);
        handleValueChange('country', mapStateNameToCountry[event.label]);
    };

    return (
        <div style={{ height: '50px', ...style }}>
            <Select
                options={stateOptions}
                onChange={onSelect}
                isSearchable={true}
                name="state"
                placeholder="State / Province *"
                theme={customTheme}
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null,
                }}
                value={stateValue}
            />
        </div>
    );
};

export default StateField;
