import React from 'react';
import styles from './RevenueWidget.module.css';
import InfoTooltip from '../Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import { REVENUE_TYPE_LABELS } from './chartHelpers';

type LegendProps = {
    showDynamicPricing: boolean;
    showEnforcement: boolean;
    showMarketing: boolean;
};

const BASELINE_TOOLTIP = {
    title: 'Cumulative gross revenue before refunds and AirGarage commission',
    text: 'Earned when rentals have ended and been charged.',
};
const DP_TOOLTIP = {
    title: 'Dynamic pricing gross revenue',
    text: 'We optimize your parking rates to maximize revenue based on demand.',
};
const ENFORCEMENT_TOOLTIP = {
    title: 'Gross revenue recovered through enforcement',
    text: 'We auto-start violator rentals and charge an additional penalty for failure to pay.',
};
const MARKETING_TOOLTIP = {
    title: 'Marketing and online booking gross revenue',
    text: 'We advertise your property on the most popular parking websites to increase your visibility, traffic, and reserved bookings.',
};

function Legend(props: LegendProps): JSX.Element {
    return (
        <ul className={styles.legendList}>
            <li className={`${styles.legendListItem} ${styles.baseline}`}>
                {REVENUE_TYPE_LABELS.baseline} Revenue
                <InfoTooltip {...BASELINE_TOOLTIP} />
            </li>
            {props.showDynamicPricing && (
                <li className={`${styles.legendListItem} ${styles.dp}`}>
                    {REVENUE_TYPE_LABELS.dynamicPricing} Revenue
                    <InfoTooltip {...DP_TOOLTIP} />
                </li>
            )}
            {props.showEnforcement && (
                <li className={`${styles.legendListItem} ${styles.enforcement}`}>
                    {REVENUE_TYPE_LABELS.enforcement} Revenue
                    <InfoTooltip {...ENFORCEMENT_TOOLTIP} />
                </li>
            )}
            {props.showMarketing && (
                <li className={`${styles.legendListItem} ${styles.marketing}`}>
                    {REVENUE_TYPE_LABELS.marketing} Revenue
                    <InfoTooltip {...MARKETING_TOOLTIP} />
                </li>
            )}
        </ul>
    );
}

export default Legend;
