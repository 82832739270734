import {
    LOGIN_REQUESTED,
    PROFILE_REQUESTED,
    ORGANIZATION_REQUESTED,
    ORGANIZATION_LIST_REQUESTED,
    SPOT_LIST_REQUESTED,
    LOGOUT,
    ORGANIZATION_SPOTS_REQUESTED,
    TEAM_REQUESTED,
    ADD_TEAM_REQUESTED,
    DELETE_TEAM_REQUESTED,
    PAYOUTS_REQUESTED,
    PAYOUT_DETAILS_REQUESTED,
    REPORTS_REQUESTED,
    AGGREGATOR_REPORTS_REQUESTED,
    PROMO_CODE_REPORTS_REQUESTED,
    ENFORCEMENTS_REQUESTED,
    UPDATE_ENFORCEMENT_REQUESTED,
    ADD_ENFORCEMENT_PENALTY_REQUESTED,
    DAILY_REPORTS_REQUESTED,
    PAYOUT_ACCOUNT_INFO_REQUESTED,
    ADD_PAYOUT_REQUESTED,
    ADD_ORGANIZATION_REQUESTED,
    UPLOAD_SPOT_PHOTO_REQUESTED,
    ADD_SPOT_REQUESTED,
    REFUNDS_REQUESTED,
    ISSUE_REFUND_REQUESTED,
    IGNORE_REFUND_REQUESTED,
    SALES_REPS_REQUESTED,
    SEND_VERIFICATION_CODE_REQUESTED,
    ADD_PROMO_CODE_REQUESTED,
    PROMO_CODES_REQUESTED,
    DELETE_PROMO_CODE_REQUESTED,
    VISITORS_TO_VALIDATE_REQUESTED,
    ADD_VISITOR_TO_VALIDATE_REQUESTED,
    VALIDATE_VISITOR_REQUESTED,
    SIGN_UP_REQUESTED,
    UPLOAD_VISITOR_CSV_REQUESTED,
    PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED,
    CURRENTLY_PARKED_REQUESTED,
    CHECK_PLATE_REQUESTED,
    SPOT_MULTIDAY_PRICING_REQUESTED,
    UPDATE_REPORTS_REQUESTED,
    GET_TICKET_REQUESTED,
    WAIVE_TICKET_REQUESTED,
} from './constants';
import { DailyReportFilters, DateFilter } from '../services/ReportsService';
import { AddTeamMemberInput } from '../services/TeamService';

interface LoginCredentials {
    email: string;
    password: string;
}

export function loginAction(userCredentials: LoginCredentials) {
    return { type: LOGIN_REQUESTED, payload: userCredentials };
}

export function sendVerificationCodeAction(username: string, phoneCode: string) {
    return { type: SEND_VERIFICATION_CODE_REQUESTED, payload: username, phoneCode };
}

export function logoutAction() {
    return { type: LOGOUT };
}

export function getProfileAction() {
    return { type: PROFILE_REQUESTED };
}

export function uploadVisitorCSVAction(data: any) {
    return { type: UPLOAD_VISITOR_CSV_REQUESTED, payload: { data: data } };
}

export function progressUploadVisitorCSVAction() {
    return { type: PROGRESS_UPLOAD_VISITOR_CSV_REQUESTED };
}

export function getSalesRepsAction() {
    return { type: SALES_REPS_REQUESTED };
}

export function getOrganizationAction(orgId: number) {
    return { type: ORGANIZATION_REQUESTED, payload: { orgId } };
}

export function getOrganizationsAction() {
    return { type: ORGANIZATION_LIST_REQUESTED };
}

export function getSpotListAction() {
    return { type: SPOT_LIST_REQUESTED };
}

export function getSpotsAction() {
    return { type: ORGANIZATION_SPOTS_REQUESTED };
}

export function getSpotMultidayPricingAction(days: number) {
    return { type: SPOT_MULTIDAY_PRICING_REQUESTED, payload: { days } };
}

export function getTeamAction(id: number) {
    return { type: TEAM_REQUESTED, payload: { id: id } };
}

export function addTeamMemberAction(invitation: AddTeamMemberInput) {
    return { type: ADD_TEAM_REQUESTED, payload: { invitation: invitation } };
}

export function deleteTeamMemberAction(invitationId: number) {
    return { type: DELETE_TEAM_REQUESTED, payload: { invitation_id: invitationId } };
}

export function getPayoutsAction() {
    return { type: PAYOUTS_REQUESTED };
}

export function getSelectedPayoutDetailsAction(stripeId: string) {
    return { type: PAYOUT_DETAILS_REQUESTED, payload: { stripeId } };
}

export function getPayoutAccountInfoAction() {
    return { type: PAYOUT_ACCOUNT_INFO_REQUESTED };
}

export function addPayoutAccountInfoAction(payoutInfo: any, token: string) {
    return { type: ADD_PAYOUT_REQUESTED, payload: { payout_info: payoutInfo, token: token } };
}

export function getReportsAction(filters?: { date: { month: number; year: number } }) {
    return { type: REPORTS_REQUESTED, payload: { filters: filters } };
}

export function updateReportsAction() {
    return { type: UPDATE_REPORTS_REQUESTED };
}

export function getAggregatorReportsAction(filters?: { date: DateFilter }) {
    return { type: AGGREGATOR_REPORTS_REQUESTED, payload: { filters: filters } };
}

export function getPromoCodeReportsAction(filters: { date: DateFilter }) {
    return { type: PROMO_CODE_REPORTS_REQUESTED, payload: { filters: filters } };
}

export function getCurrentlyParkedAction(violatorsOnly: boolean) {
    return { type: CURRENTLY_PARKED_REQUESTED, payload: { violatorsOnly } };
}

export function checkPlateAction(plate: string) {
    return { type: CHECK_PLATE_REQUESTED, payload: { plate: plate } };
}

export function getEnforcementsAction(filteredUrl: string = '') {
    return { type: ENFORCEMENTS_REQUESTED, payload: { filtered_url: filteredUrl } };
}

export function updateEnforcementAction(pk: number, body: any) {
    return { type: UPDATE_ENFORCEMENT_REQUESTED, payload: { body, pk } };
}

export function addEnforcementPenaltyAction(body: any) {
    return { type: ADD_ENFORCEMENT_PENALTY_REQUESTED, payload: { body } };
}

export function getDailyReportsAction(filters: DailyReportFilters) {
    return { type: DAILY_REPORTS_REQUESTED, payload: { filters: filters } };
}

export function getTicketAction(identifier: string) {
    return { type: GET_TICKET_REQUESTED, payload: { identifier } };
}

export function waiveTicketAction(identifier: string) {
    return { type: WAIVE_TICKET_REQUESTED, payload: { identifier } };
}

export function addOrganizationAction(organization: any) {
    return { type: ADD_ORGANIZATION_REQUESTED, payload: { organization: organization } };
}

export function uploadSpotPhotoAction(file: any) {
    return { type: UPLOAD_SPOT_PHOTO_REQUESTED, payload: { file: file } };
}

export function addSpotAction(spot: any) {
    return { type: ADD_SPOT_REQUESTED, payload: { spot } };
}

export function getRefundsAction() {
    return { type: REFUNDS_REQUESTED };
}

export function issueRefundAction(refundPk: any) {
    return { type: ISSUE_REFUND_REQUESTED, payload: { refund: refundPk } };
}

export function ignoreRefundAction(refundPk: any) {
    return { type: IGNORE_REFUND_REQUESTED, payload: { refund: refundPk } };
}

export function addPromoCodeAction(promoCode: any) {
    return { type: ADD_PROMO_CODE_REQUESTED, payload: { promoCode } };
}

export function deletePromoCodeAction(promoCodePk: number) {
    return { type: DELETE_PROMO_CODE_REQUESTED, payload: { promoCodePk } };
}

export function getPromoCodeAction(page: number = 1, usableCodes: boolean = true) {
    return { type: PROMO_CODES_REQUESTED, payload: { page, usableCodes } };
}

export function signupAction(fields: any, acceptedAgreement: boolean) {
    return { type: SIGN_UP_REQUESTED, payload: { ...fields, acceptedAgreement } };
}

export function getVisitorsToValidateAction(spot: number) {
    return { type: VISITORS_TO_VALIDATE_REQUESTED, payload: { spot } };
}

export function addVisitorToValidateAction(
    spot: number,
    plate: string,
    validation_source: string,
    state: string,
    country: string
) {
    return { type: ADD_VISITOR_TO_VALIDATE_REQUESTED, payload: { spot, plate, validation_source, state, country } };
}

export function validateVisitorAction(
    visitorPk: number,
    minutes: number,
    spot: number,
    validation_source: string,
    date: string | number | Date | null = null
) {
    return {
        type: VALIDATE_VISITOR_REQUESTED,
        payload: { visitor_pk: visitorPk, minutes: minutes, spot, validation_source, date },
    };
}
