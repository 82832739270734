import { useEffect } from 'react';

type CallbackFunction = () => void;

const useKeyDownPress = (targetKey: string, callback: CallbackFunction) => {
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === targetKey) {
                callback();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [targetKey, callback]);
};

export default useKeyDownPress;
