import { useEffect, useRef, useState } from 'react';
import { Push } from 'connected-react-router';
import usePaginatedFilters from './usePaginatedFilters';
import { UrlFilters, UseFiltersParams } from './useFilters';
import { debounce } from 'utils/helpers';

type UseSearchParams<Filters extends UrlFilters> = UseFiltersParams<Filters> & {
    push: Push;
};

type WithSearch<T extends UrlFilters> = T & { searchTerm: string };

function searchTermValidator(s: string | null): string {
    return typeof s === 'string' ? s : '';
}

export default function usePaginatedFiltersWithSearch<Filters extends UrlFilters>({
    filterValidators,
    push,
}: UseSearchParams<Filters>) {
    const {
        filters,
        urlUpdater: { searchTerm: searchTermUrlUpdater, ...urlUpdater },
    } = usePaginatedFilters<WithSearch<Filters>>({
        filterValidators: {
            ...filterValidators,
            searchTerm: searchTermValidator,
        },
        push,
    });
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>(filters.searchTerm);

    const debouncedSearch = useRef(
        debounce(function onDebounceWrapper(st: string) {
            if (st.length > 0 && st.length < 3) return;
            // So far all updates to the searchTerm have been kept in memory. URLSearchParams
            // have not yet been updated. Now, it's time.
            searchTermUrlUpdater(st);
        }, 300)
    );

    // This effect runs when searchTerm changes.
    // It's meant to send a signal the debounceSearch with the current searchTerm.
    useEffect(() => {
        debouncedSearch.current(currentSearchTerm);
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            debouncedSearch.current.cancel();
        };
    }, [currentSearchTerm]);

    function setSearchTerm(st: string) {
        if (filters.searchTerm !== st) {
            setCurrentSearchTerm(st);
            if (filters.page !== '1') urlUpdater.page('1');
        }
    }

    useEffect(() => {
        if (filters.searchTerm !== currentSearchTerm) setCurrentSearchTerm(filters.searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.searchTerm]);

    return {
        filters: {
            ...filters,
            currentSearchTerm,
        },
        urlUpdater: {
            ...urlUpdater,
            searchTerm: setSearchTerm,
        },
    };
}
