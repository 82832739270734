const FORCE_KEY = 'force__';

export default function cache(ttl: number = 5 * 60000) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const impl = descriptor.value;

        descriptor.value = async function (...args: unknown[]) {
            const cache = target.cache || {};
            let hashKey = propertyKey.replace(FORCE_KEY, ''); // First hash constructor
            const now = new Date();

            if (args.length > 0) hashKey += hashArgs(args);

            // Check if cache exists
            if (cache[hashKey]) {
                // Check if its expired:
                if (now < cache[hashKey].expirationDate && !propertyKey.includes(FORCE_KEY)) return cache[hashKey].data;
                else delete target.cache[hashKey]; // Clean if expired
            }

            const results = await impl.apply(this, args);
            const newData = { data: results, expirationDate: new Date(now.getTime() + ttl) };

            if (target.cache) target.cache[hashKey] = newData;
            else
                target.cache = {
                    [hashKey]: newData,
                };
            return results;
        };

        return descriptor;
    };
}

export function hashArgs(args: unknown[]) {
    const hashArgs = args.map(getHashArg).join(',');
    return `[${hashArgs}]`;
}

function getHashArg(arg: unknown): string {
    if (arg && typeof arg === 'object') {
        if (Array.isArray(arg)) {
            const hashArray = arg.map(getHashArg).join(',');
            return `(${hashArray})`;
        } else {
            const sortedKeys = Object.keys(arg).sort();
            const hashObject = sortedKeys
                .map((key) => {
                    const value = (arg as Record<string, any>)[key];
                    const hashValue = getHashArg(value);
                    return `${key}:${hashValue}`;
                })
                .join(',');
            return `(${hashObject})`;
        }
    } else return `${arg}`;
}
