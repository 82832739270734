import { DiscountCodeFormErrors } from 'constants/formErrors';
import { isValidInteger } from './numberValidations';

export function validateDiscountCode(discountCode: string) {
    if (!discountCode) return { discountCode: DiscountCodeFormErrors.DiscountCodeRequired };
    return { discountCode: undefined };
}

export function validateDescription(description: string) {
    if (!description) return { description: DiscountCodeFormErrors.DescriptionRequired };
    return { description: undefined };
}

export function validateDiscountAmount(discountAmount: string) {
    if (!discountAmount) return { discountAmount: DiscountCodeFormErrors.DiscountAmountRequired };
    if (!isValidInteger(discountAmount)) return { discountAmount: DiscountCodeFormErrors.DiscountAmountInvalidInteger };
    if (Number(discountAmount) <= 0) return { discountAmount: DiscountCodeFormErrors.DiscountAmountMustBePositive };
    return { discountAmount: undefined };
}

export function validateDiscountPercentage(discountPercentage: string) {
    if (!discountPercentage) return { discountPercentage: DiscountCodeFormErrors.DiscountPercentageRequired };
    if (!isValidInteger(discountPercentage))
        return { discountPercentage: DiscountCodeFormErrors.DiscountPercentageInvalid };
    if (Number(discountPercentage) <= 0 || Number(discountPercentage) > 100)
        return { discountPercentage: DiscountCodeFormErrors.DiscountPercentageOutOfRange };
    return { discountPercentage: undefined };
}

export function validateDiscountTime(discountTime: string) {
    if (!discountTime) return { discountTime: DiscountCodeFormErrors.DiscountTimeRequired };
    if (!isValidInteger(discountTime)) return { discountTime: DiscountCodeFormErrors.DiscountTimeInvalid };
    if (Number(discountTime) <= 0) return { discountTime: DiscountCodeFormErrors.DiscountTimeMustBePositive };
    return { discountTime: undefined };
}

export function validateExpirationDate(expirationDate: Date | null | undefined) {
    if (expirationDate && expirationDate < new Date())
        return { expirationDate: DiscountCodeFormErrors.ExpirationDateInvalid };
    return { expirationDate: undefined };
}

export function validateNumberOfUses(numberOfUses: string) {
    if (numberOfUses !== '' && isValidInteger(numberOfUses)) {
        if (Number(numberOfUses) <= 0) return { numberOfUses: DiscountCodeFormErrors.NumberOfUsesInvalid };
    }
    return { numberOfUses: undefined };
}

export function validateMaxNumberOfRentals(maxNumberOfRentals: string) {
    if (maxNumberOfRentals !== '' && isValidInteger(maxNumberOfRentals)) {
        if (Number(maxNumberOfRentals) <= 0)
            return { maxNumberOfRentals: DiscountCodeFormErrors.MaxNumberOfRentalsInvalid };
    }
    return { maxNumberOfRentals: undefined };
}
