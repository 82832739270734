import React from 'react';
import styles from './AGDatePicker.module.css';
import { isAfter, isBefore, isInBetweenIncluding, isTheSameDay, isToday } from './utils';

type DayProps = {
    year: number;
    month: number;
    dayNumber: number;
    handleClick: (date: Date) => void;
    isRangePicked: boolean;
    selectedStartDate?: Date;
    selectedEndDate?: Date;
    blockedUntil?: Date;
    earliestAvailableDate?: Date;
    latestAvailableDate?: Date;
};

const Day = ({
    year,
    month,
    dayNumber,
    selectedEndDate,
    selectedStartDate,
    blockedUntil,
    isRangePicked,
    earliestAvailableDate,
    latestAvailableDate,
    handleClick,
}: DayProps) => {
    const date = new Date(year, month, dayNumber);
    const today = isToday(date);
    const isStartDate = selectedStartDate && isTheSameDay(date, selectedStartDate);
    const isEndDate = selectedEndDate && isTheSameDay(date, selectedEndDate);
    const selected = Boolean(isStartDate || isEndDate);
    const disabled = Boolean(
        (earliestAvailableDate && isBefore(date, earliestAvailableDate)) ||
            (latestAvailableDate && isAfter(date, latestAvailableDate))
    );
    const blocked =
        !selected && selectedStartDate && blockedUntil
            ? isInBetweenIncluding(date, selectedStartDate, blockedUntil)
            : false;
    const isInRange = Boolean(
        isRangePicked &&
            selectedStartDate &&
            selectedEndDate &&
            isInBetweenIncluding(date, selectedStartDate, selectedEndDate)
    );
    const isStartOfRange = isInRange && isStartDate;
    const isEndOfRange = isInRange && isEndDate;

    const tdClassName = [];
    if (isInRange) tdClassName.push(styles.inRange);
    if (isStartOfRange) tdClassName.push(styles.startOfRange);
    if (isEndOfRange) tdClassName.push(styles.endOfRange);
    if (dayNumber === 1) tdClassName.push(styles.firstOfMonth);
    if (new Date(year, month + 1, 0).getDate() === dayNumber) tdClassName.push(styles.lastOfMonth);

    const buttonClassName = [styles.day];
    if (today) buttonClassName.push(styles.today);
    if (selected) buttonClassName.push(styles.selected);
    if (disabled) buttonClassName.push(styles.disabled);
    if (blocked) buttonClassName.push(styles.blocked);
    return (
        <td className={tdClassName.join(' ')}>
            {dayNumber > 0 && (
                <button
                    onClick={() => {
                        if (!blocked && !disabled) handleClick(date);
                    }}
                    className={buttonClassName.join(' ')}
                    type="button"
                >
                    {dayNumber}
                </button>
            )}
        </td>
    );
};

export default Day;
