import { TENANTS_PARKING_REQUESTED, TENANTS_PARKING_SUCCESS, TENANTS_PARKING_FAILED } from './constants';
import { Action } from '../useReducerWithMiddleware';
import { TenantsParkingData } from 'types';

export type TenantsParkingState = {
    loading: boolean;
    errorMessage: string;
    tenantsParkings: TenantsParkingData[];
};

export const initialTenantsParkingState: TenantsParkingState = {
    loading: false,
    errorMessage: '',
    tenantsParkings: [],
};

export default function tenantsParkingReducer(
    state: TenantsParkingState = initialTenantsParkingState,
    action: Action
): TenantsParkingState {
    switch (action.type) {
        case TENANTS_PARKING_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case TENANTS_PARKING_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                tenantsParkings: action.payload as TenantsParkingData[],
            };
        case TENANTS_PARKING_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialTenantsParkingState;
    }
}
