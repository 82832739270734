import React from 'react';

const FlagIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M8.3425 3.1875L8.7925 5.4375H14.6875V12.1875H10.9075L10.4575 9.9375H2.3125V3.1875H8.3425ZM10.1875 0.9375H0.0625V20.0625H2.3125V12.1875H8.6125L9.0625 14.4375H16.9375V3.1875H10.6375L10.1875 0.9375Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default FlagIcon;
