import React from 'react';
import { addOrganizationAction } from './../../actions/actions';
import { Button, Form, Message, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import useForm from './../../hooks/useForm';

const OrganizationForm = (props: any) => {
    const { inputs, handleInput, handleDropdown } = useForm({
        name: '',
        sales_representative: null,
        address_text: '',
    });
    const fields = inputs as any;

    const createOrganization = () => {
        if (inputs.name && inputs.sales_representative) {
            props.addOrganization(inputs);
        }
    };

    return (
        <div>
            <Loader active={props.loading} />
            <Form hidden={props.loading}>
                {props.errorMessage && <Message color="red">{props.errorMessage}</Message>}
                <Form.Field required>
                    <label>Organization Name</label>
                    <Form.Input
                        name="name"
                        icon="building"
                        iconPosition="left"
                        placeholder="AirGarage Properties LLC"
                        onChange={handleInput}
                        value={fields.email}
                        required
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Sales Representative</label>
                    <Form.Dropdown
                        options={props.salesReps}
                        name="sales_representative"
                        placeholder="citylauncher@airgara.ge"
                        value={fields.sales_representative}
                        onChange={handleDropdown}
                        search
                        fluid
                        selection
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Mailing Address</label>
                    <Form.Input
                        icon="map marker alternate"
                        iconPosition="left"
                        name="address_text"
                        placeholder="4616 Eagle Rock Blvd. Los Angeles, CA 90041"
                        onChange={handleInput}
                        value={fields.address_text}
                        required
                    />
                </Form.Field>
                <Button fluid type="submit" content="Create" onClick={() => createOrganization()} />
            </Form>
        </div>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.organization.errorMessage,
        loading: state.organization.loading,
    };
}

const mapDispatchToProps = {
    addOrganization: addOrganizationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
