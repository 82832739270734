import React from 'react';
import { BarElement, Chart as ChartJS, ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BreakdownFrequencyReportInterface } from 'types';
import { FREQUENT_COLOR, ONE_TIME_COLOR, RETURN_COLOR } from './utils';
import { dayCompleteName } from 'utils/constants';
import styles from './DriversFrequencyWidget.module.css';

ChartJS.register(BarElement);

type BreakdownGraphProps = {
    report: BreakdownFrequencyReportInterface;
};

type ChartLabels = string;

function BreakdownGraph({ report }: BreakdownGraphProps): JSX.Element {
    const labels = [];
    const maxValue = { value: 0, day: '' };

    const frequentDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: FREQUENT_COLOR,
        label: 'Frequent',
    };

    const returnDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: RETURN_COLOR,
        label: 'Return',
    };
    const oneTimeDataset: ChartDataset<'bar', number[]> = {
        data: [],
        backgroundColor: ONE_TIME_COLOR,
        label: 'One-time',
    };

    for (const [label, value] of Object.entries(report)) {
        labels.push(label);
        const {
            power_drivers: frequentDrivers,
            repeat_drivers: returnDrivers,
            one_time_drivers: onetimeDrivers,
            total_drivers: totalDrivers,
        } = value;

        frequentDataset.data.push(frequentDrivers.count);
        returnDataset.data.push(returnDrivers.count);
        oneTimeDataset.data.push(onetimeDrivers.count);

        if (totalDrivers > maxValue.value) {
            maxValue.value = totalDrivers;
            maxValue.day = dayCompleteName[label];
        }
    }

    const data: ChartData<'bar', number[], ChartLabels> = {
        labels,
        datasets: [frequentDataset, returnDataset, oneTimeDataset],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: { display: false, drawBorder: false },
            },
            y: {
                stacked: true,
                grid: { display: false, drawBorder: false },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label(tooltipItem: TooltipItem<'bar'>): string | string[] {
                        return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                    },
                    title(tooltipItems: TooltipItem<'bar'>[]): string | string[] {
                        return dayCompleteName[tooltipItems[0].label];
                    },
                },
            },
        },
    };
    return (
        <div>
            <p className={styles.detail}>
                Most drivers are parking on <b>{maxValue.day}s</b>
            </p>
            <Bar options={options} data={data} />
        </div>
    );
}

export default BreakdownGraph;
