import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button/Button';
import Modal, { ModalRef } from 'components/Modal/Modal';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import AGSimpleSelect, { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import { SpotInterface } from 'types';
import DateSelector from './DateSelector';
import styles from './IncomeStatement.module.css';
import { getFormattedDateYYYY_MM_DD, newUTCDate } from 'utils/helpers';
import { downloadClient } from 'clients';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { HTTPError } from 'clients/HTTPClient';
import amplitude from 'amplitude-js';

type DownloadStatementProps = {
    propertyOptions: SelectOption[] | undefined;
    startDate: string;
    endDate: string;
    spotPk: string;
    orgSpots: Record<string, SpotInterface>;
    orgUuid: string;
};

const DownloadStatement = ({ propertyOptions, orgSpots, orgUuid, ...props }: DownloadStatementProps) => {
    const [spotPk, setSpotPk] = useState(props.spotPk);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const downloadModalRef = useRef<ModalRef>();

    useEffect(() => {
        setSpotPk(props.spotPk);
    }, [props.spotPk]);

    useEffect(() => {
        setStartDate(props.startDate);
    }, [props.startDate]);

    useEffect(() => {
        setEndDate(props.endDate);
    }, [props.endDate]);

    function handleSaveDateRange(startDate: Date, endDate: Date) {
        setStartDate(getFormattedDateYYYY_MM_DD(startDate));
        setEndDate(getFormattedDateYYYY_MM_DD(endDate));
    }

    async function downloadStatement() {
        try {
            setLoading(true);
            const payload = { orgUuid, startDate, endDate, spotPk: spotPk ? orgSpots[spotPk].uuid : '' };
            amplitude.getInstance().logEvent('Download income statement', payload);
            await downloadClient.downloadIncomeStatementReport(payload);
            downloadModalRef.current?.closeModal();
        } catch (e) {
            if (HTTPError.isHTTPError(e)) {
                setErrorMessage(e.message);
            } else {
                setErrorMessage('Something went wrong');
            }
        }
        setLoading(false);
    }

    return (
        <div>
            <Button size="sm" onClick={() => downloadModalRef?.current?.openModal()} className={styles.downloadButton}>
                <Download />
                Download
            </Button>

            <Modal ref={downloadModalRef} title="Download Income Statement">
                <div className={styles.downloadModalContainer}>
                    <p>Get a CSV file containing Revenue, Refunds, Taxes, Expenses, and Net Income.</p>
                    {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                    <div>
                        <p>Date Range</p>
                        <DateSelector
                            startDate={newUTCDate(startDate)}
                            endDate={newUTCDate(endDate)}
                            onSave={handleSaveDateRange}
                            dropdownWidth="100%"
                            isInModal
                        />
                    </div>
                    {!!propertyOptions && (
                        <div>
                            <p>Properties</p>
                            <AGSimpleSelect
                                icon={<Lines />}
                                width="100%"
                                selected={spotPk}
                                options={propertyOptions}
                                onSelect={(v) => setSpotPk(v)}
                                isInModal
                            />
                        </div>
                    )}

                    <Button
                        size="sm"
                        className={styles.modalDownloadButton}
                        onClick={downloadStatement}
                        loading={loading}
                    >
                        <Download /> Download
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default DownloadStatement;
