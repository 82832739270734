import React, { useMemo, useState } from 'react';
import { Transition, Variants, motion } from 'framer-motion';
import Arrow from 'components/Icons/ArrowDown';
import IconButton from 'components/Button/IconButton';
import Skeleton from 'components/Skeleton/Skeleton';
import styles from './ImageCarousel.module.css';

type ImageLabels = {
    text: string;
    position: {
        top?: number;
        left?: number;
        right?: number;
        bottom?: number;
    };
};

export type CarouselImage = { src: string; labels?: ImageLabels[] };

type ImageCarouselProps = {
    images: CarouselImage[];
    maxImagesToShow: number;
    infinite?: boolean;
};

enum Direction {
    FORWARD = 1,
    BACKWARD = -1,
}

const variants: Variants = {
    enter: (direction: number) => {
        return {
            zIndex: 0,
            x: direction > 0 ? 10 : -10,
            opacity: 0.8,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
};

const transition: Transition = {
    x: { duration: 0.3 },
    opacity: { duration: 0.2 },
};

export default function ImageCarousel({ images, maxImagesToShow, infinite = true }: ImageCarouselProps) {
    const [[currentIndex, direction], setCurrentIndex] = useState([0, 1]);
    const [loading, setLoading] = useState(false);

    const currentImages = useMemo(
        function getImagesToShow() {
            setLoading(true);
            const imagesToShow = [];
            let indexToAdd = currentIndex;

            for (let i = 0; i < maxImagesToShow; i++) {
                if (indexToAdd >= images.length && !infinite) break;
                else if (indexToAdd >= images.length) indexToAdd = 0;
                imagesToShow.push(images[indexToAdd]);
                indexToAdd++;
            }

            return imagesToShow;
        },
        [images, maxImagesToShow, infinite, currentIndex]
    );

    function paginate(newDirection: Direction) {
        if (images[currentIndex + newDirection]) setCurrentIndex([currentIndex + newDirection, newDirection]);
        else setCurrentIndex([newDirection === Direction.FORWARD ? 0 : images.length - 1, newDirection]);
    }

    function finishLoading() {
        if (loading) setLoading(false);
    }

    return (
        <div className={styles.carousel}>
            {currentImages.map((image, i) => {
                return (
                    <motion.div
                        key={image.src}
                        className={styles.image}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        transition={transition}
                    >
                        <img src={image.src} alt="" role="img" onLoad={finishLoading} onError={finishLoading} />
                        {loading && (
                            <div className={styles.loader}>
                                <Skeleton />
                            </div>
                        )}

                        {!loading &&
                            image.labels &&
                            image.labels.map((label) => (
                                <p style={label.position} className={styles.label}>
                                    {label.text}
                                </p>
                            ))}
                    </motion.div>
                );
            })}
            {!loading && maxImagesToShow < images.length && (infinite || currentIndex !== 0) && (
                <IconButton
                    className={`${styles.arrow} ${styles.left}`}
                    onClick={() => paginate(Direction.BACKWARD)}
                    data-testid="prev"
                >
                    <Arrow direction="left" color="var(--ag-color-gray3)" />
                </IconButton>
            )}
            {!loading &&
                maxImagesToShow < images.length &&
                (infinite || currentIndex + maxImagesToShow < images.length) && (
                    <IconButton
                        className={`${styles.arrow} ${styles.right}`}
                        onClick={() => paginate(Direction.FORWARD)}
                        data-testid="next"
                    >
                        <Arrow direction="right" color="var(--ag-color-gray3)" />
                    </IconButton>
                )}
        </div>
    );
}
