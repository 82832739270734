import APIClient from './APIClient';
import { Visitor } from '../types/Visitor';
import { PagedResponse } from 'types/Pagination';

export type GetVisitorsResponse = PagedResponse<Visitor>;

// comes from backend/spots/api/views/visitor_viewset.py
export const VISITOR_PAGE_SIZE = 20;

interface VisitorCreationPayload {
    codes?: string[];
    phone?: string;
    plate: string;
    spot: number;
    state: string;
    country: string;
    validation_source?: string;
    name?: string;
}

class VisitorService {
    getVisitors(
        spot: number,
        token: string,
        page: number = 1,
        expiring?: boolean,
        searchTerm?: string
    ): Promise<GetVisitorsResponse> {
        let url = `visitors?spot=${spot}&page=${page}`;
        if (expiring) {
            url += '&expiring=True';
        }
        if (searchTerm) {
            url += `&search=${searchTerm}`;
        }
        return APIClient.get(url, token);
    }
    addVisitor(visitorInfo: VisitorCreationPayload, token: string) {
        return APIClient.post(`visitors/`, visitorInfo, token);
    }
    editVisitor(visitorInfo: any, pk: number, token: string) {
        return APIClient.patch(`visitors/${pk}/`, visitorInfo, token);
    }
    getVisitorCodes(spot: number, token: string) {
        return APIClient.get(`codes/?spot=${spot}`, token);
    }
    uploadVisitorsCSV(body: any, token: string) {
        return APIClient.postReq(`visitors_csv`, body, token);
    }
    progressUploadVisitorsCSV(spot: number, token: string) {
        return APIClient.get(`visitors_csv?spot=${spot}`, token);
    }
    addVisitorCode(codeInfo: any, token: string) {
        return APIClient.post(`codes`, codeInfo, token);
    }
    editVisitorCode(codeInfo: any, pk: number, token: string) {
        return APIClient.patch(`codes/${pk}/`, codeInfo, token);
    }
    deleteVisitorCode(pk: number, token: string) {
        return APIClient.delete(`codes/${pk}/`, token);
    }
    deleteVisitor(pk: number, token: string) {
        return APIClient.delete(`visitors/${pk}/`, token);
    }
}

export default new VisitorService();
