import React from 'react';
import { Icon } from 'semantic-ui-react';

import WidgetDataLabel from 'components/widgets/WidgetDataLabel';
import WidgetData from 'components/widgets/WidgetData';
import WidgetTitle from 'components/widgets/WidgetTitle';
import HandFanGallery from 'components/HandFanGallery/HandFanGallery';
import { EnforcementSummaryEntry } from 'types/LotTimeline';
import styles from '../timeline.module.css';
import { getFormattedDateAndTime } from 'utils/helpers';

type EnforcementSummaryProps = {
    entry: EnforcementSummaryEntry;
};

const AUTOSTART_TITLE = 'Revenue from Autostarts';
const AUTOSTART_TOOLTIP_MESSAGE =
    'When we find a  vehicle that is in our system as a previous customer, we will autostart their rental and collect revenue';
const AUTOSTART_COUNT_TITLE = 'Total Autostarts';
const AUTOSTART_COUNT_TOOLTIP_MESSAGE = 'How many vehicles were autostarted';

function Enforcement({ entry }: EnforcementSummaryProps) {
    const formattedDateAndTime = getFormattedDateAndTime(new Date(entry.timestamp));
    return (
        <div className={styles.enforcementEvent}>
            <WidgetTitle title="Enforcement" icon={<Icon name="id badge" />} lastUpdatedLabel={formattedDateAndTime} />

            <div className={styles.dailyReportWrapper}>
                <div className={styles.enforcementStats}>
                    <div>
                        <WidgetDataLabel
                            label={AUTOSTART_TITLE}
                            tooltip={{
                                title: '',
                                message: AUTOSTART_TOOLTIP_MESSAGE,
                            }}
                        />
                        <WidgetData>{entry.details.autostarted_revenue}</WidgetData>
                    </div>
                    <div>
                        <WidgetDataLabel
                            label={AUTOSTART_COUNT_TITLE}
                            tooltip={{
                                title: '',
                                message: AUTOSTART_COUNT_TOOLTIP_MESSAGE,
                            }}
                        />
                        <WidgetData>{entry.details.autostarted_count}</WidgetData>
                    </div>
                </div>
                <HandFanGallery images={entry.details.images} />
            </div>
        </div>
    );
}

export default Enforcement;
