import {
    TEAM_FAILED,
    TEAM_REQUESTED,
    TEAM_SUCCESS,
    ADD_TEAM_REQUESTED,
    ADD_TEAM_SUCCESS,
    ADD_TEAM_FAILED,
    DELETE_TEAM_REQUESTED,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAILED,
} from '../actions/constants';

export const initialTeamState = {
    invitations: null,
    errorMessage: '',
    successMessage: '',
    loading: false,
};

export default function teamReducer(state = initialTeamState, action: any) {
    switch (action.type) {
        case TEAM_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                invitations: action.payload.invitations,
            };
        case TEAM_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
            };
        case ADD_TEAM_REQUESTED:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errorMessage: '',
            };
        case ADD_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                invitations: action.payload.invitations,
                successMessage: 'Team member invited via email.',
                errorMessage: '',
            };
        case ADD_TEAM_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
                successMessage: '',
            };
        case DELETE_TEAM_REQUESTED:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errorMessage: '',
            };
        case DELETE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                invitations: action.payload.invitations,
                successMessage: 'Team member removed.',
                errorMessage: '',
            };
        case DELETE_TEAM_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
                successMessage: '',
            };
        default:
            return state;
    }
}
