import React, { useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import 'chartjs-adapter-moment';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartDataset,
    ChartOptions,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    TooltipItem,
    TimeScale,
    Filler,
    ChartEvent,
    ActiveElement,
    Chart,
    Tick,
} from 'chart.js';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { PricingPayload } from 'types';
import {
    BASELINE_DATASET_INDEX,
    dynamicPricingBaselineLabel,
    dynamicPricingHighLowLabels,
    dynamicPricingVerticalHoverLine,
    dynamicPricingOpacityOnMouseOut,
    COLORS,
    calculateGraphData,
} from './utils';

export const chartId = 'dynamic-pricing-rate-v2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    dynamicPricingBaselineLabel(chartId),
    dynamicPricingVerticalHoverLine(chartId),
    dynamicPricingHighLowLabels(chartId),
    dynamicPricingOpacityOnMouseOut(chartId),
    TimeScale,
    Filler
);

type PriceGraphProps = {
    rates: PricingPayload[];
    timezone: string;
};

function PriceGraph({ rates, timezone }: PriceGraphProps): JSX.Element {
    const { basePrices, multipleDaysData, xAxisLabels, allDaysLabels, maxPrice } = calculateGraphData(rates);

    const maxValueForGraph = useMemo(
        function getMax() {
            const basePrice = basePrices[0];
            if (!basePrice || !maxPrice) return undefined;
            const dif = maxPrice - basePrice;
            const suggestedMax = dif * 0.6 + maxPrice;
            const basePriceSuggestedMax = basePrice * 1.3;
            return suggestedMax > basePriceSuggestedMax ? suggestedMax : basePriceSuggestedMax;
        },
        [basePrices, maxPrice]
    );

    const minValueForGraph = useMemo(
        function getMin() {
            const basePrice = basePrices[0];
            if (!basePrice) return;
            const minValue = basePrice - basePrice * 0.2;
            if (maxValueForGraph) {
                const min = basePrice - (maxValueForGraph - basePrice) * 0.2;
                console.log(min, minValue);
                return min > minValue ? minValue : min;
            } else return basePrice - basePrice * 0.3;
        },
        [basePrices, maxValueForGraph]
    );

    const dynamicPriceGraphOptions: ChartOptions<'line'> = {
        maintainAspectRatio: false,
        responsive: true,
        elements: { line: { tension: 0.2 } },
        spanGaps: true,
        layout: {
            padding: {
                right: 110,
            },
        },
        onHover(_event: ChartEvent, _elements: ActiveElement[], chart: Chart) {
            const activeLinesIndexes = chart.tooltip?.dataPoints?.map((dataPoint) => dataPoint.datasetIndex) || [];

            const inactiveLinesIndexes: number[] = [];
            // i starts with 1 to avoid baseLine dataset (index 0)
            for (let i = 1; i < data.datasets.length; i++) {
                if (!activeLinesIndexes?.includes(i)) inactiveLinesIndexes.push(i);
            }

            if (activeLinesIndexes.length > 0) {
                for (let i = 0; i < inactiveLinesIndexes.length; i++) {
                    const lineIndex = inactiveLinesIndexes[i];
                    chart.data.datasets[lineIndex].borderColor = `${COLORS[lineIndex]}50`;
                }
                for (let i = 0; i < activeLinesIndexes.length; i++) {
                    const lineIndex = activeLinesIndexes[i];
                    chart.data.datasets[lineIndex].borderColor = COLORS[lineIndex];
                }
            } else {
                // this case happens when hovering over the baseline
                for (let i = 0; i < inactiveLinesIndexes.length; i++) {
                    const lineIndex = inactiveLinesIndexes[i];
                    chart.data.datasets[lineIndex].borderColor = `${COLORS[lineIndex]}`;
                }
            }

            chart.update();
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'ha',
                    },
                    stepSize: 2,
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    callback: (_tickValue: string | number, index: number, ticks: Tick[]) => {
                        if (!ticks[index]) return;
                        return moment(ticks[index].value).tz(timezone).format('ha');
                    },
                },
            },
            y: {
                ticks: {
                    maxTicksLimit: 2,
                    display: false,
                },
                grid: {
                    drawBorder: false,
                },
                min: minValueForGraph,
                max: maxValueForGraph,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    padding: 15,
                },
            },
            tooltip: {
                mode: 'nearest',
                filter(tooltipItem: TooltipItem<'line'>): boolean {
                    return tooltipItem.dataset.label !== 'Baseline rate';
                },
                callbacks: {
                    label(tooltipItem: TooltipItem<'line'>): string | string[] {
                        const dayIndex = tooltipItem.datasetIndex - 1; // -1 is needed because baseline dataset is included here but not in multipleDaysData
                        return multipleDaysData[dayIndex][tooltipItem.dataIndex].tooltipLabel;
                    },
                    labelPointStyle() {
                        return {
                            pointStyle: 'rectRounded',
                            rotation: 0,
                        };
                    },
                    title: (tooltipItems: TooltipItem<'line'>[]): string | string[] => {
                        const timestamp = xAxisLabels[tooltipItems[0]?.dataIndex];
                        return tooltipItems.length > 0
                            ? moment(timestamp).tz(timezone).format('h:mm a').replace(':00', '')
                            : '';
                    },
                },
                cornerRadius: 16,
                bodyColor: '#FFFFFF',
                borderColor: 'transparent',
                usePointStyle: true,
            },
        },
    };

    const datasets: ChartDataset<'line'>[] = [];

    datasets[BASELINE_DATASET_INDEX] = {
        label: 'Baseline rate',
        data: basePrices,
        borderDash: [10, 5],
        borderColor: COLORS[0],
        pointStyle: 'line',
    };

    for (let i = 0; i < multipleDaysData.length; i++) {
        const oneDayData = multipleDaysData[i];
        datasets.push({
            label: allDaysLabels[i],
            data: oneDayData.map((d) => d.price),
            borderColor: COLORS[i + 1],
            borderWidth: 4,
            pointBackgroundColor: COLORS[i + 1],
            pointRadius: 1,
            pointHoverBorderColor: '#fff',
            pointBorderColor: 'transparent',
            segment: {
                borderCapStyle: (ctx) =>
                    ctx.p0DataIndex === 0 || ctx.p1DataIndex === oneDayData.length - 1 ? 'round' : 'butt',
            },
            pointStyle: 'rectRounded',
        });
    }

    const data: ChartData<'line'> = {
        labels: xAxisLabels,
        datasets,
    };

    return (
        <Grid.Row>
            <Line role={chartId} data={data} options={dynamicPriceGraphOptions} height="250px" />
        </Grid.Row>
    );
}

export default PriceGraph;
