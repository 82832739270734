import useFilters, { UrlFilters, UseFiltersParams } from './useFilters';

export type WithPagination<T extends UrlFilters> = T & { page: string };

function paginationValidator(p: string | null): string {
    return p === null || isNaN(parseInt(p)) ? '1' : p;
}

export default function usePaginatedFilters<Filters extends UrlFilters>({
    filterValidators,
    push,
}: UseFiltersParams<Filters>) {
    return useFilters<WithPagination<Filters>>({
        filterValidators: {
            ...filterValidators,
            page: paginationValidator,
        },
        push,
    });
}
