import React from 'react';
import { connect } from 'react-redux';
import { Grid, Loader, Card } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import SpotHeader from 'components/Dashboard/SpotWidgets/SpotHeader';
import { AppState } from 'reducers/reducers';
import { selectedSpotInfo } from 'selectors';
import ReviewWidget from 'components/ReviewWidget/ReviewWidget';
import LotDemandWidget from 'components/Dashboard/LotDemandWidget/LotDemandWidget';
import DriversFrequencyWidget from 'components/Dashboard/DriversFrequencyWidget/DriversFrequencyWidget';

type DriverInsightsContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const DriverInsightsContainer = ({
    orgSpots,
    orgSpotsLoading,
    organization,
    currentSpot,
    push: connectedPush,
}: DriverInsightsContainerProps): JSX.Element => {
    if (orgSpots === null || orgSpotsLoading) {
        return <Loader active inline />;
    }
    // From now on, the spot object is non-null; since the selector uses the orgSpots.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spotArray = Object.values(orgSpots!);
    if (spotArray.length === 0 || !currentSpot) {
        return <></>;
    }

    const onSelectSpot = (pk: number) => {
        if (pk !== currentSpot.pk) {
            connectedPush(`/organization/${organization.pk}/spots/${pk}/driver-insights`);
        }
    };

    return (
        <Card fluid>
            <Card.Content>
                <SpotHeader
                    currentSpot={currentSpot}
                    orgSpots={spotArray}
                    onSelectSpot={onSelectSpot}
                    organization={organization}
                />
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column computer={7} tablet={16}>
                            <ReviewWidget currentSpot={currentSpot} />
                        </Grid.Column>
                        <Grid.Column computer={9} tablet={16}>
                            <LotDemandWidget currentSpot={currentSpot} />
                            <DriversFrequencyWidget currentSpot={currentSpot} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
};

function mapStateToProps(state: AppState) {
    return {
        orgSpots: state.spots.orgSpots,
        orgSpotsLoading: state.spots.orgSpotsLoading,
        currentSpot: selectedSpotInfo(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: state.organization.organization!,
    };
}

const mapDispatchToProps = {
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverInsightsContainer);
