import React from 'react';
import { Button, Modal, Form, Header, Divider, Icon } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useForm from '../../../hooks/useForm';
import useModal from '../../../hooks/useModal';

const AccountRepresentativeModalForm = (props: any) => {
    const { handleInput, inputs, handleValueChange } = useForm({ dob: new Date('01/01/1960') });
    const { isShowing, toggle } = useModal();
    const fields = inputs as any;
    function addPayoutInfo() {
        props.addInfo(inputs);
        toggle();
    }
    return (
        <div>
            <Button floated="right" icon="pencil alternate" onClick={toggle} />
            <Modal as={Form} open={isShowing} onClose={toggle} onSubmit={addPayoutInfo}>
                <Header content="Update account representative" as="h2" />
                <Header as="h5">
                    <Icon name="lock" />
                    We never see or save this sensitive data. It is sent directly to our payment processor for identity
                    verification.
                </Header>
                <Modal.Content>
                    <h4>Full name</h4>
                    <Form.Input
                        name="name"
                        value={fields.account}
                        onChange={handleInput}
                        type="text"
                        placeholder="First, Last"
                    />
                    <h4>DOB</h4>
                    <DatePicker
                        showYearDropdown={true}
                        selected={fields.dob}
                        onChange={(date: Date | null) => handleValueChange('dob', date as Date)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic floated="left" color="red" icon="times" content="Close" onClick={toggle} />
                    <Button floated="right" type="submit" color="green" icon="save" content="Save" />
                    <Divider hidden />
                    <Divider hidden />
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default AccountRepresentativeModalForm;
