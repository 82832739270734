import {
    PAYOUTS_REQUESTED,
    PAYOUTS_SUCCESS,
    PAYOUTS_FAILED,
    PAYOUT_DETAILS_REQUESTED,
    PAYOUT_DETAILS_SUCCESS,
    PAYOUT_DETAILS_FAILED,
    ADD_PAYOUT_FAILURE,
    ADD_PAYOUT_REQUESTED,
    ADD_PAYOUT_SUCCESS,
    PAYOUT_ACCOUNT_INFO_FAILED,
    PAYOUT_ACCOUNT_INFO_SUCCESS,
    PAYOUT_ACCOUNT_INFO_REQUESTED,
} from '../actions/constants';
import { PayoutInterface } from '../services/PayoutsService';
import { PayoutAccountInfoInterface, PayoutDetailInterface } from '../types/Payouts';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

type PayoutsState = {
    loading: boolean;
    errorMessage: string;
    successMessage: string;
    payoutAccountInfo: PayoutAccountInfoInterface | null;
    payouts: PayoutInterface[] | null;
    selectedPayoutDetails: PayoutDetailInterface | null;
    payoutDetailsLoading: boolean;
};

export const initialPayoutsState: PayoutsState = {
    loading: false,
    errorMessage: '',
    successMessage: '',
    payoutAccountInfo: null,
    payouts: null,
    selectedPayoutDetails: null,
    payoutDetailsLoading: false,
};

export default function payoutsReducer(state = initialPayoutsState, action: any): PayoutsState {
    const newState = cloneObject(state);
    switch (action.type) {
        case PAYOUTS_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            newState.successMessage = '';
            return newState;
        case PAYOUTS_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.successMessage = action.message;
            newState.payouts = action.payload.payouts;
            return newState;
        case PAYOUTS_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case PAYOUT_DETAILS_REQUESTED:
            newState.payoutDetailsLoading = true;
            newState.errorMessage = '';
            newState.selectedPayoutDetails = null;
            return newState;
        case PAYOUT_DETAILS_SUCCESS:
            newState.payoutDetailsLoading = false;
            newState.errorMessage = '';
            newState.successMessage = action.message;
            newState.selectedPayoutDetails = action.payload.selectedPayoutDetails;
            return newState;
        case PAYOUT_DETAILS_FAILED:
            newState.errorMessage = action.message;
            newState.payoutDetailsLoading = false;
            return newState;
        case ADD_PAYOUT_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            newState.successMessage = '';
            return newState;
        case ADD_PAYOUT_SUCCESS:
            newState.loading = false;
            newState.errorMessage = '';
            newState.successMessage = action.message;
            newState.payoutAccountInfo = action.payload.payoutAccountInfo;
            return newState;
        case ADD_PAYOUT_FAILURE:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case PAYOUT_ACCOUNT_INFO_REQUESTED:
            newState.loading = true;
            newState.errorMessage = '';
            newState.successMessage = '';
            return newState;
        case PAYOUT_ACCOUNT_INFO_SUCCESS:
            newState.loading = false;
            newState.payoutAccountInfo = action.payload.payoutAccountInfo;
            return newState;
        case PAYOUT_ACCOUNT_INFO_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        default:
            return state || initialPayoutsState;
    }
}
