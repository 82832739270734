import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader, Message } from 'semantic-ui-react';
import { getRefundsAction, issueRefundAction, ignoreRefundAction } from 'actions/actions';
import Refunds from 'components/Dashboard/Modules/Refunds';
import { AppState } from 'reducers/reducers';
import { trackPageViewed } from 'utils/analytics';

type RefundsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const RefundsModuleContainer = (props: RefundsModuleContainerProps) => {
    const { getRefunds, issueRefund, refunds, ignoreRefund, profile } = props;
    useEffect(() => {
        getRefunds();

        trackPageViewed({
            pageName: 'Refunds',
            organizationId: null,
            spotId: null,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [getRefunds, profile]);

    return (
        <div>
            {refunds ? (
                <div>
                    {props.successMessage && <Message color="green">{props.successMessage}</Message>}
                    {props.errorMessage && <Message color="red">{props.errorMessage}</Message>}
                    <Refunds ignoreRefund={ignoreRefund} issueRefund={issueRefund} refunds={props.refunds} />
                </div>
            ) : (
                <Loader active inline />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.refunds.errorMessage,
        successMessage: state.refunds.successMessage,
        loading: state.refunds.loading,
        refunds: state.refunds.refunds,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getRefunds: getRefundsAction,
    issueRefund: issueRefundAction,
    ignoreRefund: ignoreRefundAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundsModuleContainer);
