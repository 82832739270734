import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

interface ErrorMessageProps {
    errorMessage: string;
}

function ErrorMessage(props: ErrorMessageProps): JSX.Element {
    return (
        <Message negative>
            <Icon name="minus circle" />
            <h4 style={{ display: 'inline' }}>{props.errorMessage}</h4>
        </Message>
    );
}

export default ErrorMessage;
