import React from 'react';
import { Menu, MenuItemProps } from 'semantic-ui-react';
import { Organization } from 'types';
import { isActiveTab } from 'utils/helpers';
import { AuthorizedView, Roles } from '../../Utilities/AuthorizedView';
import amplitude from 'amplitude-js';

type MainMenuProps = {
    organization: Organization;
    navigateToTab(tabUrl: string): void;
};

const MainMenu = (props: MainMenuProps) => {
    const { navigateToTab, organization } = props;

    const onNavigate = (_e: React.SyntheticEvent, { name }: MenuItemProps) => {
        navigateToTab(`/organization/${organization.pk}/${name?.toLowerCase().replace(' ', '-')}`);
        amplitude.getInstance().logEvent(`Clicked org menu`, { orgPk: organization.pk, name });
    };
    return (
        <>
            <Menu.Item name="Organization" header />
            <Menu.Item
                name="Properties"
                active={isActiveTab('detail')}
                onClick={(e) => onNavigate(e, { name: 'spots' })}
            />
            <AuthorizedView allowed={Roles.Admin}>
                <Menu.Item name="Team" active={isActiveTab('team')} onClick={onNavigate} />
            </AuthorizedView>
            <AuthorizedView allowed={Roles.Admin}>
                <Menu.Item name="Payouts" active={isActiveTab('payouts')} onClick={onNavigate} />
            </AuthorizedView>
            <AuthorizedView allowed={Roles.Admin}>
                <Menu.Item name="Income Statement" active={isActiveTab('income-statement')} onClick={onNavigate} />
            </AuthorizedView>
            <AuthorizedView allowed={[Roles.Admin, Roles.Employee]}>
                <Menu.Item name="Advertising" active={isActiveTab('advertising')} onClick={onNavigate} />
            </AuthorizedView>
        </>
    );
};

export default MainMenu;
