import React, { useEffect, useState } from 'react';
import {
    MonthYear,
    convertToMonthYear,
    days,
    daysAfterDate,
    getMonthGrid,
    getMonthName,
    getNextMonthYear,
    getPreviousMonthYear,
    isAfter,
    isInBetweenIncluding,
    isTheSameDay,
} from './utils';
import Day from './Day';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import styles from './AGDatePicker.module.css';

type AGDatePickerProps = {
    startDate?: Date;
    endDate?: Date;
    minimumDays?: number;
    isRangeMode?: boolean;
    earliestAvailableDate?: Date;
    latestAvailableDate?: Date;
    setStartDate: (date: Date | undefined) => void;
    setEndDate?: (date: Date | undefined) => void;
    allowDeSelect?: boolean;
};

const AGDatePicker = ({
    isRangeMode = false,
    minimumDays,
    earliestAvailableDate,
    latestAvailableDate,
    setStartDate,
    setEndDate = (_d) => {},
    startDate,
    endDate,
    allowDeSelect = true,
}: AGDatePickerProps) => {
    const [monthYear, setMonthYear] = useState<MonthYear>(convertToMonthYear(startDate || new Date()));
    if (isRangeMode && !setEndDate) console.warn('If isRangeMode is set to true, a setEndDate prop must be set');

    useEffect(() => {
        if (startDate) setMonthYear(convertToMonthYear(startDate));
    }, [startDate]);

    function handleClickInSingleMode(d: Date) {
        const wasAlreadySelected = startDate ? isTheSameDay(d, startDate) : false;
        if (wasAlreadySelected) {
            allowDeSelect && setStartDate(undefined);
        } else {
            setStartDate(d);
        }
    }

    function handleClickInRangeMode(d: Date): void {
        if (!startDate || endDate) {
            setStartDate(d);
            // if start date is null, end date should be null anyway, but just to be safe:
            setEndDate(undefined);
            return;
        }

        if (isTheSameDay(d, startDate) && minimumDays) {
            // undo the selection
            setStartDate(undefined);
            return;
        }

        if (isAfter(d, startDate)) {
            // no need to check for minimum days here because Day.svelte should
            // handle that internally.
            setEndDate(d);
            return;
        }

        // We will attempt to swap start and end dates because the user picked an end date earlier than start date.
        const endDateCandidate = startDate;
        setStartDate(d);
        // If there is no minimum day requirement, it's okay to swap.
        if (!minimumDays) {
            setEndDate(endDateCandidate);
            return;
        }
        // If the new start and end dates don't violate minimum days, it's okay to swap.
        const newBlockedUntil = daysAfterDate(d, minimumDays);
        if (isInBetweenIncluding(endDateCandidate, d, newBlockedUntil)) {
            setEndDate(undefined);
            return;
        }
        setEndDate(endDateCandidate);
    }

    function onClick(date: Date) {
        if (isRangeMode) {
            handleClickInRangeMode(date);
        } else {
            handleClickInSingleMode(date);
        }
    }

    function previousMonthYear() {
        setMonthYear(getPreviousMonthYear(monthYear));
    }

    function nextMonthYear() {
        setMonthYear(getNextMonthYear(monthYear));
    }

    return (
        <div className={styles.pickerWrapper} data-testid="AGDatePicker">
            <div className={styles.monthYear}>
                <button type="button" onClick={previousMonthYear}>
                    <Arrow />
                </button>
                <p id={`my-${monthYear.year}-${monthYear.month}`}>
                    {getMonthName(monthYear.month)} {monthYear.year}
                </p>
                <button type="button" onClick={nextMonthYear}>
                    <Arrow />
                </button>
            </div>

            <table className={styles.monthTable}>
                <thead>
                    <tr>
                        {days.map((dayName) => (
                            <th key={dayName}>{dayName}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {getMonthGrid(monthYear.year, monthYear.month).map((week, i) => {
                        return (
                            <tr className={styles.week} key={`week-${monthYear.year}-${monthYear.month}-${i}`}>
                                {week.map((dayNumber, i) => {
                                    return (
                                        <React.Fragment
                                            key={`day-${monthYear.year}-${monthYear.month}-${dayNumber}-${i}`}
                                        >
                                            <Day
                                                year={monthYear.year}
                                                month={monthYear.month}
                                                dayNumber={dayNumber}
                                                selectedStartDate={startDate}
                                                selectedEndDate={endDate}
                                                blockedUntil={
                                                    isRangeMode && startDate && minimumDays && minimumDays >= 2
                                                        ? daysAfterDate(startDate, minimumDays - 2)
                                                        : undefined
                                                }
                                                isRangePicked={isRangeMode && !!startDate && !!endDate}
                                                earliestAvailableDate={earliestAvailableDate}
                                                latestAvailableDate={latestAvailableDate}
                                                handleClick={onClick}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default AGDatePicker;
