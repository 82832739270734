import React from 'react';
import styles from './WidgetBox.module.css';

type EmptyWidgetMessageProps = {
    title: string;
    message: string;
};

function EmptyWidgetMessage({ title, message }: EmptyWidgetMessageProps): JSX.Element {
    return (
        <div className={styles.emptyWidgetMessage}>
            <div>
                <h4>{title}</h4>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default EmptyWidgetMessage;
