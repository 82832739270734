import React from 'react';
import styles from './VisitorPlate.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { getStateFromValue } from 'assets/data/countriesAndStates';
import { VisitorValidationSource } from 'utils/constants';

const VisitorPlate = (props: any) => {
    const {
        index,
        car,
        visitor_pk: visitorPk,
        calendar,
        validateVisitor,
        validateVisitorWithDate,
        minutes,
        spot,
    } = props;
    const [isShowingCalendar, toggleCalendar] = useState(false);
    const stateData = getStateFromValue(car.state, car.country);
    return (
        <div
            key={index}
            className={styles.plate}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                calendar
                    ? toggleCalendar(!isShowingCalendar)
                    : validateVisitor(visitorPk, minutes, spot, VisitorValidationSource.KIOSK);
            }}
        >
            <div className={styles.box}>
                <p>{stateData ? stateData.label : 'AirGarage'}</p>
                <h1>{car.plate}</h1>
            </div>
            {isShowingCalendar && (
                <div onClick={(event) => event.stopPropagation()}>
                    <DatePicker
                        dateFormat="MM/dd/yyyy"
                        readOnly
                        inline
                        onChange={(date: Date | null) => validateVisitorWithDate(visitorPk, date, spot)}
                    ></DatePicker>
                </div>
            )}
        </div>
    );
};
export default VisitorPlate;
