import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import { push } from 'connected-react-router';

type AddSpotProps = {
    urlPush: typeof push;
    organizationId: string | number;
};
function AddSpot({ urlPush, organizationId }: AddSpotProps): JSX.Element {
    return (
        <Grid.Row>
            <Grid.Column>
                <AuthorizedView allowed={Roles.Superuser}>
                    <Button
                        icon="plus"
                        content="Add Spot"
                        onClick={() => urlPush(`/organization/${organizationId}/list`)}
                    />
                </AuthorizedView>
            </Grid.Column>
        </Grid.Row>
    );
}

export default AddSpot;
