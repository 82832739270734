import React, { ReactNode } from 'react';
import styles from './IncomeStatement.module.css';
import { ReactComponent as ArrowUp } from 'assets/icons/arrowUpRightCircled.svg';
import { IncomeStatementReport } from 'types';
import { convertCentsToDisplayPrice } from 'utils/currency';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import { ENTERPRISE_TOOLTIP_TEXT } from './utils';

type UpliftBoxProps = {
    report: IncomeStatementReport;
};

type UpliftInfoBoxProps = {
    backgroundColor: string;
    price: number;
    children: ReactNode;
};

function UpliftInfoBox({ backgroundColor, price, children }: UpliftInfoBoxProps) {
    return (
        <div className={styles.upliftInfo}>
            <div className={styles.upliftInfoTitle}>
                <div style={{ backgroundColor }} /> {children}
            </div>
            <p>{convertCentsToDisplayPrice(price)}</p>
        </div>
    );
}

function UpliftBox({ report }: UpliftBoxProps) {
    const {
        enforcement_revenue: enforcement,
        marketing_revenue: marketing,
        dynamic_pricing_revenue: dynamicPricing,
        enterprise_revenue: enterprise,
    } = report.revenue_uplift;

    return (
        <div className={styles.revenueUpliftContainer}>
            <p className={styles.revenueTitle}>
                <ArrowUp /> Revenue Uplift
            </p>
            <p className={styles.revenueDetail}>
                These unique AirGarage capabilities are designed to generate you more net income.
            </p>
            <div className={styles.upliftInfoContainer}>
                <UpliftInfoBox backgroundColor="#D1581F" price={enforcement}>
                    Enforcement
                </UpliftInfoBox>
                {!!marketing && (
                    <UpliftInfoBox backgroundColor="#9EA2FF" price={marketing}>
                        Marketing
                    </UpliftInfoBox>
                )}
                {!!dynamicPricing && (
                    <UpliftInfoBox backgroundColor="#FA824C" price={dynamicPricing}>
                        Dynamic Pricing
                    </UpliftInfoBox>
                )}
                {!!enterprise && (
                    <UpliftInfoBox backgroundColor="#60CBEA" price={enterprise}>
                        Enterprise
                        <InfoTooltip text={ENTERPRISE_TOOLTIP_TEXT} />
                    </UpliftInfoBox>
                )}
            </div>
        </div>
    );
}

export default UpliftBox;
