import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { addTeamMemberAction } from 'actions/actions';
import useForm from 'hooks/useForm';
import { AddTeamMemberInput } from 'services/TeamService';
import { AffiliationRole } from 'utils/constants';
import { validateEmail } from 'utils/helpers';
import Button from 'components/Button/Button';
import Modal, { ModalRef } from 'components/Modal/Modal';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import Label from 'components/AGForm/Inputs/Label/Label';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const options = [
    {
        value: '',
        label: 'Select a role',
    },
    {
        value: AffiliationRole.ADMIN,
        label: AffiliationRole.ADMIN + ' (Full access to view, edit, and delete data)',
    },
    {
        value: AffiliationRole.EMPLOYEE,
        label: AffiliationRole.EMPLOYEE + ' (Can view/edit data, but no access to financial data)',
    },
    {
        value: AffiliationRole.ENFORCER,
        label: AffiliationRole.ENFORCER + ' (Can only access enforcements)',
    },
];

type FormState = {
    firstName: string;
    lastName: string;
    email: string;
    role: AffiliationRole | '';
};

const initialState: FormState = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
};

type InvitationFormProps = {
    loading: boolean;
    addTeamMember: (inputs: AddTeamMemberInput) => void;
};

const errorInitialState = { firstName: '', lastName: '', email: '', role: '' };

const InvitationForm = (props: InvitationFormProps) => {
    const { handleInput, handleValueChange, inputs } = useForm(initialState);
    const [errors, setErrors] = useState(errorInitialState);
    const addTeamMemberRef = useRef<ModalRef>();

    const isFormComplete = inputs.firstName && inputs.lastName && inputs.email && inputs.role;

    const handleSubmit = () => {
        const newErrors = { ...errorInitialState };
        const validatedEmail = validateEmail(inputs.email);

        if (!isFormComplete || !validatedEmail) {
            if (inputs.firstName === '') newErrors.firstName = 'Please enter a first name';
            if (inputs.lastName === '') newErrors.lastName = 'Please enter a last name';
            if (!validatedEmail || inputs.email === '') newErrors.email = 'Please enter a valid email';
            if (!inputs.role) newErrors.role = 'Please choose a role';
        } else {
            props.addTeamMember({
                first_name: inputs.firstName,
                last_name: inputs.lastName,
                email: inputs.email,
                role: inputs.role as AffiliationRole,
            });
            addTeamMemberRef.current?.closeModal();
        }
        setErrors(newErrors);
    };

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (errors.email && validateEmail(event.target.value)) {
            setErrors({ ...errors, email: '' });
        }
        handleInput(event);
    }

    return (
        <>
            <div>
                <Button size="sm" onClick={() => addTeamMemberRef.current?.openModal()} style={{ width: 180 }}>
                    + Add Team Member
                </Button>
            </div>
            <Modal ref={addTeamMemberRef} title="Add a Team Member">
                <AGForm>
                    <div>
                        <Label label="Role *" />
                        <AGSimpleSelect
                            selected={inputs.role}
                            onSelect={(selection) => handleValueChange('role', selection as AffiliationRole)}
                            options={options}
                            width="340px"
                        />
                        {errors.role && <ErrorMessage errorMessage={errors.role} />}
                    </div>
                    <TextInput
                        label="First Name *"
                        onChange={handleInput}
                        placeholder="Required"
                        errors={errors.firstName}
                        name="firstName"
                        value={inputs.firstName}
                    />
                    <TextInput
                        label="Last Name *"
                        onChange={handleInput}
                        placeholder="Required"
                        errors={errors.lastName}
                        name="lastName"
                        value={inputs.lastName}
                    />
                    <TextInput
                        label="Email *"
                        onChange={handleEmailChange}
                        placeholder="Required"
                        errors={errors.email}
                        name="email"
                        value={inputs.email}
                    />

                    <Button type="button" onClick={handleSubmit} disabled={!isFormComplete}>
                        Add Team Member
                    </Button>
                </AGForm>
            </Modal>
        </>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.team.errorMessage,
        loading: state.team.loading,
        profile: state.auth.profile,
    };
}

const mapDispatchToProps = {
    addTeamMember: addTeamMemberAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationForm);
