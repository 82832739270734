import React, { useState } from 'react';

import { ReactComponent as ColorIcon } from 'assets/icons/color.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { EditValetSessionPayload } from 'clients/ValetSessionClient';
import Button from 'components/Button/Button';
import CarIcon from 'components/Icons/CarIcon';
import AGForm from 'components/AGForm/AGForm';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import WarningBox from 'components/ValetDashboard/WarningBox/WarningBox';
import { CountryAbbreviation, StateAbbreviation } from 'constants/states';
import useForm from 'hooks/useForm';
import { ValetSession } from 'types/ValetSession';
import {
    getOptionFromValues,
    states,
    stringRepresentationToValues,
    valuesToStringRepresentation,
} from 'utils/statesSelect';

interface EditValetSessionFormProps {
    valetSessionOnEdit: ValetSession;
    onCloseEditModal: () => void;
    editValetSession: (uuid: string, payload: EditValetSessionPayload) => Promise<ValetSession>;
}

function EditValetSessionForm(props: EditValetSessionFormProps) {
    const { car_details: car, notes, make, model, color } = props.valetSessionOnEdit;
    const form = useForm({
        plate: car?.plate || '',
        state: car
            ? valuesToStringRepresentation(car.country as CountryAbbreviation, car.state as StateAbbreviation)
            : '',
        make: make || '',
        model: model || '',
        color: color || '',
        notes: notes || '',
    });
    const [loading, setLoading] = useState(false);
    const [unknownError, setUnknownError] = useState(false);

    async function handleEditForm() {
        setLoading(true);
        const values = stringRepresentationToValues(form.inputs.state);
        try {
            await props.editValetSession(props.valetSessionOnEdit.uuid, {
                ...form.inputs,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                state: values!.state,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                country: values!.country,
            });
            props.onCloseEditModal();
        } catch (err) {
            setUnknownError(true);
        }
        setLoading(false);
    }

    return (
        <AGForm>
            <TextInput
                label="License Plate"
                onChange={form.handleInput}
                errors={null}
                placeholder="Enter license plate number"
                icon={<CarIcon height={20} width={20} />}
                isUppercase={true}
                name="plate"
                value={form.inputs.plate}
            />

            <SearchSelectInput
                placeholder="Enter license state"
                label="License State"
                options={states}
                errors={null}
                icon={<CarIcon height={20} width={20} />}
                onSelected={(value) => {
                    form.handleValueChange('state', value);
                }}
                selected={
                    car
                        ? getOptionFromValues(car.country as CountryAbbreviation, car.state as StateAbbreviation)
                        : undefined
                }
                name="state"
            />

            <TextInput
                label="Car Make"
                onChange={form.handleInput}
                errors={null}
                placeholder="Enter car make"
                icon={<CarIcon height={20} width={20} />}
                name="make"
                value={form.inputs.make}
            />

            <TextInput
                label="Car Model"
                onChange={form.handleInput}
                errors={null}
                placeholder="Enter car model"
                icon={<CarIcon height={20} width={20} />}
                name="model"
                value={form.inputs.model}
            />

            <TextInput
                label="Car Color"
                onChange={form.handleInput}
                errors={null}
                placeholder="Enter car color"
                icon={<ColorIcon />}
                name="color"
                value={form.inputs.color}
            />

            <TextInput
                label="Notes"
                onChange={form.handleInput}
                errors={null}
                placeholder="Add notes here"
                icon={<EditIcon />}
                name="notes"
                value={form.inputs.notes}
            />

            {unknownError && (
                <WarningBox type="error" title="Something went wrong!">
                    Please retry later
                </WarningBox>
            )}

            <Button
                type="button"
                onClick={handleEditForm}
                loading={loading}
                disabled={loading || form.inputs.plate.length === 0 || form.inputs.state.length === 0}
            >
                Save
                <CheckIcon />
            </Button>
        </AGForm>
    );
}

export default EditValetSessionForm;
