import { InvoiceStatus } from 'utils/constants';

export function getDate(timestamp: string) {
    const timestampNum = Number(timestamp);
    const date = new Date(timestampNum * 1000);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const dateString = `${month}/${day}/${year}`;
    return dateString;
}

interface StatusColorsMap {
    [key: string]: string;
}

export const STATUS_COLORS: StatusColorsMap = {
    [InvoiceStatus.OPEN]: 'gray',
    [InvoiceStatus.PAST_DUE]: 'red',
    [InvoiceStatus.PAID]: 'green',
    [InvoiceStatus.DRAFT]: 'gray',
    [InvoiceStatus.VOID]: 'gray',
    [InvoiceStatus.UNCOLLECTIBLE]: 'red',
    [InvoiceStatus.NA]: 'white',
};

export function getStatusColor(status: InvoiceStatus): string {
    return STATUS_COLORS[status] ?? 'gray';
}

export function parseInvoicesStatus(status: string): InvoiceStatus {
    switch (status.toLocaleLowerCase()) {
        case 'open':
            return InvoiceStatus.OPEN;
        case 'paid':
            return InvoiceStatus.PAID;
        case 'draft':
            return InvoiceStatus.DRAFT;
        case 'void':
            return InvoiceStatus.VOID;
        case 'uncollectible':
            return InvoiceStatus.UNCOLLECTIBLE;
        case 'past_due':
            return InvoiceStatus.PAST_DUE;
        default:
            return InvoiceStatus.NA;
    }
}

export function capitalizeStatus(status: InvoiceStatus): string {
    const words = status.split(' ');
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
}
