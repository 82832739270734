import { BreakdownFrequencyReportInterface, DriverFrequencyReportInterface } from 'types';
import {
    DRIVERS_FREQUENCY_REPORTS_SUCCESS,
    DRIVERS_FREQUENCY_REPORTS_FAILED,
    DRIVERS_FREQUENCY_REPORTS_REQUESTED,
} from './constants';
import { Action } from '../useReducerWithMiddleware';

export interface CompleteFrequencyReport {
    overallReport: DriverFrequencyReportInterface;
    breakdownReport: BreakdownFrequencyReportInterface;
    lastUpdatedAt: string;
}

export type DriversFrequencyState = {
    loading: boolean;
    errorMessage: string;
    reports: Record<string, CompleteFrequencyReport>;
};

export const initialDriversFrequencyState: DriversFrequencyState = {
    loading: false,
    errorMessage: '',
    reports: {},
};

export default function driverFrequencyReportReducer(
    state: DriversFrequencyState = initialDriversFrequencyState,
    action: Action
): DriversFrequencyState {
    switch (action.type) {
        case DRIVERS_FREQUENCY_REPORTS_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case DRIVERS_FREQUENCY_REPORTS_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const { timeKey, overallReport, breakdownReport, lastUpdatedAt } = action.payload as {
                timeKey: string;
            } & CompleteFrequencyReport;
            return {
                ...state,
                loading: false,
                errorMessage: '',
                reports: {
                    ...state.reports,
                    [timeKey]: {
                        overallReport,
                        breakdownReport,
                        lastUpdatedAt,
                    },
                },
            };
        case DRIVERS_FREQUENCY_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialDriversFrequencyState;
    }
}
