import React from 'react';
import styles from './CheckPlateCard.module.css';
import { Card, Icon, Divider, Button, Header } from 'semantic-ui-react';

const CheckPlateCard = (props: any) => {
    const { enforcement, addPenalty, index } = props;
    const newestScan = index > 0;
    const invalid = enforcement.status === 'Not found';
    const recentTicket = enforcement.status === 'Ticket issued recently';

    return (
        <Card className={newestScan ? styles.transparent : styles.opaque}>
            <Card.Content>
                <Card.Header>{enforcement.plate}</Card.Header>
                <Divider color={invalid ? 'red' : 'green'} />
                <Card.Content>
                    {recentTicket ? (
                        <p className={styles.subtext}>{enforcement.public_description}</p>
                    ) : (
                        <div className={styles.status}>
                            {invalid ? (
                                <Header as="h3" color="red">
                                    <Icon size="big" name="close" color="red"></Icon>Not Valid
                                </Header>
                            ) : (
                                <Header as="h3" color="green">
                                    <Icon size="big" name="check" color="green"></Icon>Valid
                                </Header>
                            )}
                        </div>
                    )}
                </Card.Content>

                {invalid && !enforcement.penalty && !recentTicket && (
                    <Card.Content>
                        <Divider hidden />
                        <Button
                            color="blue"
                            icon="edit"
                            content="Issue ticket"
                            fluid
                            onClick={() => {
                                addPenalty({ parent_enforcement: enforcement.pk, penalty_type: 'ticket issued' });
                            }}
                        />
                    </Card.Content>
                )}
                {enforcement.penalty && (
                    <>
                        <Divider hidden />
                        <p className={styles.subtext}>
                            Ticket has been issued by {enforcement.enforcer_name} at{' '}
                            {enforcement.penalty.created_at_formatted}.
                        </p>
                    </>
                )}
            </Card.Content>
        </Card>
    );
};

export default CheckPlateCard;
