import { ReportInterface } from 'types';

export const REVENUE_COLORS = {
    gross: '#FFEECC',
    baseline: '#E4E6EE',
    enforcement: '#D1581F',
    dynamicPricing: '#EFAB8A',
    marketing: '#9EA2FF',
};

export const REVENUE_TYPE_LABELS = {
    baseline: 'Baseline',
    enforcement: 'Enforcement',
    dynamicPricing: 'Dynamic Pricing',
    marketing: 'Marketing',
};

type Monthly = ReportInterface['monthly'][0];
type UpliftElements = Pick<
    Monthly,
    'gross_revenue' | 'marketing_revenue' | 'dynamic_pricing_revenue' | 'enforcement_revenue'
>;

/**
 * Returns percentage of uplift of enforcement, marketing and dynamic pricing. Percentage is already multiplied by 100 so just put a '%' next to it.
 * @param gross_revenue
 * @param enforcement_revenue
 * @param marketing_revenue
 * @param dynamic_pricing_revenue
 */
export function calculateUplift({
    gross_revenue,
    enforcement_revenue,
    marketing_revenue,
    dynamic_pricing_revenue,
}: UpliftElements): {
    enforcement_revenue: string | null;
    marketing_revenue: string | null;
    dynamic_pricing_revenue: string | null;
} {
    const enforcement = enforcement_revenue || 0;
    const marketing = marketing_revenue || 0;
    const dynamicPricing = dynamic_pricing_revenue || 0;
    const baseline = gross_revenue - enforcement - marketing - dynamicPricing;

    const maybeOneDecimalUplift = (n: number): string | null => {
        return n === 0 ? null : ((n / baseline) * 100).toFixed(1);
    };
    return {
        enforcement_revenue: maybeOneDecimalUplift(enforcement),
        marketing_revenue: maybeOneDecimalUplift(marketing),
        dynamic_pricing_revenue: maybeOneDecimalUplift(dynamicPricing),
    };
}
