import { TenantsParkingData } from 'types';
import HttpClient, { HTTPClientParameters } from './HTTPClient';
import cache from './cache';

// Tenants Parking equals bulk rental from BE
type TenantsParkingClientParameters = HTTPClientParameters;

class TenantsParkingClient extends HttpClient {
    constructor(params: TenantsParkingClientParameters) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    @cache(2 * 60000) // 2min of cache
    getTenantsParking(spotUuid?: string): Promise<TenantsParkingData[]> {
        let bulkRentalRequest = `bulkrentals/`;
        if (spotUuid) bulkRentalRequest += `?spot=${spotUuid}`;
        return this.get(bulkRentalRequest);
    }
}

export default TenantsParkingClient;
