import { INCOME_STATEMENT_REQUESTED, INCOME_STATEMENT_SUCCESS, INCOME_STATEMENT_FAILED } from './constants';
import { Action } from '../useReducerWithMiddleware';
import { IncomeStatementResponse } from 'types';

export type IncomeStatementState = {
    loading: boolean;
    errorMessage: string;
    incomeStatements: Record<string, IncomeStatementResponse>;
};

export const initialIncomeStatementState: IncomeStatementState = {
    loading: false,
    errorMessage: '',
    incomeStatements: {},
};

export default function incomeStatementReducer(
    state: IncomeStatementState = initialIncomeStatementState,
    action: Action
): IncomeStatementState {
    switch (action.type) {
        case INCOME_STATEMENT_REQUESTED:
            return {
                ...state,
                loading: true,
                errorMessage: '',
            };
        case INCOME_STATEMENT_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const { dateRangeKey, report } = action.payload as {
                dateRangeKey: string;
                report: IncomeStatementResponse;
            };

            return {
                ...state,
                loading: false,
                errorMessage: '',
                incomeStatements: {
                    ...state.incomeStatements,
                    [dateRangeKey]: report,
                },
            };
        case INCOME_STATEMENT_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialIncomeStatementState;
    }
}
