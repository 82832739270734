import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './Modal.module.css';
import useKeyDownPress from 'hooks/useKeyPress';

interface ModalProps {
    children?: React.ReactNode;
    title?: string;
    icon?: React.ReactNode;
    onClose?: () => void;
    type?: 'standard' | 'fullSizeImage';
}

export interface ModalRef {
    openModal: () => void;
    closeModal: () => void;
}

const Modal = forwardRef<ModalRef | undefined, ModalProps>(({ type = 'standard', ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    function openModal() {
        document.body.style.overflow = 'hidden';
        setIsOpen(true);
    }

    function closeModal() {
        document.body.removeAttribute('style');
        if (props.onClose) props.onClose();
        setIsOpen(false);
    }

    function handleOverlayClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
    }));

    useKeyDownPress('Escape', closeModal);

    return (
        <div>
            {isOpen && (
                <div className={styles.overlay} onClick={handleOverlayClick}>
                    <div className={`${styles.wrapper} ${styles[type]}`}>
                        <div className={styles.header}>
                            <div className={styles.headerRightSide}>
                                {props.icon && <div className={styles.iconWrapper}>{props.icon}</div>}
                                <h1 className={styles.title}>{props.title}</h1>
                            </div>
                            <button className={styles.closeButton} onClick={closeModal}>
                                <CloseIcon className={styles.closeIcon} />
                            </button>
                        </div>
                        <div className={styles.content}>{props.children}</div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default Modal;
