import React, { useEffect, useState } from 'react';
import useForm from '../../../hooks/useForm';
import { VisitorCodeForm } from 'contexts/VisitorCodesContext';
import { VisitorCode } from 'types/VisitorCode';
import { Event } from 'types/Event';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import Label from 'components/AGForm/Inputs/Label/Label';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { ValidityType, validateVisitorCodeForm, validityOptions } from './helpers';
import ValidityInputs from './ValidityInputs';

type VisitorCodeModalFormProps = {
    visitorCode: VisitorCode | null;
    events: Event[];
    onFinish: () => void;
    addCode?: (inputs: VisitorCodeForm, selectedSpot: number) => Promise<void>;
    editCode?: (inputs: VisitorCodeForm, selectedSpot: number) => Promise<void>;
    deleteCode?: (pk: number, selectedSpot: number) => Promise<void>;
    selectedSpot: number;
    loading: boolean;
};

function VisitorCodeModalForm(props: VisitorCodeModalFormProps) {
    const { visitorCode: code, deleteCode, onFinish, addCode, editCode, selectedSpot, loading } = props;

    const [validityType, setValidityType] = useState<ValidityType>(validityOptions[0].value as ValidityType);
    const [formError, setFormError] = useState('');

    const initialInputState = {
        name: code ? code.name : '',
        code: code ? code.code : '',
        always_valid: code ? code.always_valid : false,
        days_valid: code ? code.days_valid : null,
        hours_valid: code ? code.hours_valid : null,
        expiration_date: code ? code.expiration_date : null,
        events: code ? code.events.map((e) => String(e)) : [],
        pk: code && code.pk ? code.pk : 0,
    };

    const { handleInput, inputs, handleValueChange } = useForm(initialInputState);

    useEffect(() => {
        if (code) {
            if (code.events.length > 0) {
                setValidityType(ValidityType.EVENTS);
            } else if (code.hours_valid || code.days_valid) {
                setValidityType(ValidityType.TEMPORARY);
            } else if (code.always_valid) {
                setValidityType(ValidityType.PERMANENT);
            } else if (code.expiration_date) {
                setValidityType(ValidityType.EXPIRATION);
            }
        }
    }, [code, setValidityType]);

    useEffect(() => {
        if (formError) {
            const newValidation = validateVisitorCodeForm(inputs, validityType);
            if (newValidation !== formError) setFormError(newValidation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs, validityType]);

    function handleValidityTypeChange(newValidityType: string) {
        setValidityType(newValidityType as ValidityType);
        handleValueChange('always_valid', newValidityType === 'permanent');
        if ('expiration' !== newValidityType) {
            handleValueChange('expiration_date', null);
        }
        if ('temporary' !== newValidityType) {
            handleValueChange('days_valid', null);
            handleValueChange('hours_valid', null);
        }
        if ('events' !== newValidityType) {
            handleValueChange('events', []);
        }
    }

    async function submitVisitorCode() {
        const errorOrValidated = validateVisitorCodeForm(inputs, validityType);
        if (errorOrValidated) setFormError(errorOrValidated);
        else {
            if (validityType === ValidityType.TEMPORARY) {
                inputs.days_valid = inputs.days_valid || 0;
                inputs.hours_valid = inputs.hours_valid || 0;
            }
            if (code && editCode) await editCode(inputs, selectedSpot);
            else if (addCode) await addCode(inputs, selectedSpot);

            onFinish();
        }
    }

    function handleDateChange(date: Date | undefined) {
        handleValueChange('expiration_date', date?.toISOString() || '');
    }

    function handleEventChange(eventsPk: string[]) {
        handleValueChange('events', eventsPk);
    }

    return (
        <div style={{ width: '360px' }}>
            <AGForm>
                <TextInput
                    name="code"
                    label="Code *"
                    value={inputs.code}
                    onChange={handleInput}
                    type="text"
                    placeholder="EXAMPLE123"
                    errors={null}
                />
                <TextInput
                    name="name"
                    label="Description (for your reference only)"
                    value={inputs.name}
                    onChange={handleInput}
                    type="text"
                    placeholder="AirGarage employees"
                    errors={null}
                />

                <div>
                    <Label label="Validity" />
                    <AGSimpleSelect
                        options={validityOptions}
                        onSelect={handleValidityTypeChange}
                        selected={validityType}
                        width="100%"
                    />
                </div>

                <ValidityInputs
                    validityType={validityType}
                    events={props.events}
                    inputs={inputs}
                    handleInput={handleInput}
                    handleEventChange={handleEventChange}
                    handleDateChange={handleDateChange}
                />

                {formError && <ErrorMessage errorMessage={formError} />}
                <Button
                    onClick={submitVisitorCode}
                    disabled={!inputs.code || Boolean(formError)}
                    loading={loading}
                    type="button"
                >
                    {code ? 'Edit Validation Code' : 'Add Validation Code'}
                </Button>

                {code && code.pk && deleteCode && (
                    <Button
                        color="error-outline"
                        onClick={() => {
                            deleteCode(code.pk, selectedSpot);
                            onFinish();
                        }}
                        type="button"
                    >
                        Delete
                    </Button>
                )}
            </AGForm>
        </div>
    );
}

export default VisitorCodeModalForm;
