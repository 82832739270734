import { Option } from 'types/SelectInputs';

export enum ValetSessionStatusFilter {
    Active = 'active',
    Ended = 'ended',
    Pending = 'pending',
}

export const valetSessionFilterOptions: Option[] = [
    {
        label: 'All',
        value: '',
    },
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Ended',
        value: 'ended',
    },
    {
        label: 'Pending',
        value: 'pending',
    },
];

export function getValetSessionStatusOptionFromValue(value: string) {
    const statusOption = valetSessionFilterOptions.find((v) => v.value === value);
    return statusOption ? statusOption : valetSessionFilterOptions[0];
}
