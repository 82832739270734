import { TimelineEntry } from 'types';
import {
    TIMELINE_SUCCESS,
    TIMELINE_FAILED,
    TIMELINE_REQUESTED,
    FILTER_TIMELINE_REQUESTED,
    FILTER_TIMELINE_SUCCESS,
    FILTER_TIMELINE_FAILED,
} from './constants';
import { Action } from '../useReducerWithMiddleware';
import { TimelineResponse } from '../../clients/TimelineClient';

export type LotTimelineState = {
    loading: boolean;
    errorMessage: string;
    timeline: TimelineEntry[];
    hasNext: boolean;
};

export const initialLotTimelineState: LotTimelineState = {
    hasNext: true,
    loading: false,
    timeline: [],
    errorMessage: '',
};

// mergeTimelineEvents merges two lists of timeline entries with events in descending order (w.r.t. timestamp).
// It removes duplicates in case two lists share some entries.
function mergeTimelineEvents(x: TimelineEntry[], y: TimelineEntry[]) {
    const events = Array.from(x);
    if (y.length === 0) {
        return events;
    }
    const nextEvent = y[0];
    for (let i = x.length - 1; i >= 0; i--) {
        const lastEvent = x[i];
        // nextEvent should have an older timestamp compared to the last item in the x list
        // otherwise, we have identified a duplicate, so we just skip
        if (new Date(lastEvent.timestamp) > new Date(nextEvent.timestamp)) {
            const withoutDuplicates = events.slice(0, i + 1);
            withoutDuplicates.push(...y);
            return withoutDuplicates;
        }
    }
    // we shouldn't get here.
    events.push(...y);
    return events;
}

export default function lotTimelineReducer(
    state: LotTimelineState = initialLotTimelineState,
    action: Action
): LotTimelineState {
    switch (action.type) {
        case TIMELINE_REQUESTED:
            return {
                ...state,
                loading: true,
                timeline: (action.payload as { offset?: string }).offset ? state.timeline : [],
            };
        case FILTER_TIMELINE_REQUESTED:
            return {
                ...state,
                loading: true,
                timeline: [],
            };
        case TIMELINE_SUCCESS:
        case FILTER_TIMELINE_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                hasNext: (action.payload as TimelineResponse).next,
                timeline: mergeTimelineEvents(state.timeline, (action.payload as TimelineResponse).events),
            };
        case TIMELINE_FAILED:
        case FILTER_TIMELINE_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialLotTimelineState;
    }
}
