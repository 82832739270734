import React from 'react';
import styles from './Pagination.module.css';

type PaginationProps = {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: string) => unknown;
};

function Pagination({ totalPages, currentPage, onPageChange }: PaginationProps): JSX.Element {
    const isFirstP = currentPage === 1;
    const isSecondP = currentPage === 2;
    const isLastP = currentPage === totalPages;
    const isSecondToLastP = currentPage === totalPages - 1;

    const firstNumber = isFirstP || isSecondP || totalPages <= 3 ? null : isLastP ? totalPages - 2 : currentPage - 1;
    const secondNumber = totalPages <= 2 ? null : isFirstP ? 2 : isLastP ? totalPages - 1 : currentPage;
    const thirdNumber = isLastP || isSecondToLastP || totalPages <= 3 ? null : isFirstP ? 3 : currentPage + 1;

    function handlePageChange(page: number) {
        onPageChange(String(page));
    }

    function onPreviousClicked() {
        onPageChange(String(currentPage - 1));
    }

    function onNextClicked() {
        onPageChange(String(currentPage + 1));
    }

    const pageButton = (page: number) => {
        const isCurrentPage = page === currentPage;
        return (
            <button
                onClick={() => handlePageChange(page)}
                disabled={isCurrentPage}
                className={isCurrentPage ? styles.selected : ''}
            >
                <span>{page}</span>
            </button>
        );
    };

    if (totalPages <= 1) return <></>;

    return (
        <div className={styles.paginationContainer}>
            <button onClick={onPreviousClicked} disabled={isFirstP} className={styles.notNumberButton}>
                <span>Prev</span>
            </button>
            {pageButton(1)}

            {currentPage > 3 && <span className={styles.dots}>...</span>}
            {firstNumber && pageButton(firstNumber)}
            {secondNumber && pageButton(secondNumber)}
            {thirdNumber && pageButton(thirdNumber)}
            {currentPage < totalPages - 2 && <span className={styles.dots}>...</span>}

            {pageButton(totalPages)}

            <button onClick={onNextClicked} disabled={isLastP} className={styles.notNumberButton}>
                <span>Next</span>
            </button>
        </div>
    );
}

export default Pagination;
