import React, { useState } from 'react';
import { Table, Image, Dropdown, Button, Header, Card } from 'semantic-ui-react';
import useModal from '../../../hooks/useModal';
import PlateImage from '../PlateImage/PlateImage';
import styles from '../SpotCard/SpotCard.module.css';

const EnforcementsModule = (props: any) => {
    const { enforcements, handleDropdown, fields, updateEnforcement, superuser } = props;
    const { isShowing, toggle } = useModal();
    const [id, setEnforcementId] = useState(0);
    return (
        <>
            {superuser ? (
                <Dropdown
                    name="status"
                    placeholder="Select status"
                    selection
                    onChange={handleDropdown}
                    options={[
                        { value: 'Not found', text: 'Not found' },
                        { value: 'Valid rental', text: 'Valid rental' },
                        { value: 'Camera scans', text: 'Camera scans' },
                        { value: 'Enforcer scans', text: 'Enforcer scans' },
                        { value: 'Notes left', text: 'Notes left' },
                        { value: 'Auto started', text: 'Auto started' },
                    ]}
                    value={fields.status}
                />
            ) : null}
            <Table basic="very" selectable striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Time in</Table.HeaderCell>
                        <Table.HeaderCell>Image</Table.HeaderCell>
                        <Table.HeaderCell>Plate</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Past Violations</Table.HeaderCell>
                        {fields.status === 'Not found' && <Table.HeaderCell>Actions</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
                {enforcements?.length > 0 && (
                    <PlateImage
                        toggle={toggle}
                        isShowing={isShowing}
                        enforcement={enforcements[id]}
                        updateEnforcement={updateEnforcement}
                    />
                )}
                {enforcements?.length > 0 ? (
                    enforcements.map((enforcement: any, index: any) => {
                        const confidence_text = enforcement.confidence_percent
                            ? ` ${enforcement.confidence_percent * 100}%`
                            : '';
                        const confidence_color = enforcement.confidence_percent > 0.85 ? 'green' : 'red';
                        return (
                            <Table.Body key={enforcement.pk}>
                                <Table.Row>
                                    <Table.Cell>{enforcement.created_at_formatted}</Table.Cell>
                                    <Table.Cell>
                                        <Card
                                            onClick={() => {
                                                toggle();
                                                setEnforcementId(index);
                                            }}
                                        >
                                            <Image
                                                size="tiny"
                                                className={styles.plateImage}
                                                src={
                                                    enforcement.action === 'note'
                                                        ? enforcement.image_url
                                                        : enforcement.plate_url
                                                }
                                                rounded
                                            />
                                        </Card>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {enforcement.plate}
                                        <br />
                                        <span style={{ color: confidence_color }}>{confidence_text}</span>
                                    </Table.Cell>
                                    <Table.Cell>{enforcement.status}</Table.Cell>
                                    <Table.Cell>{enforcement.num_violation_days}</Table.Cell>
                                    <Table.Cell>
                                        {enforcement.status === 'Not found' &&
                                            enforcement.action !== 'Ticket issued' && (
                                                <Button
                                                    color="blue"
                                                    content="Issue ticket"
                                                    onClick={() =>
                                                        updateEnforcement(enforcement.pk, { action: 'Ticket issued' })
                                                    }
                                                />
                                            )}
                                        {enforcement.action === 'Ticket issued' && (
                                            <>Ticket issued by {enforcement.enforcer_username} </>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        );
                    })
                ) : (
                    <Header content="No cars meet the selected filter criteria." as="h4" />
                )}
            </Table>
        </>
    );
};

export default EnforcementsModule;
