export const EVENTS_REQUESTED = 'EVENTS_REQUESTED';
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_FAILED = 'EVENTS_FAILED';

export const ADD_EVENT_REQUESTED = 'ADD_EVENT_REQUESTED';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILED = 'ADD_EVENT_FAILED';

export const DELETE_EVENT_REQUESTED = 'DELETE_EVENT_REQUESTED';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED';

export const EDIT_EVENT_REQUESTED = 'EDIT_EVENT_REQUESTED';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILED = 'EDIT_EVENT_FAILED';
