import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { replace } from 'connected-react-router';
import { getOrganizationAction } from 'actions/actions';
import AdvertisingModuleContainer from 'containers/AdvertisingModuleContainer';
import CreateSpotModuleContainer from 'containers/CreateSpotModuleContainer';
import DashboardContent from 'components/Dashboard/DashboardContent/DashboardContent';
import PayoutsModuleContainer from 'containers/PayoutsModuleContainer';
import Sidebar from 'containers/Sidebar';
import SpotsRoutes from 'containers/SpotsRoutes';
import TeamModuleContainer from 'containers/TeamModuleContainer';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId } from 'selectors';
import IncomeStatementContainer from 'containers/IncomeStatementContainer';

type OrganizationViewProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const OrganizationView = ({
    organization,
    getOrganization,
    selectedOrganizationId: orgId,
    replace: connectedReplace,
}: OrganizationViewProps): JSX.Element => {
    const { isExact } = useRouteMatch(`/organization/${orgId}`) || { isExact: false };
    useEffect(() => {
        if (isExact) {
            // want to redirect to spots
            connectedReplace(`/organization/${orgId}/spots`);
        }
    }, [isExact, connectedReplace, orgId]);
    useEffect(() => {
        getOrganization(orgId);
    }, [orgId, getOrganization]);

    return (
        <Sidebar>
            {organization === null ? (
                <Loader inline active />
            ) : (
                <Switch>
                    <Route path={`/organization/${orgId}/team`}>
                        <DashboardContent title={'Team'}>
                            <TeamModuleContainer />
                        </DashboardContent>
                    </Route>
                    <Route path={`/organization/${orgId}/list`}>
                        <DashboardContent title={'List a spot'}>
                            <CreateSpotModuleContainer />
                        </DashboardContent>
                    </Route>
                    <Route path={`/organization/${orgId}/payouts`}>
                        <DashboardContent title={'Payouts'}>
                            <PayoutsModuleContainer />
                        </DashboardContent>
                    </Route>
                    <Route path={`/organization/${orgId}/income-statement`}>
                        <DashboardContent title={'Income Statement'}>
                            <IncomeStatementContainer />
                        </DashboardContent>
                    </Route>
                    <Route path={`/organization/${orgId}/advertising`}>
                        <DashboardContent title={'Advertising'}>
                            <AdvertisingModuleContainer />
                        </DashboardContent>
                    </Route>
                    <SpotsRoutes />
                </Switch>
            )}
        </Sidebar>
    );
};

function mapStateToProps(state: AppState) {
    return {
        organization: state.organization.organization,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedOrganizationId: selectedOrganizationId(state)!,
    };
}

const mapDispatchToProps = {
    getOrganization: getOrganizationAction,
    replace,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationView);
