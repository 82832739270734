import { ValetSession } from 'types/ValetSession';
import { RentalType } from 'utils/constants';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';

interface ValetSessionResponse {
    results: ValetSession[];
    count: number;
}

export interface CheckInPayload {
    plate: string;
    state: string;
    country?: string;
    make?: string;
    model?: string;
    color?: string;
    notes?: string;
    rental_type: RentalType;
}

export interface ResendValetSessionCodePayload {
    phone: string;
    phone_country_code: string;
}
export interface EditValetSessionPayload {
    plate?: string;
    state?: string;
    country?: string;
    make?: string;
    model?: string;
    color?: string;
    notes?: string;
}

export interface GetValetSessionsParams {
    search?: string;
    status?: string;
    page?: string;
}

type ValetSessionClientParams = HTTPClientParameters;

class ValetSessionClient extends HTTPClient {
    constructor(params: ValetSessionClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    getValetSessions({ search, status, page }: GetValetSessionsParams = {}) {
        const params = new URLSearchParams();
        if (search) params.append('search', search);
        if (status) params.append('status', status);
        if (page) params.append('page', page);
        return this.get<ValetSessionResponse>(`valet/valet-sessions/?${params.toString()}`);
    }

    getValetSessionWithCode(code: string) {
        return this.get<ValetSession>(`valet/valet-sessions/code/${code}/`);
    }

    checkInValetSession(uuid: string, payload: CheckInPayload) {
        return this.put<CheckInPayload, ValetSession>(`valet/valet-sessions/${uuid}/check_in/`, payload);
    }

    checkOutValetSession(uuid: string) {
        return this.put<Record<string, never>, ValetSession>(`valet/valet-sessions/${uuid}/check_out/`, {});
    }

    payValetSession(uuid: string) {
        return this.post<Record<string, never>, ValetSession>(`valet/valet-sessions/${uuid}/pay/`, {});
    }

    resendValetSessionCode(payload: ResendValetSessionCodePayload) {
        return this.post<ResendValetSessionCodePayload, Response>('valet/valet-sessions/resend_code/', payload);
    }

    editValetSession(uuid: string, payload: EditValetSessionPayload) {
        return this.patch<EditValetSessionPayload, ValetSession>(`valet/valet-sessions/${uuid}/`, payload);
    }
}

export default ValetSessionClient;
