import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getProfileAction } from '../../actions/actions';

class PrivateRoute extends React.Component<any, any> {
    componentDidMount() {
        this.props.getProfile();
    }
    render() {
        const { children, ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={(props) => {
                    return this.props.profile ? children : <Loader active />;
                }}
            />
        );
    }
}

function mapStateToProps(state: any) {
    return {
        profile: state.auth.profile,
    };
}

const mapDispatchToProps = {
    getProfile: getProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
