import React, { useEffect, useState } from 'react';
import SidebarDesktop from 'components/Sidebar/SidebarDesktop';
import SidebarMobile from 'components/Sidebar/SidebarMobile';
import { connect } from 'react-redux';
import { logoutAction } from 'actions/actions';
import { push } from 'connected-react-router';
import amplitude from 'amplitude-js';
import { AppState } from '../reducers/reducers';
import { selectedSpotInfo } from '../selectors';

type OwnProps = { children: React.ReactNode | React.ReactNode[] };
type SpotsViewProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

const Sidebar = (props: SpotsViewProps) => {
    const { spots, children, superuser, organization, orgSpotsLoading, selectedSpotInfo } = props;
    const [visible, setVisible] = useState(false);
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const handlePusher = () => {
        if (visible) {
            setVisible(false);
        }
    };

    const handleToggle = () => {
        setVisible(!visible);
    };

    const navigateToTab = (tabUrl: string) => {
        props.push(tabUrl);
        handleToggle();
    };

    const logout = () => {
        amplitude.getInstance().logEvent('Clicked log out');
        props.logout();
        props.push('/login');
    };

    let spotList = null;
    if (!orgSpotsLoading && spots && Object.keys(spots).length > 0) {
        spotList = Object.values(spots);
    }

    return (
        <div>
            {width > 0 && width <= 767 ? (
                <SidebarMobile
                    onToggle={handleToggle}
                    onPusherClick={handlePusher}
                    visible={visible}
                    spots={spotList}
                    organization={organization}
                    selectedSpotInfo={selectedSpotInfo}
                    navigateToTab={navigateToTab}
                    logout={logout}
                    logo={organization?.logo_url ? organization.logo_url : require('assets/share.png')}
                    superuser={superuser}
                >
                    {children}
                </SidebarMobile>
            ) : (
                <SidebarDesktop
                    spots={spotList}
                    organization={organization}
                    selectedSpotInfo={selectedSpotInfo}
                    navigateToTab={navigateToTab}
                    logout={logout}
                    superuser={superuser}
                >
                    {children}
                </SidebarDesktop>
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        spots: state.spots.orgSpots,
        orgSpotsLoading: state.spots.orgSpotsLoading,
        organization: state.organization.organization,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        superuser: state.auth.profile!.superuser,
        selectedSpotInfo: selectedSpotInfo(state),
    };
}

const mapDispatchToProps = {
    logout: logoutAction,
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
