import env from 'env';
class APIClient extends Object {
    getHeaders(token: string, file = false, filename = 'picture') {
        const headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        if (token) {
            headers['Authorization'] = 'Token ' + token;
        }
        if (file) {
            headers['Content-Disposition'] = `attachment; filename=${filename}`;
        }
        return headers;
    }

    async get(endpoint: string, token: string) {
        const response = await fetch(env.API_BASE + endpoint, {
            method: 'GET',
            headers: this.getHeaders(token),
            credentials: 'include',
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async postReq(endpoint: string, body: any, token: string): Promise<Response> {
        const response = await fetch(env.API_BASE + endpoint, {
            method: 'POST',
            headers: this.getHeaders(token),
            body: JSON.stringify(body),
            credentials: 'include',
        });
        return response;
    }

    async post(endpoint: string, params: object, token = '') {
        const body = JSON.stringify(params);
        const response = await fetch(env.API_BASE + endpoint, {
            method: 'POST',
            headers: this.getHeaders(token),
            body,
            credentials: 'include',
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async postFile(endpoint: string, file: any, filename = 'picture', token = '') {
        try {
            const response = await fetch(env.API_BASE + endpoint, {
                method: 'POST',
                headers: this.getHeaders(token, true, filename),
                body: file,
                credentials: 'include',
            });
            return response.json();
        } catch (error) {
            return { error: error.message };
        }
    }

    async patch(endpoint: string, params: object, token = '') {
        const body = JSON.stringify(params);
        const response = await fetch(env.API_BASE + endpoint, {
            method: 'PATCH',
            headers: this.getHeaders(token),
            body,
            credentials: 'include',
        });
        const result = await response.json();
        if (!response.ok) {
            const text = JSON.stringify(result).replace(/["{}[\],\\]/g, ' ');
            throw new Error(text);
        }
        return result;
    }

    async delete(endpoint: string, token: string) {
        const response = await fetch(env.API_BASE + endpoint, {
            method: 'DELETE',
            headers: this.getHeaders(token),
            credentials: 'include',
        });
        if (response.status === 204) {
            return { result: 'success' };
        }
        return { result: 'failed' };
    }
}
export default new APIClient();
