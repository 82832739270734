import React from 'react';

interface LabelProps {
    label: string;
    icon?: React.ReactNode;
    required?: boolean;
}

function Label({ label, icon, required = false }: LabelProps): JSX.Element {
    return (
        <div className="label-wrapper">
            {icon && <div className="icon-wrapper">{icon}</div>}
            <label className="input-label">{label}</label>
            {required ? <span className="required-symbol">*</span> : null}
        </div>
    );
}

export default Label;
