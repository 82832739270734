import { EventInterval, Event } from 'types/Event';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';
// import cache from './cache';

type EventClientParams = HTTPClientParameters;

export type AddOrEditEventPayload = {
    name: string;
    date?: string;
    codes: number[];
    spot: number;
    quantity?: number;
    price?: number;
    max_price?: number;
    start_time?: string;
    end_time?: string;
    interval?: EventInterval;
    repeat_on_day?: number;
    repeat_on_week?: number;
    hidden?: boolean;
    is_lot_closed?: boolean;
};

type GetEventsPayload = {
    spot: number;
    start: number;
    end: number;
};

class EventClient extends HTTPClient {
    constructor(params: EventClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    _getEvents({ spot, start, end }: GetEventsPayload): Promise<Event[]> {
        return this.get(`events/?spot=${spot}&start=${start}&end=${end}`);
    }

    // @cache(5 * 60000) TODO: add a way to clear all cache from this request before setting this up again
    getEvents(payload: GetEventsPayload): Promise<Event[]> {
        return this._getEvents(payload);
    }
    // @cache(0)
    force__getEvents(payload: GetEventsPayload): Promise<Event[]> {
        return this._getEvents(payload);
    }

    addEvent(data: AddOrEditEventPayload): Promise<Event> {
        return this.post(`events/`, data);
    }

    deleteEvent(eventPk: number): Promise<any> {
        return this.delete(`events/${eventPk}/`);
    }

    editEvent(eventPk: number, data: AddOrEditEventPayload): Promise<Event> {
        return this.patch(`events/${eventPk}/`, data);
    }
}

export default EventClient;
