import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import styles from './../ListForm/ListForm.module.css';
import { dealTypes } from './../../utils/constants';

const SpotSalesFields = (props: any) => {
    const { handleInput, handleDropdown, salesReps, fields } = props;

    return (
        <>
            <p className={styles.tabHeading}>Sales</p>
            <Form.Group widths="equal">
                {salesReps && (
                    <Form.Field required>
                        <label>Account Executive</label>
                        <Dropdown
                            fluid
                            name="account_executive"
                            placeholder="Select an account executive"
                            icon={'search'}
                            selection
                            search
                            options={salesReps}
                            onChange={handleDropdown}
                            required
                        />
                    </Form.Field>
                )}

                {salesReps && (
                    <Form.Field>
                        <label>Sales Development Representative</label>
                        <Dropdown
                            fluid
                            name="sales_development_representative"
                            placeholder="Select a sales development representative"
                            icon={'search'}
                            selection
                            search
                            options={salesReps}
                            onChange={handleDropdown}
                        />
                    </Form.Field>
                )}
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <Form.Select
                        label="Deal Type"
                        name="deal_type"
                        placeholder="Select a deal type"
                        selection
                        options={dealTypes}
                        fluid
                        onChange={handleDropdown}
                        required
                    />
                </Form.Field>

                <Form.Field>
                    <Form.Input
                        icon="dollar sign"
                        iconPosition="left"
                        name="lease_amount"
                        placeholder="5"
                        onChange={handleInput}
                        value={fields.lease_amount}
                        label="Lease Amount"
                        type="number"
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
                <Form.Field>
                    <Form.Input
                        icon="percent"
                        iconPosition="left"
                        name="hourly_commission"
                        placeholder="5"
                        onChange={handleInput}
                        value={fields.hourly_commission}
                        label="Hourly Commission (0.01% precision)"
                        type="number"
                        required
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        icon="percent"
                        iconPosition="left"
                        name="daily_commission"
                        placeholder="5"
                        onChange={handleInput}
                        value={fields.daily_commission}
                        label="Daily Commission (0.01% precision)"
                        type="number"
                        required
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
                <Form.Field>
                    <Form.Input
                        icon="percent"
                        iconPosition="left"
                        name="monthly_commission"
                        placeholder="5"
                        onChange={handleInput}
                        value={fields.monthly_commission}
                        label="Monthly Commission (0.01% precision)"
                        type="number"
                        required
                    />
                </Form.Field>
            </Form.Group>
        </>
    );
};

export default SpotSalesFields;
