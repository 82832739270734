import {
    EVENTS_REQUESTED,
    EVENTS_SUCCESS,
    EVENTS_FAILED,
    ADD_EVENT_REQUESTED,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAILED,
    DELETE_EVENT_REQUESTED,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAILED,
    EDIT_EVENT_REQUESTED,
    EDIT_EVENT_SUCCESS,
    EDIT_EVENT_FAILED,
} from './constants';
import { Action } from 'contexts/useReducerWithMiddleware';
import { Event } from 'types/Event';

export type EventsState = {
    events: Event[];
    errorMessage: string;
    loading: boolean;
    eventOperationLoading: boolean;
    eventOperationErrorMessage: string;
};

export const initialEventsState: EventsState = {
    events: [],
    errorMessage: '',
    loading: false,
    eventOperationLoading: false,
    eventOperationErrorMessage: '',
};

export default function eventsReducer(state: EventsState = initialEventsState, action: Action): EventsState {
    switch (action.type) {
        case EVENTS_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: '',
                events: (action.payload as { events: Event[] }).events,
            };
        case EVENTS_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: (action.payload as { message: string }).message,
            };
        case DELETE_EVENT_REQUESTED:
        case EDIT_EVENT_REQUESTED:
        case ADD_EVENT_REQUESTED:
            return {
                ...state,
                eventOperationLoading: true,
            };
        case DELETE_EVENT_SUCCESS:
        case EDIT_EVENT_SUCCESS:
        case ADD_EVENT_SUCCESS:
            return {
                ...state,
                eventOperationLoading: false,
                eventOperationErrorMessage: '',
            };
        case DELETE_EVENT_FAILED:
        case EDIT_EVENT_FAILED:
        case ADD_EVENT_FAILED:
            return {
                ...state,
                eventOperationLoading: false,
                eventOperationErrorMessage: (action.payload as { message: string }).message,
            };
        default:
            return state || initialEventsState;
    }
}
