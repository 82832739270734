import React from 'react';

function BuildingIcon({ color, ...restProps }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M20 8V0H0V36H40V8H20ZM8 32H4V28H8V32ZM8 24H4V20H8V24ZM8 16H4V12H8V16ZM8 8H4V4H8V8ZM16 32H12V28H16V32ZM16 24H12V20H16V24ZM16 16H12V12H16V16ZM16 8H12V4H16V8ZM36 32H20V28H24V24H20V20H24V16H20V12H36V32ZM32 16H28V20H32V16ZM32 24H28V28H32V24Z"
                fill={color || 'black'}
            />
        </svg>
    );
}

export default BuildingIcon;
