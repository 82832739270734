import React from 'react';
import { GroupedOption, Option, Options } from 'types/SelectInputs';
import styles from './Dropdown.module.css';
import { isGroupedOptions } from './utils';

interface DropdownProps {
    options: Options;
    selectedOption: Option | undefined;
    visible: boolean;
    handleSelectOption: (option: Option) => void;
}

function Dropdown({ options, visible, selectedOption, handleSelectOption }: DropdownProps) {
    return (
        <div
            className={[styles.dropdownWrapper, visible ? styles.dropdownOpen : ''].join(' ')}
            role="listbox"
            tabIndex={0}
        >
            <ul className={styles.resultsList}>
                {options.length === 0 && <li className={styles.emptyResult}>No results found</li>}
                {options.map((resultOrGroup) => {
                    if (isGroupedOptions(resultOrGroup)) {
                        const group = resultOrGroup as GroupedOption;
                        if (group.options.length === 0) return null;
                        return (
                            <div key={group.label}>
                                <span className={styles.groupLabel}>{group.label}</span>
                                {group.options.map((option) => (
                                    <li
                                        key={`${option.label}_${option.value}`}
                                        className={[
                                            styles.resultItem,
                                            option.value === selectedOption?.value ? styles.optionSelected : '',
                                        ].join(' ')}
                                    >
                                        <button onClick={() => handleSelectOption(option)}>{option.label}</button>
                                    </li>
                                ))}
                            </div>
                        );
                    } else {
                        const option = resultOrGroup as Option;
                        return (
                            <li
                                key={`${option.label}_${option.value}`}
                                className={[
                                    styles.resultItem,
                                    option.value === selectedOption?.value ? styles.optionSelected : '',
                                ].join(' ')}
                            >
                                <button onClick={() => handleSelectOption(option)} type="button">
                                    {option.label}
                                </button>
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
}

export default Dropdown;
