import { HTTPError } from '../clients/HTTPClient';
import { authClient } from '../clients';

export default function unauthorized(a?: { type: string; payload?: unknown }) {
    if (a && a.type.includes('FAILED')) {
        if (a.payload && typeof a.payload === 'object' && 'error' in a.payload) {
            const e = (a as { payload: { error: unknown } }).payload.error;
            if (HTTPError.isHTTPError(e)) {
                if (e.status === 401) {
                    authClient.logOut().then(() => {
                        window.location.href = `/login`;
                    });
                }
            }
        }
    }
}
