import HTTPClient, { HTTPClientParameters, HTTPError } from './HTTPClient';

type AuthClientServiceParams = HTTPClientParameters;

class AuthClient extends HTTPClient {
    constructor(params: AuthClientServiceParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    async logOut(): Promise<void> {
        try {
            await this.get('logout/');
        } catch (e) {
            if (HTTPError.isHTTPError(e)) {
                return Promise.resolve();
            }
        }
    }
}

export default AuthClient;
