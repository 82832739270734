import { CountryOption, usOption } from 'assets/data/countriesPhoneCodes';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { ReactComponent as NextIcon } from 'assets/icons/next.svg';
import AGForm from 'components/AGForm/AGForm';
import CodeInput from 'components/AGForm/Inputs/CodeInput/CodeInput';
import PhoneInput from 'components/AGForm/Inputs/PhoneInput/PhoneInput';
import Button from 'components/Button/Button';
import LinkLikeButton from 'components/Button/LinkLikeButton';
import { StepComponentProps } from 'components/ValetDashboard/Steps/Steps';
import React, { useState } from 'react';
import { valetSessionClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';
import { ValetSession } from 'types/ValetSession';
import styles from './CarCodeForm.module.css';
import { useForm } from '@felte/react';
import { validateCode } from 'utils/codeValidation';
import { validatePhone, validatePhoneCountryCode } from 'utils/phoneValidation';
import { FormErrors } from 'constants/formErrors';
import WarningBox from 'components/ValetDashboard/WarningBox/WarningBox';

export type ResendCodeForm = {
    phoneNumber: string;
    phoneCountryCode: CountryOption | undefined;
    api?: unknown;
};

export type ResendCodeError = {
    phoneNumber: string | null;
    phoneCountryCode: string | null;
    api?: string | null;
};

export type CodeForm = {
    code: string;
    api?: unknown;
};

export type CodeErrors = {
    code: string | null;
    api?: string | null;
};

interface CarCodeFormProps extends StepComponentProps {
    onCodeVerify: (valetSession: ValetSession) => void;
}

function CarCodeForm(props: CarCodeFormProps) {
    const codeForm = useForm<CodeForm>({
        initialValues: {
            code: '',
        },
        validate: (v: CodeForm): CodeErrors => {
            const { code } = validateCode(v.code);
            return { api: code, code: null };
        },
        onSubmit: ({ code }: CodeForm) => {
            verifyCarCode(code);
        },
    });
    const resendCodeForm = useForm<ResendCodeForm>({
        initialValues: {
            phoneNumber: '',
            phoneCountryCode: usOption,
        },
        validate: (v: ResendCodeForm): ResendCodeError => {
            const { phoneNumber } = validatePhone(v.phoneNumber);
            const { phoneCountryCode } = validatePhoneCountryCode(v.phoneCountryCode?.value);
            return { phoneNumber, phoneCountryCode, api: null };
        },
        onSubmit: (values: ResendCodeForm) => {
            resendCode(values.phoneCountryCode, values.phoneNumber);
        },
    });
    const [loading, setLoading] = useState(false);
    const [resendCodeOpen, setResendCodeOpen] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    async function verifyCarCode(code: string) {
        setLoading(true);
        try {
            const valetSession = await valetSessionClient.getValetSessionWithCode(code);
            props.onCodeVerify(valetSession);
            props.goNext();
        } catch (err) {
            if (HTTPError.isHTTPError(err)) {
                if (err.status === 404) {
                    codeForm.setErrors({ api: FormErrors.InvalidCode });
                    setLoading(false);
                    return;
                }
            }
            codeForm.setErrors({ api: FormErrors.SomethingWentWrong });
        } finally {
            setLoading(false);
        }
    }

    async function resendCode(phoneCountryCode: CountryOption | undefined, phoneNumber: string) {
        setLoading(true);
        try {
            await valetSessionClient.resendValetSessionCode({
                phone: phoneNumber,
                phone_country_code: phoneCountryCode?.value || '+1',
            });
            setShowSuccessMessage(true);
            closeResendCode();
        } catch (err) {
            if (HTTPError.isHTTPError(err)) {
                if (err.status === 404) {
                    resendCodeForm.setErrors({ api: FormErrors.InvalidPhoneForValetSession });
                    setLoading(false);
                    return;
                }
            }
            resendCodeForm.setErrors({ api: FormErrors.SomethingWentWrong });
        } finally {
            setLoading(false);
        }
    }

    function openResendCode() {
        setResendCodeOpen(true);
    }

    function closeResendCode() {
        setResendCodeOpen(false);
    }

    return (
        <>
            {resendCodeOpen ? (
                <AGForm ref={resendCodeForm.form} onSubmit={() => resendCodeForm.handleSubmit()}>
                    <div className={styles.inputHelpTextGroupe}>
                        <PhoneInput
                            phoneNumber={resendCodeForm.data().phoneNumber}
                            countryCode={resendCodeForm.data().phoneCountryCode}
                            onPhoneChange={(phone) => {
                                resendCodeForm.setFields('phoneNumber', phone, true);
                            }}
                            onCountryCodeChange={(code) => {
                                resendCodeForm.setFields('phoneCountryCode', code, true);
                            }}
                            errors={resendCodeForm.errors().phoneNumber || resendCodeForm.errors().api}
                        />
                        <small className={styles.helpText}>
                            Ask the vehicle owner for their phone number to resend them the code.
                        </small>
                    </div>

                    <Button type="submit" loading={loading} disabled={false}>
                        Re-send Code
                        <NextIcon />
                    </Button>
                    <LinkLikeButton onClick={closeResendCode}>Back to code verification</LinkLikeButton>
                </AGForm>
            ) : (
                <AGForm ref={codeForm.form} onSubmit={() => codeForm.handleSubmit()}>
                    {showSuccessMessage && (
                        <WarningBox title="Code sent" type="info">
                            The code has been sent to the vehicle owner.
                        </WarningBox>
                    )}
                    <div className={styles.inputHelpTextGroupe}>
                        <CodeInput
                            label="Code"
                            onCodeChange={(value) => {
                                codeForm.setFields({ code: value });
                            }}
                            inputmode="text"
                            errors={codeForm.errors().api}
                            disabled={loading}
                            codeLength={6}
                            name="code"
                            icon={<KeyIcon />}
                        />
                        <small className={styles.helpText}>
                            Ask the vehicle owner to provide you with a 6-digit code in order to verify ownership.
                        </small>
                    </div>

                    <Button type="submit" loading={loading} disabled={!codeForm.isValid()}>
                        Next
                        <NextIcon />
                    </Button>
                    <LinkLikeButton onClick={openResendCode}>
                        <RefreshIcon />
                        Re-send code
                    </LinkLikeButton>
                </AGForm>
            )}
        </>
    );
}

export default CarCodeForm;
