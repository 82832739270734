import { SpotInterface, PricingPayload, SpotMetadata } from 'types';
import APIClient from './APIClient';

class SpotsService extends Object {
    getSpotsInfo(organization: number, token: string): Promise<SpotInterface[]> {
        // we should use /spotmetadata?organization=${organization}&includeThirdParty=true
        // path, but we can't because we need rates / prices. Check out AG-3400.
        return APIClient.get(`spots?organization=${organization}&includeThirdParty=true`, token);
    }

    getSpots(token: string): Promise<SpotMetadata[]> {
        return APIClient.get(`spotmetadata`, token);
    }

    getPrices(spotId: string, token: string): Promise<{ result: PricingPayload }> {
        return APIClient.get(`spots/${spotId}/pricing_history/`, token);
    }

    getMultidayPrices(spotId: string, days: number, token: string): Promise<{ result: PricingPayload[] }> {
        return APIClient.get(`spots/${spotId}/multiday_pricing_history/?days=${days}`, token);
    }

    addSpot(data: any, token: string): Promise<unknown> {
        return APIClient.post(`spots/`, data, token);
    }
}

export default new SpotsService();
