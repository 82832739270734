import React from 'react';

const EmailIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                d="M16.7319 2.35505C16.7319 1.46422 16.0031 0.735352 15.1122 0.735352H2.15461C1.26378 0.735352 0.534912 1.46422 0.534912 2.35505V12.0733C0.534912 12.9641 1.26378 13.693 2.15461 13.693H15.1122C16.0031 13.693 16.7319 12.9641 16.7319 12.0733V2.35505ZM15.1122 2.35505L8.63342 6.39621L2.15461 2.35505H15.1122ZM15.1122 12.0733H2.15461V3.97476L8.63342 8.02401L15.1122 3.97476V12.0733Z"
                fill={color || '#333333'}
            />
        </svg>
    );
};

export default EmailIcon;
