import { CountryCode } from 'assets/data/countriesAndStates';
import React, { useState } from 'react';
import useForm from 'hooks/useForm';
import { Visitor } from 'types/Visitor';
import {
    states,
    stringRepresentationToOption,
    stringRepresentationToValues,
    valuesToStringRepresentation,
} from 'utils/statesSelect';
import { FormattedVisitorCode } from 'contexts/VisitorCodesContext/utils';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import AGForm from 'components/AGForm/AGForm';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import AGLabel from 'components/AGForm/Inputs/Label/Label';
import Button from 'components/Button/Button';
import SearchSelectInput from 'components/AGForm/Inputs/SelectInput/SearchSelectInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import styles from './VisitorForm.module.css';

export interface VisitorFormState {
    name: string;
    plate: string;
    phone: string;
    code: string;
    state: string;
    country: CountryCode;
}

type VisitorFormProps = {
    visitorCodes: FormattedVisitorCode[];
    deleteVisitor: (visitorPk: number) => void;
    editVisitor: (updatedVisitor: VisitorFormState, visitorPk: number) => void;
    addVisitor: (visitor: VisitorFormState) => void;
    visitor: Visitor | null;
};

function VisitorForm({ visitor, visitorCodes, ...props }: VisitorFormProps) {
    const [formError, setFormError] = useState('');
    const initialInputState: VisitorFormState = {
        name: visitor ? visitor.name : '',
        plate: visitor ? visitor.car_details?.plate : '',
        phone: visitor && visitor.phone ? visitor.phone : '',
        code: visitor && visitor.codes[0] ? visitor.codes[0].toString() : '',
        state: visitor?.car_details
            ? valuesToStringRepresentation(visitor.car_details.country, visitor.car_details.state)
            : '',
        country: visitor ? visitor.car_details?.country : 'US',
    };

    const { handleInput, handleValueChange, inputs } = useForm(initialInputState);

    const handleSubmit = () => {
        const stateAndCountry = stringRepresentationToValues(inputs.state);
        if (!stateAndCountry) setFormError('License PLate state is required');
        else {
            const { state, country } = stateAndCountry;

            if (visitor) {
                props.editVisitor({ ...inputs, state, country }, visitor.pk);
            } else {
                props.addVisitor({ ...inputs, state, country });
            }
        }
    };

    const emptyCarFields = !inputs.plate || !inputs.state;
    const emptyCode = !inputs.code;

    return (
        <div style={{ width: '360px' }}>
            <AGForm>
                {(visitor?.created_at || visitor?.updated_at) && (
                    <div className={styles.dateLabels}>
                        <div className={styles.icon}>
                            <CalendarIcon />
                        </div>
                        {visitor.created_at && (
                            <p>
                                Created:
                                <span> {visitor.created_at}</span>
                            </p>
                        )}
                        {visitor.updated_at && (
                            <p>
                                Last Updated:
                                <span> {visitor.updated_at}</span>
                            </p>
                        )}
                    </div>
                )}
                <div>
                    <AGLabel label="Validation Code *" />
                    <AGSimpleSelect
                        options={visitorCodes}
                        onSelect={(c) => handleValueChange('code', c)}
                        selected={inputs.code}
                        width="100%"
                        placeholder="Select validation code"
                    />
                </div>
                <TextInput
                    name="plate"
                    label="License Plate Number *"
                    value={inputs.plate}
                    onChange={handleInput}
                    placeholder="License Plate Number"
                    errors={null}
                />
                <SearchSelectInput
                    label="License Plate State *"
                    placeholder="License Plate State"
                    options={states}
                    errors={null}
                    onSelected={(value) => {
                        handleValueChange('state', value);
                    }}
                    name="state"
                    selected={stringRepresentationToOption(inputs.state)}
                />
                <TextInput
                    name="name"
                    label="Visitor Name"
                    value={inputs.name}
                    onChange={handleInput}
                    type="text"
                    placeholder="Visitor Name (optional)"
                    errors={null}
                />
                <TextInput
                    name="phone"
                    label="Visitor Phone"
                    value={inputs.phone}
                    onChange={handleInput}
                    type="tel"
                    placeholder="Phone (optional)"
                    errors={null}
                />
                {formError && <ErrorMessage errorMessage={formError} />}

                <Button disabled={emptyCode || emptyCarFields} type="button" onClick={handleSubmit}>
                    {visitor ? 'Edit Visitor' : 'Add Visitor'}
                </Button>

                {visitor && visitor.pk && (props as any).deleteVisitor && (
                    <Button
                        color="error-outline"
                        onClick={() => {
                            props.deleteVisitor(visitor.pk);
                        }}
                        type="button"
                    >
                        Delete
                    </Button>
                )}
            </AGForm>
        </div>
    );
}

export default VisitorForm;
