import { useCallback, useEffect, useRef } from 'react';

type UseTimeoutParams = {
    callback: () => void;
    intervalTime: number;
    lastCalledAt: Date | null;
};

export default function useInterval({ callback, intervalTime, lastCalledAt }: UseTimeoutParams) {
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const lastCalledAtRef = useRef<Date | null>(null);

    function clearTimeoutHandler() {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = null;
        }
    }

    const setNewTimeoutForCallback = useCallback(
        function () {
            let timeForTimeout = intervalTime;

            if (lastCalledAtRef.current !== null) {
                const now = new Date();
                // onBlur we stop fetching for reports update but we saved the last time we did it.
                // When coming back to focus we check if its been longer than the time we usually wait for updates to avoid making multiple calls if the user is blurring and focusing too often.
                const timeSinceLastCalled = now.getTime() - lastCalledAtRef.current.getTime();
                // If its been longer we immediately make the call, if not we wait whatever time left was to wait.
                timeForTimeout = timeSinceLastCalled >= intervalTime ? 0 : intervalTime - timeSinceLastCalled;
            }

            clearTimeoutHandler(); // This clear should not be necessary but is added just in case to avoid memory lick
            timeoutIdRef.current = setTimeout(callback, timeForTimeout);
        },
        [callback, intervalTime]
    );

    useEffect(() => {
        if (lastCalledAt) lastCalledAtRef.current = lastCalledAt;
        setNewTimeoutForCallback();
        return clearTimeoutHandler;
    }, [lastCalledAt, lastCalledAtRef, setNewTimeoutForCallback]);

    useEffect(() => {
        window.addEventListener('focus', setNewTimeoutForCallback);
        window.addEventListener('blur', clearTimeoutHandler);

        return () => {
            window.removeEventListener('focus', setNewTimeoutForCallback);
            window.removeEventListener('blur', clearTimeoutHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
