import React, { ReactElement, ReactNode, useState } from 'react';
import { ReactComponent as CaretRight } from 'assets/icons/caretRight.svg';
import styles from './AGCollapsibleTable.module.css';

type GroupedCollapsibleRowsProps = {
    children: ReactNode;
    parentRowContent: ReactElement;
    parentRowText: string;
};

const GroupedCollapsibleRows = ({ children, parentRowContent, parentRowText }: GroupedCollapsibleRowsProps) => {
    const [collapsed, toggleCollapsed] = useState(true);

    return (
        <>
            <tr onClick={() => toggleCollapsed(!collapsed)} className={styles.clickableRow}>
                <td>
                    {parentRowText}
                    <CaretRight className={`${styles.caret} ${!collapsed && styles.expanded}`} />
                </td>
                {parentRowContent}
            </tr>
            {!collapsed && children}
        </>
    );
};

export default GroupedCollapsibleRows;
