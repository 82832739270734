import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { RATES_FAILED, RATES_REQUESTED, RATES_SUCCESS } from './constants';
import rateCalendarReducer, { RatesCalendarState, initialRatesCalendarState } from './reducer';
import useReducerWithMiddleware from 'contexts/useReducerWithMiddleware';
import unauthorized from 'contexts/unauthorized';
import { ratesCalendarClient } from 'clients';

interface RatesCalendarActions {
    getRates: (spotPk: number, start: number, end: number) => void;
}

interface RatesCalendarContextType extends RatesCalendarActions, RatesCalendarState {}

export const RatesCalendarContext = createContext<RatesCalendarContextType | null>(null);

export const RatesCalendarProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        rateCalendarReducer,
        { ...initialRatesCalendarState },
        [],
        [unauthorized]
    );

    const getRates = useCallback(
        async (spotPk: number, start: number, end: number) => {
            dispatch({ type: RATES_REQUESTED });
            try {
                const rates = await ratesCalendarClient.getRates(spotPk, start, end);
                dispatch({ type: RATES_SUCCESS, payload: { rates } });
            } catch (error) {
                dispatch({
                    type: RATES_FAILED,
                    payload: { error },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<RatesCalendarContextType>(() => {
        return {
            ...state,
            getRates,
        };
    }, [state, getRates]);

    return <RatesCalendarContext.Provider value={value}>{children}</RatesCalendarContext.Provider>;
};

export const useRateCalendar = () => {
    const context = useContext(RatesCalendarContext);
    if (!context) {
        throw new Error('Error: useRateCalendar should be wrapped by RateCalendarProvider.');
    }
    return context;
};
