import { DiscountCode, ValidatedDiscountCode } from 'types';
import HTTPClient, { HTTPClientParameters } from './HTTPClient';

type DiscountCodeClientParams = HTTPClientParameters;

class DiscountCodeClient extends HTTPClient {
    constructor(params: DiscountCodeClientParams) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    applyDiscountCode(slotPk: number, discountCode: string): Promise<DiscountCode> {
        return this.post('promo', {
            promo_code: discountCode,
            slotID: slotPk,
        });
    }

    generateDiscountQrCode(discountCodePk: number): Promise<ValidatedDiscountCode> {
        return this.post('generate_promo_qr_code/', {
            promo_code_pk: discountCodePk,
        });
    }
}

export default DiscountCodeClient;
