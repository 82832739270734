import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Popup, Icon, Label } from 'semantic-ui-react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { barChartOptions, createChartGradient, DataTypeOptions, getLabel } from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type BarGraphProps = {
    title?: string;
    description?: string;
    xData: string[];
    yData: number[];
    dataLabel: string;
    dataType?: DataTypeOptions;
    showLabel?: boolean;
};
const BarGraph = (props: BarGraphProps) => {
    const { title, description, xData, yData, dataLabel, dataType, showLabel } = props;

    const chartRef = useRef<ChartJS<'bar'>>(null);
    const [chartData, setChartData] = useState<ChartData<'bar'>>({
        datasets: [],
    });

    useEffect(() => {
        const chart = chartRef.current;
        let chartData = {
            labels: xData,
            datasets: [
                {
                    label: dataLabel,
                    data: yData,
                    borderWidth: 4,
                    borderSkipped: true,
                },
            ],
        };

        if (chart) {
            chartData = {
                ...chartData,
                datasets: chartData.datasets.map((dataset) => ({
                    ...dataset,
                    backgroundColor: createChartGradient(chart.ctx),
                    borderColor: createChartGradient(chart.ctx),
                    pointHoverBackgroundColor: createChartGradient(chart.ctx),
                })),
            };
        }
        setChartData(chartData);
    }, [xData, yData, dataLabel]);

    return (
        <>
            {title && (
                <h4 style={{ marginTop: 0 }}>
                    {title}
                    {showLabel && yData.length > 0 && (
                        <Label
                            style={{ marginLeft: 5 }}
                            content={getLabel(yData, dataType)}
                            color="green"
                            size="tiny"
                        />
                    )}
                </h4>
            )}

            {description && (
                <Popup trigger={<Icon name="info circle" />} content={description} on="click" pinned inverted />
            )}

            <Bar ref={chartRef} data={chartData} options={barChartOptions} />
        </>
    );
};

export default BarGraph;
