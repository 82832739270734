import { Organization } from 'types';
import APIClient from './APIClient';

class OrganizationService {
    addOrganization(organization: any, token: string) {
        return APIClient.post(`organizations/`, organization, token);
    }
    getOrganization(id: number | string, token: string): Promise<Organization> {
        return APIClient.get(`organizations/${id}/`, token);
    }
    getOrganizations(token: string): Promise<{ name: string; pk: number }> {
        return APIClient.get(`organizations/`, token);
    }
}

export default new OrganizationService();
