import { Car } from 'types/Car';
import HttpClient, { HTTPClientParameters } from './HTTPClient';

type CarClientParameters = HTTPClientParameters;

export type CarParams = {
    plate: string;
    state: string;
    country: string;
};

class CarClient extends HttpClient {
    constructor(params: CarClientParameters) {
        super(params);
        this.headers.set('Content-Type', 'application/json');
    }

    getOrCreateCar(data: CarParams): Promise<Car> {
        return this.post(`cars/`, data);
    }
}

export default CarClient;
