import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';

import CarIcon from 'components/Icons/CarIcon';
import WidgetBox from 'components/widgets/WidgetBox';
import WidgetData from 'components/widgets/WidgetData';
import WidgetDataLabel from 'components/widgets/WidgetDataLabel';
import { ReportInterface, SpotInterface } from 'types';
import { OccupancyCopies } from '../Dashboard/SpotCard/constants';

type OccupancyWidgetProps = {
    spot: SpotInterface;
    layout?: 'horizontal' | 'vertical';
    report: ReportInterface | null;
};

function OccupancyWidget({ spot, layout = 'vertical', report }: OccupancyWidgetProps): JSX.Element {
    if (report === null) {
        return (
            <WidgetBox title="Monthly Parking" icon={<CarIcon width={21} />}>
                <Loader active inline="centered" />
            </WidgetBox>
        );
    }
    // If dynamic pricing is enabled we show the widget on the left and with a vertical layout
    // else we show it on the right with a more horizontal layout
    const columnWidth = layout === 'vertical' ? 16 : 8;
    const hasTenants = report.bulk_rentals.total_slot_count > 0;
    return (
        <WidgetBox title="Monthly Parking" icon={<CarIcon width={21} />}>
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={columnWidth}>
                    <>
                        <WidgetDataLabel
                            label={OccupancyCopies.monthlyRentals.title}
                            tooltip={{
                                title: OccupancyCopies.monthlyRentals.title,
                                message: OccupancyCopies.monthlyRentals.tooltip,
                            }}
                        />
                        <WidgetData>{spot.monthly_rentals.toLocaleString('en-US')}</WidgetData>
                    </>
                    <>
                        <WidgetDataLabel
                            label={OccupancyCopies.monthlySpaces.title}
                            tooltip={{
                                title: OccupancyCopies.monthlySpaces.title,
                                message: OccupancyCopies.monthlySpaces.tooltip,
                            }}
                        />
                        <WidgetData>{spot.quantity_monthly.toLocaleString('en-US')}</WidgetData>
                    </>
                </Grid.Column>
                {hasTenants && (
                    <Grid.Column mobile={16} tablet={8} computer={columnWidth}>
                        <>
                            <WidgetDataLabel
                                label={OccupancyCopies.tenantRentals.title}
                                tooltip={{
                                    title: OccupancyCopies.tenantRentals.title,
                                    message: OccupancyCopies.tenantRentals.tooltip,
                                }}
                            />
                            <WidgetData>{report.bulk_rentals.total_active_rentals}</WidgetData>
                        </>
                        <>
                            <WidgetDataLabel
                                label={OccupancyCopies.tenantSpaces.title}
                                tooltip={{
                                    title: OccupancyCopies.tenantSpaces.title,
                                    message: OccupancyCopies.tenantSpaces.tooltip,
                                }}
                            />
                            <WidgetData>{report.bulk_rentals.total_slot_count}</WidgetData>
                        </>
                    </Grid.Column>
                )}
            </Grid>
        </WidgetBox>
    );
}

export default OccupancyWidget;
