import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Header, Divider, Image } from 'semantic-ui-react';
import { signupAction } from 'actions/actions';
import SignupForm from 'components/SignupForm/SignupForm';
import { trackPageViewed } from 'utils/analytics';
import { parseQueryParams } from 'utils/helpers';

const SignupView = (props: any) => {
    const urlParams = parseQueryParams(props.location.search);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Signup',
            superuser: null,
            organizationId: null,
            spotId: null,
            affiliationRole: null,
        });
    });

    return (
        <Grid padded style={{ height: '100vh' }}>
            <Grid.Row>
                <Grid.Column tablet={6} computer={6} color="yellow" stretched only="large screen">
                    <Image size="small" wrapped src={require('assets/AirGarage.png')} />
                    <Image size="large" wrapped src={require('assets/money_machine.png')} centered />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={10} computer={10} style={{ backgroundColor: 'white' }}>
                    <Divider style={{ height: 80 }} hidden />
                    <Header size="large" textAlign="center">
                        Sign up for AirGarage
                    </Header>
                    <SignupForm signup={props.signup} errorMessage={props.errorMessage} urlParams={urlParams} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

function mapStateToProps(state: any) {
    return {
        errorMessage: state.auth.errorMessage,
    };
}

const mapDispatchToProps = {
    signup: signupAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupView));
