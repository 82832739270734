import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Divider, Input, Image, Icon, Label, Loader, Message, Progress } from 'semantic-ui-react';
import { progressUploadVisitorCSVAction, uploadVisitorCSVAction } from 'actions/actions';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type UploadVisitorsCSVModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const UploadVisitorsCSVModuleContainer = ({
    profile,
    selectedSpot,
    errorMessage,
    push,
    loading,
    progress,
    successMessage,
    progressUploadVisitorCSV,
    selectedOrganization,
    uploadVisitorCSV,
}: UploadVisitorsCSVModuleContainerProps) => {
    const uploadRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [file, setFile] = useState(null);
    const [code, setCode] = useState('');
    const [running, setRunning] = useState(false);

    const uploadFile = (event: any) => {
        setFile(event.target.files[0]);
        //reset progress bar for a new file
        progressUploadVisitorCSV();
    };

    const handleValidateVisitors = async () => {
        setRunning(true);
        //start progress bar when press validate button
        uploadVisitorCSV({ code: code, file: file, spot: selectedSpot });
    };

    useEffect(() => {
        trackPageViewed({
            pageName: 'Upload Visitors',
            organizationId: selectedOrganization,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [selectedOrganization, selectedSpot, profile]);

    useEffect(() => {
        let intervalID: NodeJS.Timeout;
        //refresh progress bar until the process is done
        if (running && !errorMessage) {
            intervalID = setInterval(async () => {
                if (progress.max > 0 && progress.value === progress.max) {
                    setRunning(false);
                    push(`/organization/${selectedOrganization}/spots/${selectedSpot}/visitors`);
                }
                progressUploadVisitorCSV();
            }, 2000);
        }
        return () => clearInterval(intervalID);
    }, [running, selectedOrganization, selectedSpot, progressUploadVisitorCSV, errorMessage, progress, push]);

    return (
        <>
            {loading ? (
                <Loader active />
            ) : (
                <div>
                    {errorMessage && <Message negative content={errorMessage} />}
                    {successMessage && <Message positive content={successMessage} />}
                    <h4>Upload requirements</h4>
                    <ul>
                        <li>Your spreadsheet should be a CSV file</li>
                        <li>
                            Required columns: plate and state (must be short code or long name, e.g CA or California)
                        </li>
                        <li>Optional columns: name, phone, and country (must be two characters, e.g US)</li>
                        <li>All cars on the spreadsheet will be validated with the code entered below</li>
                    </ul>
                    <div>
                        <Image size="medium" src={require('assets/csv-example.png')} />
                        <Icon name="check" color="green" style={{ paddingLeft: 10 }} size="large" />
                    </div>
                    <Divider hidden />
                    <Input placeholder="Validation code" onChange={(event) => setCode(event.target.value)} />
                    <input ref={uploadRef} type="file" id="file" hidden onChange={uploadFile} />
                    <Divider hidden />
                    {file ? (
                        <Label icon={'check'} color="green">
                            {(file as any).name}
                        </Label>
                    ) : (
                        <Button
                            content="Select file"
                            icon={'file'}
                            color="blue"
                            onClick={() => uploadRef.current.click()}
                        />
                    )}
                    {file && running && !errorMessage && (
                        <>
                            <Divider hidden />
                            <Progress value={progress.value} total={progress.max} indicating={true}></Progress>
                        </>
                    )}
                    <Divider hidden />
                    <Button disabled={!file} content="Validate Visitors" onClick={handleValidateVisitors} />
                </div>
            )}
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedOrganization: selectedOrganizationId(state)!,
        loading: state.visitors.loading,
        successMessage: state.visitors.successMessage,
        errorMessage: state.visitors.errorMessage,
        progress: state.visitors.uploadCsvProgress,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    uploadVisitorCSV: uploadVisitorCSVAction,
    progressUploadVisitorCSV: progressUploadVisitorCSVAction,
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadVisitorsCSVModuleContainer);
