import { startYear } from './constants';

export type MonthYear = {
    text: string;
    key: string;
    value: string;
};

type Month = { value: string; text: string };
export const months: Month[] = [
    { value: '01', text: 'January' },
    { value: '02', text: 'February' },
    { value: '03', text: 'March' },
    { value: '04', text: 'April' },
    { value: '05', text: 'May' },
    { value: '06', text: 'June' },
    { value: '07', text: 'July' },
    { value: '08', text: 'August' },
    { value: '09', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' },
];

class MonthYearUtil {
    static monthYearValue(m: Month, y: number | string): MonthYear {
        const monthYear = `${m.value}:${y}`;
        return {
            value: monthYear,
            text: `${m.text} ${y}`,
            key: monthYear,
        };
    }

    private readonly _monthYears: MonthYear[] = [];
    private readonly _monthYearsWithExtraMonth: MonthYear[] = [];

    constructor() {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        for (let year = startYear; year <= currentYear; year++) {
            for (const month of months) {
                if (year !== currentYear || currentMonth >= parseInt(month.value)) {
                    this._monthYears.push(MonthYearUtil.monthYearValue(month, year));
                } else {
                    break;
                }
            }
        }
        this._monthYearsWithExtraMonth = Array.from(this._monthYears);
        // if we aren't in December right now, then we add an extra month for monthYearsWithOneMonthLookahead
        if (currentMonth < 12) {
            const nextMonth = months.find(({ value }) => parseInt(value) === currentMonth + 1);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this._monthYearsWithExtraMonth.push(MonthYearUtil.monthYearValue(nextMonth!, currentYear));
        }
        // Reverse these so that the most recent month is the first; because all of our user experiences
        // start with the most recent month.
        this._monthYears = this._monthYears.reverse();
        this._monthYearsWithExtraMonth = this._monthYearsWithExtraMonth.reverse();
    }

    get monthYears(): MonthYear[] {
        return this._monthYears;
    }

    get monthYearsWithOneMonthLookahead(): MonthYear[] {
        return this._monthYearsWithExtraMonth;
    }

    get currentMonthYear(): MonthYear {
        return this._monthYears[0];
    }
}

export default MonthYearUtil;
