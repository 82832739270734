import React from 'react';

const StarIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 28 28" fill="none" {...restProps}>
            <path
                d="M13.9999 20.7318L21.2099 25.0834L19.2966 16.8817L25.6666 11.3634L17.2783 10.6517L13.9999 2.91675L10.7216 10.6517L2.33325 11.3634L8.70325 16.8817L6.78992 25.0834L13.9999 20.7318Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default StarIcon;
