import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { VisitorCodeForm } from 'contexts/VisitorCodesContext';

export enum ValidityType {
    EVENTS = 'events',
    TEMPORARY = 'temporary',
    EXPIRATION = 'expiration',
    PERMANENT = 'permanent',
}

export const validityOptions: SelectOption[] = [
    {
        label: 'For an event',
        value: ValidityType.EVENTS,
    },
    {
        label: 'For a few hours or days',
        value: ValidityType.TEMPORARY,
    },
    {
        label: 'Until a certain date',
        value: ValidityType.EXPIRATION,
    },
    {
        label: 'Always valid',
        value: ValidityType.PERMANENT,
    },
];

export function validateVisitorCodeForm(inputs: VisitorCodeForm, validityType: ValidityType): string {
    switch (validityType) {
        case ValidityType.EVENTS:
            return inputs.events.length === 0 ? 'Please select an event' : '';
        case ValidityType.EXPIRATION:
            return !inputs.expiration_date ? 'Please select a date' : '';
        case ValidityType.TEMPORARY:
            return !inputs.days_valid && !inputs.hours_valid ? 'Please choose amount of days or hours' : '';
        case ValidityType.PERMANENT:
            return '';
    }
}
