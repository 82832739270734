import React from 'react';
import { Popup } from 'semantic-ui-react';
import styles from './InfoTooltip.module.css';
import InfoIcon from 'components/Icons/InfoIcon';

export type InfoTooltiptProps = {
    title?: string;
    text: string;
};

const InfoTooltip = ({ title, text }: InfoTooltiptProps) => {
    return (
        <Popup trigger={<InfoIcon style={{ marginLeft: '4px' }} data-testid="popup" />} on="hover" inverted wide>
            {!!title && (
                <p className={styles.title}>
                    <InfoIcon style={{ marginRight: '4px' }} />
                    {title}
                </p>
            )}
            <p className={styles.message}>{text}</p>
        </Popup>
    );
};

export default InfoTooltip;
