import React, { ReactNode } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { ReactComponent as Arrow } from 'assets/icons/triangle.svg';

import styles from './AGDropdown.module.css';

export type AGDropdownProps = {
    text: string;
    children: ReactNode;
    open: boolean;
    toggleOpen: (state: boolean) => void;
    icon?: ReactNode;
    width?: string;
    isInModal?: boolean;
    maxHeigh?: string;
    disabled?: boolean;
};

export type AGDropdownWrapperProps = Omit<AGDropdownProps, 'text' | 'open' | 'toggleOpen' | 'children'>;

const AGDropdown = ({
    text,
    icon,
    children,
    width = '200px',
    open,
    toggleOpen,
    isInModal = false,
    maxHeigh,
    disabled = false,
}: AGDropdownProps) => {
    const ref = useClickOutside(() => toggleOpen(false));

    return (
        <div ref={ref} className={styles.agDropdownContainer} style={{ width }}>
            <div
                onClick={() => {
                    if (!disabled) toggleOpen(!open);
                }}
                className={`${styles.agDropdown} ag-dropdown ${disabled ? styles.disabled : styles.enabled}`}
            >
                <div className={styles.textAndIcon}>
                    {!!icon && icon}
                    <span data-testid="ag-dropdown-text">{text}</span>
                </div>
                <Arrow className={`${styles.arrow} ${open && styles.open}`} />
            </div>
            {open && (
                <div
                    className={styles.contentContainer}
                    style={{ position: isInModal ? 'relative' : undefined, maxHeight: maxHeigh || 'unset' }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default AGDropdown;
