import React from 'react';
import { Modal, Form, Header, Table, Loader } from 'semantic-ui-react';
import useModal from '../../../hooks/useModal';

const PayoutReport = (props: any) => {
    // TODO: Fix the payout details or remove the unused code
    // getSelectedPayoutDetails is unused -- AG-2379
    const { isShowing, toggle } = useModal();
    const { payout, selectedPayoutDetails } = props;

    return (
        <>
            <span>{payout.formatted_amount}</span>
            <Modal as={Form} open={isShowing} onClose={toggle} onSubmit={toggle}>
                <Header content="Payout report" as="h2" />
                <Modal.Content>
                    <Header content={payout.local_date} as="h3" />
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Count of Rentals</Table.HeaderCell>
                                <Table.HeaderCell>Gross Revenue</Table.HeaderCell>
                                <Table.HeaderCell>Count of Refunds</Table.HeaderCell>
                                <Table.HeaderCell>Refund Amount</Table.HeaderCell>
                                <Table.HeaderCell>Adjusted Revenue</Table.HeaderCell>
                                <Table.HeaderCell>AirGarage Commission</Table.HeaderCell>
                                <Table.HeaderCell>Net Revenue</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        {selectedPayoutDetails ? (
                            <>
                                {selectedPayoutDetails.daily_reports.map((report: any) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>{report.date}</Table.Cell>
                                            <Table.Cell>{report.count_of_rentals}</Table.Cell>
                                            <Table.Cell>{report.gross_revenue_formatted}</Table.Cell>
                                            <Table.Cell>{report.count_of_refunds}</Table.Cell>
                                            <Table.Cell>{report.refund_amount_formatted}</Table.Cell>
                                            <Table.Cell>{report.adjusted_revenue_formatted}</Table.Cell>
                                            <Table.Cell>{report.contribution_amount_formatted}</Table.Cell>
                                            <Table.Cell>{report.net_revenue_formatted}</Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                                {selectedPayoutDetails.total && (
                                    <Table.Row>
                                        <Table.Cell>{selectedPayoutDetails.total.date}</Table.Cell>
                                        <Table.Cell>{selectedPayoutDetails.total.count_of_rentals}</Table.Cell>
                                        <Table.Cell>{selectedPayoutDetails.total.gross_revenue_formatted}</Table.Cell>
                                        <Table.Cell>{selectedPayoutDetails.total.count_of_refunds}</Table.Cell>
                                        <Table.Cell>{selectedPayoutDetails.total.refund_amount_formatted}</Table.Cell>
                                        <Table.Cell>
                                            {selectedPayoutDetails.total.adjusted_revenue_formatted}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {selectedPayoutDetails.total.contribution_amount_formatted}
                                        </Table.Cell>
                                        <Table.Cell>{selectedPayoutDetails.total.net_revenue_formatted}</Table.Cell>
                                    </Table.Row>
                                )}
                            </>
                        ) : (
                            <Loader active inline />
                        )}
                    </Table>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default PayoutReport;
