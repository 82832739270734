import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import moment from 'moment-timezone';
import {
    getPromoCodeAction,
    addPromoCodeAction,
    deletePromoCodeAction,
    getPromoCodeReportsAction,
} from 'actions/actions';
import PromoCode from 'components/Dashboard/Modules/PromoCode';
import MonthYearUtil from 'utils/monthYear';
import useForm from 'hooks/useForm';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId, selectedSpotInfo } from 'selectors';
import { trackPageViewed } from 'utils/analytics';
import { discountCodeClient } from 'clients';
import usePaginatedFilters from 'hooks/usePaginatedFilters';
import { push } from 'connected-react-router';
import Modal, { ModalRef } from 'components/Modal/Modal';
import DiscountCodeModalForm from 'components/Dashboard/DiscountCodeModalForm/DiscountCodeModalForm';

type PromoCodeModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PromoCodeModuleContainer = (props: PromoCodeModuleContainerProps): JSX.Element => {
    const monthYearUtil = new MonthYearUtil();
    const addDiscountCodeModal = useRef<ModalRef>();
    const {
        addPromoCode,
        getPromoCodes,
        deletePromoCode,
        selectedSpotId,
        selectedSpotInfo,
        getPromoCodeReports,
        spotPromoCodes,
        spotPromoCodeReport,
        organizationId,
        profile,
        push: connectedPush,
    } = props;
    const { inputs, handleDropdown } = useForm({ monthYears: monthYearUtil.currentMonthYear.value });
    const [showingUsableCodes, setShowingUsableCodes] = useState(true);
    const { filters, urlUpdater } = usePaginatedFilters<{
        showUsableCodes: string;
    }>({
        filterValidators: {
            showUsableCodes(current) {
                if (current === null) return 'true';
                return current === 'true' ? 'true' : 'false';
            },
        },
        push: connectedPush,
    });

    useEffect(() => {
        const pageNumber = filters.page ? Number(filters.page) : 1;
        const usableCodeFilter = filters.showUsableCodes ? filters.showUsableCodes === 'true' : showingUsableCodes;
        getPromoCodes(pageNumber, usableCodeFilter);
        trackPageViewed({
            pageName: 'Promo Codes',
            organizationId,
            spotId: selectedSpotId,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [
        profile,
        organizationId,
        selectedSpotId,
        getPromoCodes,
        showingUsableCodes,
        filters.showUsableCodes,
        filters.page,
    ]);

    useEffect(() => {
        const [month, year] = inputs.monthYears.split(':');
        getPromoCodeReports({ date: { month, year } });
    }, [selectedSpotId, getPromoCodeReports, inputs]);

    if (props.loading || !selectedSpotInfo) {
        return <Loader active inline />;
    }

    moment.tz.setDefault(selectedSpotInfo.timezone);

    function toggleShowingUsableCodes() {
        urlUpdater.showUsableCodes(!showingUsableCodes ? 'true' : 'false');
        urlUpdater.page('1');
        setShowingUsableCodes(!showingUsableCodes);
    }

    function handlePageChange(pageNumber: number): void {
        urlUpdater.page(pageNumber.toString());
    }

    async function generateQrCode(discountCodePk: number) {
        return discountCodeClient.generateDiscountQrCode(discountCodePk);
    }

    function openAddDiscountModal() {
        addDiscountCodeModal.current?.openModal();
    }

    return (
        <>
            <PromoCode
                errorMessage={props.errorMessage}
                promoCodes={spotPromoCodes}
                deletePromoCode={deletePromoCode}
                promoCodeReport={spotPromoCodeReport}
                handleDropdown={handleDropdown}
                fields={inputs}
                selectedSpotInfo={selectedSpotInfo}
                getPromoCodes={getPromoCodes}
                showingUsableCodes={showingUsableCodes}
                toggleShowingUsableCodes={toggleShowingUsableCodes}
                generateQrCode={generateQrCode}
                handlePageChange={handlePageChange}
                openAddDiscountModal={openAddDiscountModal}
            />

            <Modal ref={addDiscountCodeModal} title={'Create Discount Code'}>
                <DiscountCodeModalForm loading={props.loading} addPromoCode={addPromoCode} />
            </Modal>
        </>
    );
};

function mapStateToProps(state: AppState) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spotId = selectedSpotId(state)!;
    return {
        errorMessage: state.promoCodes.errorMessage,
        loading: state.promoCodes.loading || state.spots.orgSpotsLoading,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpotId: spotId,
        selectedSpotInfo: selectedSpotInfo(state),
        spotPromoCodes: state.promoCodes.promoCodes[spotId],
        spotPromoCodeReport: state.reports.promoCodeReports[spotId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getPromoCodes: getPromoCodeAction,
    addPromoCode: addPromoCodeAction,
    deletePromoCode: deletePromoCodeAction,
    getPromoCodeReports: getPromoCodeReportsAction,
    push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeModuleContainer);
