import { slotClient } from 'clients';
import { SlotsOverview } from 'types';
import { dateWithTimeZone } from 'utils/helpers';

export type RentalsOverview = Record<'todayOverview' | 'thisMonthOverview' | 'thisYearOverview', SlotsOverview>;

export async function getAllRentalsOverview(spotId: string, spotTimeZone: string): Promise<RentalsOverview> {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    function getTodayOverviewPromise() {
        return slotClient.getSlotOverview(
            spotId,
            dateWithTimeZone(spotTimeZone, year, month, day, 0, 0).getTime() / 1000,
            dateWithTimeZone(spotTimeZone, year, month, day + 1, 0, 0).getTime() / 1000
        );
    }

    function getThisMonthOverviewPromise() {
        return slotClient.getSlotOverview(
            spotId,
            dateWithTimeZone(spotTimeZone, year, month, 1, 0, 0).getTime() / 1000,
            dateWithTimeZone(spotTimeZone, year, month + 1, 1, 0, 0).getTime() / 1000
        );
    }

    function getThisYearOverviewPromise() {
        return slotClient.getSlotOverview(
            spotId,
            dateWithTimeZone(spotTimeZone, year, 0, 1, 0, 0).getTime() / 1000,
            dateWithTimeZone(spotTimeZone, year + 1, 0, 1, 0, 0).getTime() / 1000
        );
    }

    const [todayOverview, thisMonthOverview, thisYearOverview] = await Promise.all([
        getTodayOverviewPromise(),
        getThisMonthOverviewPromise(),
        getThisYearOverviewPromise(),
    ]);

    return {
        todayOverview,
        thisMonthOverview,
        thisYearOverview,
    };
}
