import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';

function createTimeBlocks(): SelectOption[] {
    const textHours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
    const amValueHours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
    const pmValueHours = ['12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const minutes = ['00', '15', '30', '45'];
    const amSelectOptions = [];
    const pmSelectOptions = [];

    for (let i = 0; i < textHours.length; i++) {
        for (let j = 0; j < minutes.length; j++) {
            const baseText = `${textHours[i]}:${minutes[j]}`;
            amSelectOptions.push({
                label: `${baseText}am`,
                value: `${amValueHours[i]}:${minutes[j]}:00`,
            });
            pmSelectOptions.push({
                label: `${baseText}pm`,
                value: `${pmValueHours[i]}:${minutes[j]}:00`,
            });
        }
    }
    return [...amSelectOptions, ...pmSelectOptions];
}

export const timeBlocks = createTimeBlocks();

// Set from which year users are allow to select graph data.
export const startYear = 2019;

export const rentalTypesForReports: Record<string, string> = {
    '': 'View all',
    transient: 'Transient rentals',
    monthly: 'Monthly rentals',
    bulk_rental: 'Tenant rentals',
    enterprise: 'Enterprise rentals',
    hourly: 'Hourly rentals',
    'flat rate': 'Flat rate rentals',
    daily: 'Daily rentals',
    hourly_and_monthly: 'Hourly + monthly rentals',
};

export const rentalTypes = Object.keys(rentalTypesForReports).map((key, i) => {
    return {
        value: key,
        text: rentalTypesForReports[key],
        key: i,
    };
});

export const lotSource = [
    { value: 'Account Expansion', text: 'Account Expansion', key: 0 },
    { value: 'Capterra', text: 'Capterra', key: 1 },
    { value: 'Cold Call', text: 'Cold Call', key: 2 },
    { value: 'Cold Email', text: 'Cold Email', key: 3 },
    { value: 'Conference', text: 'Conference', key: 4 },
    { value: 'Direct Mail', text: 'Direct Mail', key: 5 },
    { value: 'Drop In', text: 'Drop In', key: 6 },
    { value: 'Facebook Ads', text: 'Facebook Ads', key: 7 },
    { value: 'Google Ads', text: 'Google Ads', key: 8 },
    { value: 'Local Signage', text: 'Local Signage', key: 9 },
    { value: 'Marketing Email Campaign', text: 'Marketing Email Campaign', key: 10 },
    { value: 'Organic Search', text: 'Organic Search', key: 11 },
    { value: 'Referral', text: 'Referral', key: 12 },
];

export const lotTier = [
    { value: '1', text: '1', key: 0 },
    { value: '2', text: '2', key: 1 },
    { value: '3', text: '3', key: 2 },
];

export const dealTypes = [
    { key: '1', value: 'Fixed lease', text: 'Fixed Lease' },
    { key: '2', value: 'Revenue share', text: 'Revenue Share' },
    { key: '3', value: 'Sdr flip', text: 'Sdr flip' },
    { key: '4', value: 'Minimum guarantee', text: 'Minimum guarantee' },
];

export const classificationTypes = [
    { key: '1', value: 'Paved Driveway', text: 'Paved Driveway' },
    { key: '2', value: 'Gravel/Dirt Driveway', text: 'Gravel/Dirt Drivewayy' },
    { key: '3', value: 'Garage', text: 'Garage' },
    { key: '4', value: 'Carport', text: 'Carport' },
    { key: '5', value: 'Parking Lot', text: 'Parking Lot' },
    { key: '6', value: 'Airport Lot', text: 'Airport Lot' },
];

export enum VisitorValidationSource {
    DASHBOARD = 'dashboard',
    KIOSK = 'kiosk',
}

export enum AffiliationRole {
    ADMIN = 'Admin',
    ENFORCER = 'Enforcer',
    EMPLOYEE = 'Employee',
}

export enum RentType {
    monthly = 'monthly',
    enterprise = 'enterprise',
    daily = 'daily',
    hourly = 'hourly',
    flatRate = 'flat rate',
    freeUntilHourly = 'free_until_hourly',
}

export const QUICKPAY_BASE_URL = 'https://pay.airgarage.com/';

export enum PricingStrategy {
    SURGE_PRICING = 'surge_pricing',
    STANDARD = 'standard',
    EVENT = 'event',
    RATE = 'rate',
}

export enum RateIntervalOption {
    ONE_TIME = 'one_time',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    ALWAYS_ON = 'always_on',
}

export enum RentalType {
    Daily = 'daily',
    Monthly = 'monthly',
    Hourly = 'hourly',
    Flat = 'flat rate',
    FreeUntil = 'free_until_hourly',
}

export enum PricingModelsOptions {
    LINEAR = 'linear',
    STEPWISE = 'stepwise',
    EXPONENTIAL = 'exponential',
    MOVING = 'moving',
    MOVING_TOTAL = 'moving_total',
    CUMULATIVE_TIME = 'cumulative_time',
    STATIC = 'static',
}

export enum SlotState {
    Active = 'ACTIVE',
    Upcoming = 'UPCOMING',
    Ended = 'ENDED',
}

export const DAYS_FOR_REVIEW_REPORT = 30;

export const dayCompleteName: Record<string, string> = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
};

export const getDayFnToDayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const monthCompleteName: Record<string, string> = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
};

export enum InvoiceStatus {
    DRAFT = 'draft',
    OPEN = 'open',
    PAID = 'paid',
    VOID = 'void',
    UNCOLLECTIBLE = 'uncollectible',
    PAST_DUE = 'past due',
    NA = 'n/a',
}

export const anonymousUsername = 'anonymous@user.com';

export const EFEKTA_ORG_PK = 711;
