import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { VisitorCode } from 'types/VisitorCode';
import { ReactComponent as ClipboardIcon } from 'assets/icons/clipboard.svg';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import IconButton from 'components/Button/IconButton';
import PencilIcon from 'components/Icons/PencilIcon';
import styles from './VisitorCodes.module.css';

type VisitorCodesTableProps = {
    visitorCodes: VisitorCode[];
    openEditCodeModal: (code: VisitorCode) => void;
};

function VisitorCodesTable({ visitorCodes, openEditCodeModal }: VisitorCodesTableProps) {
    const [textCopied, setTextCopied] = useState(false);

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text);
        setTextCopied(true);
    }

    function getValidityAndExpiration(visitorCode: VisitorCode): { validity: string; expiration: string } {
        const {
            days_valid: daysValid,
            hours_valid: hoursValid,
            expiration_date: expirationDate,
            event_names: events,
        } = visitorCode;

        if (visitorCode.always_valid) return { validity: 'Always valid', expiration: 'Never' };
        else if (daysValid || hoursValid) {
            const expiration = [];
            if (daysValid) expiration.push(`${daysValid} ${daysValid === 1 ? 'day' : 'days'}`);
            if (hoursValid) expiration.push(`${hoursValid} ${hoursValid === 1 ? 'hour' : 'hours'}`);
            return { validity: 'Temporary code', expiration: expiration.join(' & ') + ' after use' };
        } else if (events?.length > 0)
            return {
                validity: 'For event(s)',
                expiration: `After ${events.length === 1 ? events[0].name : 'all events'}`,
            };
        else if (expirationDate) return { validity: 'Until a certain date', expiration: expirationDate };

        return { validity: '', expiration: '' };
    }

    return (
        <AGTAbleWrapper>
            <thead>
                <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Validity</th>
                    <th>Expiration</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {visitorCodes.length > 0 ? (
                    visitorCodes
                        .sort((a: any, b: any) => b.custom - a.custom || b.pk - a.pk)
                        .map((visitorCode: VisitorCode, index: number) => {
                            const { validity, expiration } = getValidityAndExpiration(visitorCode);
                            return (
                                <tr key={index}>
                                    <td>
                                        <Popup
                                            trigger={
                                                <IconButton
                                                    onClick={() => copyToClipboard(visitorCode.code)}
                                                    className={styles.iconButton}
                                                >
                                                    <ClipboardIcon />
                                                </IconButton>
                                            }
                                            on="hover"
                                            inverted
                                            wide
                                            onClose={() => setTextCopied(false)}
                                        >
                                            {textCopied ? 'Copied!' : 'Copy to clipboard'}
                                        </Popup>

                                        {visitorCode.code}
                                    </td>
                                    <td>{visitorCode.name}</td>
                                    <td>{validity}</td>
                                    <td>{expiration}</td>
                                    <td>
                                        <IconButton onClick={() => openEditCodeModal(visitorCode)}>
                                            <PencilIcon color="black" />
                                        </IconButton>
                                    </td>
                                </tr>
                            );
                        })
                ) : (
                    <tr>
                        <td colSpan={5}>Validation Codes will show up here</td>
                    </tr>
                )}
            </tbody>
        </AGTAbleWrapper>
    );
}

export default VisitorCodesTable;
