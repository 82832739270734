import { GroupedOption, Option, Options } from 'types/SelectInputs';

export function isGroupedOptions(x: object): boolean {
    return Object.keys(x).includes('options');
}

export function valueToSelectOptionTranslator(value: string, options: Options): Option | undefined {
    let selected: Option | undefined;
    if (isGroupedOptions(options)) {
        const groupedOptions = options as GroupedOption[];
        groupedOptions.forEach((groupe) => {
            const selected = groupe.options.find((o) => o.value === value);
            if (selected) {
                return selected;
            }
        });
    } else {
        selected = (options as Option[]).find((o) => o.value === value);
    }
    return selected;
}
