import { Errors, getErrorMessage } from 'components/ValetDashboard/utils/errors';
import { useEffect, useState } from 'react';

function useErrorMessage(errors: Errors) {
    const [error, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        setErrorMessage(getErrorMessage(errors));
    }, [errors]);

    return error;
}

export default useErrorMessage;
