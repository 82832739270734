import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Organization } from 'types';
import { removeDecimalFromString } from 'utils/helpers';
import WidgetBox from '../widgets/WidgetBox';
import WidgetDataLabel from '../widgets/WidgetDataLabel';
import WidgetData from '../widgets/WidgetData';
import DollarIcon from 'components/Icons/DollarIcon';
import EmptyWidgetMessage from 'components/widgets/EmptyWidgetMessage';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
type PayoutWidgetProps = {
    organization: Organization;
};

function PayoutWidget({ organization }: PayoutWidgetProps): JSX.Element {
    const priceIsNotZeroRegex = /[1-9]/gm;
    const hasPayoutData =
        priceIsNotZeroRegex.test(organization.total_payout) || priceIsNotZeroRegex.test(organization.owed_funds);

    return (
        <AuthorizedView allowed={Roles.Admin}>
            <WidgetBox title="Payouts" icon={<DollarIcon />}>
                {hasPayoutData ? (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <WidgetDataLabel label="Lifetime Earnings" />
                                <WidgetData>{removeDecimalFromString(organization.total_payout)}</WidgetData>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <WidgetDataLabel label="Month to Date Earnings" />
                                <WidgetData>{removeDecimalFromString(organization.owed_funds)}</WidgetData>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <EmptyWidgetMessage
                        title="Your payouts will show up here"
                        message="Earnings will appear after you get your first rental"
                    />
                )}
            </WidgetBox>
        </AuthorizedView>
    );
}

export default PayoutWidget;
