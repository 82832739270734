export enum FormErrors {
    Required = 'This is required',
    InvalidPhoneNumber = 'Invalid phone number',
    MissingSelection = 'Please select an option',
    InvalidCode = 'Invalid code. Please try with a different one.',
    InvalidPhoneForValetSession = "Couldn't find a valet session associated with this phone number.",
    SomethingWentWrong = 'Something went wrong. Please try again.',
}

export enum DiscountCodeFormErrors {
    DiscountCodeRequired = 'Discount code is required',
    DiscountCodeMustBeAlphanumeric = 'Discount code must be alphanumeric without spaces',
    DescriptionRequired = 'Description is required',
    DiscountAmountRequired = 'Discount amount is required',
    DiscountAmountInvalidInteger = 'Discount amount is not a valid integer',
    DiscountAmountMustBePositive = 'Discount amount must be greater than 0',
    DiscountPercentageRequired = 'Percentage discount is required',
    DiscountPercentageInvalid = 'Discount amount is not a valid integer',
    DiscountPercentageOutOfRange = 'Percentage discount must be between 0% and 100%',
    DiscountTimeRequired = 'Time discount is required',
    DiscountTimeInvalid = 'Discount amount is not a valid integer',
    DiscountTimeMustBePositive = 'Time discount must be a positive integer',
    ExpirationDateInvalid = 'Expiration date must be today or a future date',
    NumberOfUsesInvalid = 'Number of uses must be greater than zero or empty',
    MaxNumberOfRentalsInvalid = 'Maximum number of active rentals must be greater than zero or empty',
}
