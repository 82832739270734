import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { slotClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';
import { AddSlotParams } from 'clients/SlotClient';
import { RentalType, SlotState } from 'utils/constants';
import { SlotInterface, SpotInterface } from 'types';
import { Changes } from 'components/RenterList/EditRenterForm';
import monthlyRentersReducer, { MonthlyRentalsState, initialMonthlyRentalsState } from './reducer';
import {
    ADD_RENTAL_FAILED,
    ADD_RENTAL_REQUESTED,
    ADD_RENTAL_SUCCESS,
    DELETE_RENTAL_FAILED,
    DELETE_RENTAL_REQUESTED,
    DELETE_RENTAL_SUCCESS,
    EDIT_RENTAL_FAILED,
    EDIT_RENTAL_REQUESTED,
    EDIT_RENTAL_SUCCESS,
    MONTHLY_RENTALS_FAILED,
    MONTHLY_RENTALS_REQUESTED,
    MONTHLY_RENTALS_SUCCESS,
} from './constants';
import unauthorized from '../unauthorized';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import { editRental } from 'tasks/editRental';

interface MonthlyRentalsActions {
    getMonthlyRentals: (spotPk: number, currentFilters: MonthlyRentalsFilter, force?: boolean) => Promise<void>;
    editMonthlyRental: (
        spotPk: number,
        slot: SlotInterface,
        currentFilters: MonthlyRentalsFilter,
        changes: Changes
    ) => Promise<void>;
    addMonthlyRental: (
        newRental: AddSlotParams,
        spot: SpotInterface,
        currentFilters: MonthlyRentalsFilter
    ) => Promise<void>;
    deleteMonthlyRental: (slotUuid: string, spotPk: number, currentFilters: MonthlyRentalsFilter) => Promise<void>;
}

type MonthlyRentalsFilter = {
    page: number;
    search: string;
    slotState: SlotState;
};

interface MonthlyRentalsContextType extends MonthlyRentalsState, MonthlyRentalsActions {}

export const MonthlyRentalsContext = createContext<MonthlyRentalsContextType | null>(null);

export const MonthlyRentalsProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        monthlyRentersReducer,
        { ...initialMonthlyRentalsState },
        [],
        [unauthorized]
    );

    const getMonthlyRentals = useCallback(
        async (spotPk: number, currentFilters: MonthlyRentalsFilter, force: boolean = false) => {
            const { page, slotState: state, search: searchTerm } = currentFilters;
            dispatch({ type: MONTHLY_RENTALS_REQUESTED });

            try {
                const payload = {
                    spotId: spotPk,
                    page,
                    state,
                    searchTerm,
                    rentalType: RentalType.Monthly,
                };

                let monthlyRentals;
                if (force) monthlyRentals = await slotClient.force__getSlots(payload);
                else monthlyRentals = await slotClient.getSlots(payload);

                dispatch({ type: MONTHLY_RENTALS_SUCCESS, payload: { monthlyRentals } });
            } catch (error) {
                dispatch({
                    type: MONTHLY_RENTALS_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch monthly renters' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addMonthlyRental = useCallback(
        async (newRental: AddSlotParams, spot: SpotInterface, currentFilters: MonthlyRentalsFilter) => {
            dispatch({ type: ADD_RENTAL_REQUESTED });

            try {
                await slotClient.addSlot({ ...newRental, spot: spot.uuid });
                dispatch({
                    type: ADD_RENTAL_SUCCESS,
                });
                getMonthlyRentals(spot.pk, currentFilters, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not update rental';
                }
                dispatch({ type: ADD_RENTAL_FAILED, payload: { message: errorMessage } });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const editMonthlyRental = useCallback(
        async (spotPk: number, slot: SlotInterface, currentFilters: MonthlyRentalsFilter, changes: Changes) => {
            dispatch({ type: EDIT_RENTAL_REQUESTED });
            try {
                await editRental(slot, changes);

                dispatch({ type: EDIT_RENTAL_SUCCESS });
            } catch (e) {
                let errorMessage;
                if (HTTPError.isHTTPError(e)) {
                    errorMessage = e.message;
                } else {
                    errorMessage = e?.toString() || 'Could not update rental';
                }
                dispatch({ type: EDIT_RENTAL_FAILED, payload: { message: errorMessage } });
                return;
            }

            getMonthlyRentals(spotPk, currentFilters, true);
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const deleteMonthlyRental = useCallback(
        async (slotUuid: string, spotPk: number, currentFilters: MonthlyRentalsFilter) => {
            dispatch({ type: DELETE_RENTAL_REQUESTED });
            try {
                await slotClient.deleteSlot(slotUuid);
                dispatch({ type: DELETE_RENTAL_SUCCESS });
                getMonthlyRentals(spotPk, currentFilters, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not delete rental';
                }
                dispatch({
                    type: DELETE_RENTAL_FAILED,
                    payload: { message: errorMessage },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<MonthlyRentalsContextType>(() => {
        return {
            ...state,
            getMonthlyRentals,
            editMonthlyRental,
            addMonthlyRental,
            deleteMonthlyRental,
        };
    }, [state, getMonthlyRentals, editMonthlyRental, addMonthlyRental, deleteMonthlyRental]);
    return <MonthlyRentalsContext.Provider value={value}>{children}</MonthlyRentalsContext.Provider>;
};

export const useMonthlyRentals = () => {
    const context = useContext(MonthlyRentalsContext);
    if (!context) {
        throw new Error('Error: useMonthlyRentals should be wrapped by MonthlyRentalsProvider.');
    }
    return context;
};
