import React from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import { PricingPayload, ReportInterface, SpotInterface } from 'types';
import WidgetBox from '../widgets/WidgetBox';
import DynamicPricingUplift from './DynamicPricingUplift';
import CurrentRates from './CurrentRates';
import PriceGraphWrapper from './PriceGraphWrapper';
import StarsIcon from 'components/Icons/StarsIcon';
import LiveUpdateLabel from 'components/widgets/LiveUpdateLabel';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';

type DynamicPricingWidgetProps = {
    spot: SpotInterface;
    multidayRates: PricingPayload[] | null;
    loadingRates: boolean;
    rateErrorMessage: string;
    report: ReportInterface | null;
    isReportLoading: boolean;
    updateReportsLoading: boolean;
};

function DynamicPricingWidget({
    spot,
    multidayRates,
    loadingRates,
    rateErrorMessage,
    report,
    isReportLoading,
    updateReportsLoading,
}: DynamicPricingWidgetProps): JSX.Element {
    return (
        <WidgetBox
            title="Dynamic Pricing"
            icon={<StarsIcon />}
            updatesLabel={<LiveUpdateLabel loading={updateReportsLoading} />}
        >
            <Grid>
                <Grid.Column>
                    {loadingRates || isReportLoading ? (
                        <Loader active inline="centered" />
                    ) : (
                        <>
                            <AuthorizedView allowed={Roles.Admin}>
                                <DynamicPricingUplift report={report} />
                            </AuthorizedView>
                            <Grid.Row>
                                <PriceGraphWrapper
                                    rates={multidayRates}
                                    errorMessage={rateErrorMessage}
                                    timezone={spot.timezone}
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <CurrentRates spot={spot} rates={multidayRates} />
                            </Grid.Row>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </WidgetBox>
    );
}

export default DynamicPricingWidget;
