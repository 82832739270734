import React from 'react';
import { Button, Header, Message, Table } from 'semantic-ui-react';
import PayoutAccountInfo from '../PayoutAccountInfo/PayoutAccountInfo';
import PayoutReport from '../PayoutAccountInfo/PayoutReport';
import PayoutsService, { PayoutInterface } from 'services/PayoutsService';
import { PayoutAccountInfoInterface, PayoutDetailInterface } from 'types/Payouts';

interface PayoutAccountInfoProps {
    payouts: PayoutInterface[] | null;
    selectedPayoutDetails: PayoutDetailInterface | null;
    payoutAccountInfo: PayoutAccountInfoInterface | null;
    getSelectedPayoutDetails: any;
    addPayoutAccountInfo: any;
    organization: any;
    errorMessage: string;
    successMessage: string;
    loading: boolean;
    payoutDetailsLoading: boolean;
}

const PayoutsView = (props: PayoutAccountInfoProps) => {
    const downloadPayouts = () => {
        PayoutsService.downloadMonthlyPayouts(props.organization.uuid);
    };

    return (
        <div>
            {props.payoutAccountInfo && (
                <>
                    {props.successMessage && <Message color="green">{props.successMessage}</Message>}
                    {props.errorMessage && <Message color="red">{props.errorMessage}</Message>}
                    <PayoutAccountInfo
                        organization={props.organization}
                        info={props.payoutAccountInfo}
                        addInfo={props.addPayoutAccountInfo}
                    />
                </>
            )}
            <Table basic="very">
                <Table.Row>
                    <Table.Cell width={10}>
                        <Header as="h2" content="History" />
                    </Table.Cell>
                    <Table.Cell width={6}>
                        {props.organization && (
                            <Button
                                disabled={false}
                                color="green"
                                icon="download"
                                content="Download Monthly Payouts"
                                fluid
                                onClick={() => downloadPayouts()}
                            />
                        )}
                    </Table.Cell>
                </Table.Row>
            </Table>

            <Table basic="very" striped selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Statement Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {props.payouts &&
                    props.payouts.map((payout: PayoutInterface, index) => {
                        return (
                            <Table.Body key={index}>
                                <Table.Row>
                                    <Table.Cell>{payout.local_date}</Table.Cell>
                                    <Table.Cell>
                                        <PayoutReport
                                            payout={payout}
                                            payoutDetailsLoading={props.payoutDetailsLoading}
                                            selectedPayoutDetails={props.selectedPayoutDetails}
                                            getSelectedPayoutDetails={props.getSelectedPayoutDetails}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{payout.statement_descriptor}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        );
                    })}
            </Table>
        </div>
    );
};

export default PayoutsView;
