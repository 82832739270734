import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './colors.css';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration, replayIntegration, replayCanvasIntegration } from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import env from 'env';

Sentry.init({
    dsn: 'https://5690d7194cf2422b92553e72df74c1d5@sentry.io/1833815',
    enabled: process.env.NODE_ENV !== 'development',
    environment: process.env.NODE_ENV,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    integrations: [
        browserTracingIntegration(),
        replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
        replayCanvasIntegration(),
    ],
    tracesSampleRate: 0.2,
    release: `owner-dashboard@${env.PACKAGE_VERSION}`,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
