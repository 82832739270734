import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { visitorCodesClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';

import {
    ADD_VISITOR_CODE_FAILED,
    ADD_VISITOR_CODE_REQUESTED,
    ADD_VISITOR_CODE_SUCCESS,
    DELETE_VISITOR_CODE_FAILED,
    DELETE_VISITOR_CODE_REQUESTED,
    DELETE_VISITOR_CODE_SUCCESS,
    EDIT_VISITOR_CODE_FAILED,
    EDIT_VISITOR_CODE_REQUESTED,
    EDIT_VISITOR_CODE_SUCCESS,
    REFRESH_VISITOR_CODES_FAILED,
    REFRESH_VISITOR_CODES_REQUESTED,
    REFRESH_VISITOR_CODES_SUCCESS,
    VISITOR_CODES_FAILED,
    VISITOR_CODES_REQUESTED,
    VISITOR_CODES_SUCCESS,
} from './constants';
import unauthorized from '../unauthorized';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import visitorCodesReducer, { VisitorCodesState, initialVisitorCodesState } from './reducer';
import { getFormattedDateYYYY_MM_DD } from 'utils/helpers';

interface VisitorCodesActions {
    getVisitorCodes: (spotPk: number, force?: boolean) => Promise<void>;
    refreshVisitorCodes: (spotPk: number) => Promise<void>;
    editVisitorCode: (visitorCode: VisitorCodeForm, spotPk: number) => Promise<void>;
    addVisitorCode: (newVisitorCode: VisitorCodeForm, spotPk: number) => Promise<void>;
    deleteVisitorCode: (visitorCodePk: number, spotPk: number) => Promise<void>;
}

export type VisitorCodeForm = {
    name: string;
    code: string;
    always_valid?: boolean;
    days_valid?: number | null;
    hours_valid?: number | null;
    expiration_date?: string | null;
    events: string[];
    pk: number;
};

interface VisitorCodesContextType extends VisitorCodesState, VisitorCodesActions {}

const VisitorCodesContext = createContext<VisitorCodesContextType | null>(null);

export const VisitorCodesProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        visitorCodesReducer,
        { ...initialVisitorCodesState },
        [],
        [unauthorized]
    );

    const getVisitorCodes = useCallback(
        async (spotPk: number, force: boolean = false) => {
            dispatch({ type: VISITOR_CODES_REQUESTED });

            try {
                let visitorCodes;
                if (force) visitorCodes = await visitorCodesClient.force__getVisitorCodes(spotPk);
                else visitorCodes = await visitorCodesClient.getVisitorCodes(spotPk);

                dispatch({ type: VISITOR_CODES_SUCCESS, payload: { visitorCodes } });
            } catch (error) {
                dispatch({
                    type: VISITOR_CODES_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch visitor codes' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const refreshVisitorCodes = useCallback(
        async (spotPk: number) => {
            dispatch({ type: REFRESH_VISITOR_CODES_REQUESTED });

            try {
                await visitorCodesClient.refreshVisitorCodes(spotPk);
                const visitorCodes = await visitorCodesClient.force__getVisitorCodes(spotPk);

                dispatch({ type: REFRESH_VISITOR_CODES_SUCCESS, payload: { visitorCodes } });
            } catch (error) {
                dispatch({
                    type: REFRESH_VISITOR_CODES_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch visitor codes' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addVisitorCode = useCallback(
        async (newVisitorCode: VisitorCodeForm, spotPk: number) => {
            dispatch({ type: ADD_VISITOR_CODE_REQUESTED });

            try {
                const visitorData = {
                    ...newVisitorCode,
                    spot: spotPk,
                    custom: true,
                    expiration_date: newVisitorCode.expiration_date
                        ? getFormattedDateYYYY_MM_DD(new Date(newVisitorCode.expiration_date))
                        : null,
                    events: newVisitorCode.events.map((e) => Number(e)),
                };

                await visitorCodesClient.addVisitorCode(visitorData);

                dispatch({
                    type: ADD_VISITOR_CODE_SUCCESS,
                });
                getVisitorCodes(spotPk, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not add visitor code';
                }
                dispatch({ type: ADD_VISITOR_CODE_FAILED, payload: { message: errorMessage } });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const editVisitorCode = useCallback(
        async (visitorCode: VisitorCodeForm, spotPk: number) => {
            dispatch({ type: EDIT_VISITOR_CODE_REQUESTED });
            try {
                const visitorData = {
                    ...visitorCode,
                    spot: spotPk,
                    custom: true,
                    expiration_date: visitorCode.expiration_date
                        ? getFormattedDateYYYY_MM_DD(new Date(visitorCode.expiration_date))
                        : null,
                    events: visitorCode.events.map((e) => Number(e)),
                };
                await visitorCodesClient.editVisitorCode(visitorData, visitorData.pk);

                dispatch({ type: EDIT_VISITOR_CODE_SUCCESS });
                getVisitorCodes(spotPk, true);
            } catch (e) {
                let errorMessage;
                if (HTTPError.isHTTPError(e)) {
                    errorMessage = e.message;
                } else {
                    errorMessage = e?.toString() || 'Could not update visitor code';
                }
                dispatch({ type: EDIT_VISITOR_CODE_FAILED, payload: { message: errorMessage } });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const deleteVisitorCode = useCallback(
        async (visitorCodePk: number, spotPk: number) => {
            dispatch({ type: DELETE_VISITOR_CODE_REQUESTED });
            try {
                await visitorCodesClient.deleteVisitorCode(visitorCodePk);
                dispatch({ type: DELETE_VISITOR_CODE_SUCCESS });
                getVisitorCodes(spotPk, true);
            } catch (error) {
                let errorMessage;
                if (HTTPError.isHTTPError(error)) {
                    errorMessage = error.message;
                } else {
                    errorMessage = error?.toString() || 'Could not delete visitor code';
                }
                dispatch({
                    type: DELETE_VISITOR_CODE_FAILED,
                    payload: { message: errorMessage },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<VisitorCodesContextType>(() => {
        return {
            ...state,
            getVisitorCodes,
            addVisitorCode,
            editVisitorCode,
            deleteVisitorCode,
            refreshVisitorCodes,
        };
    }, [state, getVisitorCodes, addVisitorCode, editVisitorCode, deleteVisitorCode, refreshVisitorCodes]);

    return <VisitorCodesContext.Provider value={value}>{children}</VisitorCodesContext.Provider>;
};

export const useVisitorsCode = () => {
    const context = useContext(VisitorCodesContext);
    if (!context) {
        throw new Error('Error: useVisitorCodes should be wrapped by VisitorsCodeProvider.');
    }
    return context;
};
