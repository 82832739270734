import React from 'react';
import { RateCalendarRecord } from 'types/Rate';
import { PricingModelsOptions } from 'utils/constants';
import { convertCentsToDisplayPrice } from 'utils/currency';
import { formatDate } from 'utils/helpers';
import styles from './RatesCalendar.module.css';

function RateInfo({ rate }: { rate: RateCalendarRecord }) {
    const startDate = new Date(rate.start);

    return (
        <div className={styles.modal}>
            <p className={styles.date}>
                {formatDate(startDate)}, {startDate.getFullYear()}
            </p>
            <div className={styles.content}>
                <p>
                    Pricing Model:{' '}
                    <span>{rate.pricing_model_type?.replace('_', ' ') || PricingModelsOptions.STATIC}</span>
                </p>
                <p>
                    Price:{' '}
                    <span>
                        {convertCentsToDisplayPrice(rate.price)} {rate.unit}
                    </span>
                </p>
                <p>
                    Schedule: <span>{rate.interval.replace('_', ' ')}</span>
                </p>
                <p>
                    Priority: <span>{rate.priority}</span>
                </p>
            </div>
        </div>
    );
}

export default RateInfo;
