import React, { useEffect, useRef, useState } from 'react';
import { useVisitorsCode } from 'contexts/VisitorCodesContext';
import { useEvents } from 'contexts/EventsContext';
import { resetCalendarRange } from 'utils/helpers';
import { VisitorCode } from 'types/VisitorCode';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import Modal, { ModalRef } from 'components/Modal/Modal';
import VisitorCodeModalForm from 'components/Dashboard/VisitorCodeModalForm/VisitorCodeModalForm';
import AGMessage from 'components/AGMessage/AGMessage';
import VisitorCodesTable from './VisitorCodesTable';
import styles from './VisitorCodes.module.css';

type VisitorCodesModuleProps = {
    selectedSpot: number;
};

function VisitorCodesModule({ selectedSpot }: VisitorCodesModuleProps) {
    const [selectedCode, setSelectedCode] = useState<VisitorCode | null>(null);

    const codeFormModalRef = useRef<ModalRef>();
    const {
        errorMessage,
        loading,
        visitorCodeOperationLoading,
        visitorCodeOperationErrorMessage,
        addVisitorCode,
        editVisitorCode,
        deleteVisitorCode,
        visitorCodes,
        refreshVisitorCodes: refreshCodes,
        getVisitorCodes,
    } = useVisitorsCode();

    const { events, getEvents, loading: eventsLoading } = useEvents();

    function openModal() {
        codeFormModalRef.current?.openModal();
    }

    function openEditCodeModal(code: VisitorCode) {
        setSelectedCode(code);
        openModal();
    }

    function onSubmitForm() {
        if (selectedCode) setSelectedCode(null);
        codeFormModalRef.current?.closeModal();
    }
    useEffect(() => {
        getVisitorCodes(selectedSpot);
        // TODO: what rage of events should we show for the validation codes form?
        const { start, end } = resetCalendarRange();
        getEvents(selectedSpot, { start, end });
    }, [selectedSpot, getVisitorCodes, getEvents]);

    if (eventsLoading) return <Loader />;

    return (
        <div>
            {(errorMessage || visitorCodeOperationErrorMessage) && (
                <AGMessage color="error" title={errorMessage || visitorCodeOperationErrorMessage} />
            )}
            <div className={styles.buttonGroup}>
                <Button onClick={openModal} size="sm">
                    + Add Validation Code
                </Button>
                <Button onClick={() => refreshCodes(selectedSpot)} size="sm" color="secondary">
                    <RefreshIcon />
                    Refresh Temporary Codes
                </Button>
            </div>

            {loading ? (
                <Loader />
            ) : (
                <VisitorCodesTable visitorCodes={visitorCodes} openEditCodeModal={openEditCodeModal} />
            )}

            <Modal
                ref={codeFormModalRef}
                title={selectedCode ? 'Edit Validation Code' : 'Add Validation Code'}
                onClose={() => {
                    if (selectedCode) setSelectedCode(null);
                }}
            >
                <VisitorCodeModalForm
                    events={events}
                    addCode={addVisitorCode}
                    editCode={editVisitorCode}
                    deleteCode={deleteVisitorCode}
                    onFinish={onSubmitForm}
                    visitorCode={selectedCode}
                    selectedSpot={selectedSpot}
                    loading={visitorCodeOperationLoading}
                />
            </Modal>
        </div>
    );
}

export default VisitorCodesModule;
