import React, { useState } from 'react';
import { Image, Loader } from 'semantic-ui-react';
import WidgetBox from 'components/widgets/WidgetBox';
import useModal from 'hooks/useModal';
import AGMessage from 'components/AGMessage/AGMessage';
import SearchInput from 'components/AGForm/Inputs/SearchInput/SearchInput';
import TicketLookupModal from '../../TicketLookup/TicketLookupModal';
import Ticket from '../../../../types/Ticket';
import PencilIcon from 'components/Icons/PencilIcon';
import Button from 'components/Button/Button';
import styles from './TicketLookup.module.css';

type TicketModuleProps = {
    loading: boolean;
    successMessage: string;
    errorMessage: string;
    getTicket: (identifier: string) => void;
    ticket: Ticket;
    waiveTicket: (pk: string) => void;
};

const TicketLookup = (props: TicketModuleProps) => {
    const [ticketID, setTicketID] = useState('');
    const { isShowing, toggle } = useModal();

    const waiveTicket = () => {
        toggle();
        setTicketID('');
        props.waiveTicket(props.ticket.uuid);
    };
    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (ticketID.length >= 3) {
                        props.getTicket(ticketID);
                    }
                }}
            >
                {props.loading && <Loader inline active />}
                {props.errorMessage && <AGMessage color="error">{props.errorMessage}</AGMessage>}
                <SearchInput
                    value={ticketID}
                    onChange={(e) => setTicketID(e.currentTarget.value)}
                    placeholder="Violation number or plate"
                    className={styles.searchInput}
                />
                <Button size="sm" disabled={ticketID.length < 3} onClick={() => props.getTicket(ticketID)}>
                    Lookup
                </Button>
            </form>
            <TicketLookupModal toggle={toggle} isShowing={isShowing} waiveTicket={waiveTicket} />
            {props.ticket && (
                <div className={styles.card}>
                    <WidgetBox title={`${props.ticket.plate} - ${props.ticket.state}`}>
                        <div>
                            <div className={styles.editIcon}>
                                <div>
                                    <b>Status:</b> {props.ticket.status === 'Created' ? 'Open' : props.ticket.status}
                                    <br />
                                    <b>Violation Number:</b> {props.ticket.code}
                                    <br />
                                    <b>Date Issued:</b>{' '}
                                    {new Date(props.ticket.created_at * 1000).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    })}
                                    <Image src={props.ticket.image_url} />
                                </div>
                                {props.ticket.status === 'Created' && (
                                    <button className={styles.pencilIconButton} onClick={() => toggle()}>
                                        <PencilIcon color="#464646" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </WidgetBox>
                </div>
            )}
        </div>
    );
};
export default TicketLookup;
