import { Chart as ChartJS, ChartOptions, TooltipModel } from 'chart.js';
import { PricingPayload } from 'types';
import { PricingStrategy } from 'utils/constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { toFont } = require('chart.js/helpers');

export const BASELINE_DATASET_INDEX = 0;
export const DYNAMIC_RATE_DATASET_INDEX = 1;
export const FLAT_RATE_DATASET_INDEX = 2;

export type DataTypeOptions = 'currency' | 'percent';

export const getLabel = (yData: number[], dataType?: DataTypeOptions): string => {
    let label = yData[yData.length - 1].toString();
    if (dataType === 'currency') {
        label = '$' + label;
    } else if (dataType === 'percent') {
        label = label + '%';
    }
    return label;
};

export function createChartGradient(ctx: CanvasRenderingContext2D) {
    const colorStart = 'rgba(69, 103, 255,1)';
    const colorMid = 'rgb(242, 125, 255)';
    const colorEnd = 'rgb(250, 130, 76)';

    const gradient = ctx.createLinearGradient(0, 400, 700, 0);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
}

const commonChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const lineChartOptions: ChartOptions<'line'> = {
    ...commonChartOptions,
    elements: {
        line: {
            tension: 0.4, // smooth lines
        },
    },
    scales: {
        x: {
            ticks: {
                autoSkipPadding: 40,
            },
            grid: {
                display: false,
                drawBorder: false,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: () => '',
            },
            grid: {
                display: false,
                drawBorder: false,
            },
        },
    },
};

export const barChartOptions: ChartOptions<'bar'> = {
    ...commonChartOptions,
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                autoSkipPadding: 40,
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: () => '',
            },
            grid: {
                display: false,
                drawBorder: false,
            },
        },
    },
};

export const pieChartOptions: ChartOptions<'doughnut'> = {
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const pieGraphColors = ['#fa824c', '#DD7476', '#DA5ABA', '#A558C6', '#9522DF', '#3C22DF', '#2E65F2', '#3FB4F6'];

export const usdFormatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function dynamicPricingVerticalHoverLine(chartId: string) {
    return {
        id: 'blue-vertical-hover-line',
        beforeDraw(chart: ChartJS<'line'>): void {
            if (chart.ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }
            const activeElements = chart.getActiveElements();
            if (activeElements.length === 0) {
                return;
            }
            const activePoint = activeElements[0];
            const ctx = chart.ctx;
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#2563EB';
            ctx.setLineDash([5, 2]);
            ctx.stroke();
            ctx.restore();
        },
    };
}

export function dynamicPricingBaselineLabel(chartId: string) {
    return {
        id: 'baselineLabel',
        afterDraw(chart: ChartJS<'line'>) {
            const { ctx } = chart;
            if (ctx.canvas.getAttribute('role') !== chartId) {
                return;
            }
            const { controller, data } = chart.getDatasetMeta(BASELINE_DATASET_INDEX);
            if (controller === null || data.length === 0) {
                return;
            }
            ctx.save();
            ctx.textAlign = 'left';
            const styles = controller.getStyle(0, false);
            if (styles) {
                ctx.fillStyle = styles['borderColor'] as string;
                ctx.font = '18px';
                const { x, y } = data[0];
                const firstBaselineRate = `${usdFormatter.format(
                    (chart.data.datasets[BASELINE_DATASET_INDEX].data[0] as number) / 100
                )}`;
                ctx.fillText(`${firstBaselineRate} Baseline Hourly Rate`, x + 2, y + 20);
                ctx.restore();
            }
        },
    };
}

export function dynamicPricingExternalTooltip({
    chart,
    tooltip,
}: {
    chart: ChartJS<'line'>;
    tooltip: TooltipModel<'line'>;
}) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((items) => items.lines);
    if (titleLines.length === 0 || bodyLines.length === 0) {
        return;
    }

    // Tooltip Element
    let tooltipEl = document.getElementById('dynamic-pricing-chart-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'dynamic-pricing-chart-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltip.yAlign) {
        tooltipEl.classList.add(tooltip.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }
    // Set Text
    if (tooltip.body) {
        let innerHtml = '<thead>';

        titleLines.forEach(function (title) {
            innerHtml +=
                '<tr><th style="font-size: 20px; text-align: left; line-height: 32px; margin-bottom: 5px">' +
                title +
                '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        const [label, tooltipFlag] = bodyLines[0];
        innerHtml += `<tr><td style="font-size: 14px;"><span style="padding: 2px 7px 2px 0">${label}</span>`;
        if (tooltipFlag) {
            innerHtml += `<span style="text-align: right; background: #3B8FE3; border-radius: 16px; margin-left: 10px; padding: 2px 7px">${tooltipFlag}</span></td></tr>`;
        } else {
            innerHtml += '</td></tr>';
        }
        innerHtml += '</tbody>';

        const tableRoot = tooltipEl.querySelector('table');
        if (tableRoot) {
            tableRoot.innerHTML = innerHtml;
        }
    }

    const position = chart.canvas.getBoundingClientRect();
    const bodyFont = toFont(tooltip.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.borderRadius = tooltip.options.cornerRadius + 'px';
    tooltipEl.style.backgroundColor = 'rgba(15, 23, 42, 0.7)';
    tooltipEl.style.color = '#FFFFFF';
    tooltipEl.style.padding = '6px 10px';
    tooltipEl.style.pointerEvents = 'none';
}

export function calculateDynamicPriceGraphData(priceInfo: PricingPayload): {
    prices: (number | null)[];
    priceLabels: string[];
    basePrices: (number | null)[];
    tooltipFlags: string[];
    timestamps: string[];
} {
    const prices: (number | null)[] = [];
    const priceLabels: string[] = [];
    const tooltipFlags: string[] = [];
    const basePrices: (number | null)[] = [];
    const timestamps: string[] = [];

    const serverTimestamps = Object.keys(priceInfo);
    serverTimestamps.forEach((timestamp: string, i: number) => {
        const pricingInfo = priceInfo[timestamp];

        const sharedData = {
            basePrice: pricingInfo.base_price,
            timestamp: pricingInfo.local_time,
        };

        const hourlyAndRateSharedData = {
            ...sharedData,
            price: pricingInfo.price,
            priceLabel: `${usdFormatter.format(pricingInfo.price / 100)}/h`,
        };

        const flatSharedData = {
            ...sharedData,
            pricingStrategy: 'flat',
            priceLabel: `${usdFormatter.format(pricingInfo.price / 100)}/day`,
        };

        const pushData = (data: {
            price: number | null;
            priceLabel: string;
            basePrice: number | null;
            pricingStrategy: string;
            timestamp: string;
        }) => {
            prices.push(data.price);
            priceLabels.push(data.priceLabel);
            basePrices.push(data.basePrice);
            tooltipFlags.push(data.pricingStrategy);
            timestamps.push(data.timestamp);
        };

        switch (pricingInfo.strategy) {
            case PricingStrategy.EVENT: {
                // In order to keep the line smooth, we create auxiliary data points with:
                // - Before event starts { x: start time, y: previous price }
                // - After event ends { x: end time, y: next price }

                // Collecting previous and next data points.
                const previousDataPoint = priceInfo[serverTimestamps[i - 1]] || null;
                const nextDataPoint = priceInfo[serverTimestamps[i + 1]] || null;

                if (previousDataPoint && previousDataPoint.strategy !== PricingStrategy.EVENT) {
                    const price = previousDataPoint.price;
                    pushData({ ...flatSharedData, price });
                }

                // Actual event with null price
                pushData({ ...flatSharedData, price: null });

                if (nextDataPoint && nextDataPoint.strategy !== PricingStrategy.EVENT) {
                    const price = nextDataPoint.price;
                    pushData({ ...flatSharedData, price });
                }
                break;
            }
            case PricingStrategy.STANDARD: {
                pushData({ ...hourlyAndRateSharedData, pricingStrategy: 'hourly' });
                break;
            }
            case PricingStrategy.RATE: {
                const pricingStrategy = `${pricingInfo.multiplier.toString()}x`;
                pushData({ ...hourlyAndRateSharedData, pricingStrategy });
                break;
            }
        }
    });

    return {
        prices,
        priceLabels,
        tooltipFlags,
        basePrices,
        timestamps,
    };
}
