import {
    GET_TICKET_REQUESTED,
    GET_TICKET_FAILED,
    GET_TICKET_SUCCESS,
    WAIVE_TICKET_REQUESTED,
    WAIVE_TICKET_FAILED,
    WAIVE_TICKET_SUCCESS,
} from '../actions/constants';

import Ticket from '../types/Ticket';

type TicketState = {
    ticket: Ticket | null;
    errorMessage: string;
    successMessage: string;
    loading: boolean;
};

export const initialTicketState: TicketState = {
    ticket: null,
    errorMessage: '',
    successMessage: '',
    loading: false,
};

export default function ticketReducer(state = initialTicketState, action: any) {
    switch (action.type) {
        case GET_TICKET_REQUESTED:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errorMessage: '',
            };
        case GET_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                ticket: action.payload.ticket,
                errorMessage: '',
            };
        case GET_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                ticket: null,
                errorMessage: action.message,
            };
        case WAIVE_TICKET_REQUESTED:
            return {
                ...state,
                loading: true,
                successMessage: '',
                errorMessage: '',
            };
        case WAIVE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                ticket: null,
                successMessage: 'Ticket has been waived.',
                errorMessage: '',
            };
        case WAIVE_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.message,
                successMessage: '',
            };
        default:
            return state;
    }
}
