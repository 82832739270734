import { useCallback, useEffect, useRef, useState } from 'react';
import { Push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';

export type UrlFilters<T extends string = string> = Record<string, T>;
export type FilterValidators<T extends UrlFilters> = Record<keyof T, (value: string | null) => string>;
export type UrlUpdaterFunctions<T extends UrlFilters> = Record<keyof T, (value: string | null) => void>;

// UseFiltersParams - the generic is a key-value of filters {filterName: filterValue} that
// are synchronized with the url search parameters.
export type UseFiltersParams<T extends UrlFilters> = {
    // filterValidators: keys must be the same as Filters' keys. Values are Validator functions
    filterValidators: FilterValidators<T>;
    push: Push;
};

type UseFiltersReturn<T extends UrlFilters> = {
    filters: T;
    urlUpdater: UrlUpdaterFunctions<T>;
};

export default function useFilters<Filters extends UrlFilters>({
    filterValidators,
    push,
}: UseFiltersParams<Filters>): UseFiltersReturn<Filters> {
    const { search } = useLocation();

    const [currentSearchParams, setCurrentSearchParams] = useState(search.replace('?', ''));
    const currentQueryParamRef = useRef<URLSearchParams>(new URLSearchParams(search));

    const urlUpdater: any = {};
    const filters: any = {};

    const validateFilters = useCallback(
        function _validateFilters(proposedParams: URLSearchParams) {
            for (const [k, validator] of Object.entries(filterValidators)) {
                const searchParamValue = proposedParams.get(k);
                const validatedValue = validator(searchParamValue);
                if (searchParamValue !== validatedValue) {
                    proposedParams.set(k, validatedValue);
                }
            }
        },
        [filterValidators]
    );

    validateFilters(currentQueryParamRef.current);

    for (const [k] of Object.entries(filterValidators)) {
        const filterKey = k as keyof Filters;
        filters[filterKey] = currentQueryParamRef.current.get(filterKey as string) as Filters[keyof Filters];

        urlUpdater[filterKey] = (newValue: string | null) => {
            if (currentQueryParamRef.current.get(k) === newValue) {
                return;
            }
            if (newValue === null) {
                currentQueryParamRef.current.delete(k);
            } else {
                currentQueryParamRef.current.set(k, newValue as string);
            }
            validateFilters(currentQueryParamRef.current);
            setCurrentSearchParams(currentQueryParamRef.current.toString());
        };
    }

    useEffect(() => {
        validateFilters(currentQueryParamRef.current);
        setCurrentSearchParams(currentQueryParamRef.current.toString());
    }, [validateFilters]);

    useEffect(() => {
        if (search.split('?')[1] !== currentSearchParams) {
            push({
                search: currentSearchParams,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSearchParams, push]);

    useEffect(() => {
        if (search.split('?')[1] !== currentSearchParams) {
            const newUrl = new URLSearchParams(search);
            validateFilters(newUrl);
            setCurrentSearchParams(newUrl.toString());
            currentQueryParamRef.current = newUrl;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (currentSearchParams !== currentQueryParamRef.current.toString()) {
            currentQueryParamRef.current = new URLSearchParams(currentSearchParams);
        }
    }, [currentSearchParams]);

    return {
        filters,
        urlUpdater,
    };
}
