import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { slotClient } from 'clients';

import { SlotInterface } from 'types';
import { editRental } from 'tasks/editRental';
import { HTTPError } from 'clients/HTTPClient';
import { Changes } from 'components/RenterList/EditRenterForm';
import { RentalType, SlotState } from 'utils/constants';
import unauthorized from '../unauthorized';
import useReducerWithMiddleware from '../useReducerWithMiddleware';
import dailyRentalsReducer, { DailyRentalsState, initialDailyRentalsState } from './reducer';
import {
    DAILY_RENTALS_FAILED,
    DAILY_RENTALS_REQUESTED,
    DAILY_RENTALS_SUCCESS,
    EDIT_DAILY_RENTAL_FAILED,
    EDIT_DAILY_RENTAL_REQUESTED,
    EDIT_DAILY_RENTAL_SUCCESS,
} from './constants';

interface DailyRentalsActions {
    getDailyRentals: (spotPk: number, currentFilters: DailyRentalsFilter) => Promise<void>;
    editDailyRental: (
        spotPk: number,
        slot: SlotInterface,
        currentFilters: DailyRentalsFilter,
        changes: Changes
    ) => Promise<void>;
}

type DailyRentalsFilter = {
    page: number;
    search: string;
    slotState: SlotState;
    rentalType: RentalType;
};

interface DailyRentalsContextType extends DailyRentalsState, DailyRentalsActions {}

export const DailyRentalsContext = createContext<DailyRentalsContextType | null>(null);

export const DailyRentalsProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        dailyRentalsReducer,
        { ...initialDailyRentalsState },
        [],
        [unauthorized]
    );

    const getDailyRentals = useCallback(
        async (spotPk: number, currentFilters: DailyRentalsFilter, force: boolean = false) => {
            const { page, slotState: state, search: searchTerm, rentalType } = currentFilters;
            dispatch({ type: DAILY_RENTALS_REQUESTED });

            try {
                const payload = {
                    spotId: spotPk,
                    page,
                    state,
                    rentalType,
                    searchTerm,
                };

                let dailyRentals;
                if (force) dailyRentals = await slotClient.force__getSlots(payload);
                else dailyRentals = await slotClient.getSlots(payload);

                dispatch({ type: DAILY_RENTALS_SUCCESS, payload: { dailyRentals } });
            } catch (error) {
                dispatch({
                    type: DAILY_RENTALS_FAILED,
                    payload: { error, message: error?.toString() || 'Could not fetch daily renters' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const editDailyRental = useCallback(
        async (spotPk: number, slot: SlotInterface, currentFilters: DailyRentalsFilter, changes: Changes) => {
            dispatch({ type: EDIT_DAILY_RENTAL_REQUESTED });
            try {
                await editRental(slot, changes);

                dispatch({ type: EDIT_DAILY_RENTAL_SUCCESS });
            } catch (e) {
                let errorMessage;
                if (HTTPError.isHTTPError(e)) {
                    errorMessage = e.message;
                } else {
                    errorMessage = e?.toString() || 'Could not update rental';
                }
                dispatch({ type: EDIT_DAILY_RENTAL_FAILED, payload: { message: errorMessage } });
                return;
            }

            getDailyRentals(spotPk, currentFilters, true);
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const value = useMemo<DailyRentalsContextType>(() => {
        return {
            ...state,
            getDailyRentals,
            editDailyRental,
        };
    }, [state, getDailyRentals, editDailyRental]);
    return <DailyRentalsContext.Provider value={value}>{children}</DailyRentalsContext.Provider>;
};

export const useDailyRentals = () => {
    const context = useContext(DailyRentalsContext);
    if (!context) {
        throw new Error('Error: useDailyRentals should be wrapped by DailyRentalsProvider.');
    }
    return context;
};
