import React from 'react';

const StarsIcon = ({ color, ...restProps }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M18.792 8.37533L20.0941 5.51074L22.9587 4.20866L20.0941 2.90658L18.792 0.0419922L17.4899 2.90658L14.6253 4.20866L17.4899 5.51074L18.792 8.37533Z"
                fill={color || '#717680'}
            />
            <path
                d="M18.792 14.6253L17.4899 17.4899L14.6253 18.792L17.4899 20.0941L18.792 22.9587L20.0941 20.0941L22.9587 18.792L20.0941 17.4899L18.792 14.6253Z"
                fill={color || '#717680'}
            />
            <path
                d="M10.9795 8.89616L8.37533 3.16699L5.77116 8.89616L0.0419922 11.5003L5.77116 14.1045L8.37533 19.8337L10.9795 14.1045L16.7087 11.5003L10.9795 8.89616ZM9.40658 12.5316L8.37533 14.8024L7.34408 12.5316L5.07324 11.5003L7.34408 10.4691L8.37533 8.19824L9.40658 10.4691L11.6774 11.5003L9.40658 12.5316Z"
                fill={color || '#717680'}
            />
        </svg>
    );
};

export default StarsIcon;
