import { SpotInterface } from 'types';

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function getSpotSelectOptions(orgSpots: Record<string, SpotInterface>) {
    const orgSpotsKeys = Object.keys(orgSpots);
    const selectOptions =
        orgSpotsKeys.length > 1
            ? [
                  {
                      value: '',
                      label: 'All Properties',
                  },
                  ...orgSpotsKeys.map((pk) => ({ value: pk, label: orgSpots[pk].name })),
              ]
            : undefined;

    return selectOptions;
}

export function getDateRangeKey(startDate: string, endDate: string) {
    return `${startDate}-${endDate}`;
}

export const ENTERPRISE_TOOLTIP_TEXT =
    'Enterprise includes non-parking revenue as well as partnerships with car share companies, EV charging, Fleet storage, and other businesses.';
