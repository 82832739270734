import React, { useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';

import Button from 'components/Button/Button';
import SingleDateSelector from 'components/SingleDateSelector/SingleDateSelector';
import { useLotTimeline } from 'contexts/LotTimelineContext';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { SpotInterface } from 'types';
import { getDayFnToDayName } from 'utils/constants';
import CollapsibleList from './CollapsibleList/CollapsibleList';
import styles from './timeline.module.css';

type TimelineWidgetProps = {
    spot: SpotInterface;
};

function TimelineWidget({ spot }: TimelineWidgetProps): JSX.Element {
    const [date, setDate] = useState<Date | undefined>();
    const {
        timeline: events,
        hasNext,
        errorMessage,
        loading,
        getLotTimeline,
        getFilteredLotTimeline,
    } = useLotTimeline();

    function clearFilters() {
        setDate(undefined);
    }

    const loadMore = React.useCallback(
        function getNext() {
            const lastDate = events[events.length - 1].timestamp;
            void getLotTimeline(spot.uuid, lastDate);
        },
        [events, getLotTimeline, spot.uuid]
    );

    const getFilteredTimeline = React.useCallback(
        function filterLotTimeline(date: Date) {
            const includeOffsetDate = new Date(date);
            // When selecting the 27th, the endpoint will retrieve events starting from the 26th due to the offset used for pagination.
            // Adding +1 ensures that the day you select is included in the data fetched.
            includeOffsetDate.setDate(date.getDate() + 1);
            const isoData = includeOffsetDate.toISOString();
            void getFilteredLotTimeline(spot.uuid, isoData);
        },
        [spot.uuid, getFilteredLotTimeline]
    );

    const target = useInfiniteScroll({ loadMore });

    const dayBlocks: DayBlock[] = React.useMemo(() => {
        const dayBlockList: DayBlock[] = [];

        for (const event of events) {
            const eventDate = new Date(event.timestamp);
            const date = eventDate.getDate();
            const month = eventDate.getMonth();
            const year = eventDate.getFullYear();
            const day = eventDate.getDay();
            const dayName = getDayFnToDayName[day].slice(0, 3);

            const dayBlock: DayBlock = { dayName, dayNum: date, entries: [event], date: eventDate };

            // If the list is empty, create the first DayBlock
            if (dayBlockList.length === 0) {
                dayBlockList.push(dayBlock);
            } else {
                const lastDayBlock = dayBlockList[dayBlockList.length - 1];

                const isDifferentDay =
                    lastDayBlock.date.getDate() !== date ||
                    lastDayBlock.date.getMonth() !== month ||
                    lastDayBlock.date.getFullYear() !== year;

                if (isDifferentDay) {
                    dayBlockList.push(dayBlock);
                } else {
                    lastDayBlock.entries.push(event);
                }
            }
        }

        return dayBlockList;
    }, [events]);

    React.useEffect(() => {
        if (date) {
            void getFilteredTimeline(date);
        } else {
            void getLotTimeline(spot.uuid);
        }
    }, [date, spot.uuid, getLotTimeline, getFilteredTimeline]);

    if (dayBlocks.length === 0 && loading) {
        return <Loader active inline="centered" />;
    }

    return (
        <div className={styles.timelineWidget}>
            {dayBlocks.length === 0 && !errorMessage && !date ? (
                <p className={styles.end}>There are no events in this property's timeline yet...</p>
            ) : (
                <>
                    <div className={styles.timelineFilters}>
                        <SingleDateSelector
                            date={date}
                            onSave={(date) => setDate(date)}
                            placeHolder="Jump to date ..."
                            latestAvailableDate={new Date()}
                        />
                        {!!date && (
                            <Button size="sm" className={styles.clearFilterWrapper} onClick={clearFilters}>
                                Clear Filters
                            </Button>
                        )}
                    </div>
                    <ul className={styles.timelineWrapper}>
                        <CollapsibleList items={dayBlocks} />
                        {hasNext ? (
                            <span ref={target} id="scroll-target" />
                        ) : (
                            <p className={styles.end}>You have reached the end of the timeline.</p>
                        )}
                        {loading && <Loader active inline="centered" />}
                    </ul>
                </>
            )}
            {errorMessage && <Message color="red">{errorMessage}</Message>}
        </div>
    );
}

export default TimelineWidget;
