import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getAggregatorReportsAction } from 'actions/actions';
import Rentals from 'components/Dashboard/Modules/Rentals';
import useForm from 'hooks/useForm';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId, selectedSpotId } from 'selectors';
import MonthYearUtil from 'utils/monthYear';
import { trackPageViewed } from 'utils/analytics';

type RentalsModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const RentalsModuleContainer = (props: RentalsModuleContainerProps) => {
    const monthYearUtil = new MonthYearUtil();
    const { aggregatorReports, organizationId, selectedSpot, profile, getAggregatorReports, loading } = props;
    const spotReports = aggregatorReports && aggregatorReports[selectedSpot];
    const { inputs, handleDropdown } = useForm({ monthYear: monthYearUtil.currentMonthYear.value });

    useEffect(() => {
        const [month, year] = inputs.monthYear.split(':');
        if (selectedSpot) {
            getAggregatorReports({
                date: {
                    month,
                    year,
                },
            });
        }
    }, [selectedSpot, getAggregatorReports, inputs.monthYear]);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Rentals',
            organizationId,
            spotId: selectedSpot,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    }, [organizationId, selectedSpot, profile]);

    return spotReports ? (
        <Rentals reports={spotReports} fields={inputs} handleDropdown={handleDropdown} loading={loading} />
    ) : (
        <Loader active inline />
    );
};

function mapStateToProps(state: AppState) {
    return {
        errorMessage: state.reports.errorMessage,
        loading: state.reports.loading,
        aggregatorReports: state.reports.aggregatorReports,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedSpot: selectedSpotId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    getAggregatorReports: getAggregatorReportsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalsModuleContainer);
