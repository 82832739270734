import React, { useState } from 'react';
import { Icon, Header, Label, Grid } from 'semantic-ui-react';
import amplitude from 'amplitude-js';
import { SpotInterface } from 'types';
import { RentType } from 'utils/constants';
import { baseUrl } from 'utils/helpers';
interface SpotsProps {
    spots: SpotInterface[];
}

const Advertising = (props: SpotsProps) => {
    const { spots } = props;
    const [linkCopied, setLinkCopied] = useState(false);
    const copyToClipboard = (link: string) => {
        amplitude.getInstance().logEvent('Copied advertising link');
        void navigator.clipboard.writeText(link);
        setLinkCopied(true);
    };
    const openNewTab = (link: string) => {
        amplitude.getInstance().logEvent('Open advertising link in new tab');
        window.open(link);
    };

    const spotRef = 'advertising-link';
    const paymentLink = (spot: SpotInterface) => baseUrl(RentType.hourly, spot.pk, spotRef);
    const monthlyLink = (spot: SpotInterface) => baseUrl(RentType.monthly, spot.pk, spotRef);
    const visitorLink = (spot: SpotInterface) => 'https://parking.airgarage.com/visitor/' + spot.pk;

    return (
        <>
            <div />
            <Header
                as="h5"
                content="Place the following links on your website or share them with your community for your convenience."
            />
            {linkCopied && <Label color="green">Link copied!</Label>}
            {spots &&
                spots.map((spot: SpotInterface) => (
                    <React.Fragment key={spot.pk}>
                        <h3>{spot.name} Links</h3>
                        <Grid columns={spot.price_monthly ? 3 : 2} divided>
                            <Grid.Row>
                                <Grid.Column>
                                    <Icon name="credit card" />
                                    <Header as="h5">AirGarage hourly parking payment form</Header>
                                    <a href="# " onClick={() => copyToClipboard(paymentLink(spot))}>
                                        <p>
                                            <Icon name="clipboard" />
                                            Copy link to clipboard
                                        </p>
                                    </a>
                                    <br></br>
                                    <a href="# " onClick={() => openNewTab(paymentLink(spot))}>
                                        <p>
                                            <Icon name="share square" />
                                            Open link in new tab
                                        </p>
                                    </a>
                                </Grid.Column>
                                {spot.price_monthly && (
                                    <Grid.Column>
                                        <Icon name="calendar" />
                                        <Header as="h5">AirGarage monthly parking form</Header>
                                        <a href="# " onClick={() => copyToClipboard(monthlyLink(spot))}>
                                            <p>
                                                <Icon name="clipboard" />
                                                Copy link to clipboard
                                            </p>
                                        </a>
                                        <br></br>
                                        <a href="# " onClick={() => openNewTab(monthlyLink(spot))}>
                                            <p>
                                                <Icon name="share square" />
                                                Open link in new tab
                                            </p>
                                        </a>
                                    </Grid.Column>
                                )}
                                <Grid.Column>
                                    <Icon name="check square" />
                                    <Header as="h5">Visitor registration form</Header>
                                    <a href="# " onClick={() => copyToClipboard(visitorLink(spot))}>
                                        <p>
                                            <Icon name="clipboard" />
                                            Copy link to clipboard
                                        </p>
                                    </a>
                                    <br></br>
                                    <a href="# " onClick={() => openNewTab(visitorLink(spot))}>
                                        <p>
                                            <Icon name="share square" />
                                            Open link in new tab
                                        </p>
                                    </a>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </React.Fragment>
                ))}
        </>
    );
};
export default Advertising;
