import {
    ORGANIZATION_REQUESTED,
    ORGANIZATION_FAILED,
    ORGANIZATION_SUCCESS,
    ADD_ORGANIZATION_REQUESTED,
    ADD_ORGANIZATION_FAILED,
    ADD_ORGANIZATION_SUCCESS,
    ORGANIZATION_LIST_REQUESTED,
    ORGANIZATION_LIST_SUCCESS,
    ORGANIZATION_LIST_FAILED,
    SALES_REPS_SUCCESS,
    SALES_REPS_FAILED,
} from 'actions/constants';
import { Organization, OrganizationListObj } from 'types';

export type OrganizationState = {
    organization: Organization | null;
    organizations: OrganizationListObj[] | null;
    errorMessage: string;
    loading: boolean;
    salesReps: null | any[];
};

export const initialOrganizationState = {
    organization: null,
    organizations: null,
    errorMessage: '',
    loading: false,
    salesReps: null,
};

export default function organizationReducer(
    state: OrganizationState = initialOrganizationState,
    action: any
): OrganizationState {
    const newState = { ...state };
    switch (action.type) {
        case ORGANIZATION_REQUESTED:
            newState.loading = true;
            newState.organization = null;
            newState.errorMessage = '';
            return newState;
        case ORGANIZATION_SUCCESS:
            newState.loading = false;
            newState.organization = action.payload.organization;
            return newState;
        case ORGANIZATION_FAILED:
            newState.loading = false;
            return newState;
        case ORGANIZATION_LIST_REQUESTED:
            newState.loading = true;
            return newState;
        case ORGANIZATION_LIST_SUCCESS:
            newState.loading = false;
            newState.organizations = action.payload.organizations;
            return newState;
        case ORGANIZATION_LIST_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case ADD_ORGANIZATION_REQUESTED:
            newState.loading = true;
            return newState;
        case ADD_ORGANIZATION_SUCCESS:
            newState.loading = false;
            newState.organization = action.payload.organization;
            return newState;
        case ADD_ORGANIZATION_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        case SALES_REPS_SUCCESS:
            newState.loading = false;
            newState.salesReps = action.payload.salesReps;
            return newState;
        case SALES_REPS_FAILED:
            newState.loading = false;
            newState.errorMessage = action.message;
            return newState;
        default:
            return state || initialOrganizationState;
    }
}
