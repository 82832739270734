import React from 'react';
import { List } from 'semantic-ui-react';
import styles from './Footer.module.css';

const Footer = (props: any) => (
    <div className={styles.center}>
        <p>©{new Date().getFullYear()} AirGarage, Inc</p>
        <List bulleted horizontal>
            <List.Item as="a" href="https://airgarage.com/">
                Homepage
            </List.Item>
            <List.Item as="a" href="https://airgarage.com/terms">
                Terms
            </List.Item>
            <List.Item as="a" href="https://help.airgarage.com/hc/en-us/requests/new">
                Contact us
            </List.Item>
        </List>
    </div>
);

export default Footer;
