import React from 'react';
import { Dropdown, Popup, Button, Divider, DropdownItemProps } from 'semantic-ui-react';
import { SpotInterface } from 'types';
import { rentalTypesForReports } from 'utils/constants';
import MonthYearUtil, { MonthYear } from 'utils/monthYear';
import DownloadReportModal from '../DownloadReportModal/DownloadReportModal';

type ReportMenuProps = {
    spot: SpotInterface;
    handleRentalTypeFilterSelected: (rentalType: keyof typeof rentalTypesForReports) => void;
    handleDateFilterSelected: (monthYear?: string) => void;
    fields: {
        rentalType: keyof typeof rentalTypesForReports;
        monthYear: MonthYear['value'];
    };
};
const ReportMenu = (props: ReportMenuProps) => {
    const monthYearUtil = new MonthYearUtil();
    const { spot, handleRentalTypeFilterSelected, handleDateFilterSelected, fields } = props;
    const { rentalType, monthYear } = fields;

    const rentalTypeOptions = Object.keys(rentalTypesForReports).map(
        (v: string): DropdownItemProps => ({
            value: v,
            text: rentalTypesForReports[v as keyof typeof rentalTypesForReports],
        })
    );

    return (
        <>
            <Popup
                on="click"
                pinned
                position="bottom center"
                trigger={<Button color="blue" icon="filter" content="Filters" />}
                content={
                    <>
                        <Dropdown
                            name="rentalType"
                            placeholder="Filter rental type"
                            selection
                            onChange={(e, data) =>
                                handleRentalTypeFilterSelected(data.value as keyof typeof rentalTypesForReports)
                            }
                            options={rentalTypeOptions}
                            value={rentalType}
                            style={{ marginTop: 10 }}
                        />
                        <Divider />
                        <Dropdown
                            name="monthYears"
                            placeholder="Select month and year"
                            selection
                            onChange={(e, data) => handleDateFilterSelected(data.value as string)}
                            options={monthYearUtil.monthYears}
                            value={monthYear}
                            style={{ marginTop: 10 }}
                        />
                    </>
                }
            />
            <DownloadReportModal spot={spot} />
        </>
    );
};

export default ReportMenu;
