import APIClient from './APIClient';
import { AffiliationRole } from '../utils/constants';

export type AddTeamMemberInput = {
    first_name: string;
    last_name: string;
    email: string;
    role: AffiliationRole;
};
class TeamService {
    getTeam(token: string, organizationId?: number) {
        let url = `invitations/`;
        if (organizationId) {
            url += `?organization=${organizationId}`;
        }
        return APIClient.get(url, token);
    }
    sendInvite(invitation: AddTeamMemberInput, token: string) {
        return APIClient.post(`invitations/`, invitation, token);
    }
    deleteInvite(invitationId: number, token: string) {
        return APIClient.delete(`invitations/${invitationId}/`, token);
    }
}

export default new TeamService();
