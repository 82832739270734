import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getOrganizationsAction, getSpotListAction } from 'actions/actions';
import SuperuserMenu from 'components/Dashboard/Modules/SuperuserMenu';
import { SpotInterface } from 'types';
import { AppState } from 'reducers/reducers';

type OwnProps = {
    selectedSpotInfo: SpotInterface | null;
    navigateToTab: (tabUrl: string) => void;
};
type SuperUserMenuContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

const SuperuserMenuContainer = ({
    getOrganizations,
    organization,
    organizations,
    navigateToTab,
    spotList,
    getSpotList,
    selectedSpotInfo,
}: SuperUserMenuContainerProps) => {
    useEffect(() => {
        getOrganizations();
    }, [getOrganizations]);

    useEffect(() => {
        getSpotList();
    }, [getSpotList]);

    return organizations ? (
        <SuperuserMenu
            organization={organization}
            navigateToTab={navigateToTab}
            organizations={organizations}
            spotList={spotList}
            spot={selectedSpotInfo}
        />
    ) : (
        <Loader inline active />
    );
};

const mapDispatchToProps = {
    getOrganizations: getOrganizationsAction,
    getSpotList: getSpotListAction,
};

function mapStateToProps(state: AppState) {
    return {
        organizations: state.organization.organizations,
        organization: state.organization.organization,
        spotList: state.spots.spotList,
        spots: state.spots.orgSpots,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperuserMenuContainer);
