import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { getSpotsAction } from 'actions/actions';
import Advertising from 'components/Dashboard/Modules/Advertising';
import { AppState } from 'reducers/reducers';
import { selectedOrganizationId } from 'selectors';
import { trackPageViewed } from 'utils/analytics';

type AdvertisingModuleContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
const AdvertisingModuleContainer = ({
    getSpots,
    orgSpots,
    loading,
    organizationId,
    profile,
}: AdvertisingModuleContainerProps) => {
    useEffect(() => {
        if (orgSpots === null && !loading) {
            getSpots();
        }
    }, [getSpots, orgSpots, loading]);

    useEffect(() => {
        trackPageViewed({
            pageName: 'Advertising',
            organizationId,
            spotId: null,
            affiliationRole: profile.affiliation_role,
            superuser: profile.superuser,
        });
    });

    return (
        <div>
            {orgSpots ? (
                <div>
                    <Advertising spots={Object.values(orgSpots)} />
                </div>
            ) : (
                <Loader active inline />
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        loading: state.spots.orgSpotsLoading,
        orgSpots: state.spots.orgSpots,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organizationId: selectedOrganizationId(state)!,
    };
}

const mapDispatchToProps = {
    getSpots: getSpotsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingModuleContainer);
