import React, { HTMLAttributes } from 'react';
import styles from './AGMessage.module.css';
import { ReactComponent as InfoIcon } from 'assets/icons/infoFill.svg';

export type AGMessageColor = 'error' | 'success' | 'info' | 'warning';

interface AGMessageProps extends HTMLAttributes<HTMLDivElement> {
    color?: AGMessageColor;
    title?: string;
}

function AGMessage({ color = 'info', title, children, className, ...restProps }: AGMessageProps) {
    return (
        <div className={`${styles.wrapper} ${styles[color]} ${className}`} {...restProps}>
            {title && (
                <div className={styles.titleWrapper}>
                    <InfoIcon />
                    <span className={styles.title}>{title}</span>
                </div>
            )}
            {children}
        </div>
    );
}

export default AGMessage;
