import React from 'react';
import { connect } from 'react-redux';
import { Button, Loader } from 'semantic-ui-react';
import { AppState } from '../reducers/reducers';
import { selectedSpotInfo } from '../selectors';
import env from 'env';

type SlotsModuleContainerProps = ReturnType<typeof mapStateToProps>;
const SlotsModuleContainer = (props: SlotsModuleContainerProps) => {
    const { spot, superuser } = props;

    if (!spot) {
        return <Loader active inline />;
    }

    const invoice_link = `${env.DJANGO_ADMIN}spots/slot/custom_invoice_creation/${spot.pk}`;

    return (
        <div>
            {superuser && (
                <>
                    <h4>Create a custom invoice for {spot.name}.</h4>
                    <Button
                        content="Create an Invoice"
                        onClick={() => {
                            window.open(invoice_link, '_blank');
                        }}
                    />
                </>
            )}
        </div>
    );
};

function mapStateToProps(state: AppState) {
    return {
        spot: selectedSpotInfo(state),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        superuser: state.auth.profile!.superuser,
    };
}

export default connect(mapStateToProps)(SlotsModuleContainer);
