import React from 'react';
import styles from './Layout.module.css';

interface LayoutProps {
    children?: React.ReactNode;
}

function Layout({ children }: LayoutProps): JSX.Element {
    return <div className={styles.container}>{children}</div>;
}

export default Layout;
