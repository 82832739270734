import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import styles from './WarningBox.module.css';

interface WarningBoxProps {
    title?: string;
    type?: 'info' | 'warning' | 'error';
    children?: React.ReactNode;
}

function WarningBox({ title, type = 'info', children }: WarningBoxProps) {
    const iconFill = {
        info: 'var(--ag-primary)',
        warning: 'var(--ag-primary)',
        error: '#dc2626',
    };

    return (
        <div className={`${styles.warningBox} ${styles[type]}`}>
            <div>
                <div className={styles.warningBoxIcon}>
                    <WarningIcon style={{ color: iconFill[type] }} />
                </div>
            </div>
            <div>
                {title && <h4 className={styles.warningBoxTitle}>{title}</h4>}
                <p className={styles.warningBoxMessage}>{children}</p>
            </div>
        </div>
    );
}

export default WarningBox;
