import React, { useState } from 'react';
import { Button, Form, Message, Image, Header, Segment, Icon, Grid } from 'semantic-ui-react';
import useForm from './../../hooks/useForm';
import ConfettiAnimation from '../Decorations/ConfettiAnimation';
import SpotPricingFields from './SpotPricingFields';
import SpotOpsFields from './SpotOpsFields';
import SpotSalesFields from './SpotSalesFields';
import SpotServicesFields from './SpotServicesFields';
import { classificationTypes } from './../../utils/constants';

const ListForm = (props: any) => {
    const { addSpot, uploadSpotPhoto, photo_url: photoUrl, spotPhotoLoading, organization, salesReps } = props;
    const [showConfetti, setShowConfetti] = useState(false);
    const { inputs, handleInput, handleDropdown, handleValueChange } = useForm({
        name: '',
        address_text: '',
        quantity: '',
        quantity_monthly: null,
        price_hourly: '',
        price_monthly: null,
        price_daily: null,
        daily_max: '',
        source: '',
        tier: '',
        services_agreement_date: '',
        visitor_registration_phone: null,
        payment_phone: null,
        account_executive: '',
        sales_development_representative: '',
        deal_type: '',
        lease_amount: null,
        hourly_commission: null,
        daily_commission: null,
        monthly_commission: null,
        active: false,
        hidden: true,
        tax_percent: 0,
        surge_percent: 100,
        classification: '',
        lpr_service: false,
        cleaning_service: false,
        security_service: false,
        snow_service: false,
        utilities_service: false,
    });

    const fields = inputs as any;
    const fileInputRef = React.createRef() as any;

    const handleSpotPhotoUpload = (e: any) => {
        uploadSpotPhoto(e.target.files[0]);
    };

    return organization ? (
        <div>
            {props.errorMessage && <Message color="red">{props.errorMessage}</Message>}
            {props.successMessage && <Message color="green">{props.successMessage}</Message>}
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Form.Input
                                        icon="at"
                                        iconPosition="left"
                                        name="name"
                                        placeholder="AirBeach Garage"
                                        onChange={handleInput}
                                        value={fields.name}
                                        label="Spot name"
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        icon="map marker alternate"
                                        iconPosition="left"
                                        name="address_text"
                                        placeholder="4616 Eagle Rock Blvd. Los Angeles, CA 90041"
                                        onChange={handleInput}
                                        value={fields.address_text}
                                        label="Address"
                                        required
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Form.Checkbox
                                        name="active"
                                        label="Mark this spot as active?"
                                        onChange={() => {
                                            handleValueChange('active', !fields.active);
                                        }}
                                        checked={fields.active}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                        name="hidden"
                                        label="Hide this spot?"
                                        onChange={() => {
                                            handleValueChange('hidden', !fields.hidden);
                                        }}
                                        checked={fields.hidden}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <Form.Input
                                        name="tax_percent"
                                        placeholder="1000"
                                        onChange={handleInput}
                                        value={fields.tax_percent}
                                        label="Tax percent (0.01% precision)"
                                        type="number"
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        name="surge_percent"
                                        placeholder="100"
                                        onChange={handleInput}
                                        value={fields.surge_percent}
                                        label="Surge percent"
                                        type="number"
                                        required
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <Form.Select
                                    label="Classification"
                                    name="classification"
                                    placeholder="Select a classification"
                                    selection
                                    options={classificationTypes}
                                    fluid
                                    onChange={handleDropdown}
                                    required
                                />
                            </Form.Field>
                            <SpotPricingFields handleInput={handleInput} fields={fields} />

                            <SpotOpsFields handleInput={handleInput} handleDropdown={handleDropdown} fields={fields} />

                            <SpotServicesFields handleValueChange={handleValueChange} fields={fields} />

                            <SpotSalesFields
                                handleInput={handleInput}
                                handleDropdown={handleDropdown}
                                fields={fields}
                                salesReps={salesReps}
                            />

                            <input ref={fileInputRef} type="file" hidden onChange={handleSpotPhotoUpload} />
                            <Button
                                fluid
                                type="submit"
                                content="Create Spot"
                                loading={props.loading}
                                onClick={() => {
                                    setShowConfetti(!showConfetti);
                                    addSpot({ ...inputs, photo_url: photoUrl });
                                }}
                                disabled={
                                    !inputs.name ||
                                    !inputs.address_text ||
                                    !inputs.quantity ||
                                    !inputs.price_hourly ||
                                    !inputs.daily_max ||
                                    spotPhotoLoading
                                }
                            />
                            <div style={{ marginLeft: '50%' }}>
                                <ConfettiAnimation active={showConfetti} />
                            </div>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        {photoUrl ? (
                            <>
                                <Image src={photoUrl} fluid rounded centered />
                                <br></br>
                                <a
                                    href="# "
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                >
                                    <Icon name="redo" />
                                    Re-upload different photo
                                </a>
                            </>
                        ) : (
                            <Segment placeholder style={{ maxHeight: 300 }}>
                                <Header icon>
                                    <Icon name="file image outline" />
                                    No photo is listed for this spot.
                                </Header>
                                <Button
                                    color="green"
                                    icon={'add'}
                                    content={'Upload'}
                                    onClick={() => {
                                        fileInputRef.current.click();
                                    }}
                                    loading={spotPhotoLoading}
                                    fluid
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    ) : (
        <p>Select or create a new organization to continue</p>
    );
};

export default ListForm;
