import React from 'react';
import InfoTooltip from '../Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import styles from './RevenueWidget.module.css';
import LineUpIcon from 'components/Icons/LineUpIcon';

export type UpliftProps = {
    dynamicPricing?: string;
    enforcement?: string;
    marketing?: string;
    months: number;
};

function Uplift({ dynamicPricing, enforcement, marketing, months }: UpliftProps): JSX.Element {
    const TOOLTIP_MESSAGE = `Revenue lift provided by each AirGarage feature over the past ${months} months`;
    if (!dynamicPricing && !enforcement && !marketing) return <></>;

    return (
        <div className={styles.upliftWrapper}>
            <div className={styles.upliftTitleWrapper}>
                <LineUpIcon />
                <span className={styles.upliftTitle}>{months}-Month Revenue Uplift</span>
                <InfoTooltip title="Revenue Uplift" text={TOOLTIP_MESSAGE} />
            </div>
            {enforcement && (
                <span className={styles.upliftLabel}>
                    <span className={styles.upliftPercent}>{enforcement}</span>Enforcement
                </span>
            )}
            {dynamicPricing && (
                <span className={styles.upliftLabel}>
                    <span className={styles.upliftPercent}>{dynamicPricing}</span>Dynamic Pricing
                </span>
            )}
            {marketing && (
                <span className={styles.upliftLabel}>
                    <span className={styles.upliftPercent}>{marketing}</span>Marketing
                </span>
            )}
        </div>
    );
}

export default Uplift;
