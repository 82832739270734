import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SpotInterface } from 'types';
import WidgetBox from '../widgets/WidgetBox';
import CurrentRates from './CurrentRates';

type StandardPricingWidgetProps = {
    spot: SpotInterface;
};

function StandardPricingWidget({ spot }: StandardPricingWidgetProps): JSX.Element {
    return (
        <WidgetBox title="">
            <Grid>
                <Grid.Column>
                    <CurrentRates spot={spot} rates={null} />
                </Grid.Column>
            </Grid>
        </WidgetBox>
    );
}

export default StandardPricingWidget;
