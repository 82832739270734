import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';

function IconButton({ children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button {...props} className={`${styles.iconButton} ${props.className ? props.className : ''}`}>
            {children}
        </button>
    );
}

export default IconButton;
