import React from 'react';
import styles from './WidgetDataLabel.module.css';
import InfoTooltip from '../Dashboard/SpotCard/InfoTooltip/InfoTooltip';

type WidgetHeaderProps = {
    label: string;
    tooltip?: {
        title: string;
        message: string;
    };
};

function WidgetDataLabel({ label, tooltip }: WidgetHeaderProps): JSX.Element {
    return (
        <>
            <span className={styles.widgetHeaderTitle}>{label}</span>
            {tooltip && <InfoTooltip title={tooltip.title} text={tooltip.message} />}
        </>
    );
}

export default WidgetDataLabel;
