import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Loader } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import Sidebar from '../containers/Sidebar';
import DashboardContent from '../components/Dashboard/DashboardContent/DashboardContent';
import HelpCenter from '../components/Dashboard/HelpCenter/HelpCenter';

type MainViewProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const MainView = ({ organization, profile, push: connectedPush }: MainViewProps): JSX.Element => {
    const helpCenterRouteMatch = useRouteMatch('/help-center');
    useEffect(() => {
        if (profile.affiliation && !helpCenterRouteMatch) {
            connectedPush(`/organization/${profile.affiliation}`);
        }
    }, [organization, profile.affiliation, connectedPush, helpCenterRouteMatch]);

    const renderFallback = () => {
        if (!organization) {
            if (profile.superuser) {
                return (
                    <Card>
                        <Card.Header>Hello {profile.first_name}</Card.Header>
                    </Card>
                );
            }
            return <Loader />;
        }
    };

    return (
        <Sidebar>
            <Route path="/help-center">
                <DashboardContent title={'Help Center'}>
                    <HelpCenter />
                </DashboardContent>
            </Route>
            <Route path="/">{renderFallback()}</Route>
        </Sidebar>
    );
};

function mapStateToProps(state: any) {
    return {
        organization: state.organization.organization,
        profile: state.auth.profile,
    };
}

const mapDispatchToProps = {
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainView);
