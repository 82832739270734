import { getOrdinalDayOfWeekInMonthWord } from '../../../utils/helpers';
import { EventInterval } from 'types/Event';
import { FormattedEvent } from 'contexts/EventsContext/utils';
import { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import { EventFormInputs } from 'contexts/EventsContext';

export function getRecurrenceDropdown(date: Date, dayOfWeek: string): SelectOption[] {
    const ordinal = getOrdinalDayOfWeekInMonthWord(date);
    return [
        { label: 'One Time', value: EventInterval.OneTime },
        { label: 'Daily', value: EventInterval.Daily },
        { label: `Weekly on ${dayOfWeek}`, value: EventInterval.Weekly },
        { label: `Monthly on the ${ordinal} ${dayOfWeek}`, value: EventInterval.Monthly },
    ];
}

export function createInitialInputState(selectedEvent: FormattedEvent | null): EventFormInputs {
    const today = new Date();
    const defaultRecurrence = EventInterval.OneTime;

    if (selectedEvent) {
        return {
            name: selectedEvent.name,
            selectedDate: new Date(selectedEvent.date_string.replace(/-/g, '/')),
            startTime: selectedEvent.start_time,
            endTime: selectedEvent.end_time,
            recurrence: selectedEvent.interval,
            numSpotsAvailable: selectedEvent.quantity || 0,
            existingVisitorCodePks: selectedEvent.codes.map((code: number) => code.toString()),
            is_lot_closed: selectedEvent.is_lot_closed,
            newVisitorCodeName: '',
            newVisitorCodeCode: '',
        };
    } else
        return {
            name: '',
            selectedDate: today,
            startTime: '09:00:00',
            endTime: '17:00:00',
            recurrence: defaultRecurrence,
            numSpotsAvailable: 0,
            existingVisitorCodePks: [],
            newVisitorCodeName: '',
            newVisitorCodeCode: '',
            is_lot_closed: true,
        };
}
