import {
    ADD_PROMO_CODE_REQUESTED,
    ADD_PROMO_CODE_SUCCESS,
    ADD_PROMO_CODE_FAILED,
    PROMO_CODES_REQUESTED,
    PROMO_CODES_SUCCESS,
    PROMO_CODES_FAILED,
    DELETE_PROMO_CODE_REQUESTED,
    DELETE_PROMO_CODE_SUCCESS,
    DELETE_PROMO_CODE_FAILED,
} from '../actions/constants';
import { PaginatedPromoCodes } from 'types';

const cloneObject = function (obj: any) {
    return JSON.parse(JSON.stringify(obj));
};

export type PromoCodesState = {
    loading: boolean;
    errorMessage: string;
    promoCodes: Record<number, PaginatedPromoCodes>;
};

export const initialPromoCodeState: PromoCodesState = {
    loading: false,
    errorMessage: '',
    promoCodes: {},
};

export default function promoCodesReducer(state = initialPromoCodeState, action: any): PromoCodesState {
    const newState = cloneObject(state);
    const selectedSpotID = action.selectedSpotID;
    switch (action.type) {
        case PROMO_CODES_REQUESTED:
            newState.loading = true;
            return newState;
        case PROMO_CODES_SUCCESS:
            newState.loading = false;
            newState.promoCodes[selectedSpotID] = action.payload.promoCodes;
            newState.errorMessage = '';
            return newState;
        case PROMO_CODES_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case ADD_PROMO_CODE_REQUESTED:
            newState.loading = true;
            return newState;
        case ADD_PROMO_CODE_SUCCESS:
            newState.loading = false;
            newState.promoCodes[selectedSpotID] = action.payload.promoCodes;
            newState.errorMessage = '';
            return newState;
        case ADD_PROMO_CODE_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        case DELETE_PROMO_CODE_REQUESTED:
            newState.loading = true;
            return newState;
        case DELETE_PROMO_CODE_SUCCESS:
            newState.loading = false;
            newState.promoCodes[selectedSpotID] = action.payload.promoCodes;
            return newState;
        case DELETE_PROMO_CODE_FAILED:
            newState.errorMessage = action.message;
            newState.loading = false;
            return newState;
        default:
            return state || initialPromoCodeState;
    }
}
